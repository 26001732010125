import React, { useState, useEffect, FC } from 'react'

import { swap } from 'react-grid-dnd'
import {
  CreateInformerListCardMutation,
  CreateInformerListCardMutationVariables,
  CreateInformerListCardToMembershipGroupMutation,
  CreateInformerListCardToMembershipGroupMutationVariables,
  CreateInformerListCardToMembershipMutation,
  CreateInformerListCardToMembershipMutationVariables,
  DeleteInformerListCard,
  DeleteInformerListCardToMembershipGroupMutation,
  DeleteInformerListCardToMembershipGroupMutationVariables,
  DeleteInformerListCardToMembershipMutation,
  DeleteInformerListCardToMembershipMutationVariables,
  DeleteInformerListCardVariables,
  UpdateInformerListCardMutation,
  UpdateInformerListCardMutationVariables,
  UpdateInformerListCardsMutation,
  UpdateInformerListCardsMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import {
  InformerSection,
  Membership,
  MembershipGroup,
  SettingsLayoutIcon,
} from 'src/components/SettingsLayoutSection/SettingsLayoutSectionCell'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import {
  CREATE_CARD_ITEM,
  DELETE_CARD_ITEM,
  CREATE_CARD_TO_GROUP,
  DELETE_CARD_TO_GROUP,
  CREATE_CARD_TO_MEMBERSHIP,
  DELETE_CARD_TO_MEMBERSHIP,
  UPDATE_CARD_ITEM,
  UPDATE_CARD_ITEMS,
} from 'src/lib/queries/Settings/Layout/SettingsLayout'
import { ListManager } from 'src/lib/react-beautiful-dnd-grid'
import { SelectedSection } from 'src/pages/SettingsClientToolsPage/SettingsClientToolsPage'
import { filterArray } from 'src/Util'

import HeroCard from '../../HeroCard/HeroCard'
import {
  QUERY,
  SectionCard,
  SettingsLayoutCardRefetch,
} from '../SettingsLayoutCardCell'
import SectionCardsHeader from '../SettingsLayoutCardsHeader'
import { CardDefaultOptionsModal } from '../SettingsLayoutCardStackModals/SettingsLayoutCardDefaultOptionsModal'
import { CardAdminModal } from '../SettingsLayoutCardStackModals/SettingsLayoutCardStackModals'
import SettingsLayoutCardStackSingle from '../SettingsLayoutCardStackSingle/SettingsLayoutCardStackSingle'
import SettingsLayoutCardViewSelector from '../SettingsLayoutCardViewSelector/SettingsLayoutCardViewSelector'

import iconTemplateBG from './iconTemplateBG.png'
import { Item } from './SettingsLayoutCardStackHelper'

interface FormattedOption {
  id: number
  name: string
  group: string
  avatarUrl?: string
}

const filterCardsBySearchTerms = (
  cards?: Item[],
  searchText?: string,
  searchViewAs?: FormattedOption,
  allMembers?: Membership[],
) => {
  const selectedMemberData = allMembers.reduce(
    (selectedMember, currentLoopMember) => {
      if (
        currentLoopMember?.id === searchViewAs?.id &&
        searchViewAs?.group === 'Membership'
      ) {
        selectedMember.id = currentLoopMember?.id
        selectedMember.membershipGroupIds =
          currentLoopMember?.membershipGroups.map(
            (group) => group.membershipGroup.id,
          )
      }
      return selectedMember
    },
    {
      id: null,
      membershipGroupIds: [],
    },
  )

  let cardsFilteredBySelectedOption = cards

  if (searchViewAs?.group === 'Membership Group') {
    // Filter by Membership Group -> only show cards that match the assigned group
    const cardsFilteredByMembershipGroupView = cards.reduce(
      (filteredCards, currentCard) => {
        // Find the cards that match the current group
        const currentCardMembershipGroupsMatch =
          currentCard?.membershipGroups.some((membershipGroup) => {
            return membershipGroup?.membershipGroupId === searchViewAs?.id
          })

        // Check if any membership groups are on the card
        const currentCardHasNoMembershipGroups =
          currentCard?.membershipGroups.length === 0

        // If no membership groups, or some match. Show the card
        if (
          currentCardHasNoMembershipGroups ||
          currentCardMembershipGroupsMatch
        ) {
          filteredCards.push(currentCard)
        }

        return filteredCards
      },
      [],
    )

    // Update the return array
    cardsFilteredBySelectedOption = cardsFilteredByMembershipGroupView
  } else if (searchViewAs?.group === 'Membership') {
    // Filter by Membership View -> only show cards with member assigned groups, cards assigned to user, global cards assigned to section with member assigned group
    // cardsFilteredBySelectedOption = cards

    const cardsFilteredByMembershipView = cards.reduce(
      (filteredCards, currentCard) => {
        // Check if card is global
        const cardHasNoGroupsAndNoAssigneeAttached =
          !currentCard?.membershipGroups.length && !currentCard?.members.length

        // Check if card is assigned to selected user
        const cardHasSelectedUserAttached = currentCard?.members.some(
          (member) => {
            return member.membershipId === selectedMemberData.id
          },
        )

        // Check if card is assigned to membership group user is assigned to
        const cardHasMembershipGroupFromSelectedUserAttached =
          currentCard?.membershipGroups.some((membershipGroup) => {
            return selectedMemberData?.membershipGroupIds.includes(
              membershipGroup.membershipGroupId,
            )
          })

        if (
          cardHasNoGroupsAndNoAssigneeAttached ||
          cardHasSelectedUserAttached ||
          cardHasMembershipGroupFromSelectedUserAttached
        ) {
          filteredCards.push(currentCard)
        }

        return filteredCards
      },
      [],
    )

    // Update the return array
    cardsFilteredBySelectedOption = cardsFilteredByMembershipView
  }

  // Filter items by search string
  const cardsFilteredBySearchText = cardsFilteredBySelectedOption.filter(
    (card) => {
      return card.name.toLowerCase().includes(searchText.toLowerCase())
    },
  )
  return cardsFilteredBySearchText
}

type Props = {
  sectionCards: SectionCard[]
  informerSections: InformerSection[]
  membershipGroups: MembershipGroup[]
  members: Membership[]
  globalIcons: SettingsLayoutIcon[]
  refetch: SettingsLayoutCardRefetch
  selectedSection: SelectedSection
  sectionedObjects?: object
  onboardingMode?: boolean
}

const SettingsLayoutCardStack: FC<Props> = ({
  sectionCards,
  informerSections,
  membershipGroups,
  members,
  globalIcons,
  refetch,
  selectedSection,
  sectionedObjects,
  onboardingMode = false,
}) => {
  // Analytics init
  const { trackEvent } = useAnalytics()

  // Select the cards based on selected section
  const cardsInSelectedSection = sectionedObjects?.[selectedSection?.id]
    ? sectionedObjects[selectedSection.id]?.items
    : []

  const formattedMembers = members.map((member) => {
    return {
      id: member.id,
      name: member?.user?.name,
      group: 'Membership',
      avatarUrl: member?.user?.avatarUrl,
    }
  })

  const formattedMemberships = membershipGroups.map((group) => {
    return { id: group.id, name: group?.name, group: 'Membership Group' }
  })

  const formattedOptions: FormattedOption[] =
    formattedMemberships.concat(formattedMembers)

  // View As options
  const [filterByOption, setFilterByOption] = useState(null)
  const [filterInputValue, setFilterInputValue] = useState<string>('')
  const [adminCardsTextSearchValue, setAdminCardsTextSearchValue] =
    useState<string>('')
  const [dragDropEnabled, setDragDropEnabled] = useState<boolean>(true)

  const [cardAdminModalOpen, setCardAdminModalOpen] = useState<boolean>(false)
  const [defaultCardOptionsModalOpen, setDefaultCardOptionsModalOpen] =
    useState<boolean>(false)

  const [deletingCardId, setDeletingCardId] = useState(null)
  const [items, setItems] = useState(
    filterCardsBySearchTerms(
      cardsInSelectedSection,
      adminCardsTextSearchValue,
      filterByOption,
      members,
    ),
  )
  const [modalAction, setModalAction] = useState('')
  const [currentlySaving, setCurrentlySaving] = useState<boolean>(false)
  const [currentlyDeleting, setCurrentlyDeleting] = useState<boolean>(false)
  const [newAssignedGroups, setNewAssignedGroups] = useState<any[]>([])
  const [newAssignedMemberships, setNewAssignedMemberships] = useState<any[]>(
    [],
  )
  const [selectedCard, setSelectedCard] = useState(null)

  // Mutation Functions
  const [createCardItemMutation] = useMutation<
    CreateInformerListCardMutation,
    CreateInformerListCardMutationVariables
  >(CREATE_CARD_ITEM, {
    onCompleted: async (item) => {
      toast.success('Card Created', {
        duration: 2000,
      })
      const newData = item.createInformerListCard

      // Create records for new assigned groups
      if (newAssignedGroups) {
        for (const groupItem of newAssignedGroups) {
          createCardToGroupMutation({
            variables: {
              input: {
                informerListCardId: newData.id,
                membershipGroupId: parseInt(groupItem),
              },
            },
          })
        }
      }

      // Create Records for new Assigned Members
      if (newAssignedMemberships) {
        for (const memberItem of newAssignedMemberships) {
          createCardToMembershipMutation({
            variables: {
              input: {
                informerListCardId: newData.id,
                membershipId: parseInt(memberItem),
              },
            },
          })
        }
      }

      await refetch()
      setCardAdminModalOpen(false)
      setCurrentlySaving(false)
    },
    onError: (error) => {
      toast.error(' Error creating Card \n \n' + error.message, {
        duration: 5000,
      })
      setCurrentlySaving(false)
    },
  })

  const [createCardToGroupMutation] = useMutation<
    CreateInformerListCardToMembershipGroupMutation,
    CreateInformerListCardToMembershipGroupMutationVariables
  >(CREATE_CARD_TO_GROUP, {
    onCompleted: () => {
      setNewAssignedGroups([])
    },
    onError: (error) => {
      toast.error('Error assigning card to group \n \n' + error.message, {
        duration: 5000,
      })
    },
  })

  const [createCardToMembershipMutation] = useMutation<
    CreateInformerListCardToMembershipMutation,
    CreateInformerListCardToMembershipMutationVariables
  >(CREATE_CARD_TO_MEMBERSHIP, {
    onCompleted: (_) => {
      setNewAssignedMemberships([])
    },
    onError: (error) => {
      toast.error('Error assigning card to member \n \n' + error.message, {
        duration: 5000,
      })
    },
  })

  function createCardItem(args) {
    // Get the icon ID
    const iconStorageObjectId = args.data?.iconStorageObject?.id
      ? Number(args.data?.iconStorageObject?.id)
      : null

    createCardItemMutation({
      variables: {
        input: {
          name: args.data.name,
          iconStorageObjectId: iconStorageObjectId,
          url: args.data.url,
          order: items?.length + 1,
          isAll: args.data.isAll,
          isEmbed: args.data.isEmbed,
          informerSectionId: parseInt(args.data.section),
        },
      },
    })
  }

  const [deleteCardItemMutation] = useMutation<
    DeleteInformerListCard,
    DeleteInformerListCardVariables
  >(DELETE_CARD_ITEM, {
    onCompleted: async () => {
      toast.success('Card Deleted', {
        duration: 2000,
      })
      await refetch()
      setCurrentlyDeleting(false)
      setCardAdminModalOpen(false)
    },
    onError: (error) => {
      toast.error('Error deleting your card \n \n' + error.message, {
        duration: 5000,
      })
      setCurrentlyDeleting(false)
    },
  })

  const [deleteCardToGroupMutation] = useMutation<
    DeleteInformerListCardToMembershipGroupMutation,
    DeleteInformerListCardToMembershipGroupMutationVariables
  >(DELETE_CARD_TO_GROUP, {
    onError: (error) => {
      toast.error(
        'Error un-assigning your card from the group \n \n' + error.message,
        {
          duration: 5000,
        },
      )
      setDeletingCardId(null)
    },
  })

  const [deleteCardToMembershipMutation] = useMutation<
    DeleteInformerListCardToMembershipMutation,
    DeleteInformerListCardToMembershipMutationVariables
  >(DELETE_CARD_TO_MEMBERSHIP, {
    onError: (error) => {
      toast.error(
        'Error un-assigning your card from the group \n \n' + error.message,
        {
          duration: 5000,
        },
      )
      setDeletingCardId(null)
    },
  })

  function deleteCardItem(id: number) {
    // Now deleting
    setCurrentlyDeleting(true)

    // Delete card by ID
    deleteCardItemMutation({
      variables: {
        id: id,
      },
    })
  }

  const [updateCardItemMutation] = useMutation<
    UpdateInformerListCardMutation,
    UpdateInformerListCardMutationVariables
  >(UPDATE_CARD_ITEM, {
    onCompleted: async () => {
      toast.success('Card Updated', {
        duration: 2000,
      })

      const newCardGroupIds = newAssignedGroups
      const selectedCardGroupIds: number[] = selectedCard?.membershipGroups.map(
        (group) => group.membershipGroupId,
      )

      // If the new assignee group field is empty and selected card has some groups already > delete all
      // Cards with no assignee and no new assignee will skip this section
      if (!newCardGroupIds.length && selectedCard?.membershipGroups) {
        // Delete All Records
        const deletePromises = selectedCard.membershipGroups.map((group) =>
          deleteCardToGroupMutation({
            variables: {
              id: group.id,
            },
          }),
        )

        await Promise.all(deletePromises)
      } else {
        const createCardToGroupList = filterArray(
          newCardGroupIds,
          selectedCardGroupIds,
        )
        const deleteCardToGroupList = filterArray(
          selectedCardGroupIds,
          newCardGroupIds,
        )

        // Create the new records
        const createPromises = createCardToGroupList.map((groupId) =>
          createCardToGroupMutation({
            variables: {
              input: {
                informerListCardId: selectedCard.id,
                membershipGroupId: parseInt(groupId),
              },
            },
          }),
        )

        await Promise.all(createPromises)

        // Delete the records that are no longer required
        let deleteId = null
        for (const deleteGroupId of deleteCardToGroupList) {
          for (const groupItem of selectedCard?.membershipGroups || []) {
            if (groupItem.membershipGroupId === deleteGroupId) {
              deleteId = groupItem.id
              break
            }
          }

          // Item found, delete it
          if (deleteId) {
            await deleteCardToGroupMutation({
              variables: {
                id: deleteId,
              },
            })
          }

          // Reset delete id
          deleteId = null
        }
      }

      const newCardMembershipIds = newAssignedMemberships
      const selectedCardMembershipIds = selectedCard?.members.map((member) => {
        return member.membershipId
      })

      if (!newCardMembershipIds.length && selectedCard?.members) {
        // Delete All Records
        const deletePromises = selectedCard.members.map((member) =>
          deleteCardToMembershipMutation({
            variables: {
              id: member.id,
            },
          }),
        )

        await Promise.all(deletePromises)
      } else {
        const createCardToMembershipList = filterArray(
          newCardMembershipIds,
          selectedCardMembershipIds,
        )
        const deleteCardToMembershipList = filterArray(
          selectedCardMembershipIds,
          newCardMembershipIds,
        )

        // Create the new records
        const createPromises = createCardToMembershipList.map((membershipId) =>
          createCardToMembershipMutation({
            variables: {
              input: {
                informerListCardId: selectedCard.id,
                membershipId: parseInt(membershipId),
              },
            },
          }),
        )

        await Promise.all(createPromises)

        // Delete the records that are no longer required
        let deleteId = null
        for (const deleteMembershipId of deleteCardToMembershipList) {
          for (const membership of selectedCard?.members || []) {
            if (membership.membershipId === deleteMembershipId) {
              deleteId = membership.id
              break
            }
          }

          // Item found, delete it
          if (deleteId) {
            await deleteCardToMembershipMutation({
              variables: {
                id: deleteId,
              },
            })
          }

          // Reset delete id
          deleteId = null
        }
      }

      await refetch()
      setCurrentlySaving(false)
      setCardAdminModalOpen(false)
    },
    onError: (error) => {
      toast.error(' Error updating Card \n \n' + error.message, {
        duration: 5000,
      })
      setCurrentlySaving(false)
    },
  })

  function updateCardItem(args: { data: any }) {
    // Get the icon ID to save to db
    const iconStorageObjectId = args.data?.iconStorageObject?.id
      ? Number(args.data?.iconStorageObject?.id)
      : null

    updateCardItemMutation({
      variables: {
        id: selectedCard.id,
        input: {
          name: args.data.name,
          iconStorageObjectId: iconStorageObjectId,
          url: args.data.url,
          isEmbed: args.data.isEmbed,
          order: parseInt(args.data.order),
          isAll: args.data.isAll,
          informerSectionId: parseInt(args.data.section),
        },
      },
    })
  }

  const [updateCardItemsMutation] = useMutation<
    UpdateInformerListCardsMutation,
    UpdateInformerListCardsMutationVariables
  >(UPDATE_CARD_ITEMS, {
    onCompleted: (selectedCard) => {
      setDragDropEnabled(true)
      toast.success('Cards Order Saved', {
        duration: 2000,
      })

      if (selectedCard.updateInformerListCards) {
        const cardsOrder = selectedCard.updateInformerListCards
          .map((x) => x.name)
          .join(', ')
        // DD = Drag and Drop, CO = Card order. We can only feed 100 characters into the label of GA.
        trackEvent('Settings', 'DD HS Cards', { CO: cardsOrder })
      }
    },
    onError: (error) => {
      setDragDropEnabled(true)
      toast.error('Error ordering your cards \n \n' + error.message, {
        duration: 5000,
      })
    },
    refetchQueries: [
      {
        query: QUERY,
        fetchPolicy: 'network-only',
        variables: { sectionId: selectedSection.id },
      },
    ],
    awaitRefetchQueries: true,
  })

  function updateCardsOrder(ids, inputArray) {
    setDragDropEnabled(false)
    return updateCardItemsMutation({
      variables: {
        ids: ids,
        inputArray: inputArray,
      },
    })
  }

  const submitCardForm = (values) => {
    // Isolate all the IDs from the assigned card form values
    const assignedGroupIds: number[] = values.data.groups.map(
      (group) => group.id,
    )
    const assignedMembershipIds: number[] = values.data.members.map(
      (group) => group.id,
    )

    // Card Details
    const cardArgs = {
      data: {
        name: values.data.name,
        iconStorageObject: values.data.iconStorageObject,
        url: values.data.link,
        order: values.data.order,
        isEmbed: values.data.isEmbed,
        isAll: true,
        section: values.data.section,
      },
    }

    // Create a new Card
    if (modalAction === 'create') {
      setNewAssignedGroups(assignedGroupIds)
      setNewAssignedMemberships(assignedMembershipIds)
      setCurrentlySaving(true)
      createCardItem(cardArgs)
    }

    // Update the selected card
    if (modalAction === 'edit') {
      setNewAssignedGroups(assignedGroupIds)
      setNewAssignedMemberships(assignedMembershipIds)
      setCurrentlySaving(true)
      updateCardItem(cardArgs)
    }

    // Duplicate a new Card
    if (modalAction === 'duplicate') {
      setNewAssignedGroups(assignedGroupIds)
      setNewAssignedMemberships(assignedMembershipIds)
      setCurrentlySaving(true)
      createCardItem(cardArgs)
    }
  }

  // Update the cards when the query gets re-fetched
  useEffect(() => {
    setItems(
      filterCardsBySearchTerms(
        cardsInSelectedSection,
        adminCardsTextSearchValue,
        filterByOption,
        members,
      ),
    )
  }, [sectionCards])

  // If there is an active filter, disable drag n drop
  useEffect(() => {
    if (filterByOption || adminCardsTextSearchValue) {
      setDragDropEnabled(false)
    } else {
      setDragDropEnabled(true)
    }
    setItems(
      filterCardsBySearchTerms(
        cardsInSelectedSection,
        adminCardsTextSearchValue,
        filterByOption,
        members,
      ),
    )
  }, [filterByOption, adminCardsTextSearchValue])

  // target id will only be set if dragging from one dropzone to another.
  function onGridOrderChange(startIndex, endIndex) {
    const startSlice = startIndex > endIndex ? endIndex : startIndex
    const endSlice = startIndex > endIndex ? startIndex : endIndex

    const nextState = swap(items, startIndex, endIndex)

    setItems(nextState)

    // Get the order of ids
    const itemIdsToUpdate = nextState
      .slice(startSlice, startSlice + (endSlice - startSlice) + 1)
      .map((item) => {
        return item.id
      })

    // Get the order update value
    const itemOrderToUpdate = itemIdsToUpdate.map((_, index) => {
      // Adding 1 to index so order starts at 1
      return { order: index + 1 + startSlice }
    })

    updateCardsOrder(itemIdsToUpdate, itemOrderToUpdate)
  }

  const ListElement = (card) => {
    return (
      <div className="h-52 p-1">
        <SettingsLayoutCardStackSingle
          disableDragDrop={!dragDropEnabled}
          key={card.item.id}
          cardIndex={card.cardGridIndex}
          card={card.item}
          deleteCardItem={deleteCardItem}
          deletingCardId={deletingCardId}
          setModalAction={setModalAction}
          setCardAdminModalOpen={setCardAdminModalOpen}
          setSelectedCard={setSelectedCard}
        />
      </div>
    )
  }

  const openDefaultCardModal = () => {
    setDefaultCardOptionsModalOpen(true)
  }

  const openBlankCardModal = () => {
    setCardAdminModalOpen(true)
    setModalAction('create')
  }

  return (
    <>
      {!onboardingMode && (
        <SettingsLayoutCardViewSelector
          viewAsOptions={formattedOptions}
          filterByOption={filterByOption}
          setFilterByOption={setFilterByOption}
          filterInputValue={filterInputValue}
          setFilterInputValue={setFilterInputValue}
          adminCardsTextSearchValue={adminCardsTextSearchValue}
          setAdminCardsTextSearchValue={setAdminCardsTextSearchValue}
          dragDropEnabled={dragDropEnabled}
        />
      )}
      <SectionCardsHeader
        title={selectedSection.name}
        setCardAdminModalOpen={setCardAdminModalOpen}
        setDefaultCardOptionsModalOpen={setDefaultCardOptionsModalOpen}
        setModalAction={setModalAction}
        dragDropEnabled={dragDropEnabled}
      />
      <div className="grow overflow-x-hidden p-5">
        {items.length <= 0 ? (
          <>
            {dragDropEnabled ? (
              <div className="mt-10 flex w-full max-w-[1400px] flex-wrap items-start justify-start gap-10 px-4 xl:flex-nowrap">
                <HeroCard
                  title="Start With Template Library"
                  subtitle="Choose from a range of tools and services."
                  onClick={openDefaultCardModal}
                  backgroundImageUrl={iconTemplateBG}
                />
                <HeroCard
                  title="Create New Card"
                  subtitle="Create a new card from a blank template."
                  onClick={openBlankCardModal}
                />
              </div>
            ) : (
              <div className="flex h-full w-full items-center justify-center gap-10">
                <p className="text-gray-500">
                  No cards match your search term.
                </p>
              </div>
            )}
          </>
        ) : (
          <ListManager
            id="items"
            items={items}
            direction="horizontal"
            maxItems={onboardingMode ? 4 : 7}
            disableDragDrop={!dragDropEnabled}
            render={(item, cardIndex) => (
              <ListElement
                item={item}
                cardGridIndex={cardIndex}
                disableDragDrop={!dragDropEnabled}
              />
            )}
            onDragEnd={onGridOrderChange}
          />
        )}
      </div>
      <CardDefaultOptionsModal
        defaultCardOptionsModalOpen={defaultCardOptionsModalOpen}
        setDefaultCardOptionsModalOpen={setDefaultCardOptionsModalOpen}
        refetch={refetch}
        selectedSectionId={selectedSection?.id ?? null}
        selectedSectionCardCount={items?.length ?? 0}
        selectedSectionItems={items}
      />
      <CardAdminModal
        selectedSection={selectedSection}
        informerSections={informerSections}
        membershipGroups={membershipGroups}
        members={members}
        cardAdminModalOpen={cardAdminModalOpen}
        setCardAdminModalOpen={setCardAdminModalOpen}
        submitCardForm={submitCardForm}
        modalAction={modalAction}
        setModalAction={setModalAction}
        currentlySaving={currentlySaving}
        currentlyDeleting={currentlyDeleting}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        deleteCardItem={deleteCardItem}
        globalIcons={globalIcons}
      />
    </>
  )
}

export default SettingsLayoutCardStack
