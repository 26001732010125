import { FC } from 'react'

import { Stepper } from '@mui/material'

import AutomationTrackActionStep from 'src/components/Automations/AutomationTrack/AutomationTrackActionStep'
import AutomationTrackCompleteStep from 'src/components/Automations/AutomationTrack/AutomationTrackCompleteStep'
import AutomationTrackFilterStep from 'src/components/Automations/AutomationTrack/AutomationTrackFilterStep'
import AutomationTrackTriggerStep from 'src/components/Automations/AutomationTrack/AutomationTrackTriggerStep'
import AutomationTrackWarnings from 'src/components/Automations/AutomationTrack/AutomationTrackWarnings'

import { CellAutomation } from '../AutomationCell'
import { SettingsSidebarEditState } from '../AutomationLayout'

interface AutomationTrackProps {
  automation: CellAutomation
  handleOpenEdit: (editMode: SettingsSidebarEditState) => void
}

const AutomationTrack: FC<AutomationTrackProps> = ({
  automation,
  handleOpenEdit,
}) => {
  return (
    <div className="mx-auto flex w-full max-w-xl flex-col gap-10 py-10">
      {!automation?.isValidConfig?.valid && (
        <AutomationTrackWarnings automation={automation} />
      )}
      <Stepper orientation="vertical">
        <AutomationTrackTriggerStep
          automation={automation}
          handleOpenEdit={handleOpenEdit}
        />
        <AutomationTrackFilterStep
          automation={automation}
          handleOpenEdit={handleOpenEdit}
        />

        <AutomationTrackActionStep
          automation={automation}
          handleOpenEdit={handleOpenEdit}
        />

        <AutomationTrackCompleteStep />
      </Stepper>
    </div>
  )
}

export default AutomationTrack
