import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/solid'

import Button from '../Library/Button'
import Modal from '../Modal'

const ReloadModal = () => {
  return (
    <Modal
      open={true}
      onClose={() => {}}
      dialogClassName="max-w-[400px] !top-32"
      closeButtonVisible={false}
      backDropClickDisable
    >
      <div className="flex items-center justify-center">
        <div className="py-6 text-center">
          <h1 className="text-xl font-medium text-slate-800">
            Your active membership changed.
          </h1>
          <h2 className="text-md pt-4 font-medium text-slate-400">
            Click the button to reload the page!
          </h2>
          <Button
            buttonDataTestId="sidebar-reload-page-btn"
            className="my-6 min-w-[0px] items-center gap-2 px-0"
            onClick={() => location.reload()}
          >
            <ArrowPathRoundedSquareIcon
              className="h-5 w-5"
              aria-hidden="true"
            />
            Reload Page
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ReloadModal
