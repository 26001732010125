import { FC } from 'react'

import type {
  FindOnboardingQuery,
  FindOnboardingQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { default as EmptyComponent } from 'src/components/Library/Empty/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

import Onboarding from '../Onboarding'

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'no-cache',
  nextFetchPolicy: 'no-cache',
})

export const QUERY = gql`
  query FindOnboardingQuery {
    client: currentClient {
      onboardingProgress {
        clientSettings
        memberships
        membershipGroups
        homeLinks
        integrations
        completed
      }
    }
  }
`

type CellProps = CellSuccessProps<
  FindOnboardingQuery,
  FindOnboardingQueryVariables
> & { billing: boolean } // Temp - this will be from the CELL

export type OnboardingProgress = CellProps['client']['onboardingProgress']

export const Loading = () => (
  <div className="grid h-screen place-items-center">
    <LoadingSpinner />
  </div>
)

export const Failure = ({
  error,
}: CellFailureProps<FindOnboardingQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Empty: FC<CellProps> = () => (
  <div className="grid h-screen place-items-center">
    <EmptyComponent title="Unable to calculate client progress." />
  </div>
)

export const Success: FC<CellProps> = ({ client, billing = false }) => {
  return (
    <Onboarding
      onboardingProgress={client.onboardingProgress}
      billing={billing}
    />
  )
}
