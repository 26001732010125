import { useState, type Dispatch, type FC, type SetStateAction } from 'react'

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

import { Accordion } from 'src/components/Library/Accordion'
import Switch from 'src/components/Library/Switch/Switch'

import { CardType } from '../../lib/enums'
import type {
  BarChartOrientation,
  BarChartSettings,
  ChartHeadingSettings,
  HubDashChartSettings,
  LegendPosition,
} from '../../lib/types'

interface ChartAppearanceSettingsProps {
  cardType: CardType
  chartSettings: HubDashChartSettings
  setChartSettings: Dispatch<SetStateAction<HubDashChartSettings>>
}

const ChartAppearanceSettings: FC<ChartAppearanceSettingsProps> = ({
  cardType,
  chartSettings,
  setChartSettings,
}) => {
  const barChartSettings = chartSettings?.barChart as BarChartSettings
  const headingSettings = chartSettings?.headings as ChartHeadingSettings

  const [showLegend, setShowLegend] = useState(
    chartSettings?.legend?.visible ?? true,
  )

  return (
    <>
      <Accordion title={'Legend'} expanded>
        <div className="flex flex-col gap-4 pt-2">
          <FormControl>
            <InputLabel id="legend-position">Position</InputLabel>
            <Select
              labelId="legend-position"
              label="Position"
              value={chartSettings?.legend?.position || 'right'}
              onChange={(event) => {
                setChartSettings({
                  ...chartSettings,
                  legend: {
                    ...chartSettings?.legend,
                    position: event.target.value as LegendPosition,
                  },
                })
              }}
            >
              <MenuItem value="right">Right</MenuItem>
              <MenuItem value="left">Left</MenuItem>
              <MenuItem value="top">Top</MenuItem>
              <MenuItem value="bottom">Bottom</MenuItem>
            </Select>
          </FormControl>
          <div className="flex w-full items-center justify-between">
            <p>Show Legend</p>
            <Switch
              checked={showLegend}
              onChange={(event) => {
                setShowLegend(event.target.checked)
                setChartSettings({
                  ...chartSettings,
                  legend: {
                    ...chartSettings?.legend,
                    visible: event.target.checked,
                  },
                })
              }}
            />
          </div>
        </div>
      </Accordion>

      {cardType === CardType.CHART_BAR && (
        <Accordion title={'Headings'} expanded>
          <div className="flex flex-col gap-4 pt-2">
            <TextField
              label="X-Axis Heading"
              value={headingSettings?.xAxis ?? ''}
              onChange={(event) => {
                setChartSettings({
                  ...chartSettings,
                  headings: {
                    ...headingSettings,
                    xAxis: event.target.value,
                  },
                })
              }}
            />
            <TextField
              label="Y-Axis Heading"
              value={headingSettings?.yAxis ?? ''}
              onChange={(event) => {
                setChartSettings({
                  ...chartSettings,
                  headings: {
                    ...headingSettings,
                    yAxis: event.target.value,
                  },
                })
              }}
            />
          </div>
        </Accordion>
      )}
      {cardType === CardType.CHART_BAR && (
        <Accordion title={'Layout'} expanded>
          <div className="flex flex-col gap-4 pt-2">
            <FormControl>
              <InputLabel id="bar-orientation">Bar Orientation</InputLabel>
              <Select
                labelId="bar-orientation"
                label="Bar Orientation"
                value={barChartSettings?.layout?.orientation || 'horizontal'}
                onChange={(event) => {
                  setChartSettings({
                    ...chartSettings,
                    barChart: {
                      ...barChartSettings,
                      layout: {
                        ...barChartSettings?.layout,
                        orientation: event.target.value as BarChartOrientation,
                      },
                    },
                  })
                }}
              >
                {/* Naming switch is intentional */}
                {/* Recharts is opposite... */}
                {/* https://recharts.org/en-US/api/BarChart#layout */}
                <MenuItem value="horizontal">Vertical</MenuItem>
                <MenuItem value="vertical">Horizontal</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Accordion>
      )}
    </>
  )
}

export default ChartAppearanceSettings
