import { FEATURE } from 'api/src/common/enums'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import CustomerIntegrationsCreate from 'src/components/CustomerIntegrations/CustomerIntegrationsCreate'
import PageHeader from 'src/components/PageHeader/PageHeader'
import useFeature from 'src/lib/hooks/useFeature'

const CustomerIntegrationsNewPage = () => {
  // Get Customer Integrations Status
  const customerIntegrationsFeatureFlags = useFeature(
    FEATURE.CUSTOMER_INTEGRATIONS,
  )

  // Navigate away when permission denied
  if (!customerIntegrationsFeatureFlags) {
    navigate(routes.home())
  }

  return (
    <>
      <Metadata title="New Integration" description="New Integration page" />
      <PageHeader title="New Integration" />
      <div className="flex min-h-[calc(100vh-80px)] w-full flex-col bg-gray-50">
        <div className="mx-auto flex h-full w-full max-w-6xl flex-1 grow flex-col gap-10 p-6">
          <CustomerIntegrationsCreate />
        </div>
      </div>
    </>
  )
}

export default CustomerIntegrationsNewPage
