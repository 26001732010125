import Button from '../Library/Button/Button'

const IconButton = (props) => {
  const {
    bgColor = 'none',
    color = 'gray-600',
    size = '6',
    children,
    danger,
    className,
    onClick,
    loading = false,
  } = props

  return (
    <Button
      loading={loading}
      fullWidth={false}
      variant="text"
      onClick={onClick}
      className={`m-0 min-w-[0px] p-0 ${className} rounded-full bg-${bgColor} mx-2 w-${size} h-${size} text-${color} ${
        danger ? 'hover:bg-red-50 hover:text-red-400' : 'hover:bg-gray-300'
      }`}
    >
      <span className="text">{children}</span>
    </Button>
  )
}

export default IconButton
