import React, { FC } from 'react'

import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Menu } from '@mui/material'

import Button from '../Library/Button/Button'

interface InformerOrgChartAlertPopupProps {
  children?: JSX.Element
  variant: 'warning' | 'danger' | 'info' | 'success'
  title: string
}

const InformerOrgChartAlertPopup: FC<InformerOrgChartAlertPopupProps> = ({
  children = null,
  variant,
  title,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  let classNames = ''
  if (variant === 'info') {
    classNames = 'bg-blue-100 hover:bg-blue-200 text-blue-600'
  }
  if (variant === 'success') {
    classNames = 'bg-green-100 hover:bg-green-200 text-green-600'
  }
  if (variant === 'warning') {
    classNames = 'bg-amber-100 hover:bg-amber-200 text-amber-600'
  }
  if (variant === 'danger') {
    classNames = 'bg-red-100 hover:bg-red-200 text-red-600'
  }

  return (
    <div>
      <Button
        className={`${classNames}`}
        onClick={handleButtonClick}
        startIcon={<ExclamationCircleIcon className="h-5 w-5" />}
      >
        {title}
      </Button>
      {children && (
        <Menu
          elevation={3}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            marginTop: '10px',
            padding: 0,
            borderRadius: '10px',
            '& ul': {
              padding: 0,
            },
          }}
        >
          <div className="max-h-[400px] max-w-[400px] rounded-lg">
            {children}
          </div>
        </Menu>
      )}
    </div>
  )
}

export default InformerOrgChartAlertPopup
