import { useAuth } from 'src/Providers'

import { Client } from '../AliasSwitcherCell/AliasSwitcherCell'
import Button from '../Library/Button/Button'

interface AliasItemProps {
  client: Client
  selectAlias: (clientId: number) => void
}

const AliasItem = (props: AliasItemProps) => {
  const { currentUser } = useAuth()
  const { client, selectAlias } = props

  return (
    <li key={client.id}>
      <Button
        className="flex w-full items-center justify-between !border-gray-800 bg-gray-800 text-left hover:bg-gray-600 focus:bg-gray-600"
        buttonDataTestId={`alias-switcher-${client.name.replace(' ', '-')}`}
        onClick={() => selectAlias(client.id)}
        variant="outlined"
      >
        <span>
          {client.id === currentUser.membershipData.clientId ? (
            <div className="flex flex-row items-end gap-x-2">
              <h1 className="text-2xl text-white">{client.name}</h1>
              <span className="text-lg text-green-500">Support Mode</span>
            </div>
          ) : (
            <h1 className="text-2xl text-white">{client.name}</h1>
          )}

          {client.status === 'INACTIVE' && (
            <span className="text-lg normal-case text-red-500">Inactive</span>
          )}

          {client.status === 'PAUSED' && (
            <span className="text-lg normal-case text-orange-400">Paused</span>
          )}

          {client.status === 'SUSPENDED' && (
            <span className="text-lg normal-case text-red-500">Suspended</span>
          )}
        </span>

        <img
          className="h-[50px]"
          height="50"
          width="auto"
          alt={client?.name}
          src={client.logoUrl}
        />
      </Button>
    </li>
  )
}

export default AliasItem
