import { FC } from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'

import Button from '../../../../Library/Button/Button'
interface ExpandButtonProps {
  onExpand: (event: any) => void
  isOpened: boolean
  allowOpen: boolean
  objectiveTestId: string
}
const ExpandButton: FC<ExpandButtonProps> = ({
  onExpand,
  isOpened,
  objectiveTestId,
  allowOpen,
}) => {
  // Return empty span when not allowed to open objective. This keeps all the elements inline
  if (!allowOpen) {
    return <span className="w-16"></span>
  }

  return (
    <Button
      className="toggle-open-objective relative h-full min-w-fit rounded-none px-0.5 px-2 hover:!bg-gray-200 sm:px-6"
      variant="text"
      size="small"
      fullWidth={false}
      disableRipple
      onClick={(e) => {
        onExpand(e)
      }}
    >
      <ChevronDownIcon
        className={`h-4 w-4 text-gray-900 transition-all ${
          isOpened && 'rotate-180'
        }`}
        data-testid={objectiveTestId}
      />
    </Button>
  )
}

export default ExpandButton
