import { FC } from 'react'

export enum ValueChipTheme {
  VIOLET = 'violet',
  BLUE = 'blue',
  GREEN = 'green',
  ORANGE = 'orange',
  RED = 'red',
}

export const ValueChip: FC<{
  value: string
  theme?: ValueChipTheme
  size?: 'small' | null
}> = ({ value, theme = '', size }) => {
  let themeClasses = 'text-slate-600 bg-slate-100 border-slate-300'

  if (theme === 'blue')
    themeClasses = 'text-blue-500 bg-blue-50 border-blue-200'
  if (theme === 'green')
    themeClasses = 'text-emerald-500 bg-emerald-50 border-emerald-200'
  if (theme === 'violet')
    themeClasses = 'text-violet-500 bg-violet-50 border-violet-200'
  if (theme === 'orange')
    themeClasses = 'text-orange-500 bg-orange-50 border-orange-200'
  if (theme === 'red') themeClasses = 'text-red-500 bg-red-50 border-red-200'

  return (
    <span
      className={`my-1 mr-2 ${size === 'small' ? 'text-sm' : ''} no-wrap inline-block text-nowrap rounded border p-0.5 px-2 font-normal uppercase shadow-sm ${themeClasses}`}
    >
      {value}
    </span>
  )
}
