import React, { useState } from 'react'

import { themeQuartz } from '@ag-grid-community/theming'
import { Button } from '@mui/material'
import Stack from '@mui/material/Stack'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import {
  AncillaryLeadTriggerType,
  LeadCompany,
  LeadStatus,
} from 'types/graphql'

import { AncillaryLead } from 'src/components/Ancil/AncillaryLeadListCell/AncillaryLeadListCell'
import LeadStatusChip from 'src/components/Ancil/LeadStatusChip'
import OpportunityChip from 'src/components/Ancil/OpportunityChip'
import PartnerChip from 'src/components/Ancil/PartnerChip'

import ReferLeadModal from './ReferLeadModal'
import StaffChip from './StaffChip'

const ancilGridTheme = themeQuartz.withParams({
  accentColor: '#6366F1',
  browserColorScheme: 'light',
  headerFontSize: 14,
  sidePanelBorder: true,
  wrapperBorder: true,
})

interface AncilTableProps {
  ancillaryLeads: AncillaryLead[]
}

const AncilTable: React.FC<AncilTableProps> = React.memo(
  function MemoizedAncilTable({ ancillaryLeads }) {
    const [isReferModalOpen, setIsReferModalOpen] = useState(false)

    const colDefs: ColDef<AncillaryLead>[] = [
      {
        headerName: 'Name',
        field: 'decryptedName',
      },
      {
        headerName: 'Opportunity',
        field: 'triggerType',
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
          <OpportunityChip
            opportunity={params.data.triggerType as AncillaryLeadTriggerType}
          />
        ),
      },
      {
        headerName: 'Property',
        field: 'propertyAddress',
      },
      {
        headerName: 'Staff',
        field: 'triggeredByUser.name',
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
          <Stack>
            {params.data.extPrimaryAgentName && (
              <StaffChip name={params.data.extPrimaryAgentName} />
            )}
            {params.data.extSecondaryAgentName && (
              <StaffChip name={params.data.extSecondaryAgentName} />
            )}
            {!params.data.extPrimaryAgentName &&
              !params.data.extSecondaryAgentName && (
                <StaffChip name={'No Staff Allocated'} />
              )}
          </Stack>
        ),
      },
      {
        headerName: 'Partner',
        field: 'leadCompany',
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
          <PartnerChip partner={params.data.leadCompany as LeadCompany} />
        ),
      },
      {
        headerName: 'Date',
        field: 'createdAt',
      },
      {
        headerName: 'Status',
        field: 'leadStatus',
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
          <LeadStatusChip status={params.data.leadStatus as LeadStatus} />
        ),
      },
      {
        headerName: 'Referred By',
        field: 'triggeredByUser.name',
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
          <StaffChip name={params.data.triggeredByUser?.name} />
        ),
      },
      {
        headerName: 'Actions',
        flex: 0.5,
        cellRenderer: () => (
          <Button
            variant={'text'}
            size={'small'}
            className={'px-4 py-1 capitalize'}
            disableRipple
            onClick={() => setIsReferModalOpen(true)}
          >
            {'Refer Lead'}
          </Button>
        ),
        cellStyle: () => ({
          border: 'none', // Suppresses focus after clicking refer button
        }),
      },
    ]

    // This config is applied to all columns
    const defaultColDef = React.useMemo(
      () => ({
        sortable: true,
        filter: false,
        resizable: true,
        cellClass: 'flex items-center',
        flex: 1,
      }),
      [],
    )

    return (
      <div
        className="ag-theme-quartz"
        style={{ height: 'calc(100vh - 230px)', width: '100%' }}
      >
        <AgGridReact
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowData={ancillaryLeads}
          overlayNoRowsTemplate={'No Leads Available'}
          pagination={true}
          paginationPageSize={100}
          rowStyle={{ background: 'none' }}
          suppressRowHoverHighlight
          data-testid={'ancil-table'}
          rowBuffer={100}
          groupDisplayType="groupRows"
          theme={ancilGridTheme}
          gridOptions={{
            rowHeight: 50,
          }}
          groupDefaultExpanded={-1} // Expand all groups by default
          loadThemeGoogleFonts={false}
        />
        <ReferLeadModal
          open={isReferModalOpen}
          onClose={() => setIsReferModalOpen(false)}
          onRefer={() => setIsReferModalOpen(false)}
        />
      </div>
    )
  },
)

export default AncilTable
