import { Dispatch, FC, SetStateAction } from 'react'

import { AcademicCapIcon } from '@heroicons/react/24/outline'

import TemplateCard from 'src/components/Library/TemplateCard/TemplateCard'
import MilestoneArrow from 'src/components/MilestoneArrow/MilestoneArrow'
import Tag from 'src/components/Tag/Tag'

import { ProgressionTypes } from '../constants'
import { GoalMember } from '../interfaces'

interface GoalModalTemplatesSelectionProps {
  showMilestones: boolean
  searchValue: string
  templateOptions: GoalMember[]
  selectedTemplateId?: number | null
  setSelectedTemplateId?: Dispatch<SetStateAction<number | null>>
}
export const GoalModalTemplatesSelection: FC<
  GoalModalTemplatesSelectionProps
> = ({
  showMilestones,
  searchValue,
  templateOptions,
  selectedTemplateId,
  setSelectedTemplateId,
}) => {
  const templateSelections = templateOptions.filter((template) => {
    return template?.goalTitle.toLowerCase().includes(searchValue.toLowerCase())
  })

  return (
    <div className="template-container px-2">
      {!templateSelections.length && (
        <p className="w-full py-20 text-center text-gray-400">
          No Templates Found.
        </p>
      )}
      {templateSelections.length > 0 && (
        <div className="grid max-h-[500px] gap-4 overflow-y-scroll px-4 pb-6 pt-4 xl:grid-cols-2">
          {templateSelections.map((template) => (
            <div key={template.id}>
              <TemplateCard
                title={
                  <span className="flex items-center justify-between">
                    Template
                    {template?.isGlobal && (
                      <Tag color={'green'} globalThis>
                        Global
                      </Tag>
                    )}
                  </span>
                }
                subheader={template?.goalTitle}
                author={template?.client?.name}
                isSelected={template.id === selectedTemplateId}
                onClick={() => {
                  if (template.id === selectedTemplateId) {
                    setSelectedTemplateId(null)
                  } else {
                    setSelectedTemplateId(template?.id)
                  }
                }}
                duration={`${template?.dueDateNumber} ${
                  template?.dueDateFormat
                }${template?.dueDateNumber === 1 ? '' : 's'}`}
                parentDataTestId={`template-card-selection ${template.goalTitle}`}
              >
                {showMilestones &&
                  (template?.templateType !== ProgressionTypes.milestone ||
                    !template?.orderedMilestones.length) && (
                    <p className="text-sm text-gray-300">No Milestones</p>
                  )}

                {showMilestones &&
                  template?.templateType === ProgressionTypes.milestone &&
                  template?.orderedMilestones.map((dayObj) => {
                    return dayObj?.items.map((milestone) => {
                      return (
                        <div
                          key={milestone?.id}
                          className="mb-2 flex items-center gap-2"
                        >
                          <span className="rounded bg-gray-100 p-1">
                            {milestone?.templateType ===
                              ProgressionTypes.learner && (
                              <AcademicCapIcon className="h-4 w-4 text-gray-600" />
                            )}
                            {milestone?.templateType !==
                              ProgressionTypes.learner && (
                              <MilestoneArrow size="small" />
                            )}
                          </span>
                          <span className="text-xs">
                            {milestone?.goalTitle}
                          </span>
                        </div>
                      )
                    })
                  })}
              </TemplateCard>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
