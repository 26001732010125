import React, { ReactNode } from 'react'

import { ClockIcon, SwatchIcon } from '@heroicons/react/24/outline'
import { Divider } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'

import { calculateTimeSince } from 'src/Util'

interface Props {
  headerIcon?: JSX.Element
  title?: React.ReactNode
  subheader?: React.ReactNode
  author?: string
  className?: string
  isSelected?: boolean
  createdTime?: string | Date
  duration?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
  parentDataTestId?: string
  color?: string
  children?: ReactNode
}
const TemplateCard: React.FC<Props> = ({
  headerIcon,
  title = 'Template',
  subheader,
  children,
  author,
  isSelected,
  createdTime,
  duration,
  onClick,
  parentDataTestId,
  color = 'indigo',
}) => {
  if (!headerIcon) {
    headerIcon = <SwatchIcon className={`h-5 w-5 text-${color}-600`} />
  }

  return (
    <Card
      variant="outlined"
      onClick={onClick}
      className={`hover:cursor-pointer hover:ring hover:ring-${color}-600 ${
        isSelected && `ring ring-${color}-600`
      } transition duration-300 ease-in-out`}
      data-testid={parentDataTestId}
    >
      <CardHeader
        className="p-4"
        avatar={headerIcon}
        title={
          <span>
            <h1
              className={`text-${color}-600 mt-0.5 text-xs font-medium uppercase leading-4 tracking-wider`}
            >
              {title}
            </h1>
          </span>
        }
        subheader={subheader}
      />
      {children && (
        <CardContent className="pt-0">
          <div className="text-sm font-light leading-5 text-gray-700">
            {children}
          </div>
        </CardContent>
      )}
      <CardContent className="p-4 !pb-2 pt-0">
        <Divider />
        <div className="mt-4 flex w-full items-center justify-between">
          <p className="text-xs font-light uppercase leading-4 tracking-wider text-gray-400">
            {author}
          </p>
          {createdTime && (
            <div className="flex">
              <ClockIcon className="h-5 w-5 text-gray-400" />
              <p className="ml-2 mt-0.5 text-xs font-light uppercase leading-4 tracking-wider text-gray-400">
                {calculateTimeSince(createdTime)}
              </p>
            </div>
          )}
          {duration && (
            <div className="flex">
              <ClockIcon className="h-5 w-5 text-gray-400" />
              <p className="ml-2 mt-0.5 text-xs font-light uppercase leading-4 tracking-wider text-gray-400">
                {duration}
              </p>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default TemplateCard
