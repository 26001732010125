import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { PencilIcon } from '@heroicons/react/24/solid'
import Button from '@mui/material/Button'
import { SUPPORTED_FILE_MIME_TYPES } from 'api/src/common/enums'
import {
  GetHeroStorageObject,
  GetHeroStorageObjectVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Empty from 'src/components/Empty/Empty'
import useUploadStorageObject from 'src/lib/hooks/StorageObject/useUploadStorageObject'
import { useAuth } from 'web/src/Providers'

interface Props {
  course: any
  onSave(id: number, data: any)
}

interface Data {
  learnerCategoryId: number
  heroStorageObjectId: number
}

const GET_STORAGE_OBJECT = gql`
  query GetHeroStorageObject($id: Int!) {
    storageObject(id: $id) {
      id
      downloadUrl
    }
  }
`

const HeroImageUploader: React.FC<Props> = ({ course, onSave }) => {
  const { currentUser } = useAuth()
  const clientId = useMemo(() => currentUser.parentData.id, [currentUser])

  const [heroStorageObject, setHeroStorageObject] = useState(
    course?.heroStorageObject,
  )

  const { loading: getHeroLoading } = useQuery<
    GetHeroStorageObject,
    GetHeroStorageObjectVariables
  >(GET_STORAGE_OBJECT, {
    skip: !course?.heroStorageObject,
    variables: { id: heroStorageObject?.id },
    onCompleted: ({ storageObject }) => {
      setHeroStorageObject(storageObject)
    },
  })

  const [
    uploadHero,
    { result: heroResult, error: heroError, loading: heroLoading },
  ] = useUploadStorageObject({
    prefixes: [`${clientId}`, 'learner', 'course'],
    fileName: 'hero',
    allowedFileMimeTypes: [SUPPORTED_FILE_MIME_TYPES.IMAGE],
  })

  useEffect(() => {
    if (heroStorageObject?.id !== course?.heroStorageObject?.id) {
      onSubmit()
    }
  }, [heroStorageObject])

  useEffect(() => {
    if (heroResult) {
      setHeroStorageObject(heroResult)
    }
  }, [heroResult])

  useEffect(() => {
    if (heroError) toast.error(heroError.message)
  }, [heroError])

  const onSubmit = () => {
    const data: Data = {
      learnerCategoryId: course?.learnerCategoryId,
      heroStorageObjectId: heroStorageObject?.id,
    }
    const id = course?.id
    onSave(id, data)
  }

  const uploadHeroHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files || event.target.files.length === 0) {
        toast.error('You must select an image to upload.')
        return
      }

      const file: File = event.target.files[0]
      uploadHero({ file })
    },
    [uploadHero],
  )

  return (
    <>
      <div className="group relative h-[200px] w-[380px] rounded-lg">
        {course.heroStorageObject?.downloadUrl ? (
          <img
            src={course.heroStorageObject?.downloadUrl}
            alt="Course Hero"
            className="h-[200px] w-[380px] rounded-lg bg-gray-200 object-cover object-center group-hover:brightness-50"
          />
        ) : (
          <Empty className="rounded-md bg-gray-200" image />
        )}
        <Button
          component="label"
          color="primary"
          disabled={heroLoading || getHeroLoading}
          className="invisible absolute inset-0 group-hover:visible"
        >
          <PencilIcon className="m-auto w-1/3 text-white" />
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={(e) => uploadHeroHandler(e)}
            disabled={heroLoading || getHeroLoading}
          />
        </Button>
      </div>
    </>
  )
}

export default HeroImageUploader
