import { LinearProgress } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'

import { navigate, routes } from '@redwoodjs/router'

import Button from 'src/components/Library/Button/Button'

const BillingPlan = ({
  status,
  totalCost,
  currency,
  usage,
  activeMembers,
  nextInvoice,
  billingPeriod,
}) => {
  let color = 'bg-emerald-500'
  if (status !== 'ACTIVE') {
    color = 'bg-yellow-500'
  }

  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: currency,
  })

  return (
    <Card
      elevation={0}
      variant={'outlined'}
      className={'flex h-52 w-[592px] flex-col px-2 py-2'}
    >
      <CardHeader
        title={
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <div className="font-['SF Pro'] text-lg leading-normal text-gray-900">
              Subscription
            </div>
            <Chip
              size={'small'}
              variant="outlined"
              label={
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <div className={`h-1.5 w-1.5 ${color} rounded-full`}></div>
                  <div className="text-xs leading-none text-black">
                    {status}
                  </div>
                </Stack>
              }
            />
          </Stack>
        }
        subheader={
          <div className="font-['SF Pro Text'] mt-1 text-sm font-normal leading-tight text-gray-500">
            Next invoice: {nextInvoice}
          </div>
        }
        action={
          <Button
            onClick={() => navigate(routes.clientSubscriptionUpgrade())}
            variant={'outlined'}
          >
            Upgrade
          </Button>
        }
      />
      <CardContent className={'py-0'}>
        <Stack
          direction={'row'}
          spacing={1}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <div className={'w-2/3'}>
            <div className="font-['SF Pro Text'] text-sm font-normal leading-tight text-gray-500">
              {activeMembers} Billable Members
            </div>
            <div>
              <LinearProgress variant="determinate" value={usage} />
            </div>
          </div>
          <div className="font-['SF Pro'] text-lg font-bold leading-7 text-gray-900">
            {formatter.format(totalCost)}{' '}
            {billingPeriod === 'year'
              ? '/yr'
              : billingPeriod === 'month'
                ? '/mo'
                : ' '}
          </div>
        </Stack>
      </CardContent>
      <CardActions className={'mt-auto'}>
        <Button
          variant={'outlined'}
          onClick={() => navigate(routes.settingsUsers())}
        >
          Manage Members
        </Button>
      </CardActions>
    </Card>
  )
}

export default BillingPlan
