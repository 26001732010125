import { FC } from 'react'

import { Tooltip } from '@mui/material'

interface SummaryGridItemProps {
  title: string
  value: string
  icon: JSX.Element
  tooltipTitle?: string
}

const SummaryGridItem: FC<SummaryGridItemProps> = ({
  title,
  value,
  icon,
  tooltipTitle,
}) => {
  return (
    <Tooltip
      title={
        <span className="block py-0.5 text-base">{tooltipTitle || title}</span>
      }
      arrow
      componentsProps={{
        tooltip: { className: 'bg-grey-700 max-w-[200px]' },
        arrow: { className: 'text-grey-700' },
      }}
    >
      <div className="flex flex-col-reverse items-center justify-end gap-2 rounded-lg bg-gray-100 p-6 text-center 2xl:flex-row 2xl:justify-between 2xl:py-10 2xl:text-left">
        <div>
          <span className="block text-lg font-bold">{value}</span>

          <span className="block text-sm uppercase text-indigo-600">
            {title}
          </span>
        </div>

        {icon}
      </div>
    </Tooltip>
  )
}
export default SummaryGridItem
