import { Dispatch, FC, SetStateAction } from 'react'

import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline'
import { Chip } from '@mui/material'
import TextField from '@mui/material/TextField'
import ToggleButton from '@mui/material/ToggleButton'
import dayjs from 'dayjs'
import { CSVLink } from 'react-csv'

import {
  CourseOption,
  Membership,
  MembershipGroup,
} from 'src/components/Learner/CourseReports/interfaces'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import useAnalytics from 'src/lib/hooks/useAnalytics'

import ButtonWithContextMenu from '../../../ButtonWithContextMenu/ButtonWithContextMenu'
import { generateCSVData } from '../helpers'
import { LearnerCategoryType } from '../types'

const buttonClasses =
  'h-10 text-base !bg-white rounded-md !border !border-solid !border-gray-300 capitalize hover:!border-gray-400 hover:!bg-white-50 text-gray-600 px-3'
export interface FiltersProps {
  sortAscending: boolean
  setSortAscending: Dispatch<SetStateAction<boolean>>
  setSelectedGroups: Dispatch<SetStateAction<MembershipGroup[]>>
  setSelectedMembers: Dispatch<SetStateAction<Membership[]>>
  selectedCourses: CourseOption[]
  setSelectedCourses: Dispatch<SetStateAction<CourseOption[]>>
  courseOptions: CourseOption[]
  selectedMembers: Membership[]
  membershipOptions: Membership[]
  selectedGroups: MembershipGroup[]
  membershipGroupOptions: MembershipGroup[]
  csvData: LearnerCategoryType[]
}

const Filters: FC<FiltersProps> = ({
  sortAscending,
  setSortAscending,
  courseOptions,
  selectedCourses,
  setSelectedCourses,
  membershipOptions,
  selectedMembers,
  setSelectedMembers,
  membershipGroupOptions,
  selectedGroups,
  setSelectedGroups,
  csvData,
}) => {
  // CSV CONTENT
  const csvFileName = `LearnerCourseReporting_${dayjs().format(
    'YYYY-MM-DD_HH-mm-ss',
  )}.csv`

  const csvDataArray = generateCSVData(csvData, membershipOptions)
  const { trackEvent } = useAnalytics()

  return (
    <div className="sticky top-0 z-10 mb-6 flex flex-wrap items-center justify-between gap-4 border-b border-gray-300 bg-gray-50 p-6">
      <Autocomplete
        multiple
        limitTags={2}
        id="tags-outlined"
        className="w-full max-w-[400px] rounded bg-white"
        size="small"
        options={courseOptions}
        groupBy={(option: CourseOption) => option.category}
        getOptionLabel={(option: { id: number; name: string }) => option.name}
        disableCloseOnSelect
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} placeholder="Search" />}
        value={selectedCourses}
        onChange={(
          _: any,
          newValue: { id: number; name: string; category: string }[],
        ) => {
          setSelectedCourses(newValue)
          if (newValue.length > 0) {
            const names = []

            for (let i = 0; i < newValue.length; i++) {
              names.push(newValue[i].name)
            }

            trackEvent('Course Reporting', 'main search', {
              courseName: names.join(', '),
            })
          }
        }}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id
        }}
      />
      <div className="flex flex-wrap items-center gap-2">
        <ButtonWithContextMenu
          buttonClasses={buttonClasses}
          label={
            <div className="flex items-center gap-2">
              <span>Groups</span>
              <Chip
                label={selectedGroups.length}
                size="small"
                className="h-6 w-6 cursor-pointer bg-indigo-100 pt-0.5 text-indigo-600"
              />
            </div>
          }
          reactGAButtonLabel="Groups"
        >
          <div className="border-b border-gray-600 px-4 py-2">
            <Autocomplete
              multiple
              id="tags-outlined"
              className="w-full max-w-[400px] rounded bg-white"
              size="small"
              options={membershipGroupOptions}
              getOptionLabel={(option: { id: number; name: string }) =>
                option.name
              }
              disableCloseOnSelect
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} placeholder="Search" />
              )}
              value={selectedGroups}
              onChange={(_: any, newValue: { id: number; name: string }[]) => {
                setSelectedGroups(newValue)
              }}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id
              }}
            />
          </div>
        </ButtonWithContextMenu>
        <ButtonWithContextMenu
          buttonClasses={buttonClasses}
          label={
            <div className="flex items-center gap-2">
              <span>Members</span>
              <Chip
                label={selectedMembers.length}
                size="small"
                className="h-6 w-6 cursor-pointer bg-indigo-100 pt-0.5 text-indigo-600"
              />
            </div>
          }
          reactGAButtonLabel="Members"
        >
          <div className="border-b border-gray-600 px-4 py-2">
            <Autocomplete
              multiple
              id="tags-outlined"
              className="w-full max-w-[400px] rounded bg-white"
              size="small"
              options={membershipOptions}
              getOptionLabel={(option: { id: number; name: string }) =>
                option.name
              }
              disableCloseOnSelect
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} placeholder="Search" />
              )}
              value={selectedMembers}
              onChange={(_: any, newValue: { id: number; name: string }[]) => {
                setSelectedMembers(newValue)
              }}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id
              }}
            />
          </div>
        </ButtonWithContextMenu>
        <ButtonWithContextMenu
          buttonClasses={buttonClasses}
          label="Export"
          reactGAButtonLabel="Export"
        >
          <CSVLink
            className="flex w-full items-center justify-between px-4 py-2 text-gray-600 hover:bg-gray-100"
            data={csvDataArray}
            filename={csvFileName}
            onClick={() => {
              trackEvent('Course Reporting', 'export csv', {
                exportRowCount: csvDataArray.length,
              })
            }}
          >
            <TableCellsIcon className="h-5 w-5" />
            <span className="normal-case">Export as CSV</span>
          </CSVLink>
        </ButtonWithContextMenu>
        <ToggleButton
          size="small"
          value="Sort Ascending"
          selected={sortAscending}
          onChange={() => {
            setSortAscending(!sortAscending)
            trackEvent('Course Reporting', 'press sort button', {
              sortAscending: !sortAscending,
            })
          }}
          className={buttonClasses}
        >
          {sortAscending ? (
            <BarsArrowDownIcon className="h-6 w-6 text-gray-600" />
          ) : (
            <BarsArrowUpIcon className="h-6 w-6 text-gray-600" />
          )}
        </ToggleButton>
      </div>
    </div>
  )
}

export default Filters
