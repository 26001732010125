import React, { FC, useCallback } from 'react'

import type { ApolloError } from '@apollo/client'
import { Button } from '@mui/material'
import { PROD_CLIENT } from 'api/src/common/enums'
import { EditClientById } from 'types/graphql'

import {
  FieldError,
  Form,
  FormError,
  Label,
  SelectField,
  Submit,
  TextField,
} from '@redwoodjs/forms'
import { navigate, routes } from '@redwoodjs/router'

import { HubClientType } from '../EditClientCell'

interface ClientFormProps {
  client?: EditClientById['hubClient']
  onSave?: (input: HubClientType) => Promise<void>
  loading?: boolean
  error?: ApolloError
  children?: React.ReactNode
  products?: EditClientById['products']
}

const ClientForm: FC<ClientFormProps> = ({
  client,
  onSave,
  loading,
  error,
  children,
  products,
}) => {
  const onSubmit = useCallback(
    async (data: HubClientType) => {
      return await onSave(data)
    },
    [onSave],
  )

  return (
    <div className="rw-form-wrapper">
      <Form onSubmit={onSubmit} error={error}>
        <FormError
          error={error}
          wrapperClassName="rw-form-error-wrapper"
          titleClassName="rw-form-error-title"
          listClassName="rw-form-error-list"
        />
        <Label
          name="name"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Name
        </Label>

        <TextField
          name="name"
          defaultValue={client?.name}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
        />

        <FieldError name="name" className="rw-field-error" />
        {client?.id !== PROD_CLIENT.STAFFLINK && (
          <div className="pt-2">
            <Label
              name="Status"
              className="rw-label"
              errorClassName="rw-label rw-label-error"
            >
              Status
            </Label>
            <SelectField
              name="status"
              className="rw-input mt-3 w-60 p-2"
              errorClassName="rw-input rw-input-error"
              defaultValue={client?.status}
              nonce={client?.status}
            >
              <option>ACTIVE</option>
              <option>PAUSED</option>
              <option>SUSPENDED</option>
              <option>INACTIVE</option>
            </SelectField>
          </div>
        )}

        <div className="pt-2">
          <Label
            name="product"
            className="rw-label"
            errorClassName="rw-label rw-label-error"
          >
            Product
          </Label>
          <SelectField
            name="stripeDefaultProductId"
            className="rw-input mt-3 w-60 p-2"
            errorClassName="rw-input rw-input-error"
            defaultValue={
              products?.find(
                (product) => product.id === client?.stripeDefaultProductId,
              )?.id
            }
            nonce={client?.name}
          >
            <option>Choose a product</option>
            {products?.map((product) => (
              <option key={product.id} value={product.id}>
                {product.name}
              </option>
            ))}
          </SelectField>
        </div>

        <div className="mb-12 pt-2">
          <Label
            name="mfaSetting"
            className="rw-label"
            errorClassName="rw-label rw-label-error"
          >
            MFA Setting
          </Label>
          <SelectField
            name="mfaSetting"
            className="rw-input mt-3 w-60 p-2"
            errorClassName="rw-input rw-input-error"
            defaultValue={client?.mfaSetting}
            nonce={client?.mfaSetting}
          >
            <option>OPTIONAL</option>
            <option>MANDATORY</option>
          </SelectField>
        </div>

        {children}

        <div className="rw-button-group">
          <Submit disabled={loading} className="rw-button rw-button-blue">
            Save
          </Submit>
          <Button
            variant={'text'}
            onClick={() => {
              navigate(routes.clients())
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default ClientForm
