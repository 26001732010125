import React, { FC } from 'react'

import { CheckIcon, PencilIcon } from '@heroicons/react/24/outline'
import { Stack, TextField, Tooltip, Typography } from '@mui/material'

import Button from 'src/components/Library/Button/Button'

export interface ReactMapControlPanelTitleSectionProps {
  isEditing: boolean
  saveMap: () => void
  mapName: string
  setMapName: (mapName: string) => void
  isEditingTitle: boolean
  setIsEditingTitle: (isEditingTitle: boolean) => void
  saveMapLoading: boolean
}
const ReactMapControlPanelTitleSection: FC<
  ReactMapControlPanelTitleSectionProps
> = ({
  mapName,
  setMapName,
  isEditing,
  isEditingTitle,
  setIsEditingTitle,
  saveMap,
  saveMapLoading,
}) => {
  const [titleDisabled, setTitleDisabled] = React.useState(false)
  return (
    <Stack
      direction={'row'}
      spacing={1}
      alignItems={'center'}
      className={'flex justify-between rounded-md border bg-white'}
    >
      {isEditingTitle && isEditing ? (
        <TextField
          size={'small'}
          value={mapName}
          fullWidth
          disabled={titleDisabled}
          onChange={(e) => {
            setMapName(e.target.value)
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              setTitleDisabled(true)
              if (isEditingTitle) {
                await saveMap()
              }
              setIsEditingTitle(!isEditingTitle)
              setTitleDisabled(false)
            }
          }}
          className="rounded-md bg-white"
        />
      ) : (
        <Typography
          className="max-w-xs truncate p-2"
          data-testid="map-name-input"
        >
          {mapName}
        </Typography>
      )}
      {isEditing && (
        <Tooltip title={isEditingTitle ? 'Save Title' : 'Edit Title'}>
          <div>
            <Button
              fullWidth={false}
              variant="text"
              loading={saveMapLoading}
              className={`mr-1 min-w-[0] rounded-full p-2 ${
                isEditingTitle ? 'text-green-600' : 'text-gray-500'
              }`}
              onClick={async () => {
                if (isEditingTitle) {
                  await saveMap()
                }
                setIsEditingTitle(!isEditingTitle)
              }}
              buttonDataTestId={`map-title-edit-${isEditingTitle}-button`}
            >
              {isEditingTitle ? (
                <CheckIcon className="h-4 w-4" />
              ) : (
                <PencilIcon className="h-4 w-4" />
              )}
            </Button>
          </div>
        </Tooltip>
      )}
    </Stack>
  )
}

export default ReactMapControlPanelTitleSection
