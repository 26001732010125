import CardLibraryList from 'src/components/HubDash/CardLibrary/CardLibraryList'
import Spinner from 'src/components/Library/Loading'

export const QUERY = gql`
  query FindCardLibraryQuery {
    cardList: hubDashCards {
      id
      name
      description
      type
      source
      cardSettings
      cardData
    }
  }
`

export const Loading = () => <Spinner />

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({ cardList }) => {
  return <CardLibraryList cardList={cardList} />
}
