import { FC } from 'react'

import { BuildingLibraryIcon } from '@heroicons/react/24/outline'
import Stack from '@mui/material/Stack'
import { BankAccount as BankAccountType } from 'types/graphql'

import Button from 'src/components/Library/Button/Button'

interface BankAccountProps {
  bankAccount: BankAccountType
  updatePayment: () => void
}

const BankAccount: FC<BankAccountProps> = ({ bankAccount, updatePayment }) => {
  return (
    <div className="relative h-52 w-96 transform rounded-xl bg-gradient-to-l from-indigo-400 to-indigo-300 text-white transition-transform hover:scale-105 hover:shadow-2xl">
      <div className="absolute top-8 w-full px-8">
        <Stack
          alignItems={'center'}
          justifyContent={'space-between'}
          direction={'row'}
        >
          <BuildingLibraryIcon className={'h-12 w-12 text-white'} />
          <Button
            variant={'outlined'}
            fullWidth={false}
            className={'h-1/2 border-white text-white hover:text-indigo-800'}
            onClick={updatePayment}
          >
            Update
          </Button>
        </Stack>
        <Stack spacing={1} direction={'column'} justifyContent="space-around">
          <div className="pt-4">
            <p className="text-xs font-light">Account Number</p>
            <p className="text-xl font-medium tracking-widest">
              ••••{bankAccount.last4}
            </p>
          </div>
          <div className="pr-6">
            <p className="text-xs font-light">BSB</p>
            <p className="text-xl font-medium tracking-widest">
              {bankAccount.bsb.slice(0, 3)} - {bankAccount.bsb.slice(3, 6)}
            </p>
          </div>
        </Stack>
      </div>
    </div>
  )
}

export default BankAccount
