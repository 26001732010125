import { useContext, useEffect, useState } from 'react'

import { captureException } from '@sentry/browser'
import { CognitoUser } from 'amazon-cognito-identity-js'

import { Form, PasswordField, TextField } from '@redwoodjs/forms'
import { navigate, routes, useParams } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import Button from 'src/components/Library/Button/Button'
import { DefaultAuthClient } from 'src/lib/authClients'
import { AuthClientContext } from 'src/lib/AuthContext'
import TheHubIcon from 'src/lib/icons/TheHubIcon/TheHubIcon'
import { useAuth } from 'src/Providers'

const PasswordResetTokenPage = () => {
  const { resetEmail: resetEmailEscaped } = useParams()
  const [passwordError, setPasswordError] = useState<string>()
  const [resetLoading, setResetLoading] = useState(false)

  const { client, isAuthenticated } = useAuth()
  const [_auth, setAuth] = useContext(AuthClientContext)

  const resetEmail = decodeURIComponent(resetEmailEscaped)

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.home())
    }
  }, [isAuthenticated])

  useEffect(() => {
    localStorage.setItem('authClient', JSON.stringify(DefaultAuthClient))
    setAuth(DefaultAuthClient)
  }, [setAuth])

  const redirectLogin = () => {
    const tID = setTimeout(function () {
      window.clearTimeout(tID) // clear time out.
      navigate(routes.login())
    }, 3000)
  }

  const onSubmitResetPassword = (formData: {
    password: string
    passwordRepeat: string
    email: string
    token: string
  }) => {
    setPasswordError(null)
    setResetLoading(true)
    if (formData.password !== formData.passwordRepeat) {
      setPasswordError('Passwords do not match')
      setResetLoading(false)
    } else {
      const userData = {
        Username: formData.email,
        Pool: client.client,
      }
      const cognitoUser = new CognitoUser(userData)
      cognitoUser.confirmPassword(formData.token, formData.password, {
        onSuccess() {
          setPasswordError('Password updated successfully. Redirecting now...')
          redirectLogin()
        },
        onFailure(err) {
          captureException(err, {
            tags: { message: 'Password not confirmed!' },
          })
          setPasswordError(err.message)
          setResetLoading(false)
        },
      })
    }
  }

  // Added Intercom Chat
  const chatId = process.env.FRONT_THE_HUB_CHAT_ID
  const [openChat, setOpenChat] = useState(false)
  useEffect(() => {
    if (chatId && openChat && window.Intercom) {
      window.Intercom('show')

      window.Intercom('onHide', function () {
        setOpenChat(false)
      })
    }
  }, [openChat, chatId])

  return (
    <>
      <Metadata title="Reset Password" description="Reset Password" />
      <div className="flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="justify-center space-x-2">
            <TheHubIcon />
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Reset your password
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
            <Form className="space-y-6" onSubmit={onSubmitResetPassword}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <TextField
                    id="email"
                    name="email"
                    autoComplete="email"
                    defaultValue={resetEmail}
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#038EFD] focus:outline-none focus:ring-[#038EFD] sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="token"
                  className="block text-sm font-medium text-gray-700"
                >
                  Verification Code
                </label>
                <div className="mt-1">
                  <TextField
                    id="token"
                    name="token"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#038EFD] focus:outline-none focus:ring-[#038EFD] sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  New Password
                </label>
                <div className="mt-1">
                  <PasswordField
                    id="password"
                    name="password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#038EFD] focus:outline-none focus:ring-[#038EFD] sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="passwordRepeat"
                  className="block text-sm font-medium text-gray-700"
                >
                  Repeat New Password
                </label>
                <div className="mt-1">
                  <PasswordField
                    id="passwordRepeat"
                    name="passwordRepeat"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#038EFD] focus:outline-none focus:ring-[#038EFD] sm:text-sm"
                  />
                </div>
              </div>
              {passwordError && (
                <p className="text-sm text-red-600">{passwordError}</p>
              )}

              <div>
                <Button
                  type="submit"
                  loading={resetLoading}
                  disabled={resetLoading}
                >
                  Update Password
                </Button>
              </div>
            </Form>

            <div className="mt-4 flex items-center justify-end">
              <div className="text-sm">
                <Button
                  variant="text"
                  onClick={() => {
                    setOpenChat(true)
                  }}
                  fullWidth={false}
                >
                  Having issues?
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PasswordResetTokenPage
