import { useState, FC } from 'react'

import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { InputAdornment, TextField } from '@mui/material'
import { SEARCH_RESULT_TYPE } from 'api/src/common/enums'

import useAnalytics from 'src/lib/hooks/useAnalytics'
import useDebounce from 'src/lib/hooks/UseDebounce'
import { useAuth } from 'src/Providers'

import GlobalSearchCell from '../GlobalSearch/GlobalSearchCell'
import IconButton from '../Library/IconButton/IconButton'

interface SearchSelectProps {
  canEdit: boolean
}

const SearchSelect: FC<SearchSelectProps> = ({ canEdit }) => {
  const [searchVal, setSearchVal] = useState<string>('')
  const { hasRole } = useAuth()

  // check if the current user has the role to see all statuses while searching
  const showAllStatuses = hasRole(['ADMIN', 'OWNER', 'EDITOR'])

  let debouncedSearchTerm = ''
  debouncedSearchTerm = useDebounce(searchVal.toLocaleLowerCase(), 300)

  const { trackEvent } = useAnalytics()

  return (
    <div className="relative">
      <TextField
        fullWidth
        id="desktop-search"
        value={searchVal}
        className="rounded bg-white"
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MagnifyingGlassIcon
                width={24}
                height={24}
                color="#a7a8a9"
                strokeWidth="2px"
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchVal.length > 0 && (
                <IconButton
                  aria-label="close search"
                  onClick={() => {
                    setSearchVal('')
                    trackEvent('Search', 'KB clear search')
                  }}
                  disableRipple
                  sx={{
                    ml: 1,
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  <XMarkIcon className="h-6 w-6" />
                </IconButton>
              )}
            </InputAdornment>
          ),
          onBlur: () => {
            // Clear/Hide when user clicks away from input
            // Wait a moment to allow other click events to proceed.
            setTimeout(() => {
              setSearchVal('')
            }, 200)
          },
        }}
        variant="outlined"
        onChange={(event) => {
          setSearchVal(event.target.value)
        }}
      />
      {debouncedSearchTerm && (
        <div className="absolute z-10 mt-2 max-h-[50vh] min-h-[320px] w-full overflow-y-auto rounded-lg bg-white shadow-2xl">
          <GlobalSearchCell
            query={debouncedSearchTerm}
            handleClose={() => {}}
            tabVal={SEARCH_RESULT_TYPE.KNOWLEDGE_BASE}
            hideTabLabel
            showAllStatuses={canEdit ? showAllStatuses : false}
          />
        </div>
      )}
    </div>
  )
}

export default SearchSelect
