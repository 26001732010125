import React, { FC } from 'react'

import { GlobeAltIcon } from '@heroicons/react/24/outline'

interface TagProps {
  color?: string
  large?: boolean
  className?: string
  children?: React.ReactNode
  globalThis?: boolean
  custom?: boolean
  specId?: string
}

const Tag: FC<TagProps> = ({
  color = 'blue',
  large,
  className,
  children,
  globalThis = false,
  custom = false,
  specId,
}) => {
  if (custom) {
    return (
      <span
        className={`${className} font-small m-1 inline-flex items-center rounded-full px-3 py-0.5 text-sm text-${color}-800`}
      >
        {children}
      </span>
    )
  }

  if (!children) {
    return (
      <span
        className={
          'm-1 inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium'
        }
      >
        <svg
          className={`${className} h-3 w-3`}
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx={4} cy={4} r={3} />
        </svg>
      </span>
    )
  }

  return (
    <>
      {!large ? (
        <span
          id={specId}
          className={`m-1 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium bg-${color}-100 text-${color}-600 ${className}`}
        >
          {globalThis && (
            <GlobeAltIcon
              className={`-ml-0.5 mr-1.5 h-3 w-3 text-${color}-400`}
            />
          )}
          {children}
        </span>
      ) : (
        <span
          className={`m-1 inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium bg-${color}-100 text-${color}-600 ${className}`}
        >
          {globalThis && (
            <GlobeAltIcon
              className={`-ml-1 mr-1.5 h-4 w-4 text-${color}-500`}
            />
          )}
          {children}
        </span>
      )}
    </>
  )
}

export default Tag
