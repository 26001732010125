import React, { FC, useEffect, useState } from 'react'

import { SparklesIcon } from '@heroicons/react/24/solid'
import { Chip, Drawer, Grid2 as Grid, InputLabel, Tooltip } from '@mui/material'
import Stack from '@mui/material/Stack'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import Select from 'react-select'
import {
  generatePropertyContent,
  type generatePropertyContentVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { IntegrationLogo } from 'src/components/CustomerIntegrations/IntegrationCard'
import Button from 'src/components/Library/Button/Button'
import PropertyGenieCell from 'src/components/PropertyGenie/PropertyGenieCell'
import PropertyGenieListingFormCell from 'src/components/PropertyGenie/PropertyGenieListingFormCell'
import {
  PropertyTemplate,
  PropertyWithSourceData,
} from 'src/components/PropertyGenie/PropertyLocationsCell'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { GENERATE_PROPERTY_CONTENT } from 'src/lib/queries/PropertyGenie/PropertyContent'

export interface PropertyGenieMainPageProps {
  propertyTemplates: PropertyTemplate[]
  propertyContents: PropertyWithSourceData[]
  isUsageBlocked: boolean
  reloadQuota: () => void
}

interface SortTypes {
  value: string
  label: string
}

interface StatusTypes {
  value: string
  label: string
  color: string
}

export type themeType = {
  id: number
  label: string
}

const statusTypes: StatusTypes[] = [
  { value: 'PENDING', label: 'Pending', color: '#FF9800' }, // Yellow
  { value: 'APPROVED', label: 'Approved', color: '#4CAF50' }, // Green
  { value: 'REJECTED', label: 'Rejected', color: '#F44336' }, // Red
  { value: 'ARCHIVED', label: 'Archived', color: '#9E9E9E' }, // Grey
]

enum SortTypeValues {
  CREATED_DATE = '0',
  LISTING_ADDRESS_A_Z = '1',
  LISTING_ADDRESS_Z_A = '2',
  CREATED_BY = '3',
  STATUS = '4',
}

const sortTypes: SortTypes[] = [
  { value: SortTypeValues.CREATED_DATE, label: 'Created Date' },
  { value: SortTypeValues.LISTING_ADDRESS_A_Z, label: 'Listing Address: A-Z' },
  { value: SortTypeValues.LISTING_ADDRESS_Z_A, label: 'Listing Address: Z-A' },
  { value: SortTypeValues.CREATED_BY, label: 'Created By' },
  { value: SortTypeValues.STATUS, label: 'Status' },
]

const defaultSortOption = sortTypes.find(
  (item) => item.value === SortTypeValues.CREATED_DATE,
)

type PropertyLabelType = {
  value: string
  label: string
}

const PropertyGenieMainPage: FC<PropertyGenieMainPageProps> = ({
  propertyTemplates,
  propertyContents,
  isUsageBlocked,
  reloadQuota,
}) => {
  const [viewCreateAdModal, setViewCreateAdModal] = useState<boolean>(false)
  const [openCreateAdModal, setOpenCreateAdModal] = useState<boolean>(false)
  const [selectedThemes, setSelectedThemes] = useState<themeType[]>([])
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([])
  const [filteredPropertyContents, setFilteredPropertyContents] = useState<
    PropertyWithSourceData[]
  >([])
  const [propertyContentsReduced, setPropertyContentsReduced] = useState<
    PropertyWithSourceData[]
  >([])
  const [sortedPropertyContents, setSortedPropertyContents] = useState<
    PropertyWithSourceData[]
  >([])
  const [validPropertiesWithContent, setValidPropertiesWithContent] = useState<
    PropertyLabelType[]
  >([])
  const [selectedContent, setSelectedContent] = useState(null)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [filterValue, setFilterValue] = useState<StatusTypes[]>([])
  const [filteredAddress, setFilteredAddress] =
    useState<PropertyLabelType>(null)
  const [sortValue, setSortValue] = useState<SortTypes>(defaultSortOption)
  const { trackEvent } = useAnalytics()

  const [generatePropertyContent] = useMutation<
    generatePropertyContent,
    generatePropertyContentVariables
  >(GENERATE_PROPERTY_CONTENT, {
    onCompleted: () => {
      handleClose()
      reloadQuota()
    },
    refetchQueries: [
      'FindPropertyGenieQuery',
      'PropertyLocationsQuery',
      'FindManagementQuery',
      'FindListingQuery',
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const handleClose = () => {
    setOpenCreateAdModal(false)
    trackEvent('Ad Generator', 'close property generate modal')
  }

  const toTitleCase = (str: string) => {
    if (typeof str !== 'string') {
      return ''
    }
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  const hexToRgba = (hex: string, alpha: number) => {
    const [r, g, b] = [
      parseInt(hex.slice(1, 3), 16),
      parseInt(hex.slice(3, 5), 16),
      parseInt(hex.slice(5, 7), 16),
    ]
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }

  const getStatusColor = (status: string) => {
    const statusItem = statusTypes.find((item) => item.value === status)
    return statusItem ? statusItem.color : '#FFFFFF' // Default to white if not found
  }

  const SquareDot = ({ color }: { color: string }) => (
    <div
      style={{
        width: '5px',
        height: '5px',
        backgroundColor: color,
        marginLeft: '8px',
      }}
    />
  )

  const cleanString = (str: string) =>
    str.replace(/[^a-zA-Z]/g, '').toLowerCase()

  const sortProperties = (properties: PropertyWithSourceData[]) => {
    const copiedProperties = [...properties]
    return copiedProperties.sort((a, b) => {
      const aLatestAd = a
      const bLatestAd = b

      switch (sortValue.value) {
        case SortTypeValues.LISTING_ADDRESS_A_Z: {
          const aAddress = a.sourceData.address || ''
          const bAddress = b.sourceData.address || ''

          if (!aAddress && bAddress) return 1
          if (aAddress && !bAddress) return -1

          return cleanString(aAddress).localeCompare(cleanString(bAddress))
        }

        case SortTypeValues.LISTING_ADDRESS_Z_A: {
          const aAddress = a.sourceData.address || ''
          const bAddress = b.sourceData.address || ''

          if (!aAddress && bAddress) return -1
          if (aAddress && !bAddress) return 1

          return cleanString(bAddress).localeCompare(cleanString(aAddress))
        }

        case SortTypeValues.CREATED_DATE: {
          const aDate = aLatestAd?.createdAt || ''
          const bDate = bLatestAd?.createdAt || ''
          if (!aDate && bDate) return 1
          if (aDate && !bDate) return -1
          return aDate < bDate ? 1 : aDate > bDate ? -1 : 0
        }

        case SortTypeValues.CREATED_BY: {
          const aName = String(aLatestAd?.createdByUser?.name || '')
          const bName = String(bLatestAd?.createdByUser?.name || '')
          if (!aName && bName) return 1
          if (aName && !bName) return -1
          return aName.localeCompare(bName)
        }

        case SortTypeValues.STATUS: {
          const aStatus = aLatestAd?.status || ''
          const bStatus = bLatestAd?.status || ''
          if (!aStatus && bStatus) return 1
          if (aStatus && !bStatus) return -1
          return aStatus.localeCompare(bStatus)
        }

        default: {
          return 0
        }
      }
    })
  }

  const handleFilter = (value: StatusTypes[]) => {
    setFilterValue(value)
    trackEvent('Ad Generator', 'filter by status', {
      status: value.map((item) => item.value).join(','),
    })
  }

  const handleFilterAddress = (searchString: PropertyLabelType) => {
    if (!searchString) {
      setPropertyContentsReduced(propertyContents)
      setFilteredAddress(null)
      return
    }
    setPropertyContentsReduced(
      propertyContents.filter((item) => {
        const address = item.sourceData.address || 'No Address Found'

        const matched = address
          .toLowerCase()
          .includes(searchString.label.toLowerCase())

        return matched
      }),
    )
    setFilteredAddress(searchString)
  }

  const handleSort = (value: SortTypes) => {
    setSortValue(value)
    trackEvent('Ad Generator', 'sort by', {
      sortBy: value.label,
    })
  }

  const columns: GridColDef<PropertyWithSourceData>[] = [
    {
      field: 'address',
      headerName: 'LISTING ADDRESS',
      minWidth: 500,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <IntegrationLogo
            size={'small'}
            integrationType={params.row?.sourceData.integrationType}
          />
          <span>{params.row?.sourceData?.address || 'No Address Found'}</span>
        </Stack>
      ),
    },
    {
      field: 'lastCreated',
      sortable: false,
      filterable: false,
      headerName: 'LAST CREATED',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.row.createdAt)
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}/${date.getFullYear()}`
        return <span>{formattedDate}</span>
      },
    },
    {
      field: 'createdBy',
      sortable: false,
      filterable: false,
      headerName: 'CREATED BY',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return <span>{params.row?.createdByUser?.name}</span>
      },
    },
    {
      field: 'integrationType',
      sortable: false,
      filterable: false,
      headerName: 'SOURCE',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction={'row'} spacing={0.5}>
            <div>
              {['NONE', null, undefined].includes(
                params.row?.sourceData?.integrationType,
              )
                ? 'Manual'
                : params.row?.sourceData?.integrationType}
            </div>
          </Stack>
        )
      },
    },
    {
      field: 'STATUS',
      sortable: false,
      filterable: false,
      headerName: 'STATUS',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={toTitleCase(params.row.status)}
            size="small"
            icon={<SquareDot color={getStatusColor(params.row.status)} />}
            style={{
              backgroundColor: hexToRgba(
                getStatusColor(params.row.status),
                0.2,
              ),
              color: getStatusColor(params.row.status),
              borderRadius: '5px',
            }}
          />
        ) // This closing parenthesis was missing
      },
    },
    {
      field: 'TEMPLATE',
      sortable: false,
      filterable: false,
      headerName: 'TEMPLATE',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return <span>{params.row?.template?.name || 'No Template Used'}</span>
      },
    },
  ]

  useEffect(() => {
    if (filterValue.length > 0) {
      const filteredData = propertyContentsReduced.filter((property) => {
        return filterValue.some((status) => {
          return property.status === status.value
        })
      })
      setFilteredPropertyContents(filteredData)
    } else {
      setFilteredPropertyContents(propertyContentsReduced)
    }
  }, [propertyContentsReduced, filterValue])

  useEffect(() => {
    if (propertyContents?.length === 0) return
    const addressSet = new Set(
      propertyContents?.map(
        (content) => content.sourceData?.address || 'No Address Found',
      ),
    )

    const uniquePropertyOptions = Array.from(addressSet).map((address) => ({
      value: address,
      label: address,
    }))

    setValidPropertiesWithContent(uniquePropertyOptions)
    setPropertyContentsReduced(propertyContents)
  }, [propertyContents])

  useEffect(() => {
    if (!sortValue) {
      setSortedPropertyContents(filteredPropertyContents)
    } else {
      const sortedProperties = sortProperties([...filteredPropertyContents])
      setSortedPropertyContents(sortedProperties)
    }
  }, [sortValue, filteredPropertyContents])

  return (
    <div className={'relative flex h-full w-full flex-row'}>
      <div className="h-full w-full justify-center overflow-y-auto p-5">
        {viewCreateAdModal && (
          <PropertyGenieListingFormCell
            setVersion={null}
            version={null}
            sourceType={selectedContent?.sourceData?.sourceType}
            propertyTemplates={propertyTemplates}
            updatedProperty={null}
            open={openCreateAdModal}
            handleClose={handleClose}
            generatePropertyContent={generatePropertyContent}
            selectedKeywords={selectedKeywords}
            setSelectedKeywords={setSelectedKeywords}
            selectedThemes={selectedThemes}
            setSelectedThemes={setSelectedThemes}
            setSelectedContent={setSelectedContent}
            setOpenDrawer={setOpenDrawer}
          />
        )}
        <Grid container spacing={2} className="my-2">
          <Grid size={{ xs: 3 }}>
            <Select
              // isMulti
              className="w-full"
              value={filteredAddress}
              placeholder="Address"
              onChange={handleFilterAddress}
              options={validPropertiesWithContent}
              isClearable={true}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  height: '40px',
                  overflowY: 'auto',
                }),
              }}
            />
          </Grid>
          <Grid size={{ xs: 1 }}></Grid>

          <Grid
            size={{ xs: 1 }}
            style={{
              alignItems: 'center',
              justifyContent: 'right',
              display: 'flex',
            }}
          >
            <InputLabel htmlFor="filter-select">SORT BY</InputLabel>
          </Grid>

          <Grid size={{ xs: 2 }}>
            <Select
              className="w-full"
              value={sortValue}
              onChange={handleSort}
              placeholder="Default"
              options={sortTypes}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                  borderRadius: '5px',
                  height: '40px',
                  paddingLeft: '15px',
                }),
              }}
            />
          </Grid>

          <Grid
            size={{ xs: 1 }}
            style={{
              alignItems: 'center',
              justifyContent: 'right',
              display: 'flex',
            }}
          >
            <InputLabel htmlFor="filter-select">FILTER BY</InputLabel>
          </Grid>

          <Grid size={{ xs: 2 }}>
            <Select
              className="w-full"
              value={filterValue}
              isMulti
              placeholder="Status"
              onChange={handleFilter}
              options={statusTypes}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  height: '40px',
                  overflowY: 'auto',
                }),
              }}
            />
          </Grid>
          <Tooltip
            title={
              isUsageBlocked
                ? 'Quota used, please contact Stafflink support to increase your usage'
                : ''
            }
            placement="top"
          >
            <Grid size={{ xs: 2 }}>
              <Button
                startIcon={<SparklesIcon className="h-5 w-5" />}
                className="min-w-[0] rounded-lg"
                disabled={isUsageBlocked}
                onClick={() => {
                  setOpenCreateAdModal(true)
                  setViewCreateAdModal(true)
                  trackEvent('Ad Generator', 'click create ad')
                }}
                buttonDataTestId="ad-gen-create-new-ad-button"
              >
                Create New Ad
              </Button>
            </Grid>
          </Tooltip>
        </Grid>
        <div className={'rounded-md bg-white'}>
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              zIndex: 0,
              height: '100%',
            }}
            rows={sortedPropertyContents}
            columns={columns}
            onRowClick={(params) => {
              setSelectedContent(params.row)
              setOpenDrawer(true)
              trackEvent('Ad Generator', 'click property', {
                propertyId: params.row.id,
              })
            }}
            pageSizeOptions={[10, 15, 20, 30, 50, 100]}
            initialState={{
              pagination: { paginationModel: { pageSize: 15 } },
            }}
            onPaginationModelChange={(params) => {
              trackEvent('Ad Generator', 'paginate through properties', {
                pageIndex: params.page,
              })
            }}
          />
        </div>
      </div>

      <div>
        <Drawer
          open={openDrawer}
          anchor="right"
          onClose={() => setOpenDrawer(false)}
          sx={{ width: '50%', flexShrink: 0 }}
          PaperProps={{ sx: { width: '50%' } }}
        >
          <PropertyGenieCell
            openDrawer={openDrawer}
            sourceType={selectedContent?.sourceData?.sourceType}
            id={selectedContent?.sourceData?.id}
            contentId={selectedContent?.id}
            isUsageBlocked={isUsageBlocked}
            propertyTemplates={propertyTemplates}
            statusTypes={statusTypes}
            setOpenDrawer={setOpenDrawer}
            setOpenCreateAdModal={setOpenCreateAdModal}
            handleClose={handleClose}
            generatePropertyContent={generatePropertyContent}
            selectedKeywords={selectedKeywords}
            setSelectedKeywords={setSelectedKeywords}
            selectedThemes={selectedThemes}
            setSelectedThemes={setSelectedThemes}
            openCreateAdModal={openCreateAdModal}
            setSelectedContent={setSelectedContent}
          />
        </Drawer>
      </div>
    </div>
  )
}

export default PropertyGenieMainPage
