import React, { ChangeEvent, FC } from 'react'

import { TextField } from '@mui/material'
import Stack from '@mui/material/Stack'

interface SimpleFormProps {
  handleChange?: (key: string, value: string) => void
  records: Map<string, string | number>
  fieldDataTestId?: string
}
//Note ID will always be hidden
const CustomTextField: FC<{
  value: string | number
  onChange: (e: any) => void
  fieldDataTestId?: string
}> = ({ value, onChange, fieldDataTestId }) => {
  const [myTextField, setMyTextField] = React.useState(value)
  const handleUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    setMyTextField(e.target.value)
    onChange(e)
  }
  return (
    <TextField
      data-testid={fieldDataTestId}
      variant="outlined"
      fullWidth
      size={'small'}
      value={myTextField}
      onChange={handleUpdate}
      onFocus={(event) => {
        event.target.select()
      }}
    />
  )
}

const SimpleForm: FC<SimpleFormProps> = ({ handleChange, records }) => {
  const updateHandler = (key: string, value: string) => {
    handleChange(key, value)
  }

  return (
    <Stack spacing={2}>
      {[...records.keys()].map((key) => {
        if (key !== 'Id')
          return (
            <Stack spacing={1} key={key}>
              <h2
                className={
                  'text-xs font-normal uppercase leading-3 text-gray-600'
                }
              >
                {key}
              </h2>
              <CustomTextField
                value={records.get(key)}
                onChange={(e) => {
                  updateHandler(key, e.target.value)
                  handleChange(key, e.target.value)
                }}
                fieldDataTestId={`custom-text-field-${key}`}
              />
            </Stack>
          )
        else return null
      })}
    </Stack>
  )
}

export default SimpleForm
