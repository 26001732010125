import {
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'

import {
  ArrowTopRightOnSquareIcon,
  EnvelopeIcon,
  PhoneArrowUpRightIcon,
} from '@heroicons/react/24/outline'
import { Rating, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs from 'dayjs'
import Select, { components, StylesConfig } from 'react-select'

import {
  ColoredChip,
  FieldRenderer,
} from 'src/components/HubDash/FieldRenderer'
import Checkbox from 'src/components/Library/Checkbox'
import IconButton from 'src/components/Library/IconButton/IconButton'
import {
  isSimplePhoneNumber,
  isValidAbsoluteURL,
  isValidEmail,
} from 'src/lib/baserow/modules/core/utils/string'
import {
  transformBaserowNumberInput,
  validateBaserowNumberInput,
} from 'src/lib/helpers'
import useDebounce from 'src/lib/hooks/UseDebounce'

import numberInputFormatter from '../lib/numberInputFormatter'

import { CellDurationRender } from './renderTypes/CellDurationRender'
import { CellFileRender } from './renderTypes/CellFileRender'
import { CellLinkRowRender } from './renderTypes/CellLinkRowRender'
import { CellLongTextRender } from './renderTypes/CellLongTextRender'
import { CellMultipleCollaboratorsRender } from './renderTypes/CellMultipleCollaboratorsRender'

interface CellRendererProps {
  field: any // not typed yet
  record: any // not typed yet
  className: string
  isName: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  baseId: number
}

export const CellRenderer: FC<CellRendererProps> = ({
  field,
  record,
  className,
  isName,
  setLoading,
  baseId,
}) => {
  const getCellValueValidity = (value): boolean => {
    // Use Baserow validation functions to proof inputs before they send.
    switch (field.type) {
      case 'number': {
        return value ? validateBaserowNumberInput(value) : true
      }
      case 'email': {
        return value?.length > 0 ? isValidEmail(cellValue) : true
      }
      case 'phone_number': {
        return value?.length > 0 ? isSimplePhoneNumber(value) : true
      }
      case 'url': {
        return value?.length > 0 ? isValidAbsoluteURL(value) : true
      }
      // No special requirements for the following inputs.
      case 'text':
      case 'single_select':
      case 'multiple_select':
      case 'duration':
      case 'date':
      case 'boolean':
      case 'long_text':
      case 'file':
      case 'link_row':
      case 'formula':
      case 'multiple_collaborators':
      case 'rating':
      default:
        return true
    }
  }

  const getInitialCellValue = () => {
    switch (field.type) {
      case 'text':
      case 'email':
      case 'number':
      case 'phone_number':
        return record.getCellValueAsString(field.name)
      case 'single_select': {
        const rawValue = record.getCellValue(field.name)
        return {
          value: rawValue?.value,
          color: rawValue?.color,
          id: rawValue?.id,
        }
      }
      case 'multiple_select': {
        return record.getCellValue(field.name).map((option) => {
          return {
            value: option.value,
            id: option.id,
            color: option.color,
          }
        })
      }
      case 'duration':
      case 'date':
      case 'boolean':
      case 'long_text':
      case 'file':
      case 'link_row':
      case 'formula':
      case 'multiple_collaborators':
      case 'rating':
      case 'url':
        return record.getCellValue(field.name)
      default:
        return null
    }
  }

  const transformCellValueToBaserow = (value) => {
    switch (field.type) {
      case 'number': {
        return transformBaserowNumberInput(
          value,
          field?.number_decimal_places,
          field?.number_negative,
        )
      }
      case 'single_select': {
        return value?.id || null
      }
      case 'multiple_select': {
        return value.map((option) => option.id)
      }
      case 'date': {
        if (value) {
          if (field.date_include_time) {
            const utcValue = value.utc()
            if (utcValue) {
              return utcValue.format('YYYY-MM-DDTHH:mm:ss.SSS')
            } else {
              return null
            }
          } else {
            return value.format('YYYY-MM-DD')
          }
        } else {
          return null
        }
      }
      case 'link_row': {
        return value.map((linkRow) => {
          return linkRow.id
        })
      }
      default:
        return value
    }
  }

  const getFieldTypeDebounce = () => {
    switch (field.type) {
      case 'boolean':
      case 'single_select':
      case 'rating':
      case 'file':
        return 0
      case 'long_text':
        return 1500
      default:
        return 750
    }
  }

  const startingValue = getInitialCellValue()

  const [cellValue, setCellValue] = useState(startingValue)
  const [cellValueIsValid, setCellValueIsValid] = useState<boolean>(
    getCellValueValidity(startingValue),
  )

  //number input state
  const [numberInputEdit, setNumberInputEdit] = useState(false)
  const [numberInputTempValue, setNumberInputTempValue] = useState(
    field.type === 'number' ? cellValue : '',
  )

  const [isEditing, setIsEditing] = useState(false)
  const debouncedValue = useDebounce<string>(cellValue, getFieldTypeDebounce())

  const isLocalChangeRef = useRef(false)

  // Disabled outbound link on field if the value is not valid OR if doesn't exist
  const linkDisabled = !cellValueIsValid || !cellValue

  useEffect(() => {
    isLocalChangeRef.current = false
    setLoading(false)
    setCellValue(startingValue)
    setCellValueIsValid(getCellValueValidity(startingValue))
  }, [record])

  useEffect(() => {
    //sync number input temp value with cell value after cell value changes
    if (field.type === 'number') {
      setNumberInputTempValue(cellValue)
    }
  }, [cellValue])

  useEffect(() => {
    if (isLocalChangeRef.current && cellValueIsValid) {
      setLoading(true)
      setIsEditing(true)
    }
  }, [cellValue])

  // improve this to handle quick updates within the debounce
  useEffect(() => {
    const updateRecord = async () => {
      await record.updateRecord({
        [`field_${field.id}`]: transformCellValueToBaserow(debouncedValue),
      })

      setIsEditing(false)
    }
    if (isEditing) {
      updateRecord()
    }
  }, [debouncedValue])

  const handleChange = (newValue) => {
    isLocalChangeRef.current = true
    setCellValueIsValid(getCellValueValidity(newValue))
    setCellValue(newValue)
  }

  switch (field.type) {
    case 'boolean':
      return (
        <Checkbox
          checked={cellValue}
          onChange={(e) => handleChange(e.target.checked)}
        />
      )
    case 'multiple_collaborators':
      return (
        <CellMultipleCollaboratorsRender
          cellValue={cellValue}
          setCellValue={handleChange}
        />
      )
    case 'email':
      return (
        <div className="flex gap-2">
          <TextField
            data-testid="hubdash-email-field"
            value={cellValue}
            onChange={(e) => handleChange(e.target.value)}
            className={className}
            size="small"
            error={!cellValueIsValid}
            helperText={`${!cellValueIsValid ? 'Please enter a valid email.' : ''}`}
            fullWidth
          />
          <IconButton
            href={'mailto:' + cellValue}
            className={`h-10 rounded !border !border-solid !border-[#0000003b] bg-white px-3 ${linkDisabled && '!border-gray-200 !bg-gray-100 !text-gray-300'}`}
            disabled={linkDisabled}
          >
            <EnvelopeIcon className="h-4 w-4" />
          </IconButton>
        </div>
      )
    case 'number':
      return (
        <TextField
          data-testid="hubdash-number-field"
          value={
            !numberInputEdit
              ? numberInputFormatter(
                  field.number_separator,
                  numberInputTempValue,
                  field.number_prefix,
                  field.number_suffix,
                  field.number_decimal_places,
                )
              : numberInputTempValue
          }
          onChange={(e) => {
            setNumberInputTempValue(e.target.value)
          }}
          onBlur={(e) => {
            setNumberInputEdit(false)
            handleChange(e.target.value)
          }}
          onFocus={() => {
            setNumberInputEdit(true)
          }}
          className={className}
          size="small"
          error={!cellValueIsValid}
          helperText={`${!cellValueIsValid ? 'Please enter a valid number.' : ''}`}
          fullWidth
        />
      )
    case 'url':
      return (
        <div className="flex gap-2">
          <TextField
            data-testid="hubdash-url-field"
            value={cellValue}
            onChange={(e) => handleChange(e.target.value)}
            className={className}
            size="small"
            error={!cellValueIsValid}
            helperText={`${!cellValueIsValid ? 'Please enter a valid url.' : ''}`}
            fullWidth
          />

          <IconButton
            href={cellValue}
            target="_blank"
            className={`h-10 rounded !border !border-solid !border-[#0000003b] bg-white px-3 ${linkDisabled && '!border-gray-200 !bg-gray-100 !text-gray-300'}`}
            disabled={linkDisabled}
          >
            <ArrowTopRightOnSquareIcon className="h-4 w-4" />
          </IconButton>
        </div>
      )
    case 'phone_number':
      return (
        <div className="flex gap-2">
          <TextField
            data-testid="hubdash-phone-number-field"
            value={cellValue}
            onChange={(e) => handleChange(e.target.value)}
            className={className}
            size="small"
            error={!cellValueIsValid}
            helperText={`${!cellValueIsValid ? 'Please enter a valid phone number.' : ''}`}
            fullWidth
          />
          <IconButton
            href={'tel:' + cellValue}
            className={`h-10 rounded !border !border-solid !border-[#0000003b] bg-white px-3 ${linkDisabled && '!border-gray-200 !bg-gray-100 !text-gray-300'}`}
            disabled={linkDisabled}
          >
            <PhoneArrowUpRightIcon className="h-4 w-4" />
          </IconButton>
        </div>
      )
    case 'text':
      if (isName) {
        return (
          <TextField
            value={cellValue}
            data-testid="hubdash-text-field-medium"
            onChange={(e) => handleChange(e.target.value)}
            className={className}
            size="medium"
            fullWidth
            sx={{
              '& .MuiInputBase-root': {
                fontSize: '1.5rem',
              },
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: '1px solid #e5e7eb',
              },
            }}
          />
        )
      } else {
        return (
          <TextField
            data-testid="hubdash-text-field"
            value={cellValue}
            onChange={(e) => handleChange(e.target.value)}
            className={className}
            size="small"
            fullWidth
          />
        )
      }
    case 'long_text':
      return (
        <CellLongTextRender
          cellValue={cellValue}
          setCellValue={handleChange}
          richTextEnabled={field?.long_text_enable_rich_text}
        />
      )

    case 'file':
      return (
        <CellFileRender
          field={field}
          cellValue={cellValue}
          setCellValue={handleChange}
          uploadFile={record?.uploadFile}
        />
      )
    case 'date':
      if (field.date_include_time) {
        const showTimeZone = field.date_show_tzinfo
        const timeZone = field.date_force_timezone
          ? field.date_force_timezone
          : dayjs.tz.guess()

        return (
          <DateTimePicker
            ampm={field.date_time_format === '12'}
            value={cellValue ? dayjs(cellValue) : null}
            onChange={handleChange}
            className={className}
            timezone={timeZone}
            label={showTimeZone ? `Timezone: ${timeZone}` : ''}
            slotProps={{
              textField: {
                size: isName ? 'medium' : 'small',
                fullWidth: true,
              },
            }}
          />
        )
      } else {
        return (
          <DatePicker
            value={cellValue ? dayjs(cellValue) : null}
            onChange={handleChange}
            className={className}
            slotProps={{
              textField: {
                size: isName ? 'medium' : 'small',
                fullWidth: true,
              },
            }}
          />
        )
      }
    case 'multiple_select':
    case 'single_select': {
      const options = []

      if (field?.type === 'single_select') {
        options.push({
          value: null,
          id: 0,
          color: null,
        })
      }

      for (const option of field.select_options) {
        options.push({
          value: option.value,
          id: option.id,
          color: option.color,
        })
      }

      const Option = (props) => (
        <components.Option {...props}>
          <ColoredChip data={props.data} isName={isName} />
        </components.Option>
      )

      const SingleValue = (props) => (
        <components.SingleValue {...props}>
          <ColoredChip data={props.data} isName={isName} />
        </components.SingleValue>
      )

      const MultiValue = (props) => (
        <components.MultiValue {...props}>
          <ColoredChip data={props.data} isName={isName} />
          <components.MultiValueRemove {...props}>
            {({ removeProps }) => <div {...removeProps}>x</div>}
          </components.MultiValueRemove>
        </components.MultiValue>
      )

      return (
        <Select
          isMulti={field.type === 'multiple_select'}
          options={options}
          isClearable
          isSearchable
          defaultValue={cellValue}
          value={cellValue}
          onChange={(newValue) => {
            handleChange(newValue)
          }}
          components={{ Option, SingleValue, MultiValue }}
          styles={
            {
              menu: (styles) => ({
                ...styles,
                zIndex: 9999,
              }),
            } as StylesConfig
          }
          id={`hubdash-cell-renderer-select-${field?.name}`}
        />
      )
    }
    case 'link_row': {
      return (
        <CellLinkRowRender
          field={field}
          cellValue={cellValue}
          setCellValue={handleChange}
          baseId={baseId}
          workspaceId={record?.workspaceId}
        />
      )
    }
    case 'rating': {
      return (
        <Rating
          value={cellValue}
          onChange={(_, newValue) => {
            // Send 0 in place of null value
            handleChange(newValue ?? 0)
          }}
          max={field.max_value}
        />
      )
    }
    case 'duration': {
      return (
        <CellDurationRender
          cellValue={cellValue}
          setCellValue={handleChange}
          field={field}
        />
      )
    }
    // Non-Editable Field Types
    case 'autonumber':
    case 'count':
    case 'created_on':
    case 'created_by':
    case 'formula':
    case 'last_modified':
    case 'last_modified_by':
    case 'lookup':
    case 'rollup':
    case 'uuid':
      return <FieldRenderer field={field} record={record} isName={isName} />
    // Field Types not yet implemented
    case 'ai': // WIP
    case 'password': // WIP
    default:
      return <FieldRenderer field={field} record={record} isName={isName} />
  }
}

export default CellRenderer
