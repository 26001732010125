import { FC, ReactElement, useEffect } from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { useBoolean, useLocalStorage } from 'usehooks-ts'

import type { KBItem } from 'src/components/KnowledgeCell/types'
import { useAuth } from 'src/Providers'

interface KBFolderProps {
  folder: KBItem
  children?: ReactElement
  isSubFolder?: boolean
  forceOpen?: boolean
  FolderTitleActions?: JSX.Element
  handleSetSelectedKbItem: (value: KBItem) => void
}

const KBFolder: FC<KBFolderProps> = ({
  folder,
  children,
  isSubFolder = false,
  forceOpen = false,
  FolderTitleActions = null,
  handleSetSelectedKbItem,
}) => {
  const folderIsSubFolder = isSubFolder

  const { currentUser } = useAuth()

  // Keep Accordions Open on state change or refetch
  const [openFolderIds, setOpenFolderIds] = useLocalStorage<number[]>(
    `folder-expanded-${currentUser.parentData.id}-${currentUser.userData.id}`,
    [],
  )

  const handleExpand = (id) => {
    if (!openFolderIds.includes(id)) {
      setOpenFolderIds([...openFolderIds, id])
    }
  }

  const handleCollapse = (id) => {
    setOpenFolderIds([...openFolderIds.filter((savedId) => savedId !== id)])
  }

  const FolderTitle = ({ title, open }) => {
    return (
      <div
        className={`flex w-full items-center ${folderIsSubFolder && 'pl-3'}`}
        data-intercom-target={`folder-title-${open ? 'open' : 'collapsed'}`}
        data-testid={`kb-item-${folder.type}-${folder.id}`}
      >
        <ChevronDownIcon
          className={`h-4 w-4 ${open ? 'rotate-0' : '-rotate-90'} `}
        />
        <h3
          className="line-clamp-1 flex-1 pl-2 text-gray-600"
          data-testid={`folder-title-${open ? 'open' : 'collapsed'}-${title}`}
        >
          {title}
        </h3>
      </div>
    )
  }

  const folderIsExpanded = useBoolean(openFolderIds.includes(folder.id))

  useEffect(() => {
    if (folderIsExpanded.value) {
      handleExpand(folder.id)
    } else {
      handleCollapse(folder.id)
    }
  }, [folderIsExpanded.value])

  useEffect(() => {
    forceOpen && folderIsExpanded.setTrue()

    if (!forceOpen) {
      if (openFolderIds.includes(folder.id)) {
        folderIsExpanded.setTrue()
      } else {
        folderIsExpanded.setFalse()
      }
    }
  }, [forceOpen])

  return (
    <div>
      <Accordion
        disableGutters
        elevation={0}
        expanded={folderIsExpanded.value}
        className={`!overflow-hidden ${
          folderIsSubFolder
            ? '!rounded-none !border-0 !border-b bg-white'
            : '!rounded !border bg-gray-100'
        }`}
      >
        <AccordionSummary
          sx={{
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
          className={`m-0 flex items-center gap-2 p-0 pl-2 pr-4 ${
            folderIsSubFolder && '!rounded-0'
          }`}
          expandIcon={null}
          aria-controls="folder-content"
          id="folder-header"
          onClick={(event) => {
            event.stopPropagation()
            handleSetSelectedKbItem(folder)
            folderIsExpanded.toggle()
          }}
        >
          <div className="flex w-full items-center justify-between gap-5">
            <FolderTitle title={folder.title} open={folderIsExpanded.value} />
            {FolderTitleActions}
          </div>
        </AccordionSummary>
        <AccordionDetails
          onClick={(event) => event.stopPropagation()}
          className={`overflow-hidden border-t p-0 ${
            folderIsSubFolder && 'bg-white'
          }`}
        >
          <div className="overflow-hidden">
            {folderIsExpanded.value && children}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default KBFolder
