import React, { Dispatch, FC, SetStateAction } from 'react'

import { GlobeAltIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'

import { useAuth } from 'src/Providers'
import { published } from 'src/Util'

import { mapsEditRoles } from './ReactMapSidePanel'
import { ReactMap } from './ReactMapSidePanelCell'

interface ReactMapSideItemProps {
  item: ReactMap
  selectedItem: number
  setSelectedItem: Dispatch<SetStateAction<number>>
  DragHandle: React.ReactNode
}

const ReactMapSideItem: FC<ReactMapSideItemProps> = ({
  item,
  selectedItem,
  setSelectedItem,
  DragHandle,
}) => {
  const { hasRole } = useAuth()

  const isPublished = published(item)

  return (
    <div
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={() => {
        setSelectedItem(item.id)
      }}
      role="button"
      className={`flex cursor-pointer items-center gap-2 !border bg-white p-0 py-2 pr-2 text-left ${
        selectedItem === item.id
          ? '!border-indigo-600 !bg-indigo-50 hover:bg-indigo-100'
          : '!border-gray-50 !border-b-gray-200 bg-gray-50 hover:bg-gray-100'
      }`}
    >
      {hasRole(mapsEditRoles) ? DragHandle : <span className="w-[26px]"></span>}
      <span className="wrap line-clamp-1 inline-flex grow py-1">
        <div
          data-testid={`drag-drop-${item.name}`}
          className={'line-clamp-1 grow text-sm text-gray-600'}
        >
          {item.name}
        </div>
      </span>
      {hasRole(mapsEditRoles) && (
        <div className="flex flex-row items-center justify-end gap-1">
          {item.isTemplate && (
            <Tooltip title={'Global Template'}>
              <GlobeAltIcon className={'h-4 w-4 text-blue-400'} />
            </Tooltip>
          )}
          {isPublished && (
            <span className="rounded-full bg-emerald-100 px-2 py-0.5 text-xs text-emerald-600">
              Published
            </span>
          )}
          {!isPublished && (
            <span className="rounded-full bg-amber-100 px-2 py-0.5 text-xs text-amber-600">
              Draft
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default ReactMapSideItem
