import { FC } from 'react'

import {
  directoryListClientQuery,
  directoryListClientQueryVariables,
} from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import InformerDirectoryList from 'src/components/InformerDirectoryList'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

import { default as EmptyComponent } from '../Library/Empty'

export const beforeQuery = (props) => {
  const { updateSelectedMembership, ...queryProps } = props
  return {
    variables: queryProps,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  }
}

const clientQuery = gql`
  query directoryListClientQuery {
    directoryList: membershipsByClient(activeOnly: true) {
      id
      clientId
      role
      membershipType
      division
      department
      team
      position
      user {
        id
        avatarUrl
        email
        name
        position
      }
    }
  }
`

export function QUERY() {
  return clientQuery
}

export type AzDirectory = {
  [key: string]: directoryListClientQuery['directoryList']
}

export const afterQuery = ({ directoryList }: directoryListClientQuery) => {
  const sortDirectoryList: directoryListClientQuery['directoryList'] =
    JSON.parse(JSON.stringify(directoryList))

  sortDirectoryList.sort((a, b) =>
    a.user.name?.toLowerCase().localeCompare(b.user.name?.toLowerCase()),
  )

  const azDirectoryList: AzDirectory = {}
  let currentLetter = '?'
  let currentLetterArray: directoryListClientQuery['directoryList'] = []

  for (const item of sortDirectoryList) {
    const firstLetter = item.user.name?.charAt(0)?.toUpperCase()
    if (currentLetter === '?') {
      currentLetter = firstLetter
      currentLetterArray.push(item)
      azDirectoryList[currentLetter] = currentLetterArray
    } else if (currentLetter === firstLetter) {
      currentLetterArray.push(item)
    } else {
      currentLetter = firstLetter
      currentLetterArray = []
      currentLetterArray.push(item)
      azDirectoryList[currentLetter] = currentLetterArray
    }
  }

  return { azDirectoryList, directoryList }
}

export const Loading = () => (
  <div
    className="flex items-center justify-center"
    style={{ minHeight: 'calc(100vh - 80px)' }}
  >
    <LoadingSpinner />
  </div>
)

export const Empty = () => {
  return (
    <div className="flex h-[calc(100vh-64px)]">
      <div className="grid grow place-items-center p-1">
        <EmptyComponent title="No Memberships Found" />
      </div>
    </div>
  )
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

type SuccessProps = CellSuccessProps<
  directoryListClientQuery,
  directoryListClientQueryVariables
> & {
  azDirectoryList: AzDirectory
  updateSelectedMembership: (id: number) => void
}

export const Success: FC<SuccessProps> = ({
  azDirectoryList,
  directoryList,
  updateSelectedMembership,
}) => {
  return (
    <InformerDirectoryList
      azDirectoryList={azDirectoryList}
      directoryList={directoryList}
      updateSelectedMembership={updateSelectedMembership}
    />
  )
}
