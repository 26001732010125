// TODO: marquee needs better mobile support
// TODO: add support for client/org colours
// TODO: associate announcements with membershipGroup

import { FC } from 'react'

import { MegaphoneIcon } from '@heroicons/react/24/outline'
import Marquee from 'react-fast-marquee'

import useAnalytics from 'src/lib/hooks/useAnalytics'

import { Announcement } from '../AnnouncerBarCell'

interface AnnouncerBarProps {
  events?: Announcement[]
}

const AnnouncerBar: FC<AnnouncerBarProps> = ({ events }) => {
  const { trackEvent } = useAnalytics()
  return (
    <Marquee gradient={false} pauseOnHover={true} speed={40} delay={2}>
      {events.map((event) => (
        <div
          key={event.id}
          className="mx-6 rounded-lg border border-black/5 bg-gray-100"
        >
          <div className="p-1">
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-1 items-center">
                <span className="flex rounded-lg bg-white p-1">
                  <MegaphoneIcon
                    className="h-5 w-5 text-gray-800"
                    aria-hidden="true"
                  />
                </span>
                <p className="text-gray700 ml-3 truncate text-sm font-light">
                  <span className="md:hidden">{event.eventBody}</span>
                  <span className="hidden md:inline">{event.eventBody}</span>
                </p>
              </div>
              {event.eventLink && (
                <div className="order-3 ml-4 mt-0 sm:order-2 sm:mt-0 sm:w-auto">
                  <a
                    href={event.eventLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center rounded-md border border-transparent bg-white px-2 py-1 text-sm font-medium text-[#0E1C26] shadow-sm hover:bg-indigo-50"
                    onClick={() => {
                      trackEvent('Announcer', 'Hyperlink click', {
                        linkTitle: event.eventBody,
                      })
                    }}
                  >
                    {event.eventButtonText}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </Marquee>
  )
}

export default AnnouncerBar
