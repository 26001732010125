import { useState } from 'react'

import { NoSymbolIcon } from '@heroicons/react/24/solid'

import Button from 'src/components/Library/Button/Button'
import { SearchField } from 'src/components/Library/SearchField/SearchField'
import { useAuth } from 'src/Providers'

interface AliasHeaderProps {
  selectAlias: (clientId: number) => void
  handleSearchChange: (searchString: string) => void
}

const AliasHeader = (props: AliasHeaderProps) => {
  const { currentUser } = useAuth()
  const { selectAlias, handleSearchChange } = props
  const [search, setSearch] = useState('')

  return (
    <div className="sticky top-0 z-50 bg-white pb-2">
      <h1 className={'mb-2 text-xl text-gray-600'}>{'Enter Support Mode'}</h1>
      <div className="relative mt-1 rounded-md">
        <div className="mb-2 flex min-w-0 flex-1 justify-between">
          <div>
            <SearchField
              value={search}
              onChange={(value) => {
                setSearch(value)
                handleSearchChange(value)
              }}
              placeholder={'Search Clients'}
            />
          </div>

          {currentUser.isClientAlias && (
            <Button
              buttonDataTestId="alias-switcher-return-to-stafflink"
              size="small"
              fullWidth={false}
              onClick={() => {
                selectAlias(null)
              }}
            >
              <NoSymbolIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              Return to Stafflink
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default AliasHeader
