import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react'

import { PROD_CLIENT } from 'api/src/common/enums'
import { TableName, ACLPrincipalType } from 'types/graphql'

import Switch from 'src/components/Library/Switch/Switch'
import useACL, { SaveACLFn, DeleteACLFn } from 'src/lib/hooks/ACL/useACL'
import { useAuth } from 'src/Providers'

import { Record } from '../RecordSettingsModal'

interface AccessSettingsProps {
  record: Record
  principalTypes?: ACLPrincipalType[]
  onSetNewRecord: (record: Record) => void
}
export interface AccessMethods {
  saveACL: SaveACLFn
  deleteACL: DeleteACLFn
}

const AccessSettings: ForwardRefRenderFunction<
  AccessMethods,
  AccessSettingsProps
> = (
  {
    record,
    principalTypes = ['MEMBERSHIP', 'MEMBERSHIPGROUP'],
    onSetNewRecord,
  },
  ref,
) => {
  const { currentUser, hasRole } = useAuth()
  const { AccessControlList, saveACL, deleteACL } = useACL({
    resourceType: record.type as TableName,
    resourceId: record.id,
    principalTypes,
  })

  const clientId = currentUser.membershipData.clientId

  // Expose the incrementCount method through the ref system
  useImperativeHandle(ref, () => ({
    saveACL,
    deleteACL,
  }))

  return Object.prototype.hasOwnProperty.call(record, 'isGlobal') &&
    hasRole(['SUPERADMIN', 'STAFFLINK']) &&
    clientId === PROD_CLIENT.STAFFLINK ? (
    <>
      <div className="mb-2 flex">
        <span className="my-auto mr-auto flex">
          Global (Share with all clients)
        </span>
        <Switch
          data-testid="access-control-list-restrict-is-global"
          className="my-auto flex"
          checked={record?.isGlobal}
          disabled={hasRole(['STAFFLINK'])}
          onChange={(e) =>
            onSetNewRecord({ ...record, isGlobal: e.target.checked })
          }
        />
      </div>
      {!record?.isGlobal && <AccessControlList />}
    </>
  ) : (
    <AccessControlList />
  )
}

export default forwardRef<AccessMethods, AccessSettingsProps>(AccessSettings)
