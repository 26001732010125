import { FC } from 'react'

import { routes } from '@redwoodjs/router'

import SettingsClientConfigurationCell from '../SettingsClientConfigurationCell'

import OnboardingSubNavigation, {
  ButtonOption,
} from './OnboardingSubNavigation'

const OnboardingSettings: FC = () => {
  const backButton: ButtonOption = {
    label: 'Onboarding',
    url: routes.onboarding(),
  }

  const nextButton: ButtonOption = {
    label: 'Members',
    url: routes.onboardingMembers(),
  }

  return (
    <div className="mx-auto max-w-6xl p-4 py-24">
      <div className="flex flex-col gap-8">
        <OnboardingSubNavigation
          title="Client Settings"
          backButton={backButton}
          nextButton={nextButton}
        />
        <div className="rounded-xl border bg-white p-8">
          <SettingsClientConfigurationCell />
        </div>
      </div>
    </div>
  )
}

export default OnboardingSettings
