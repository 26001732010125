import { FC } from 'react'

interface AutomationEmptyDisplayProps {
  title: string
  subtitle: string
  icon: JSX.Element
}

const AutomationEmptyDisplay: FC<AutomationEmptyDisplayProps> = ({
  title,
  subtitle,
  icon,
}) => {
  return (
    <div className="mt-6 py-10 text-center text-lg font-light italic text-gray-500">
      {icon}
      <p>{title}</p>
      <p className="text-md">{subtitle}</p>
    </div>
  )
}

export default AutomationEmptyDisplay
