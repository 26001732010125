import {
  DocumentTextIcon,
  MapIcon,
  PuzzlePieceIcon,
  SparklesIcon,
  VideoCameraIcon,
  CodeBracketIcon,
} from '@heroicons/react/24/outline'

const TaskTypeLabel = (props) => {
  const { parentClassName, lessonTypeText } = props

  const renderIcon = () => {
    switch (lessonTypeText) {
      case 'Video':
        return <VideoCameraIcon className="mx-1 my-0.5 h-4 w-4 text-gray-400" />
      case 'Quiz':
        return <PuzzlePieceIcon className="mx-1 my-0.5 h-4 w-4 text-gray-400" />
      case 'Process Map':
        return <MapIcon className="mx-1 my-0.5 h-4 w-4 text-gray-400" />
      case 'Text':
        return (
          <DocumentTextIcon className="mx-1 my-0.5 h-4 w-4 text-gray-400" />
        )
      case 'Embed':
        return <CodeBracketIcon className="mx-1 my-0.5 h-4 w-4 text-gray-400" />
      default:
        return <SparklesIcon className="mx-1 my-0.5 h-4 w-4 text-indigo-600" />
    }
  }

  return (
    <div className={parentClassName}>
      <div className="w-18 absolute ml-14 flex h-5 flex-row items-center justify-center space-x-1 rounded-full bg-indigo-100 p-0.5 px-2.5">
        {renderIcon()}
        <span className="w-8.5 order-1 h-4 flex-none flex-grow-0 whitespace-nowrap text-center text-xs font-medium leading-4 text-indigo-800">
          {lessonTypeText}
        </span>
      </div>
    </div>
  )
}

export default TaskTypeLabel
