import * as React from 'react'
import { FC } from 'react'

import { BuildingOfficeIcon } from '@heroicons/react/24/outline'

interface OrganisationNodeProps {
  data: any // not typed
}

const OrganisationNode: FC<OrganisationNodeProps> = ({ data }) => {
  return (
    <div
      className={
        'relative w-[450px] cursor-auto rounded-xl border-2 border-gray-300 bg-white p-6 py-10 text-center'
      }
    >
      <BuildingOfficeIcon className="absolute left-[50%] top-[-40px] h-20 w-20 translate-x-[-50%] rounded-full bg-gray-400 p-2 text-white" />

      <p className="pt-6 text-[32px] font-bold uppercase text-gray-600">
        {data.position}
      </p>
    </div>
  )
}

export default OrganisationNode
