import React, { FC, useEffect, useState } from 'react'

import Stack from '@mui/material/Stack'
import {
  FindUpgradeAddonQuery,
  FindUpgradeAddonQueryVariables,
  UpdateSubscriptionAddons,
  UpdateSubscriptionAddonsVariables,
} from 'types/graphql'
import { useBoolean } from 'usehooks-ts'

import { navigate, routes } from '@redwoodjs/router'
import { CellFailureProps, CellSuccessProps, useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Addon from 'src/components/Billing/Addon/Addon'
import { CustomAddon } from 'src/components/Billing/ProductPlansCell/ProductPlansCell'
import { default as EmptyData } from 'src/components/Library/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import Modal from 'src/components/Modal/Modal'
import { UPDATE_SUBSCRIPTION_ADDONS } from 'src/lib/queries/Settings/Billing/stripeSubscriptions'
import { formatCurrency } from 'src/Util'

export const QUERY = gql`
  query FindUpgradeAddonQuery {
    products: productPlans(getEnterprise: true) {
      addons {
        id
        name
        description
        defaultPrice {
          id
          currency
          unitAmount
          tiers {
            flatAmountDecimal
            unitAmountDecimal
            upTo
          }
        }
        yearlyPrice {
          id
          currency
          unitAmount
          tiers {
            flatAmountDecimal
            unitAmountDecimal
            upTo
          }
        }
        metadata {
          isFixedPrice
          tier
        }
      }
    }
    currentPlan: dashboardData {
      items
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => <EmptyData />

export const Failure = ({
  error,
}: CellFailureProps<FindUpgradeAddonQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

type Props = CellSuccessProps<
  FindUpgradeAddonQuery,
  FindUpgradeAddonQueryVariables
> & {
  addonId: string
  currentPlan: unknown
}

export type ProductAddon = FindUpgradeAddonQuery['products']['addons'][0]

export interface SubscriptionItem {
  price: {
    recurring: {
      interval: string
    }
    product: string
  }
  quantity: number
}

export const Success: FC<Props> = ({ products, addonId, currentPlan }) => {
  const { addons } = products
  const items = currentPlan.items as unknown as SubscriptionItem[]

  const currentBillingPeriod =
    items[0].price?.recurring?.interval === 'year' ? 'yearly' : 'monthly'

  // get the item from items that matches the addonId
  const currentCustomerAddon = items
    .filter((item) => item.price?.product === addonId)
    .map((addon) => ({
      id: addon.price.product,
      quantity: addon.quantity,
    }))[0]

  const [billingPeriod, setBillingPeriod] = useState<'monthly' | 'yearly'>(
    currentBillingPeriod,
  )
  const [selectedAddon, setSelectedAddon] = useState<CustomAddon>()
  const orderSummaryModal = useBoolean(false)
  const [loading, setLoading] = useState(false)
  const [updateAddons] = useMutation<
    UpdateSubscriptionAddons,
    UpdateSubscriptionAddonsVariables
  >(UPDATE_SUBSCRIPTION_ADDONS, {
    onCompleted: () => {
      toast.success('Subscription updated successfully')
      setLoading(false)
    },
    onError: () => {
      toast.error('Failed to update subscription')
      setLoading(false)
    },
  })
  const handleCheckout = () => {
    setLoading(true)
    updateAddons({
      variables: {
        ...(selectedAddon.upTo && { unitAmount: selectedAddon.upTo }),
        stripePriceId:
          billingPeriod === 'monthly'
            ? selectedAddon.defaultPrice.id
            : selectedAddon.yearlyPrice.id,
      },
    })
      .then(() => {
        navigate(routes.clientBillingConfirmation({ status: 'success' }))
      })
      .catch(() => {
        routes.clientBillingConfirmation({ status: 'error' })
      })
  }

  const handleModalClose = () => {
    orderSummaryModal.setFalse()
    setSelectedAddon(undefined)
  }

  useEffect(() => {
    if (selectedAddon) {
      orderSummaryModal.setTrue()
    }
  }, [selectedAddon])

  if (addons.length < 1) {
    return (
      <div className={'mt-12'}>
        <EmptyData
          title="No Addons Available"
          description="There are currently no addons available for purchase. Please check back later. If you believe this is an error, please contact support."
        />
      </div>
    )
  }

  return (
    <div>
      <Stack spacing={3} justifyContent={'center'} alignItems={'center'}>
        <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Select Your Hubs Addons
          </p>
        </div>
        <div className={'w-full'}>
          {addons.map((addon) => (
            <div className={'m-auto my-4 w-full'} key={addon.id}>
              <Addon
                addon={addon}
                isSelected={addon.id === addonId}
                disabled={addon.id === addonId}
                onClick={(passsedAddon) =>
                  setSelectedAddon(passsedAddon as CustomAddon)
                }
                billingPeriod={billingPeriod}
                currentCustomerAddon={currentCustomerAddon}
              />
            </div>
          ))}
        </div>
      </Stack>
      <Modal
        open={orderSummaryModal.value}
        title={'Order Summary'}
        footerVisible
        onClose={handleModalClose}
        onCancel={handleModalClose}
        confirmText={'Checkout'}
        onConfirm={handleCheckout}
        loading={loading}
      >
        <div className="mx-auto my-12 w-full max-w-lg">
          <div className="flow-root">
            <ul className="-my-6 divide-y divide-gray-200">
              <li className="flex space-x-6 py-6">
                <div className="flex-auto">
                  <div className="space-y-1 sm:flex sm:items-start sm:justify-between sm:space-x-6">
                    <div className="flex-auto space-y-1 text-sm font-medium">
                      <h3 className="text-gray-900">{selectedAddon?.name}</h3>
                      <h3 className="text-xs font-medium text-gray-500">
                        {selectedAddon?.description}
                      </h3>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
            <div className="flex justify-between">
              <dt>Subtotal</dt>
              <dd className="text-gray-900">
                {selectedAddon &&
                  formatCurrency(
                    (billingPeriod === 'monthly'
                      ? selectedAddon?.defaultPrice.unitAmount
                      : selectedAddon?.yearlyPrice?.unitAmount) / 100,
                    'en-AU',
                    'AUD',
                  )}
              </dd>
            </div>
            <div className="flex justify-between">
              <dt>GST</dt>
              <dd className="text-gray-900">not included</dd>
            </div>
            <div className="flex justify-between border-t border-gray-200 pt-6 text-gray-900">
              <dt className="text-base">Total</dt>
              <dd className="text-base">
                {selectedAddon &&
                  formatCurrency(
                    (billingPeriod === 'monthly'
                      ? selectedAddon?.defaultPrice.unitAmount
                      : selectedAddon?.yearlyPrice?.unitAmount) / 100,
                    'en-AU',
                    'AUD',
                  )}
              </dd>
            </div>
          </dl>
        </div>
      </Modal>
    </div>
  )
}
