import React from 'react'

import {
  ArrowRightCircleIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  MinusCircleIcon,
} from '@heroicons/react/24/solid'
import { Chip, Tooltip } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  BASEROW_IMPORT_MATCH_TYPE,
  DetailedFieldRecord,
  DetailedTableRecord,
  DetailedViewRecord,
} from 'api/src/common/baserowImporterTypes'

import { FieldOptionForChipType } from 'src/components/BaserowImporter/utils'

export const getItemMatchStatus = (
  recordItem: DetailedTableRecord | DetailedViewRecord | DetailedFieldRecord,
  recordType: string,
) => {
  const recordIndexName = `matching${recordType}`
  if (!recordItem[recordIndexName]?.matchType) {
    return (
      <Tooltip title={'Match Is Not Defined'}>
        <ExclamationCircleIcon className={'h-5 w-5 text-red-500'} />
      </Tooltip>
    )
  }

  switch (recordItem[recordIndexName].matchType) {
    case BASEROW_IMPORT_MATCH_TYPE.DETECTED:
      return (
        <Tooltip title={'Match Automatically Detected'}>
          <CheckCircleIcon className={'h-5 w-5 text-green-500'} />
        </Tooltip>
      )
    case BASEROW_IMPORT_MATCH_TYPE.MANUAL:
      return (
        <Tooltip title={'Match Manually Configured'}>
          <CheckCircleIcon className={'h-5 w-5 text-orange-500'} />
        </Tooltip>
      )
    case BASEROW_IMPORT_MATCH_TYPE.CREATE:
      return (
        <Tooltip title={`${recordType} Will Be Created`}>
          <ArrowRightCircleIcon className={'h-5 w-5 text-blue-500'} />
        </Tooltip>
      )
    case BASEROW_IMPORT_MATCH_TYPE.IGNORE:
      return (
        <Tooltip title={`${recordType} Will Be Ignored`}>
          <MinusCircleIcon className={'h-5 w-5 text-gray-500'} />
        </Tooltip>
      )
  }
}

export const returnOptionChip = (details: FieldOptionForChipType[]) => {
  return details
    .filter((detail) => detail.title || detail.label)
    .map((detail, index) => {
      return (
        <Stack key={index} direction={'row'} spacing={1} className={'w-full'}>
          {detail.title && (
            <Typography className={'capitalize'}>{detail.title}</Typography>
          )}
          <Chip
            size={'small'}
            label={detail.label ? detail.label.slice(0, 50) : ''}
            variant={'outlined'}
            sx={{
              ...(detail.color ? { backgroundColor: detail.color } : {}),
            }}
            className={`rounded text-sm ${
              !detail.color ? 'bg-violet-100 text-violet-500' : ''
            } px-2 capitalize`}
          />
        </Stack>
      )
    })
}
