import { FC } from 'react'

import { Divider } from '@mui/material'

import { routes } from '@redwoodjs/router'

import GroupsDataGridCell from '../GroupsDataGridCell'

import OnboardingSubNavigation, {
  ButtonOption,
} from './OnboardingSubNavigation'

const OnboardingMembershipGroups: FC = () => {
  const backButton: ButtonOption = {
    label: 'Members',
    url: routes.onboardingMembers(),
  }

  const nextButton: ButtonOption = {
    label: 'Home Links',
    url: routes.onboardingHomeLinks(),
  }

  return (
    <div className="mx-auto max-w-6xl p-4 py-24">
      <div className="flex flex-col gap-8">
        <OnboardingSubNavigation
          title="Groups"
          backButton={backButton}
          nextButton={nextButton}
        />
        <div className="rounded-xl border bg-white px-2 pt-4">
          <div className="mt-14 w-full px-10">
            <div className="flex flex-wrap items-center justify-between gap-4">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Membership Groups
                </h3>
                <p className="max-w-2xl text-sm text-gray-500">
                  Create Groups to control what content members can access.
                </p>
              </div>
            </div>
            <Divider className="mt-6" />
          </div>
          <div className="flex min-h-[400px] items-center">
            <div className="w-full">
              <GroupsDataGridCell />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnboardingMembershipGroups
