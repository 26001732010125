import React, { FC, useMemo } from 'react'

import Accordion from 'src/components/Library/Accordion/Accordion'
import { handleFilterEmailByRating } from 'src/components/SentimentAnalysis/SentimentAnalysisUtils'
import SentimentEmailsCell from 'src/components/SentimentAnalysis/SentimentEmailsCell'
import useSentimentStore from 'src/lib/stores/sentimentStore'

import {
  SentimentAnalysisLandlordAccordionProps,
  SentimentAnalysisRatingOptions,
} from '../SentimentAnalysisHelper'

import SentimentAnalysisItemHeader from './SentimentAnalysisItemHeader'

const SentimentAnalysisLandlordAccordion: FC<
  SentimentAnalysisLandlordAccordionProps
> = ({
  membership,
  filterDateRange,
  filterESIRating,
  filterByFlag,
  filterByClientStatus,
  debouncedSearchText,
}) => {
  const [selectedBucket] = useSentimentStore((state) => [state.bucketOption])

  const filteredLandlords = useMemo(() => {
    if (filterESIRating === SentimentAnalysisRatingOptions.ALL) {
      return membership?.landlordsWithEmailsAndSentimentScores
    }

    return membership?.landlordsWithEmailsAndSentimentScores.filter(
      (contact) => {
        const filterValue = handleFilterEmailByRating(contact, filterESIRating)
        return filterValue
      },
    )
  }, [filterESIRating, membership?.landlordsWithEmailsAndSentimentScores])

  const totalEmails: number = useMemo(() => {
    return filteredLandlords.reduce((total: number, landlord) => {
      return total + landlord.totalEmailsCount
    }, 0)
  }, [filteredLandlords])

  return (
    <Accordion
      title={
        <SentimentAnalysisItemHeader
          propertyOwner={membership.memberDetails}
          hideEmailScore={true}
          avatarUrl={membership?.user?.avatarUrl || '/'}
          totalEmails={totalEmails}
          inboxEmail={membership?.user?.email}
        />
      }
      removeDetailsPadding={true}
      GATrackerCategory="Sentiment Analysis"
      GATrackerEventName="click admin ESI accordion"
    >
      {membership.id && (
        <SentimentEmailsCell
          filterDateRange={filterDateRange}
          membershipId={membership.id}
          filterESIRating={filterESIRating}
          filterByFlag={filterByFlag}
          filterByClientStatus={filterByClientStatus}
          selectedBucket={selectedBucket}
          debouncedSearchText={debouncedSearchText}
        />
      )}
    </Accordion>
  )
}

export default SentimentAnalysisLandlordAccordion
