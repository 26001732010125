import { FC, useState } from 'react'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { UPDATE_ASSET_ITEM } from 'src/lib/queries/Settings/AssetLibrary/AssetLibrary'

import AssetLibraryItem from '../AssetLibraryItem/AssetLibraryItem'
import AssetLibraryToolbar from '../AssetLibraryToolbar/AssetLibraryToolbar'
import { Asset } from '../types'
interface AssetLibraryGridProps {
  assets: Asset[]
  refetch: () => Promise<unknown>
}

const AssetLibraryGrid: FC<AssetLibraryGridProps> = ({ assets, refetch }) => {
  const [isDeleting, setIsDeleting] = useState(false)

  // UPDATE ASSET ITEM
  // Soft delete - update the isGlobal without destroying the reference object

  const [updateStorageObjectMutation] = useMutation(UPDATE_ASSET_ITEM, {
    onCompleted: async () => {
      await refetch()
      setIsDeleting(false)
    },
    onError: (error) => {
      setIsDeleting(false)
      toast.error(error.message, {
        duration: 5000,
      })
    },
  })

  function updateAssetItem(id: number) {
    // Start Update
    setIsDeleting(true)

    // Update card by ID
    updateStorageObjectMutation({
      variables: {
        id: id,
        input: {
          isGlobal: false,
        },
      },
    })
  }

  return (
    <div>
      <div className="bg-white p-5">
        <AssetLibraryToolbar refetch={refetch} />

        {!assets.length ? (
          <p className="w-full p-10 text-center text-gray-400">
            No Assets found.
          </p>
        ) : (
          <div
            className="grid w-full gap-4"
            style={{
              gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
              gridTemplateRows: 'repeat(auto-fill, minmax(80px,auto))',
            }}
          >
            {assets.map((asset, index) => (
              <AssetLibraryItem
                key={index}
                asset={asset}
                deleteAssetItem={updateAssetItem}
                isDeleting={isDeleting}
              />
            ))}
          </div>
        )}
        {isDeleting && (
          <div className="absolute left-0 top-0 z-10 h-full w-full bg-gray-100 opacity-50"></div>
        )}
      </div>
    </div>
  )
}

export default AssetLibraryGrid
