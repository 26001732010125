import { FC } from 'react'

import Loading from 'src/components/Library/Loading/Loading'

const AutomationFetchLoading: FC<{ text?: string }> = ({
  text = 'Fetching Workspace Information',
}) => {
  return (
    <div className="grid grow place-items-center">
      <div className="flex flex-col items-center justify-between gap-4">
        <Loading />
        <p className="text-gray-400">{text}</p>
      </div>
    </div>
  )
}
export default AutomationFetchLoading
