import React from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { DetailedViewRecord } from 'api/src/common/baserowImporterTypes'

interface BaserowImporterViewInformationModalProps {
  enrichedData: DetailedViewRecord
}

const BaserowImporterModalViewDetailsFieldVisibility = ({
  enrichedData,
}: BaserowImporterViewInformationModalProps) => {
  return (
    <Accordion>
      <AccordionSummary
        className="rounded border border-solid border-gray-300"
        expandIcon={<ChevronDownIcon className="h-5 w-5 cursor-pointer" />}
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'column', // Display details in a column
            alignItems: 'flex-start', // Align details to the left
          },
        }}
      >
        <Typography fontWeight={'bold'}>Field Visibility</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          {enrichedData?.details?.columnOrder?.map((col) => {
            const color = col.visibility ? 'primary' : 'secondary'
            const label = col.visibility ? 'Visible' : 'Hidden'
            return (
              <Stack direction={'row'} key={col.columnId} spacing={3}>
                <Chip
                  label={label}
                  color={color}
                  size={'small'}
                  variant={'outlined'}
                />
                <Typography>{col.name}</Typography>
              </Stack>
            )
          })}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default BaserowImporterModalViewDetailsFieldVisibility
