import React, { FC, useEffect, useState } from 'react'

import { Divider, TextField } from '@mui/material'
import * as OTPAuth from 'otpauth'
import QRCode from 'react-qr-code'

import { navigate, routes } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import useAnalytics from 'src/lib/hooks/useAnalytics'

import Button from '../Library/Button/Button'

import FormErrorMessage from './FormErrorMessage'
import { FormError } from './LoginForm'
import { validateMFAInput } from './LoginFormUtils'

export interface MFAProps {
  getTOTP: OTPAuth.TOTP
  onSubmitQR: (toptCode: string) => void
  MFAError: string
  mandatory: boolean
  setFailedLoginCount: React.Dispatch<React.SetStateAction<number>>
}

export const MFA: FC<MFAProps> = ({
  getTOTP,
  onSubmitQR,
  MFAError,
  mandatory,
  setFailedLoginCount,
}) => {
  const [showQR, setShowQR] = useState(false)
  const [mfaCode, setMfaCode] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [secretCopied, setSecretCopied] = useState<boolean>(false)
  const { trackEvent } = useAnalytics()

  const [MFAFormError, setMFAFormError] = useState<FormError>({
    error: false,
    message: [],
  })

  const copySetupKey = () => {
    const toptCode = getTOTP.secret.base32
    navigator.clipboard.writeText(toptCode).then(() => {
      setSecretCopied(true)
      toast.success('MFA Key Copied to Clipboard')
    })
  }

  const handleMFASubmit = () => {
    setIsLoading(true)
    setMFAFormError({
      error: false,
      message: [],
    })

    const mfaInput = validateMFAInput(mfaCode)
    if (mfaInput.valid) {
      onSubmitQR(mfaCode)
    } else {
      setFailedLoginCount((currentValue) => currentValue + 1)
      setMFAFormError({
        error: true,
        message: mfaInput.message,
      })
    }
    setIsLoading(false)
    trackEvent('Pages', 'click verify mfa')
  }

  useEffect(() => {
    if (MFAError) {
      setMFAFormError({
        error: true,
        message: [MFAError],
      })
    }
    setIsLoading(false)
  }, [MFAError])

  useEffect(() => {
    if (secretCopied) {
      // Start the timer
      const timeoutId = setTimeout(() => {
        // Timeout logic
        setSecretCopied(false)
      }, 2500)

      // Cleanup function to clear the timer if the component is unmounted or value is updated
      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [secretCopied])

  return (
    <div className="flex flex-1 flex-col gap-4">
      {showQR === true && (
        <>
          <h2 className="text-center text-xl font-bold">
            Use an authenticator app to scan in the QR Code Below
          </h2>
          <div className="my-2 flex w-full justify-center">
            <QRCode value={getTOTP.toString()} />
          </div>

          <p className="text-center text-sm text-gray-600">
            Enter the code from your authenticator app.
          </p>

          <TextField
            id="mfa-code"
            name="mfa-code"
            data-testid="mfa-register-code-field"
            variant="outlined"
            fullWidth={true}
            label="Authentication Code"
            onChange={(e) => setMfaCode(e.target.value)}
            error={MFAFormError.error}
            helperText={<FormErrorMessage messages={MFAFormError.message} />}
          />
          <Button loading={isLoading} onClick={handleMFASubmit}>
            Verify Code
          </Button>
          <Divider className="my-6" />

          <p className="text-center text-sm text-gray-600">
            If you can not scan the barcode,&nbsp;
            <button
              className="bg-transparent text-blue-500 underline hover:text-blue-700"
              onClick={() => {
                copySetupKey()
                trackEvent('Pages', 'click copy mfa key')
              }}
            >
              copy the security key
            </button>
            &nbsp; for manual setup.
          </p>
        </>
      )}
      {showQR === false && (
        <>
          <h2 className="text-center text-xl font-bold">
            Enable Multi-Factor Authentication
          </h2>
          <p className="mb-8 text-center text-sm text-gray-600">
            {mandatory === true && (
              <span data-testid="mfa-mandatory-message">
                You must enable MFA to continue.
              </span>
            )}
            {mandatory === false && (
              <span data-testid="mfa-optional-message">
                Multi-Factor Authentication is highly recommended to secure your
                account.
              </span>
            )}
          </p>
          <Button
            buttonDataTestId="setup-mfa-button"
            onClick={() => {
              setShowQR(true)
              trackEvent('Pages', 'click setup mfa')
            }}
          >
            Setup MFA (Takes less than a minute)!
          </Button>
        </>
      )}
      {mandatory === false && (
        <Button
          variant="text"
          buttonDataTestId="skip-mfa-button"
          onClick={() => {
            navigate(routes.home())
            trackEvent('Pages', 'click skip mfa')
          }}
        >
          Skip for Now
        </Button>
      )}
    </div>
  )
}
