import type { Dispatch, FC, SetStateAction } from 'react'

import { ChartBarIcon, ChartPieIcon } from '@heroicons/react/24/outline'

import { CardType } from '../../lib/enums'

interface CardSettingsChartTypeProps {
  chartType: CardType
  setChartType: Dispatch<SetStateAction<CardType>>
}

const CardSettingsChartType: FC<CardSettingsChartTypeProps> = ({
  chartType,
  setChartType,
}) => {
  const chartTypes = [
    {
      name: 'Pie',
      value: CardType.CHART_PIE,
      icon: <ChartPieIcon className="h-8 w-8" />,
    },
    {
      name: 'Bar',
      value: CardType.CHART_BAR,
      icon: <ChartBarIcon className="h-8 w-8" />,
    },
  ]

  return (
    <div className="flex flex-wrap gap-2 px-4">
      {chartTypes.map((chartItem) => (
        <button
          key={chartItem.value}
          className="flex w-16 flex-col items-center justify-center rounded-lg border-2 p-2 hover:bg-gray-100"
          style={{
            borderColor: chartType === chartItem.value ? '#6366f1' : '#9e9e9e',
            color: chartType === chartItem.value ? '#6366f1' : '#9e9e9e',
          }}
          onClick={() => setChartType(chartItem.value)}
        >
          {chartItem.icon}
          <p>{chartItem.name}</p>
        </button>
      ))}
    </div>
  )
}

export default CardSettingsChartType
