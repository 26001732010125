import { useState, type Dispatch, type FC, type SetStateAction } from 'react'

import * as IconSet from '@heroicons/react/24/outline'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
import { TextField } from '@mui/material'
import {
  DeleteColor,
  DeleteColorVariables,
  type CreateColor,
  type CreateColorVariables,
  type UpdateColor,
  type UpdateColorVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import {
  QUERY as ColorQuery,
  type RecordColor,
} from 'src/components/HubDash/CardExpand/InfoPanel/ColorModuleCell'
import { QUERY as LayoutQuery } from 'src/components/HubDash/HubDashLayoutListCell'
import { getHexForColor } from 'src/components/HubDash/lib/baserow/baserowColors'
import type { HubDashCardType } from 'src/components/HubDash/lib/types'
import Button from 'src/components/Library/Button/Button'
import { SearchField } from 'src/components/Library/SearchField/SearchField'

interface ColorModuleProps {
  colorList: RecordColor[]
  selectedColor: string
  setSelectedColor: Dispatch<SetStateAction<string>>
  card: HubDashCardType
  view: any // not typed yet
  records: any[] // not typed yet
}

const ColorModule: FC<ColorModuleProps> = ({
  colorList,
  selectedColor,
  setSelectedColor,
  view,
  records,
  card,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [selectedIcon, setSelectedIcon] = useState('')
  const [selectedName, setSelectedName] = useState('')
  const [selectedDescription, setSelectedDescription] = useState('')
  const [iconSearchValue, setIconSearchValue] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const CREATE_COLOR = gql`
    mutation CreateColor($input: CreateHubDashColorMapInput!) {
      createHubDashColorMap(input: $input) {
        id
        name
        description
        colorName
        icon
      }
    }
  `

  const [createColor] = useMutation<CreateColor, CreateColorVariables>(
    CREATE_COLOR,
    {
      onCompleted: () => {
        setEditMode(false)
        setSelectedIcon('')
        setSelectedName('')
        setSelectedDescription('')
        setIsSaving(false)
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: ColorQuery, variables: { hubDashCardId: card.id } },
        { query: LayoutQuery },
      ],
    },
  )

  const UPDATE_COLOR = gql`
    mutation UpdateColor($id: Int!, $input: UpdateHubDashColorMapInput!) {
      updateHubDashColorMap(id: $id, input: $input) {
        id
        name
        description
        colorName
        icon
      }
    }
  `

  const [updateColor] = useMutation<UpdateColor, UpdateColorVariables>(
    UPDATE_COLOR,
    {
      onCompleted: () => {
        setEditMode(false)
        setSelectedIcon('')
        setSelectedName('')
        setSelectedDescription('')
        setIsSaving(false)
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: ColorQuery, variables: { hubDashCardId: card.id } },
        { query: LayoutQuery },
      ],
    },
  )

  const allColorList = []
  allColorList.push({
    id: 0,
    name: 'All Records',
    description: view.name,
    colorName: 'white',
    recordCount: records.length,
    icon: 'HomeIcon',
  })

  const DELETE_COLOR = gql`
    mutation DeleteColor($id: Int!) {
      deleteHubDashColorMap(id: $id) {
        id
      }
    }
  `

  const [deleteColor] = useMutation<DeleteColor, DeleteColorVariables>(
    DELETE_COLOR,
    {
      onCompleted: () => {
        setEditMode(false)
        setSelectedIcon('')
        setSelectedName('')
        setSelectedDescription('')
        setIsDeleting(false)
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: ColorQuery, variables: { hubDashCardId: card.id } },
        { query: LayoutQuery },
      ],
    },
  )

  const colorOrder =
    view?.decorations?.[0]?.value_provider_conf?.colors?.map(
      (color) => color.color,
    ) ?? []

  for (const colorItem of colorList) {
    allColorList.push({
      id: colorItem.id,
      exists: true,
      name: colorItem.name,
      description: colorItem.description,
      colorName: colorItem.colorName,
      icon: colorItem.icon,
      recordCount: records.filter((record) => {
        if (record?.decorators?.left_border_color.length > 0) {
          return record.decorators.left_border_color.some(
            (color) => color.color === colorItem.colorName,
          )
        }
        return false
      }).length,
    })
  }

  let viewUnknownColors = []
  const isSingleSelectColor =
    view?.decorations[0]?.value_provider_type === 'single_select_color'
  if (isSingleSelectColor) {
    //find non-existent single select color, get unique colors from each record
    const fieldId = view?.decorations[0]?.value_provider_conf?.field_id
    for (const record of records) {
      for (const field of record.fields) {
        if (field.id === fieldId && field.value) {
          if (!viewUnknownColors.some((item) => item.id === field.value.id)) {
            viewUnknownColors.push(field.value)
          }
        }
      }
    }
    //delete those the colorList already has
    viewUnknownColors = viewUnknownColors.filter((viewColor) => {
      return !colorList.some((color) => color.colorName === viewColor.color)
    })
  } else {
    viewUnknownColors =
      view?.decorations[0]?.value_provider_conf?.colors?.filter((viewColor) => {
        return !colorList.some((color) => color.colorName === viewColor.color)
      }) ?? []
  }

  for (const colorItem of viewUnknownColors) {
    allColorList.push({
      id: colorItem.id,
      exists: false,
      name: isSingleSelectColor ? colorItem.value : 'Unknown',
      description: 'Color not defined',
      colorName: colorItem.color,
      recordCount: records.filter((record) => {
        if (record?.decorators?.left_border_color.length > 0) {
          return record.decorators.left_border_color.some(
            (color) => color.color === colorItem.color,
          )
        }
        return false
      }).length,
      icon: '',
    })
  }

  //reorder allColorList based on colorOrder
  if (colorOrder) {
    allColorList.sort((a, b) => {
      return colorOrder.indexOf(a.colorName) - colorOrder.indexOf(b.colorName)
    })
  }

  const handleSave = (colorItem) => {
    setIsSaving(true)
    if (colorItem.exists) {
      updateColor({
        variables: {
          id: colorItem.id,
          input: {
            name: selectedName,
            description: selectedDescription,
            colorName: selectedColor,
            icon: selectedIcon,
          },
        },
      })
    } else {
      createColor({
        variables: {
          input: {
            name: selectedName,
            description: selectedDescription,
            colorName: selectedColor,
            icon: selectedIcon,
            hubDashCardId: card.id,
          },
        },
      })
    }
  }

  const handleDelete = (colorItem) => {
    setIsDeleting(true)

    const id = Number(colorItem.id)
    if (typeof id !== 'number' || isNaN(id)) {
      setIsDeleting(false)
      throw new Error('Invalid color id')
    }

    deleteColor({ variables: { id: colorItem.id } })
  }

  const colorCard = (colorItem: any) => {
    let ColorIcon = IconSet[colorItem.icon]
    const allColorsIcons = Object.entries(IconSet).map(([name, Icon]) => ({
      name,
      icon: Icon,
    }))

    if (
      editMode &&
      colorItem.id !== 0 &&
      selectedColor === colorItem.colorName
    ) {
      ColorIcon = IconSet[selectedIcon]
      return (
        <div
          key={colorItem.id}
          className={`flex w-full flex-col gap-3 rounded-lg p-2 hover:bg-gray-100 ${
            selectedColor === colorItem.colorName ? 'bg-gray-100' : ''
          }`}
          onClick={() => setSelectedColor(colorItem.colorName)}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
        >
          <SearchField value={iconSearchValue} onChange={setIconSearchValue} />
          <div className="flex max-h-48 flex-wrap gap-2 overflow-scroll">
            {allColorsIcons
              .filter((iconItem) =>
                iconItem.name
                  .toLowerCase()
                  .includes(iconSearchValue.toLowerCase()),
              )
              .map((iconItem) => (
                <button
                  key={iconItem.name}
                  className="h-8 w-8 rounded-md bg-gray-400"
                  onClick={() => setSelectedIcon(iconItem.name)}
                  style={{
                    backgroundColor:
                      selectedIcon === iconItem.name
                        ? getHexForColor(colorItem.colorName)
                        : 'gray',
                  }}
                >
                  <iconItem.icon className="h-8 w-8 text-white" />
                </button>
              ))}
          </div>
          <div className="flex items-center gap-2">
            <div
              className={'mb-auto h-8 w-8 rounded-md'}
              style={{ backgroundColor: getHexForColor(colorItem.colorName) }}
            >
              {ColorIcon && (
                <ColorIcon
                  className={`h-8 w-8 ${
                    colorItem.id === 0 ? 'text-gray-600' : 'text-white'
                  }`}
                />
              )}
            </div>
            <p className="text-gray-600">
              {selectedIcon && selectedIcon !== '' ? selectedIcon : 'No Icon'}
            </p>
          </div>
          <TextField
            value={selectedName}
            onChange={(e) => setSelectedName(e.target.value)}
            label="Name"
            size="small"
            className="bg-white"
          />
          <TextField
            value={selectedDescription}
            onChange={(e) => setSelectedDescription(e.target.value)}
            label="Description"
            size="small"
            className="bg-white"
          />
          <div className="flex w-full justify-between">
            <div>
              <Button
                className="bg-red-500 text-white hover:bg-red-400"
                onClick={() => handleDelete(colorItem)}
                disabled={isSaving}
                loading={isDeleting}
              >
                Delete
              </Button>
            </div>
            <div className="flex gap-2">
              <div>
                <Button
                  className="bg-gray-100 text-gray-500 hover:bg-gray-200"
                  onClick={() => {
                    setEditMode(false)
                    setSelectedIcon('')
                    setSelectedName('')
                    setSelectedDescription('')
                  }}
                  disabled={isSaving || isDeleting}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => handleSave(colorItem)}
                  disabled={isDeleting}
                  loading={isSaving}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div
        key={colorItem.id}
        className={`flex flex-row items-center justify-between gap-2 rounded-lg p-2 hover:bg-gray-100 ${
          selectedColor === colorItem.colorName ? 'bg-gray-100' : ''
        }`}
        onClick={() => setSelectedColor(colorItem.colorName)}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
      >
        <div className="flex gap-2">
          <div
            className={'mb-auto h-6 w-6 rounded-md'}
            style={{ backgroundColor: getHexForColor(colorItem.colorName) }}
          >
            {ColorIcon && (
              <ColorIcon
                className={`h-6 w-6 ${
                  colorItem.id === 0 ? 'text-gray-600' : 'text-white'
                }`}
              />
            )}
          </div>
          <div className="flex flex-col">
            <p className="text-sm">{colorItem.name}</p>
            {selectedColor === colorItem.colorName && (
              <p className="text-sm text-gray-600">{colorItem.description}</p>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2">
          {colorItem.id !== 0 && selectedColor === colorItem.colorName && (
            <button
              className="rounded-lg p-1 hover:bg-gray-200"
              onClick={() => {
                setEditMode(true)
                setSelectedIcon(colorItem.icon)
                setSelectedName(colorItem.name)
                setSelectedDescription(colorItem.description)
              }}
            >
              <PencilSquareIcon className="h-5 w-5 text-gray-500" />
            </button>
          )}
          <p className="flex items-center justify-center rounded-lg bg-indigo-100 px-2 text-xs text-indigo-500">
            {colorItem.recordCount}
          </p>
        </div>
      </div>
    )
  }
  return (
    <div className="flex flex-col p-2 px-4">
      <div className="flex flex-row justify-between">
        <p className="pb-2 pt-2 text-sm uppercase text-gray-500">
          Filter By Colour
        </p>
      </div>
      <div className="flex max-h-[700px] flex-col gap-1 overflow-scroll border-b-2 border-gray-300 pb-4 pt-2">
        {allColorList.map((colorItem) => colorCard(colorItem))}
      </div>
    </div>
  )
}

export default ColorModule
