import { FC } from 'react'

import { LearnerCategory, LearnerCourse, Membership } from 'types/graphql'

import { toFirstDecimal } from 'src/lib/helpers'

import SummaryGrid from './SummaryGrid/SummaryGrid'
import SummaryRadial from './SummaryRadial'

interface DashboardProps {
  learnerCategory: LearnerCategory
  learnerCourse: LearnerCourse
  memberships: Membership[]
}

const Dashboard: FC<DashboardProps> = ({
  learnerCategory,
  learnerCourse,
  memberships,
}) => {
  return (
    <div className="grid grid-cols-none gap-4 xl:grid-cols-[50%_50%] xl:gap-0 2xl:grid-cols-[60%_40%]">
      <div className="px-6 xl:pr-3">
        <SummaryGrid stats={learnerCourse.reporting?.stats} />
      </div>
      <div className="px-6 xl:pl-3">
        <SummaryRadial
          memberships={memberships}
          learnerCategory={learnerCategory}
          stats={learnerCourse.reporting?.stats}
        />
      </div>
    </div>
  )
}

export default Dashboard
