import { FC, ReactNode } from 'react'

import { useMediaQuery } from 'usehooks-ts'

interface InformerMasterHeaderProps {
  children?: ReactNode
}

const InformerMasterHeader: FC<InformerMasterHeaderProps> = ({ children }) => {
  // Check if mobile
  const isMobile = useMediaQuery('(max-width: 769px)')

  return (
    <>
      {children && (
        <>
          <p className="mb-2 text-lg text-gray-500">Recent Items</p>
          <div
            className={`flex flex-row flex-wrap gap-4 ${
              isMobile
                ? 'flex w-full flex-col'
                : 'items-start justify-between rounded-md border border-gray-200 bg-gray-100 p-3'
            }`}
          >
            <div className="order-1 w-full grow">{children}</div>
          </div>
        </>
      )}
    </>
  )
}

export default InformerMasterHeader
