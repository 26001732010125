import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useMediaQuery } from 'usehooks-ts'

import { Metadata } from '@redwoodjs/web'

import Button from 'src/components/Library/Button'
import endUserTermsAndConditionsText from 'src/lib/endUserTermsAndConditionsText'
import TheHubIcon from 'src/lib/icons/TheHubIcon/TheHubIcon'

const EndUserTermsAndConditionsPage = () => {
  const isMobile = useMediaQuery('(max-width: 640px)')
  return (
    <>
      <Metadata
        title="End User Terms And Conditions"
        description="End User Terms And Conditions"
      />

      <div
        className={
          'relative flex h-screen flex-col place-items-center bg-slate-50 p-4 md:p-8'
        }
      >
        <div className={'h-12 w-60'}>
          <a href="/">
            <TheHubIcon variant="default" />
          </a>
        </div>
        <div
          className={
            'h-3/4 w-full max-w-[800px] rounded-lg bg-transparent pt-12 text-center md:px-12'
          }
        >
          <h2 className={'text-3xl font-extrabold text-slate-700'}>
            End User Terms and Conditions Agreement
          </h2>
          <br />
          <div
            className={
              'border-1 h-full overflow-y-scroll rounded-lg border border-slate-200 bg-[#FFF] p-4'
            }
          >
            <ReactMarkdown
              className={
                'prose prose-sm max-w-none text-left text-slate-500 prose-headings:text-slate-700 prose-strong:text-slate-600'
              }
              remarkPlugins={[remarkGfm]}
            >
              {endUserTermsAndConditionsText}
            </ReactMarkdown>
          </div>
          <Button
            className="mt-6 md:mt-8"
            variant="outlined"
            fullWidth={isMobile ? true : false}
            onClick={() => window.history.back()}
          >
            Go Back
          </Button>
        </div>
      </div>
    </>
  )
}

export default EndUserTermsAndConditionsPage
