import React, { useEffect, useState } from 'react'

import { BILLING_CYCLE } from 'api/src/common/enums'
import {
  CreateCheckoutSession,
  CreateCheckoutSessionVariables,
  ProductPlan,
  ProductPlansQuery,
  ProductPlansQueryVariables,
} from 'types/graphql'

import { useParams } from '@redwoodjs/router'
import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import AddonsView from 'src/components/Billing/AddonsView/AddonsView'
import ProductsView from 'src/components/Billing/ProductsView/ProductsView'
import { default as EmptyData } from 'src/components/Library/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { ViewSwitch } from 'src/components/Library/ViewSwitch/ViewSwitch'
import { openWindowWithBlockCheck } from 'src/lib/helpers'
import { CREATE_CHECKOUT_SESSION } from 'src/lib/queries/Settings/Billing/ProductPlans'

export const QUERY = gql`
  query ProductPlansQuery {
    productPlans(getEnterprise: true) {
      products {
        id
        name
        description
        features {
          name
        }
        defaultPrice {
          id
          currency
          unitAmount
        }
        yearlyPrice {
          id
          currency
          unitAmount
        }
        metadata {
          isFixedPrice
          tier
        }
      }
      addons {
        id
        name
        description
        defaultPrice {
          id
          currency
          unitAmount
          tiers {
            flatAmountDecimal
            unitAmountDecimal
            upTo
          }
        }
        yearlyPrice {
          id
          currency
          unitAmount
          tiers {
            flatAmountDecimal
            unitAmountDecimal
            upTo
          }
        }
        metadata {
          isFixedPrice
          tier
        }
      }
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => (
  <EmptyData
    title={'No Products Found'}
    description={
      'There appears to be no Products available for purchase at this time.'
    }
  />
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)
export interface CustomAddon extends ProductPlan {
  upTo: number
  tier: string
}

export const Success = ({
  productPlans,
}: CellSuccessProps<ProductPlansQuery, ProductPlansQueryVariables>) => {
  const { products, addons } = productPlans
  const { product } = useParams()
  const [selectedProduct, setSelectedProduct] = useState<ProductPlan>(null)
  const [selectedAddons, setSelectedAddons] = useState<CustomAddon[]>([])
  const [billingPeriod, setBillingPeriod] = useState<BILLING_CYCLE>(
    BILLING_CYCLE.MONTHLY,
  )
  const [checkoutStep, setCheckoutStep] = useState(0)
  const [filteredProducts, setFilteredProducts] = useState(
    products as ProductPlan[],
  )
  const [createCheckoutSession] = useMutation<
    CreateCheckoutSession,
    CreateCheckoutSessionVariables
  >(CREATE_CHECKOUT_SESSION, {
    onCompleted: ({ checkoutSession }) => {
      openWindowWithBlockCheck(checkoutSession, '_self')
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  useEffect(() => {
    if (product) {
      const searchedProduct = products.filter((p) => p.id === product)
      if (!searchedProduct.length) {
        setFilteredProducts(products as ProductPlan[])
      } else {
        setFilteredProducts(searchedProduct as ProductPlan[])
      }
    } else {
      setFilteredProducts(products as ProductPlan[])
    }
  }, [product])

  useEffect(() => {
    if (
      (selectedProduct && checkoutStep === 2) ||
      selectedProduct?.metadata.tier === '4'
    ) {
      const addonPrices = selectedAddons.map((addon) => {
        return {
          id:
            billingPeriod === BILLING_CYCLE.MONTHLY
              ? addon?.defaultPrice.id
              : addon?.yearlyPrice.id,
          quantity: addon.upTo || 1,
        }
      })
      createCheckoutSession({
        variables: {
          priceId:
            billingPeriod === BILLING_CYCLE.MONTHLY
              ? selectedProduct?.defaultPrice.id
              : selectedProduct?.yearlyPrice.id,
          addons: addonPrices,
        },
      })
    }
  }, [selectedProduct, checkoutStep])

  return (
    <div className={'h-full w-full'}>
      <ViewSwitch condition={checkoutStep}>
        <ViewSwitch.Case when={0}>
          <ProductsView
            billingPeriod={billingPeriod}
            setBillingPeriod={setBillingPeriod}
            products={filteredProducts}
            setSelectedProduct={setSelectedProduct}
            setCheckoutStep={setCheckoutStep}
          />
        </ViewSwitch.Case>
        <ViewSwitch.Case when={1}>
          <AddonsView
            addons={addons}
            setCheckoutStep={setCheckoutStep}
            billingPeriod={billingPeriod}
            setSelectedAddons={setSelectedAddons}
            selectedAddons={selectedAddons}
            selectedProduct={selectedProduct}
          />
        </ViewSwitch.Case>
        <ViewSwitch.Default>
          <LoadingSpinner />
        </ViewSwitch.Default>
      </ViewSwitch>
    </div>
  )
}
