import React, { FC, useState } from 'react'

import { CheckIcon, PencilIcon } from '@heroicons/react/24/outline'
import { Stack, TextField, Tooltip, Typography, Box } from '@mui/material'

import Button from 'src/components/Library/Button/Button'

import { legendType } from './ReactMapControlPanel'

export interface ReactMapLegendItemProps {
  legendItem: legendType
  index: number
  onUpdate: (index: number, newLabel: string) => void
  isEditing: boolean
  saveMapLoading: boolean
}

const ReactMapLegendItem: FC<ReactMapLegendItemProps> = ({
  legendItem,
  index,
  onUpdate,
  isEditing,
  saveMapLoading,
}) => {
  const [editLegendItem, setEditLegendItem] = useState(false)
  const [legendItemLabel, setLegendItemLabel] = useState(legendItem.label)

  const handleSave = () => {
    onUpdate(index, legendItemLabel) // Call the onUpdate function passed from the parent
    setEditLegendItem(false)
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      className="flex w-full justify-between"
    >
      <Stack
        direction="row"
        spacing={0}
        alignItems="center"
        className="min-w-0 flex-grow"
      >
        <Box
          className={'h-5 min-h-[20px] w-5 min-w-[20px] rounded-full border'}
          sx={{
            bgcolor: legendItem.color,
          }}
        />

        {editLegendItem && isEditing ? (
          <TextField
            value={legendItemLabel}
            fullWidth
            variant="standard"
            className="h-5 justify-center p-2 align-middle text-sm text-black"
            onChange={(e) => setLegendItemLabel(e.target.value)}
          />
        ) : (
          <Box className="max-w-full overflow-hidden">
            <Typography className="overflow-hidden truncate text-ellipsis whitespace-nowrap p-2">
              {legendItem.label}
            </Typography>
          </Box>
        )}
      </Stack>
      {isEditing && (
        <Tooltip
          title={editLegendItem ? 'Save Legend Item' : 'Edit Legend Item'}
        >
          <div>
            <Button
              fullWidth={false}
              variant="text"
              loading={saveMapLoading}
              className={`min-w-[0] rounded-full p-2 ${
                editLegendItem ? 'text-green-600' : 'text-gray-500'
              }`}
              onClick={async () => {
                if (editLegendItem) {
                  await handleSave()
                }
                setEditLegendItem(!editLegendItem)
              }}
            >
              {editLegendItem ? (
                <CheckIcon className="h-3 w-3" />
              ) : (
                <PencilIcon className="h-3 w-3" />
              )}
            </Button>
          </div>
        </Tooltip>
      )}
    </Stack>
  )
}

export default ReactMapLegendItem
