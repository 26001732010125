import { Metadata } from '@redwoodjs/web'

import PageHeader from 'src/components/PageHeader/PageHeader'
import ClientSettingsNavigation from 'src/components/Settings/Client/ClientSettingsNavigation'
import SettingsLayoutSectionCell from 'src/components/SettingsLayoutSection/SettingsLayoutSectionCell'

export type SelectedSection = {
  id: number
  name: string
}

const SettingsClientToolsPage = () => {
  return (
    <>
      <Metadata
        title="Settings - Client - Home Screen"
        description="Settings - Client - Home Screen"
      />
      <PageHeader
        title="Client Settings"
        parentDataTestId="client-settings-page"
      />
      <ClientSettingsNavigation />

      <div className="flex h-[calc(100vh-120px)] gap-2">
        <SettingsLayoutSectionCell fileNames={['asset-library-icon']} />
      </div>
    </>
  )
}

export default SettingsClientToolsPage
