import React, { ReactNode } from 'react'

import { ClockIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Chip, Tooltip } from '@mui/material'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import { isNil } from 'ramda'

interface Props {
  headerIcon: React.FC | string | JSX.Element
  title: React.ReactNode
  subheader?: React.ReactNode
  author?: string
  className?: string
  isSelected?: boolean
  onClick?: () => void
  duration?: string
  variant?: 'objective' | 'milestone'
  warning?: string | JSX.Element
  actionLabel?: React.ReactNode
}
const TrackItemCard: React.FC<Props> = ({
  headerIcon,
  title,
  subheader,
  duration,
  isSelected,
  onClick,
  variant = 'milestone',
  className,
  warning,
  actionLabel,
}) => {
  // Check if milestone
  const isMilestone = variant === 'milestone'

  return (
    <Card
      variant="outlined"
      onClick={onClick}
      className={`${
        !isNil(onClick) &&
        'hover:cursor-pointer hover:ring hover:ring-indigo-600'
      } ${
        isSelected && 'ring ring-indigo-600'
      } transition duration-300 ease-in-out ${isMilestone && 'bg-gray-50 bg-white'} ${className} `}
    >
      <CardHeader
        title={
          <div
            className={`flex ${
              isMilestone ? 'items-start' : 'items-center'
            } gap-2`}
          >
            <div
              className={`mr-2 rounded-md ${
                isMilestone ? 'bg-indigo-100 p-2' : 'p-1'
              }`}
            >
              {headerIcon as ReactNode}
            </div>
            <div className="flex flex-col">
              <h1 className="mt-0.5 text-xs font-medium uppercase leading-4 tracking-wider text-indigo-600">
                {title}
              </h1>
              <div className="text-sm">{subheader}</div>
            </div>
          </div>
        }
        action={
          <div className="my-2 ml-4 flex flex-row items-center gap-4">
            {actionLabel}
            {warning && (
              <Tooltip title={warning} placement="top">
                <div className="rounded bg-red-100 p-1">
                  <ExclamationTriangleIcon className="h-5 w-5 text-red-600" />
                </div>
              </Tooltip>
            )}
            <Chip
              className="bg-gray-200"
              icon={<ClockIcon className="h-5 w-5" />}
              label={
                <p className="mt-0.5 text-xs font-light uppercase leading-4 tracking-wider">
                  {duration}
                </p>
              }
            />
          </div>
        }
      />
    </Card>
  )
}

export default TrackItemCard
