import React, { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'

import {
  CellLogType,
  RefetchQuery,
} from 'src/components/BaserowImporter/BaserowImporterLogCell/BaserowImporterLogCell'
import BaserowImporterModalRequestLogItem from 'src/components/BaserowImporter/BaserowImporterModalRequestLogItem'
import Modal from 'src/components/Modal/Modal'

import { default as EmptyComponent } from '../Library/Empty/Empty'

interface BaserowImporterViewInformationModalProps {
  logData: CellLogType[]
  refetchQuery: RefetchQuery
  handleCloseModal: () => void
  openModal: boolean
}

const BaserowImporterModalRequestLog = ({
  logData,
  refetchQuery,
  openModal,
  handleCloseModal,
}: BaserowImporterViewInformationModalProps) => {
  const [sortedLogData, setSortedLogData] = useState<CellLogType[]>([])
  const [isRefetching, setIsRefetching] = useState(false)

  const handleRefetch = async () => {
    setIsRefetching(true)
    await refetchQuery()
    setIsRefetching(false)
  }

  useEffect(() => {
    // Create a new array from logData to avoid mutating the original array
    const dataSortedByCreatedDate = [...logData].sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    )

    // Set the sorted array to the state
    setSortedLogData(dataSortedByCreatedDate)
  }, [logData])

  return (
    <Modal
      open={openModal}
      onCancel={handleCloseModal}
      onClose={handleCloseModal}
      title={'Request Log'}
      footerVisible={true}
      onConfirm={handleRefetch}
      cancelButtonVisible={true}
      confirmText={'Refresh'}
      cancelText={'Close'}
      loading={isRefetching}
      className="w-4xl"
    >
      <Box className={'m-4 border-2 p-4'}>
        {sortedLogData.length === 0 ? (
          <EmptyComponent title="No Logs Found" />
        ) : (
          <Stack spacing={1}>
            {sortedLogData
              .filter(
                (log) => log.logType === 'ERROR' || log.logType === 'WARNING',
              )
              .map((log, index) => {
                return (
                  <BaserowImporterModalRequestLogItem log={log} key={index} />
                )
              })}
          </Stack>
        )}
      </Box>
    </Modal>
  )
}

export default BaserowImporterModalRequestLog
