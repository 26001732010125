import React, { FC } from 'react'

export enum AutomationTriggerType {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

interface AutomationTrackTriggerProps {
  children: React.ReactNode
}

const AutomationTrackTrigger: FC<AutomationTrackTriggerProps> = ({
  children,
}) => {
  return (
    <div className="relative w-full rounded border border-gray-300 bg-white p-4 px-3">
      {children}
    </div>
  )
}

export default AutomationTrackTrigger
