import React from 'react'

import { Chip } from '@mui/material'
import { AncillaryLeadTriggerType } from 'types/graphql'

interface Props {
  opportunity: AncillaryLeadTriggerType
}

export default function OpportunityChip({
  opportunity,
}: Props): React.ReactElement {
  const opportunityClassNames: Record<AncillaryLeadTriggerType, string> = {
    ['MANUAL']: 'bg-green-100 text-green-600',
    ['OFFER_RECEIVED']: 'bg-indigo-100 text-indigo-600',
    ['DRAFT_CONTRACT']: 'bg-red-100 text-red-600',
    ['ACCEPTED_CONTRACT']: 'bg-indigo-100 text-indigo-600',
    ['CONDITIONAL_CONTRACT']: 'bg-gray-100 text-gray-600',
    ['UNCONDITIONAL_CONTRACT']: 'bg-blue-100 text-blue-600',
    ['FALLEN_CONTRACT']: 'bg-cyan-100 text-cyan-600',
    ['TENANCY_APPLICATION']: 'bg-orange-100 text-orange-600',
    ['TENANCY_APPLICATION_APPROVED']: 'bg-green-100 text-green-600',
    ['TENANCY_STARTED']: 'bg-gray-100 text-gray-600',
    ['TENANCY_ENDED']: 'bg-cyan-100 text-cyan-600',
    ['MANAGEMENT_APPOINTMENT']: 'bg-blue-100 text-blue-600',
  }

  const opportunityHumanNames: Record<AncillaryLeadTriggerType, string> = {
    ['MANUAL']: 'Manual',
    ['OFFER_RECEIVED']: 'Offer Received',
    ['DRAFT_CONTRACT']: 'Contract Drafted',
    ['ACCEPTED_CONTRACT']: 'Contract Accepted',
    ['CONDITIONAL_CONTRACT']: 'Conditional Contract',
    ['UNCONDITIONAL_CONTRACT']: 'Unconditional Contract',
    ['FALLEN_CONTRACT']: 'Contract Crashed',
    ['TENANCY_APPLICATION']: 'Tenancy Application Received',
    ['TENANCY_APPLICATION_APPROVED']: 'Tenancy Application Approved',
    ['TENANCY_STARTED']: 'Tenancy Started',
    ['TENANCY_ENDED']: 'Tenancy Ended',
    ['MANAGEMENT_APPOINTMENT']: 'Management Appointment',
  }

  return (
    <Chip
      size={'small'}
      label={opportunityHumanNames[opportunity] || opportunity}
      className={`rounded-xl px-1 py-2 ${opportunityClassNames[opportunity] || 'bg-gray-300 text-black'}`}
    />
  )
}
