import * as React from 'react'
import { FC, useEffect, useState } from 'react'

import { PowerIcon, SparklesIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Box, Chip, Tooltip } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import Select from 'react-select'
import {
  DeleteIntegrationMutation,
  DeleteIntegrationMutationVariables,
  FindIntegrations,
  ToggleIntegrationMutation,
  ToggleIntegrationMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import {
  integrationOptions,
  IntegrationType,
} from 'src/components/CustomerIntegrations/integrations'
import Button from 'src/components/Library/Button/Button'
import Switch from 'src/components/Library/Switch/Switch'
import { QUERY } from 'src/components/SuperAdmin/Integrations/IntegrationsCell'
import NewIntegration from 'src/components/SuperAdmin/Integrations/NewIntegration'
import { useConfirm } from 'src/lib/hooks/Confirmation'

const DELETE_INTEGRATION_MUTATION = gql`
  mutation DeleteIntegrationMutation($id: Int!) {
    deleteIntegration(id: $id) {
      id
    }
  }
`

const TOGGLE_INTEGRATION_MUTATION = gql`
  mutation ToggleIntegrationMutation($id: Int!, $power: Boolean!) {
    toggleIntegration(id: $id, power: $power) {
      id
    }
  }
`

type IntegrationsQueryType = FindIntegrations['integrations']

interface Props {
  integrations: FindIntegrations['integrations']
  clients: FindIntegrations['clients']
}

const IntegrationsList: FC<Props> = ({ integrations, clients }) => {
  const confirm = useConfirm()

  const [openNewIntegrationModal, setOpenNewIntegrationModal] = useState(false)
  const [filterClientOptions, setFilterClientOptions] = useState([])
  const [filteredIntegrations, setFilteredIntegrations] = useState([])
  const [filterIntegrationTypes, setFilterIntegrationTypes] = useState([])
  const [showActiveOnly, setShowActiveOnly] = useState(false)
  const [selectedClients, setSelectedClients] = useState([])
  const [selectedIntegrationTypes, setSelectedIntegrationTypes] = useState([])
  const [selectedRecord, setSelectedRecord] =
    useState<IntegrationsQueryType[0]>(null)

  const columnDef: GridColDef[] = [
    {
      field: 'clientName',
      headerName: 'Client Name',
      sortable: true,
      flex: 1,
      valueGetter: (_value, row) => {
        return row.client.name
      },
    },
    {
      field: 'integrationName',
      headerName: 'Integration Name',
      sortable: true,
      flex: 1,
      valueGetter: (_value, row) => {
        return row.integrationName
      },
    },
    {
      field: 'integrationType',
      headerName: 'Integration Type',
      headerAlign: 'center',
      sortable: true,
      flex: 1,
      renderCell: (params) => {
        const integrationColour = getIntegrationColour(
          params.row.integrationType,
        )
        return (
          <Box className={'flex h-full w-full items-center justify-center'}>
            <Chip
              label={params.row.integrationType}
              size={'small'}
              sx={{
                backgroundColor: integrationColour,
                color: '#FFFFFF',
              }}
            />
          </Box>
        )
      },
    },

    {
      field: 'apiKey',
      headerName: 'API Key',
      headerAlign: 'center',
      sortable: false,
      flex: 1,

      renderCell: (params) => {
        const { stateText, stateColour } = getIntegrationKeyRequiredFields(
          params,
          'apiKey',
        )
        return (
          <Box className={'flex h-full w-full items-center justify-center'}>
            <Chip
              label={stateText}
              color={stateColour as any}
              size={'small'}
              variant={'outlined'}
            />
          </Box>
        )
      },
    },

    {
      field: 'apiSecret',
      headerName: 'API Secret',
      headerAlign: 'center',
      sortable: false,
      flex: 1,

      renderCell: (params) => {
        const { stateText, stateColour } = getIntegrationKeyRequiredFields(
          params,
          'apiSecret',
        )
        return (
          <Box className={'flex h-full w-full items-center justify-center'}>
            <Chip
              label={stateText}
              color={stateColour as any}
              size={'small'}
              variant={'outlined'}
            />
          </Box>
        )
      },
    },

    {
      field: 'apiAccount',
      headerName: 'API Account',
      headerAlign: 'center',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const { stateText, stateColour } = getIntegrationKeyRequiredFields(
          params,
          'apiAccountKey',
        )
        return (
          <Box className={'flex h-full w-full items-center justify-center'}>
            <Chip
              label={stateText}
              color={stateColour as any}
              size={'small'}
              variant={'outlined'}
            />
          </Box>
        )
      },
    },

    {
      field: 'created',
      headerName: 'Created',
      headerAlign: 'center',
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box className={'flex h-full w-full items-center justify-center'}>
          {new Date(params.row.createdAt).toLocaleDateString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}
        </Box>
      ),
    },

    {
      field: 'updated',
      headerName: 'Updated',
      headerAlign: 'center',
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box className={'flex w-full justify-center'}>
          {new Date(params.row.updatedAt).toLocaleDateString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}
        </Box>
      ),
    },
    {
      field: 'isActive',
      headerName: 'Active',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <Box className={'flex h-full w-full items-center justify-center'}>
            <Tooltip
              title={
                params.row.active ? 'Currently Active' : 'Currently Inactive'
              }
            >
              {params.row.active ? (
                <Chip label={'Active'} color={'success'} size={'small'} />
              ) : (
                <Chip label={'Inactive'} color={'error'} size={'small'} />
              )}
            </Tooltip>
          </Box>
        )
      },
    },

    {
      field: 'actions',
      headerName: 'Edit',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => (
        <Stack
          direction={'row'}
          spacing={2}
          className={'flex w-full justify-center'}
          alignItems={'center'}
        >
          <Tooltip title={'Delete Integrations'}>
            <div>
              <Button
                fullWidth={false}
                variant="outlined"
                className="min-w-[0] rounded-full p-1"
                onClick={() => onDeleteClick(params.row.id)}
              >
                <TrashIcon className="h-3 w-3 text-red-600" />
              </Button>
            </div>
          </Tooltip>

          <Tooltip
            title={`Turn ${params.row.active ? 'Off' : 'On'} Integrations`}
          >
            <div>
              <Button
                fullWidth={false}
                variant="outlined"
                className="min-w-[0] rounded-full p-1"
                onClick={() => togglePower(params.row.id, !params.row.active)}
              >
                <PowerIcon
                  className={`h-3 w-3 ${
                    params.row.active ? 'text-green-600' : 'text-red-600'
                  }`}
                />
              </Button>
            </div>
          </Tooltip>
        </Stack>
      ),
    },
  ]

  const getIntegrationColour = (integrationType: string) => {
    return integrationOptions[integrationType].primaryColor
  }

  const getIntegrationKeyRequiredFields = (
    params: Record<string, any>,
    key: IntegrationType,
  ) => {
    const isKeyRequiredField =
      !!integrationOptions[params.row.integrationType].formFields[key].value

    const stateText =
      isKeyRequiredField && params.row?.[key]
        ? 'Provided'
        : isKeyRequiredField
          ? 'Not Provided'
          : 'Not Required'
    const stateColour =
      isKeyRequiredField && params.row?.[key]
        ? 'success'
        : isKeyRequiredField
          ? 'error'
          : 'primary'
    return { stateText, stateColour }
  }

  const handleCloseNewIntegrationModal = () => {
    setOpenNewIntegrationModal(false)
  }

  const [deleteIntegration] = useMutation<
    DeleteIntegrationMutation,
    DeleteIntegrationMutationVariables
  >(DELETE_INTEGRATION_MUTATION, {
    onCompleted: () => {
      toast.success('Integrations deleted')
    },
    onError: (error) => {
      toast.error(error.message)
    },
    refetchQueries: [{ query: QUERY }],
    awaitRefetchQueries: true,
  })

  const [toggleIntegration] = useMutation<
    ToggleIntegrationMutation,
    ToggleIntegrationMutationVariables
  >(TOGGLE_INTEGRATION_MUTATION, {
    onCompleted: () => {
      toast.success('Integrations toggled')
    },
    onError: (error) => {
      toast.error(error.message)
    },
    refetchQueries: [{ query: QUERY }],
    awaitRefetchQueries: true,
  })

  const onDeleteClick = (id: number) => {
    confirm({
      title: 'Delete integration?',
      description: `Are you sure you want to delete integration ${id}?`,
    }).then(() => {
      deleteIntegration({ variables: { id } })
    })
  }

  const togglePower = (id: number, power: boolean) => {
    const integration = integrations.find(
      (integration) => integration.id === id,
    )
    confirm({
      title: 'Toggle integration?',
      description: `Are you sure you want to turn ${
        integration.active ? 'off' : 'on'
      } integration ${id}?`,
    }).then(() => {
      toggleIntegration({ variables: { id, power } })
    })
  }

  const handleClientChange = (selectedClients) => {
    setSelectedClients(selectedClients)
  }

  const handleIntegrationTypeChange = (selectedIntegrationTypes) => {
    setSelectedIntegrationTypes(selectedIntegrationTypes)
  }

  useEffect(() => {
    if (clients) {
      setFilterClientOptions(
        clients.map((client) => ({
          value: client.id,
          label: client.name,
        })),
      )
    }
    if (integrations) {
      setFilterIntegrationTypes(
        Array.from(
          new Set(
            integrations.map((integration) => integration.integrationType),
          ),
        ).map((integrationType) => ({
          value: integrationType,
          label: integrationType,
        })),
      )
    }
  }, [clients, integrations])

  useEffect(() => {
    const integrationsFilteredActive = showActiveOnly
      ? integrations.filter((integration) => integration.active)
      : integrations
    const integrationsFilteredClients =
      selectedClients.length > 0
        ? integrationsFilteredActive.filter((integration) =>
            selectedClients
              .map((client) => client.value)
              .includes(integration.client.id),
          )
        : integrationsFilteredActive
    const integrationsFilteredTypes =
      selectedIntegrationTypes.length > 0
        ? integrationsFilteredClients.filter((integration) =>
            selectedIntegrationTypes
              .map((integrationType) => integrationType.value)
              .includes(integration.integrationType),
          )
        : integrationsFilteredClients

    setFilteredIntegrations(integrationsFilteredTypes)
  }, [
    clients,
    integrations,
    selectedClients,
    selectedIntegrationTypes,
    showActiveOnly,
  ])

  return (
    <>
      <Stack>
        <Stack
          direction={'row'}
          spacing={2}
          className={'flex justify-between p-2'}
        >
          <Stack
            direction={'row'}
            spacing={2}
            className={'flex w-[50%] justify-start px-2'}
            alignItems={'center'}
          >
            <Typography>Clients</Typography>
            <Select
              className={'w-[50%]'}
              isMulti
              options={filterClientOptions}
              value={selectedClients}
              onChange={handleClientChange}
            />
            <Typography>Integrations</Typography>

            <Select
              className={'w-[50%]'}
              isMulti
              options={filterIntegrationTypes}
              value={selectedIntegrationTypes}
              onChange={handleIntegrationTypeChange}
            />
          </Stack>
          <Tooltip title={'Show Active Only'}>
            <Switch
              checked={showActiveOnly}
              onChange={() => setShowActiveOnly(!showActiveOnly)}
            />
          </Tooltip>
          <Button
            fullWidth={false}
            startIcon={<SparklesIcon className="h-5 w-5" />}
            className="min-w-[0] rounded-lg"
            onClick={() => {
              setSelectedRecord(null)
              setOpenNewIntegrationModal(true)
            }}
          >
            New Integration
          </Button>
        </Stack>

        <div className={'rounded-md bg-white p-2'}>
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              zIndex: 0,
            }}
            autoHeight
            rows={filteredIntegrations}
            columns={columnDef}
            pageSizeOptions={[10, 20, 50]}
            initialState={{
              pagination: { paginationModel: { pageSize: 20 } },
            }}
          />
        </div>
        <NewIntegration
          handleClose={handleCloseNewIntegrationModal}
          open={openNewIntegrationModal}
          clients={clients}
          integration={selectedRecord}
        />
      </Stack>
    </>
  )
}

export default IntegrationsList
