import { FC } from 'react'

import { navigate, routes } from '@redwoodjs/router'

import { KBItemSorted } from 'src/components/KnowledgeCell/types'
import Button from 'src/components/Library/Button/Button'
import Tag from 'src/components/Tag/Tag'
import useAnalytics from 'src/lib/hooks/useAnalytics'

import { KbItemIcon } from '../Home/KnowledgeBaseArticleLink'
import KBStatusChip from '../KBStatusChip'

interface KnowledgeBaseItemLinkProps {
  kbItem: KBItemSorted
  index: number
  linkType: string
  isHeading?: boolean
  canEdit: boolean
}

const KnowledgeBaseItemLink: FC<KnowledgeBaseItemLinkProps> = ({
  kbItem,
  index,
  linkType,
  isHeading = false,
  canEdit,
}) => {
  const { trackEvent } = useAnalytics()

  return (
    <Button
      variant="text"
      className={`group w-full justify-start !rounded-none !border-none !border-gray-300 px-3 py-2 text-sm ${isHeading ? 'hover:!bg-transparent' : 'hover:!bg-gray-100'} hover:!rounded-md`}
      onClick={() => {
        navigate(routes.knowledgeWithId({ id: kbItem.id }))
        trackEvent('Knowledge Base', linkType + ' Click', {
          // listRank being the order of the item in the list
          listRank: index + 1,
        })
      }}
      startIcon={!isHeading && KbItemIcon(kbItem)}
    >
      <div className="flex w-full items-center">
        <div
          className={`font-sm ${isHeading ? '-ml-2 mb-2 w-full border-b border-gray-300 pb-2 text-xl' : 'ml-2'} text-left normal-case !tracking-normal text-gray-600 group-hover:!text-indigo-500`}
        >
          {kbItem.title}
          {canEdit && <KBStatusChip status={kbItem.status} size="small" />}
          {canEdit && kbItem.isGlobal && (
            <Tag color={'green'} globalThis className="ml-4">
              Global
            </Tag>
          )}
        </div>
      </div>
    </Button>
  )
}

export default KnowledgeBaseItemLink
