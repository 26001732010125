import { FC } from 'react'

import {
  FindAssetLibraryQuery,
  FindAssetLibraryQueryVariables,
} from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import AssetLibraryGrid from '../AssetLibraryGrid/AssetLibraryGrid'

export const QUERY = gql`
  query FindAssetLibraryQuery($fileNames: [String!]) {
    assets: globalStorageObjects(fileNames: $fileNames) {
      id
      downloadUrl
      fileName
      metadata
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = ({ assets, queryResult: { refetch } }) => (
  <AssetLibraryGrid assets={assets} refetch={refetch} />
)

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success: FC<
  CellSuccessProps<FindAssetLibraryQuery, FindAssetLibraryQueryVariables>
> = ({ assets, queryResult: { refetch } }) => (
  <AssetLibraryGrid assets={assets} refetch={refetch} />
)
