import React, { FC, useCallback, useEffect, useState } from 'react'

import { CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { SUPPORTED_FILE_MIME_TYPES } from 'api/src/common/enums'
import { HeaderType } from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import useUploadStorageObject from 'src/lib/hooks/StorageObject/useUploadStorageObject'
import { getAcceptedTypesForInput } from 'src/lib/hooks/StorageObject/utils'

import Loading from '../Loading/Loading'

interface storageObjectReturn {
  id: number
  downloadUrl: string
  fileType: HeaderType
}

interface DragAndDropFileUploadProps {
  name: string
  storageObjectType: {
    prefixes: string[]
    fileName: string
  }
  description?: string
  acceptedFileTypes: SUPPORTED_FILE_MIME_TYPES[]
  onUpload?: (storageObject: storageObjectReturn) => void
  onError?: (errorMessage: string) => void
}

const DragAndDropFileUpload: FC<DragAndDropFileUploadProps> = ({
  name,
  description = 'PNG, JPG or GIF (Max 800x400px)',
  storageObjectType,
  acceptedFileTypes,
  onUpload = () => {},
  onError = (errorMessage) => {
    toast.error(errorMessage)
  },
}) => {
  const [fileType, setFileType] = useState<HeaderType>(null)

  const [
    uploadFile,
    {
      result: uploadFileResult,
      error: uploadFileError,
      loading: uploadFileLoading,
    },
  ] = useUploadStorageObject({
    prefixes: storageObjectType.prefixes,
    fileName: storageObjectType.fileName,
    allowedFileMimeTypes: [
      SUPPORTED_FILE_MIME_TYPES.IMAGE,
      SUPPORTED_FILE_MIME_TYPES.VIDEO,
    ],
  })

  const uploadFileHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files || event.target.files.length === 0) {
        throw new Error('You must select a file to upload.')
      }
      const uploadType = event.target.files[0].type.substring(0, 5)

      if (uploadType === 'image') {
        setFileType('IMAGE')
      } else if (uploadType === 'video') {
        setFileType('VIDEO')
      } else {
        throw new Error('You must select an image or video to upload.')
      }

      const file: File = event.target.files[0]
      uploadFile({ file })
    },
    [uploadFile],
  )

  useEffect(() => {
    if (uploadFileResult) {
      const storageObject: storageObjectReturn = {
        id: uploadFileResult.id,
        downloadUrl: uploadFileResult.downloadUrl,
        fileType: fileType,
      }
      onUpload(storageObject)
    }
  }, [uploadFileResult])

  useEffect(() => {
    uploadFileError && onError(uploadFileError.message)
  }, [uploadFileError])

  return (
    <div className="flex w-full items-center justify-center">
      <label
        htmlFor={name}
        className="relative flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border border-dashed border-gray-300 bg-gray-50 text-sm text-gray-500 hover:bg-gray-100"
      >
        {uploadFileLoading && <Loading />}
        {!uploadFileLoading && (
          <>
            <CloudArrowUpIcon className="mb-2 h-8 w-8 text-gray-500" />
            Click to upload, or drag and drop files here.
            <span className="text-xs text-gray-500">{description}</span>
          </>
        )}
        <input
          type="file"
          id={name}
          name={name}
          accept={getAcceptedTypesForInput(acceptedFileTypes)}
          className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
          onChange={(e) => uploadFileHandler(e)}
        />
      </label>
    </div>
  )
}

export default DragAndDropFileUpload
