import * as React from 'react'

import { FLAG_FEATURES } from 'api/src/common/enums'

import { Metadata } from '@redwoodjs/web'

import AutomationCell from 'src/components/Automations/AutomationCell'
import PageHeader from 'src/components/PageHeader/PageHeader'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'

const AutomationPage = () => {
  return (
    <>
      <Metadata title="Automations" description="Automations" />
      <div className={'flex h-screen flex-col'}>
        <PageHeader title={'Automations'} />
        <FeatureToggle feature={FLAG_FEATURES.AUTOMATIONS}>
          <div className="flex h-full flex-col overflow-y-auto">
            <AutomationCell />
          </div>
        </FeatureToggle>
      </div>
    </>
  )
}

export default AutomationPage
