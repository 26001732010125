import { FC } from 'react'

import Button from 'src/components/Library/Button/Button'

interface TemplatePreviewTimelineHeaderProps {
  templateId: number
  createGoalTemplate: (parentGoalId: number) => void
  isLoading: boolean
  isMilestoneType: boolean
}
const TemplatePreviewHeader: FC<TemplatePreviewTimelineHeaderProps> = ({
  templateId,
  createGoalTemplate,
  isLoading,
  isMilestoneType,
}) => {
  return (
    <div className="flex min-h-[68px] items-center justify-between gap-4 pb-8">
      <p className="bold text-lg">Template Builder</p>
      {isMilestoneType && (
        <Button
          loading={isLoading}
          fullWidth={false}
          onClick={() => {
            createGoalTemplate(templateId)
          }}
        >
          Add a Milestone
        </Button>
      )}
    </div>
  )
}

export default TemplatePreviewHeader
