import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

import { Metadata } from '@redwoodjs/web'

import PageHeader from 'src/components/PageHeader/PageHeader'

const UnsupportedPage = () => {
  return (
    <>
      <Metadata title="Unsupported" description="Unsupported page" />
      <PageHeader title="" />
      <div className="flex min-h-full flex-col bg-white pb-12 pt-16">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
          <div className="flex flex-shrink-0 justify-center">
            <a href="/app" className="inline-flex">
              <span className="sr-only">Your Company</span>
              <ExclamationCircleIcon
                className="h-12 w-auto text-indigo-600"
                aria-hidden="true"
              />
            </a>
          </div>
          <div className="py-8">
            <div className="text-center">
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Page not Supported
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Sorry, this page is not supported on your current device.
              </p>
              <div className="mt-6">
                <a
                  href="/app"
                  className="text-base font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Go back home
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default UnsupportedPage
