export type BaserowColor = {
  name: string
  hex: string
  position?: string
}

export const baserowColors: BaserowColor[] = [
  { position: '1-1', hex: '#f0f4fc', name: 'light-blue' },
  { position: '1-2', hex: '#dae4fd', name: 'blue' },
  { position: '1-3', hex: '#acc8f8', name: 'dark-blue' },
  { position: '1-4', hex: '#689ef1', name: 'darker-blue' },
  { position: '2-1', hex: '#ecfcf1', name: 'light-green' },
  { position: '2-2', hex: '#d0f6dc', name: 'green' },
  { position: '2-3', hex: '#a0eeba', name: 'dark-green' },
  { position: '2-4', hex: '#41dd75', name: 'darker-green' },
  { position: '3-1', hex: '#cff5fa', name: 'light-cyan' },
  { position: '3-2', hex: '#a0ebf5', name: 'cyan' },
  { position: '3-3', hex: '#70e0ef', name: 'dark-cyan' },
  { position: '3-4', hex: '#41d6ea', name: 'darker-cyan' },
  { position: '4-1', hex: '#fffbf0', name: 'light-yellow' },
  { position: '4-2', hex: '#ffe9b4', name: 'yellow' },
  { position: '4-3', hex: '#ffdd8f', name: 'dark-yellow' },
  { position: '4-4', hex: '#ffd269', name: 'darker-yellow' },
  { position: '5-1', hex: '#fffbf0', name: 'light-orange' },
  { position: '5-2', hex: '#fff4da', name: 'orange' },
  { position: '5-3', hex: '#ffe9b4', name: 'dark-orange' },
  { position: '5-4', hex: '#ffd269', name: 'darker-orange' },
  { position: '6-1', hex: '#fff2f0', name: 'light-red' },
  { position: '6-2', hex: '#ffdeda', name: 'red' },
  { position: '6-3', hex: '#ffbdb4', name: 'dark-red' },
  { position: '6-4', hex: '#ff7b69', name: 'darker-red' },
  { position: '7-1', hex: '#f5e6dc', name: 'light-brown' },
  { position: '7-2', hex: '#f5ceb0', name: 'brown' },
  { position: '7-3', hex: '#f5c098', name: 'dark-brown' },
  { position: '7-4', hex: '#f5a96e', name: 'darker-brown' },
  { position: '8-1', hex: '#f9f1fd', name: 'light-purple' },
  { position: '8-2', hex: '#dfb9f7', name: 'purple' },
  { position: '8-3', hex: '#cf96f2', name: 'dark-purple' },
  { position: '8-4', hex: '#bf73ee', name: 'darker-purple' },
  { position: '9-1', hex: '#f7e1f2', name: 'light-pink' },
  { position: '9-2', hex: '#f7b2e7', name: 'pink' },
  { position: '9-3', hex: '#f285d9', name: 'dark-pink' },
  { position: '9-4', hex: '#ff6dde', name: 'darker-pink' },
  { position: '10-1', hex: '#f5f5f5', name: 'light-gray' },
  { position: '10-2', hex: '#d7d8d9', name: 'gray' },
  { position: '10-3', hex: '#cdcecd', name: 'dark-gray' },
  { position: '10-4', hex: '#b5b5b7', name: 'darker-gray' },
  { position: '0-0', hex: '#FFC03C', name: 'deep-dark-orange' },
  { position: '0-0', hex: '#11B741', name: 'deep-dark-green' },
]

export const getHexForColor = (colorName: string): string => {
  if (!colorName) return '#ffffff'
  const color = baserowColors.find((c) => c.name === colorName)
  if (!color) {
    return '#ffffff'
  } else {
    return color.hex
  }
}
