import { FC } from 'react'

import { Skeleton } from '@mui/material'

const KnowledgeBaseArticleSkeleton: FC = () => {
  return (
    <div className="py-4">
      <div className="flex gap-2">
        <Skeleton animation="wave" variant="circular" width={30} height={30} />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={250}
          height={30}
        />
      </div>
      <div className="flex flex-col gap-4 py-10">
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={'75%'}
          height={40}
          className="mb-4"
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={'100%'}
          height={24}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={'100%'}
          height={24}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={'50%'}
          height={24}
        />
      </div>
    </div>
  )
}

export default KnowledgeBaseArticleSkeleton
