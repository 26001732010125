import { FC } from 'react'

interface GraphItemSummary {
  title: string
  value: number
  innerLabel?: string
}

interface RadialProgressGraphOptions {
  stroke?: number
  padding?: number
  radius?: number
}

interface RadialProgressGraphProps {
  summary?: GraphItemSummary
  progressRings?: GraphItemSummary[]
  settings?: RadialProgressGraphOptions
}

interface RadialProgressRingProps {
  index: number
  ringData?: GraphItemSummary
  settings?: RadialProgressGraphOptions
}

interface RingLabelProps {
  index: number
  label: string
}

// RING COLOUR OPTIONS
const strokeColorOptions = {
  0: {
    main: 'stroke-green-400',
    hover: 'group-hover:stroke-green-600',
    background: 'bg-green-400',
  },
  1: {
    main: 'stroke-blue-400',
    hover: 'group-hover:stroke-blue-600',
    background: 'bg-blue-400',
  },
  2: {
    main: 'stroke-red-400',
    hover: 'group-hover:stroke-red-600',
    background: 'bg-red-400',
  },
}

/**
 * Radial Progress Rings. Displays a series of radial progress bars with hover labels
 * @param {index} number - Array position index used as a multiply value to calculate radius size
 * @param {progressRings} object - Title, Value object for the ring
 * @param {settings} object - Optional. Define Padding, Radius, Stroke values
 */
const RadialProgressRing: FC<RadialProgressRingProps> = ({
  index,
  settings,
  ringData,
}) => {
  // RING SETTINGS
  const { radius, stroke, padding } = settings

  // RING COLOUR
  const strokeColorMain = strokeColorOptions[index].main
  const strokeColorHover = strokeColorOptions[index].hover

  // RING SIZE
  const strokeWidthOfOneRing = stroke + padding * 2
  const amountToReduceRadiusBy = strokeWidthOfOneRing * index
  const scaledRadius = radius - amountToReduceRadiusBy

  // RING PROGRESS
  const radialPercent = ringData.value
  const circumference = 2 * Math.PI * scaledRadius
  const visibleCircumferencePercentage = (radialPercent / 100) * circumference

  // RING POSITIONING
  const circlePositionX = radius + padding
  const circlePositionY = radius + padding

  // LABEL POSITIONING
  const charlength = ringData.value.toString().length + 1
  const labelWidth = charlength * 16
  const labelHeight = 30

  // LABEL SIZE
  const labelPosY = amountToReduceRadiusBy + padding / 4
  const labelPosX = radius + padding - labelWidth / 2

  return (
    <g className="group">
      <circle
        cx={circlePositionX}
        cy={circlePositionY}
        r={scaledRadius}
        strokeLinecap="round"
        style={{
          transform: 'rotate(-90deg)',
          transformOrigin: 'center center 0px',
          strokeDashoffset: circumference,
          fill: 'none',
          strokeWidth: stroke,
          strokeDasharray: circumference * 2,
          transition: 'all ease .1s',
        }}
        className="stroke-slate-200 group-hover:stroke-slate-300"
      />
      <circle
        cx={circlePositionX}
        cy={circlePositionY}
        r={scaledRadius}
        strokeLinecap="round"
        style={{
          transform: 'rotate(-90deg)',
          transformOrigin: 'center center 0px',
          strokeDashoffset: circumference,
          fill: 'none',
          strokeWidth: stroke,
          strokeDasharray: visibleCircumferencePercentage + circumference,
          transition: 'all ease .1s',
        }}
        className={`${strokeColorMain} ${strokeColorHover}`}
      />
      <rect
        x={labelPosX}
        y={labelPosY - 2}
        width={labelWidth}
        height={labelHeight}
        className="z-10 hidden cursor-default select-none text-xs group-hover:block"
        rx={4}
      ></rect>
      <text
        x={labelPosX + 4}
        y={labelPosY + labelHeight * 0.65}
        className="z-10 hidden cursor-default select-none font-mono text-lg group-hover:block"
        fill="white"
        textLength={charlength * 12}
        lengthAdjust="spacingAndGlyphs"
      >
        {radialPercent + '%'}
      </text>
    </g>
  )
}

/**
 * Radial Progress Graph. Displays a series of radial progress bars with hover labels
 * @param {index} number - Array position index used as colour indicator
 * @param {label} string - Metric label
 */
const RingLabel: FC<RingLabelProps> = ({ index, label }) => {
  return (
    <div className="flex items-center gap-1">
      <span
        className={`h-2 w-2 rounded-full ${strokeColorOptions[index].background}`}
      ></span>
      <span className="text-xs">{label}</span>
    </div>
  )
}

/**
 * Radial Progress Graph. Displays a series of radial progress bars with hover labels
 * @param {summary} object - Define Title, Value of the overall data
 * @param {progressRings} array - Define up to 4 Title, Value objects for the rings
 * @param {settings} object - Optional. Define Padding, Radius, Stroke values
 */
const RadialProgressGraph: FC<RadialProgressGraphProps> = ({
  summary,
  progressRings,
  settings,
}) => {
  // Set Default Settings
  const padding = settings?.padding ?? 10
  const radius = settings?.radius ?? 200
  const stroke = settings?.stroke ?? 20
  const ringSettings = { radius, padding, stroke }

  // SVG Size and Position Calculations
  const viewBoxSize = radius * 2 + padding * 2

  return (
    <div className="relative">
      {summary?.title && (
        <p className="mb-4 text-center text-lg font-bold">{summary?.title}</p>
      )}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="auto"
        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
        style={{ maxWidth: viewBoxSize + 'px' }}
      >
        {progressRings.map((ring, index) => (
          <RadialProgressRing
            key={index}
            index={index}
            settings={ringSettings}
            ringData={ring}
          />
        ))}
      </svg>
      <p className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] select-none text-center text-2xl font-bold">
        {(summary?.value || 0) + '%'}
        <small className="block max-w-[100px] text-center text-xs font-normal">
          {summary?.innerLabel}
        </small>
      </p>
      <div className="mt-4 flex w-full flex-wrap items-center justify-between gap-2 text-center">
        {progressRings.map((ring, index) => (
          <RingLabel key={index} index={index} label={ring.title} />
        ))}
      </div>
    </div>
  )
}

export default RadialProgressGraph
