import { FC } from 'react'

import { HomeIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Breadcrumbs } from '@mui/material'

import { navigate, routes } from '@redwoodjs/router'

import useAnalytics from 'src/lib/hooks/useAnalytics'

import Button from '../Library/Button/Button'

interface OnboardingPageHeaderProps {
  currentPage?: {
    name: string
    url: string
  }
}

const OnboardingPageHeader: FC<OnboardingPageHeaderProps> = ({
  currentPage,
}) => {
  const { trackEvent } = useAnalytics()
  return (
    <div className="sticky top-0 z-10 flex items-center justify-between gap-4 border-b border-gray-300 bg-white px-10 py-6">
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<ChevronRightIcon className="h-4 w-4" />}
      >
        <Button
          variant="text"
          fullWidth={false}
          className="px-3 text-gray-600 hover:bg-gray-100"
          onClick={() => {
            navigate(routes.onboarding())
          }}
          startIcon={<HomeIcon className="h-5 w-5" />}
        >
          <span className="pt-0.5">Setup</span>
        </Button>
        {currentPage?.name && currentPage?.url && (
          <Button
            variant="text"
            fullWidth={false}
            className="px-4 text-gray-600 hover:bg-gray-100"
            onClick={() => {
              navigate(currentPage.url)
            }}
          >
            {currentPage.name}
          </Button>
        )}
      </Breadcrumbs>
      <Button
        endIcon={<ChevronRightIcon className="h-5 w-5" />}
        variant="text"
        fullWidth={false}
        className="px-4 text-indigo-600 hover:bg-indigo-100"
        onClick={() => {
          navigate(routes.home())
          trackEvent('Settings', 'onboarding - skip setup')
        }}
      >
        <span className="pt-0.5">Skip Setup</span>
      </Button>
    </div>
  )
}
export default OnboardingPageHeader
