import {
  PencilIcon,
  RocketLaunchIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { InputLabel, Skeleton, Tooltip } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'

import IconButton from 'src/components/Library/IconButton'
import Switch from 'src/components/Library/Switch/Switch'
import { useConfirm } from 'src/lib/hooks/Confirmation'

const FeatureCard = ({
  feature,
  loading,
  selectedFeature,
  handleEdit,
  setSelectedFeature,
  updateFeature,
  deleteFeature,
}) => {
  const confirm = useConfirm()
  return (
    <Card elevation={0} variant={'outlined'}>
      <CardHeader
        avatar={
          loading.value && feature.id === selectedFeature.id ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : (
            <Avatar
              className={feature.enabled ? 'bg-green-400' : 'bg-gray-500'}
              aria-label="feature"
            >
              <RocketLaunchIcon
                className={`h-6 w-6 ${
                  feature.enabled ? 'text-gray-800' : 'text-white'
                }`}
              />
            </Avatar>
          )
        }
        action={
          loading.value && feature.id === selectedFeature.id ? null : (
            <Stack direction="row" className="items-center" spacing={2}>
              <div className="flex items-center gap-2">
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleEdit(feature)}>
                    <PencilIcon className={'h-5 w-5'} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => {
                      loading.setTrue()
                      confirm({
                        title: 'Are you sure you want to delete this feature?',
                        description: 'This action cannot be undone.',
                      }).then(() =>
                        deleteFeature({
                          variables: {
                            id: feature.id,
                          },
                        }),
                      )
                    }}
                  >
                    <TrashIcon className={'h-5 w-5'} />
                  </IconButton>
                </Tooltip>
              </div>
              <Stack className="flex items-center">
                <InputLabel className="text-xs">Enabled</InputLabel>
                <Switch
                  checked={feature.enabled}
                  onChange={() => {
                    loading.setTrue()
                    setSelectedFeature(feature)
                    updateFeature({
                      variables: {
                        id: feature.id,
                        input: { enabled: !feature.enabled },
                      },
                    })
                  }}
                  name="enabled"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </Stack>
            </Stack>
          )
        }
        title={
          loading.value && feature.id === selectedFeature.id ? (
            <Skeleton height={10} width="80%" style={{ marginBottom: 6 }} />
          ) : (
            feature.name
          )
        }
        subheader={
          loading.value && feature.id === selectedFeature.id ? (
            <Skeleton height={10} width="40%" />
          ) : (
            feature.description
          )
        }
      />
    </Card>
  )
}

export default FeatureCard
