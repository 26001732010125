import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { DialogContent, DialogTitle, Divider, TextField } from '@mui/material'
import { SUPPORTED_FILE_MIME_TYPES } from 'api/src/common/enums'

import { toast } from '@redwoodjs/web/toast'

import useUploadStorageObject from 'src/lib/hooks/StorageObject/useUploadStorageObject'
import { validatePhone } from 'src/lib/phoneValidator'
import { useAuth } from 'web/src/Providers'

import RichTextEditor from '../Library/RichTextEditor/RichTextEditor'
import UserAvatar from '../UserAvatar/UserAvatar'

const EditUserProfileForm = ({
  personalInfoData,
  user,
  handleFormSubmit,
  setTextEditorAbout,
  setTextEditorLikes,
  setTextEditorDislikes,
  setTextEditorHobbies,
  setAvatarStorageObject,
  name,
  setName,
  phone,
  setPhone,
}) => {
  const { currentUser } = useAuth()

  const clientId = useMemo(() => currentUser.parentData.id, [currentUser])

  const [avatarUrl, setAvatarUrl] = useState(user.avatarUrl)

  const [uploadAvatar, { result: avatarResult, error: avatarError }]: any =
    useUploadStorageObject({
      prefixes: [`${clientId}`, 'user', 'profile'],
      fileName: 'avatar',
      allowedFileMimeTypes: [SUPPORTED_FILE_MIME_TYPES.IMAGE],
    })

  useEffect(() => {
    if (avatarResult) {
      setAvatarStorageObject(avatarResult)
      setAvatarUrl(avatarResult.downloadUrl)
    }
  }, [avatarResult])

  useEffect(() => {
    avatarError && toast.error(avatarError.message)
  }, [avatarError])

  const uploadAvatarHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files || event.target.files.length === 0) {
        toast.error('You must select an image to upload.')
        return
      }

      const file: File = event.target.files[0]
      uploadAvatar({ file })
    },
    [uploadAvatar],
  )

  const [phoneStatus, setPhoneStatus] = useState(validatePhone(user.phone))

  const setPhoneCheck = (phone: string) => {
    setPhoneStatus(validatePhone(phone))
    setPhone(phone)
  }

  const [aboutMe] = useState(personalInfoData?.aboutMe)
  const [likes] = useState(personalInfoData?.likes)
  const [dislikes] = useState(personalInfoData?.dislikes)
  const [hobbies] = useState(personalInfoData?.hobbies)

  return (
    <form
      className="h-100 flex flex-1 grow flex-col"
      onSubmit={(e) => {
        handleFormSubmit(e)
      }}
    >
      <DialogTitle>Edit Profile</DialogTitle>
      <Divider />
      <DialogContent>
        <div className="mt-4 items-center gap-2 lg:flex">
          <div className="mb-5 w-[25%] text-sm font-medium text-gray-900 lg:mb-0">
            <UserAvatar src={avatarUrl} height={24} />
          </div>
          <input
            type="file"
            accept="image/*, video/*"
            onChange={(e) => uploadAvatarHandler(e)}
            className="w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          />
        </div>

        <div className="mt-4 items-center gap-2 lg:flex">
          <p className="w-[25%] text-sm font-medium text-gray-900">Name</p>
          <TextField
            className="w-full bg-white"
            id="userNameEdit"
            variant="outlined"
            size="small"
            sx={{ width: 400 }}
            required={true}
            defaultValue={name || user?.name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="mt-4 items-center gap-2 lg:flex">
          <div className="flex w-[25%] flex-col">
            <p className="whitespace-nowrap text-sm font-medium text-gray-900">
              Phone
            </p>
            <p
              className="whitespace-nowrap text-sm font-medium text-gray-900"
              style={phoneStatus?.isValid ? {} : { color: 'orange' }}
            >
              {phoneStatus?.message}
            </p>
          </div>

          <TextField
            className="w-full bg-white"
            id="userPhoneEdit"
            variant="outlined"
            size="small"
            sx={{ width: 400 }}
            defaultValue={phone || user?.phone}
            onChange={(e) => setPhoneCheck(e.target.value)}
          />
        </div>
        <Divider className="mt-4" />

        <div className="gap-2 py-4 lg:flex">
          <p className="w-[25%] text-sm font-medium text-gray-900">About Me</p>
          <div className="w-full">
            <RichTextEditor
              id="itemAboutMe"
              name="itemAboutMe"
              onChange={setTextEditorAbout}
              defaultValue={aboutMe}
              className="max-h-[200px]"
            />
          </div>
        </div>
        <Divider />

        <div className="gap-2 py-4 lg:flex">
          <p className="w-[25%] text-sm font-medium text-gray-900">Likes</p>
          <div className="w-full">
            <RichTextEditor
              id="itemLikes"
              name="itemLikes"
              onChange={setTextEditorLikes}
              defaultValue={likes}
              className="max-h-[200px]"
            />
          </div>
        </div>
        <Divider />

        <div className="gap-2 py-4 lg:flex">
          <p className="w-[25%] text-sm font-medium text-gray-900">Dislikes</p>
          <div className="w-full">
            <RichTextEditor
              id="itemDislikes"
              name="itemDislikes"
              onChange={setTextEditorDislikes}
              defaultValue={dislikes}
              className="max-h-[200px]"
            />
          </div>
        </div>
        <Divider />

        <div className="gap-2 py-4 lg:flex">
          <p className="w-[25%] text-sm font-medium text-gray-900">Hobbies</p>
          <div className="w-full">
            <RichTextEditor
              id="itemHobbies"
              name="itemHobbies"
              onChange={setTextEditorHobbies}
              defaultValue={hobbies}
              className="max-h-[200px]"
              // debounce={0}
            />
          </div>
        </div>
      </DialogContent>
    </form>
  )
}

export default EditUserProfileForm
