import { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react'

import { LinearProgress, Tooltip } from '@mui/material'
import { GoalStatusTypes } from 'api/src/common/enums'
import { goalStatusChangeToNew } from 'api/src/common/utils'
import { clamp } from 'ramda'
import {
  ACLPrincipalMembership,
  ACLPrincipalMembershipGroup,
} from 'types/graphql'

import {
  EditFormStates,
  StatusTextColors,
  StatusBGColors,
} from 'src/components/Goals/constants'
import GoalTargetIcon from 'src/components/Goals/GoalTargetIcon/GoalTargetIcon'
import { GoalListCellItemType } from 'src/components/Goals/interfaces'
import ObjectiveDescription from 'src/components/Goals/MainPanel/GoalList/GoalItem/GoalObjective/ObjectiveDescription'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'src/Providers'

import AssignedMembers from '../AssignedMembers'
import ExpandButton from '../ExpandButton'
import MilestoneIndicator from '../Milestone/MilestoneIndicator'

import ObjectiveDuration from './ObjectiveDuration'
interface GoalObjectiveProps {
  goal: GoalListCellItemType
  foundGoalId?: number | null
  setCreatingMilestone?: Dispatch<SetStateAction<boolean>>
  selectedObjective?: number | null
  setSelectedObjective?: Dispatch<SetStateAction<number | null>>
  setSidebarState?: Dispatch<
    SetStateAction<
      EditFormStates.empty | EditFormStates.edit | EditFormStates.new
    >
  >
  allowEdit?: boolean
  allowOpen: boolean
  isProfileView?: boolean
  children?: ReactNode
}
const GoalObjective: FC<GoalObjectiveProps> = ({
  goal,
  foundGoalId,
  setCreatingMilestone,
  selectedObjective,
  setSelectedObjective,
  setSidebarState,
  allowOpen = true,
  allowEdit = false,
  isProfileView = false,
  children,
}) => {
  const { currentUser } = useAuth()
  const currentUserId = currentUser.userData.id
  const milestoneIds = goal?.childGoals.map((milestone) => milestone.id)
  const foundGoalIdInMilestones = milestoneIds.includes(foundGoalId)

  // Set opened state of the Goal
  const [isOpened, setOpened] = useState<boolean>(
    foundGoalId !== null &&
      (foundGoalId === goal?.id || foundGoalIdInMilestones),
  )

  // init Analytics
  const { trackEvent } = useAnalytics()

  // Check if current objective is selected
  const objectiveIsSelected = selectedObjective === goal.id

  // Check if current objective has selected milestone
  const childGoalIsSelected = goal?.childGoals?.some(({ id }) => {
    return id === selectedObjective
  })

  // Objective can be expanded
  const objectiveCanBeSelected = allowOpen && allowEdit

  // Set the objective testing ID
  const objectiveTestId = `goal-objective-expand-${goal.goalTitle}`

  // Handle Objective Selection
  const handleObjectiveClick = async (
    currentObjectiveId,
    selectedObjectiveId,
  ) => {
    // Check if objective is selected or unselected
    const clickOnCurrentObjective = selectedObjectiveId === currentObjectiveId

    // Only fire if a new goal is selected
    if (!clickOnCurrentObjective) {
      trackEvent('Goals', 'click on goal', { goalId: currentObjectiveId })
    }

    // open goal if it is not opened
    if (!isOpened) {
      setOpened(true)
    }

    if (!isProfileView) {
      // Set false because we are now going to edit a goal
      setCreatingMilestone(false)
    }

    // Set the selected objective only if there are no field changes in the form
    // awaited so it waits for the save to complete with the previous id
    await setSelectedObjective(currentObjectiveId)

    if (!isProfileView) {
      // Update the sidebar state
      setSidebarState(
        currentObjectiveId ? EditFormStates.edit : EditFormStates.empty,
      )
    }
  }

  // Handle Objective Expand
  const handleObjectiveExpand = (e, currentObjectiveId) => {
    e.stopPropagation()
    trackEvent('Goals', `${isOpened ? 'close' : 'open'} milestone`, {
      goalTitle: goal.goalTitle,
    })

    // Set the selected objective
    setSelectedObjective(currentObjectiveId)

    // Update the sidebar state
    if (!isProfileView) {
      setSidebarState(
        currentObjectiveId ? EditFormStates.edit : EditFormStates.empty,
      )
    }

    setOpened(!isOpened)
  }

  // Custom classes for major elements
  const objectiveWrapperClasslist = `single-objective w-full overflow-hidden flex flex-wrap bg-white border border-gray-200 mb-1 ${
    (objectiveIsSelected || childGoalIsSelected) &&
    'ring-1 ring-indigo-600 z-50 shadow-md'
  }`

  const objectiveSelectorClasslist = `w-full flex flex-col sm:flex-row justify-between items-center gap-2 relative text-left grow sm:pr-0
  ${objectiveCanBeSelected && 'hover:bg-gray-100 cursor-pointer '}
    ${objectiveIsSelected && 'bg-indigo-100'}`

  const objectivePreviewClasslist = `bg-gray-50 w-full p-1 md:pl-5 md:pr-2 2xl:px-9 border-t border-t-grey-300 sm:border-1 ${
    isOpened ? 'block' : 'hidden'
  }`

  // Get Color classes for each status type
  const textColorClass = StatusTextColors[goal?.currentStatus]
  const bgColorClass = StatusBGColors[goal?.currentStatus]
  const strokeColorClass = StatusTextColors[goal?.currentStatus]

  // Collect the assigned Memberships and Membership Groups
  const objectiveACLMembershipPrincipals: {
    memberships: ACLPrincipalMembership[]
    membershipGroups: ACLPrincipalMembershipGroup[]
  } = goal?.aclPrincipals.reduce(
    (objectiveAssignees, current) => {
      if (current?.principalType === 'MEMBERSHIP') {
        objectiveAssignees.memberships.push(current?.principal)
      }

      if (current?.principalType === 'MEMBERSHIPGROUP') {
        objectiveAssignees.membershipGroups.push(current?.principal)
      }

      return objectiveAssignees
    },
    { memberships: [], membershipGroups: [] },
  )

  return (
    <div className={objectiveWrapperClasslist}>
      <div
        tabIndex={0}
        role="button"
        onKeyUp={() => {
          handleObjectiveClick(goal?.id, selectedObjective)
        }}
        className={objectiveSelectorClasslist}
        onClick={() => {
          handleObjectiveClick(goal?.id, selectedObjective)
        }}
      >
        <div className="flex w-full min-w-[0px] flex-col p-4 pb-0 sm:!p-4">
          <div className="objective-description goal-objective-goal-title flex items-center break-words text-sm">
            <GoalTargetIcon
              sizeInPixels={14}
              fillColour={`${objectiveIsSelected ? '#ffffff' : '#9ca3af'}`}
              className={`mr-2 min-h-[28px] min-w-[28px] rounded-md bg-gray-100 p-1 ${
                objectiveIsSelected ? '!bg-indigo-600' : ''
              }`}
            />
            <div className="flex w-full flex-col">
              <p
                className={
                  'text-left text-[11px] uppercase leading-4 text-gray-500'
                }
              >
                <Tooltip
                  title={
                    <div>
                      <div>
                        Created by{' '}
                        {currentUserId === goal.createdBy
                          ? 'you'
                          : goal.createdByUserName}{' '}
                        on {new Date(goal.createdAt).toLocaleString()}
                      </div>
                      <div>
                        Update by{' '}
                        {currentUserId === goal.updatedBy
                          ? 'you'
                          : goal.updatedByUserName}{' '}
                        on {new Date(goal.updatedAt).toLocaleString()}
                      </div>
                    </div>
                  }
                  arrow
                  placement="right"
                >
                  <span>Goal</span>
                </Tooltip>
              </p>
              <p
                className="min-w-[0px] break-words text-sm capitalize"
                data-testid={`goal-title-${goal.goalTitle}`}
              >
                {goal.goalTitle}
              </p>
            </div>
          </div>
        </div>

        <div className="flex h-full w-full items-center justify-between gap-x-1 px-4 pb-3 sm:!p-0 md:justify-end xl:gap-x-4">
          <div className="flex items-center gap-2">
            {objectiveACLMembershipPrincipals.membershipGroups.length > 0 && (
              <div className="flex flex-col">
                <p className="hidden text-left text-[11px] uppercase leading-4 text-gray-500 xl:block">
                  Groups
                </p>
                <AssignedMembers
                  isGroup={true}
                  members={objectiveACLMembershipPrincipals.membershipGroups}
                />
              </div>
            )}
            {objectiveACLMembershipPrincipals.memberships.length > 0 && (
              <div className="flex flex-col">
                <p className="hidden text-left text-[11px] uppercase leading-4 text-gray-500 xl:block">
                  Members
                </p>
                <AssignedMembers
                  members={objectiveACLMembershipPrincipals.memberships}
                />
              </div>
            )}
          </div>
          <MilestoneIndicator count={goal?.childGoals.length} />

          <ObjectiveDuration
            strokeColorClass={strokeColorClass}
            textColorClass={textColorClass}
            isComplete={goal?.isComplete}
            diffDays={goal?.diffDays}
            dueDate={goal?.dueDate as string}
            completedDate={goal?.completedDate as string}
          />

          <div className="flex w-[130px] flex-col">
            <div className="mb-0.5 flex justify-between">
              <p
                className={`text-[11px] leading-4 ${textColorClass} text-left font-medium uppercase`}
                data-testid={`goal-progress-status-${goal.goalTitle}-${goal?.currentStatus}`}
              >
                {goal?.currentStatus &&
                  goalStatusChangeToNew(goal?.currentStatus as GoalStatusTypes)}
              </p>
              <p
                className="mt-0.5 w-10 pl-2 text-right text-xs font-medium uppercase leading-4 tracking-wider"
                data-testid={`goal-progress-value-${goal.goalTitle}-${goal?.percentProgress}`}
              >
                {goal?.percentProgress}%
              </p>
            </div>
            <LinearProgress
              variant="determinate"
              value={clamp(0, 100, goal?.percentProgress)}
              color="inherit"
              className={`w-full ${bgColorClass} ${textColorClass} h-1.5 rounded`}
            />
          </div>
          <ExpandButton
            allowOpen={allowOpen}
            onExpand={(event) => handleObjectiveExpand(event, goal?.id)}
            isOpened={isOpened}
            objectiveTestId={objectiveTestId}
          />
        </div>
      </div>

      <div className={objectivePreviewClasslist}>
        <ObjectiveDescription description={goal?.goalBody} />
        {children}
      </div>
    </div>
  )
}

export default GoalObjective
