import { FC } from 'react'

import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/24/solid'

import { navigate } from '@redwoodjs/router'

import useAnalytics from 'src/lib/hooks/useAnalytics'
import TheHubIcon from 'src/lib/icons/TheHubIcon/TheHubIcon'

import Button from '../Library/Button/Button'

export interface ButtonOption {
  label: string
  url: string
}

interface OnboardingSubNavigationProps {
  title: string
  backButton: ButtonOption
  nextButton: ButtonOption
}

const OnboardingSubNavigation: FC<OnboardingSubNavigationProps> = ({
  title,
  backButton,
  nextButton,
}) => {
  const { trackEvent } = useAnalytics()
  return (
    <>
      <div className="mx-auto max-w-[300px]">
        <TheHubIcon />
        <p className="py-3 text-center text-2xl">{title}</p>
      </div>

      <div className="flex items-center justify-between gap-4">
        <Button
          variant="text"
          fullWidth={false}
          className="px-3 text-gray-600 hover:bg-gray-100"
          onClick={() => {
            navigate(backButton.url)
            trackEvent('Settings', `OB ${title} click ${backButton.label} `)
          }}
          startIcon={<ChevronLeftIcon className="h-5 w-5" />}
        >
          <span className="pt-0.5">{backButton.label}</span>
        </Button>
        <Button
          variant="text"
          fullWidth={false}
          className="px-3 text-gray-600 hover:bg-gray-100"
          onClick={() => {
            navigate(nextButton.url)
            trackEvent('Settings', `OB ${title} click ${nextButton.label} `)
          }}
          endIcon={<ChevronRightIcon className="h-5 w-5" />}
        >
          <span className="pt-0.5">{nextButton.label}</span>
        </Button>
      </div>
    </>
  )
}

export default OnboardingSubNavigation
