import { FC, useState } from 'react'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

interface EmbedComponentProps {
  isPreview?: boolean
  embedURL?: string
  parentClassName?: string
}

const EmbedComponent: FC<EmbedComponentProps> = ({
  isPreview,
  embedURL,
  parentClassName,
}) => {
  const [loading, setLoading] = useState(true)

  return (
    <div className={parentClassName}>
      {loading && (
        <div className="flex h-full w-full">
          <LoadingSpinner />
        </div>
      )}
      {embedURL && (
        <div>
          <iframe
            className={`w-full ${
              isPreview ? 'min-h-[500px]' : 'min-h-[calc(100vh-300px)]'
            } `}
            src={embedURL}
            title="Embedded content"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            onLoad={() => setLoading(false)}
          />
        </div>
      )}
    </div>
  )
}

export default EmbedComponent
