import React from 'react'

import { Chip } from '@mui/material'
import { KnowledgeBaseItemStatus } from 'types/graphql'

type KBStatusChipProps = {
  status: KnowledgeBaseItemStatus
  size: 'small' | 'medium'
}

const KBStatusChip = ({
  status,
  size,
}: KBStatusChipProps): React.ReactElement => {
  switch (status) {
    case 'DRAFT':
      return (
        <Chip
          size={size}
          className="ml-2 bg-amber-100 text-amber-600"
          label="Draft"
        />
      )
    case 'ARCHIVED':
      return (
        <Chip
          size={size}
          className="ml-2 bg-gray-500 text-gray-50"
          label="Archived"
        />
      )
    default:
      return null
  }
}

export default KBStatusChip
