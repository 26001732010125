import type React from 'react'

import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'

interface SavingTextProps {
  saving: boolean
}

const savingText: React.FC<SavingTextProps> = ({ saving }) => {
  if (saving) {
    return (
      <div className="flex flex-row items-center">
        <ArrowPathIcon className="mr-2 h-6 w-6 animate-spin" />
        <p>Saving...</p>
      </div>
    )
  } else {
    return (
      <div className="flex flex-row items-center">
        <CheckCircleIcon className="mr-2 h-6 w-6 text-green-400" />
        <p>Saved</p>
      </div>
    )
  }
}

export default savingText
