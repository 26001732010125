import React, { useState } from 'react'

import { PencilIcon } from '@heroicons/react/24/outline'
import Stack from '@mui/material/Stack'
import { useBoolean } from 'usehooks-ts'

import Button from 'src/components/Library/Button/Button'
import IconButton from 'src/components/Library/IconButton'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { npsMeasurementConfig } from 'src/components/Library/RecordSettingsModal/config'
import RecordSettingsModal from 'src/components/Library/RecordSettingsModal/RecordSettingsModal'

export const QUERY = gql`
  query NpsMeasurementsList {
    npsMeasurements {
      id
      name
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => (
  <div className={'flex justify-center'}>
    <h1
      className={
        'text-gray-3z00 text-xs font-normal uppercase leading-3 tracking-wider'
      }
    >
      no categories
    </h1>
  </div>
)

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({
  npsMeasurements,
  setMeasurement,
  setView,
  saveMeasurement,
  deleteMeasurement,
}) => {
  const [selectedMeasurement, setSelectedMeasurement] = useState(null)
  const editRecord = useBoolean(false)

  return (
    <>
      {editRecord.value && (
        <RecordSettingsModal
          modalVisible={editRecord}
          record={selectedMeasurement}
          config={npsMeasurementConfig}
          onDelete={(record) => {
            deleteMeasurement({
              variables: {
                id: record.id,
              },
            })
            editRecord.setFalse()
            setMeasurement(null)
          }}
          onSave={(record) => {
            saveMeasurement({
              variables: {
                id: record.id,
                input: {
                  name: record.name,
                },
              },
            })
          }}
        />
      )}

      <Stack spacing={0}>
        <>
          {npsMeasurements.map((measurement) => (
            <div
              className={`group flex hover:!bg-indigo-50 group-hover:bg-indigo-50 ${
                selectedMeasurement?.id === measurement.id &&
                '!border-indigo-200 !bg-indigo-50 text-indigo-600'
              } group`}
              key={measurement.id}
            >
              <Button
                variant={'text'}
                fullWidth
                size={'small'}
                color={'primary'}
                className={`group h-8 justify-start bg-white text-gray-700 hover:!border-indigo-200 hover:!text-indigo-600 group-hover:!bg-transparent ${
                  selectedMeasurement?.id === measurement.id &&
                  '!border-indigo-200 !bg-indigo-50 text-indigo-600'
                }`}
                onClick={() => {
                  setSelectedMeasurement(measurement)
                  setMeasurement(measurement.id)
                  setView()
                }}
              >
                {measurement.name}
              </Button>
              <div className={'flex flex-1 justify-end'}>
                <IconButton
                  onClick={() => {
                    setSelectedMeasurement(measurement)
                    editRecord.setTrue()
                  }}
                >
                  <PencilIcon className={'h-4 w-4 stroke-2'} />
                </IconButton>
              </div>
            </div>
          ))}
        </>
      </Stack>
    </>
  )
}
