import React from 'react'

import { Metadata } from '@redwoodjs/web'

import FeatureDashboardCell from 'src/components/FeatureDashboardCell'
import PageHeader from 'src/components/PageHeader/PageHeader'

const FeatureDashboardPage = () => {
  return (
    <>
      <Metadata title="FeatureDashboard" description="FeatureDashboard page" />
      <PageHeader
        title={'Feature Management'}
        parentDataTestId="feature-management-page-header"
      />
      <div className="flex h-[calc(100vh-5rem)] flex-1 justify-center">
        <div className="mb-2 h-full w-full overflow-y-auto px-8 pt-8">
          <FeatureDashboardCell />
        </div>
      </div>
    </>
  )
}

export default FeatureDashboardPage
