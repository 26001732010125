import { FC } from 'react'

import useLiveFeature from 'src/lib/hooks/LiveFeatureFlag/UseLiveFeature'
import { useAuth } from 'src/Providers'

import GlobalSearch from '../GlobalSearch/GlobalSearch'

interface Props {
  isMobile: boolean
}
const InformerHeader: FC<Props> = ({ isMobile }) => {
  const [globalSearchModernEnabled] = useLiveFeature('globalSearchModern')

  const { currentUser } = useAuth()

  const { headerUrl, headerType, logoUrl, name } = currentUser.parentData

  return (
    <>
      <div className="relative z-10 flex flex-auto items-center justify-center overflow-visible bg-white shadow">
        {headerType === 'VIDEO' && (
          <video
            autoPlay
            loop
            muted
            playsInline
            className="absolute z-10 h-full w-full object-cover shadow"
          >
            <source src={headerUrl} type="video/mp4" />
            Your browser does not support the video tag, Chrome/Safari/Firefox
            are supported.
          </video>
        )}
        <div
          className="z-30 flex h-full min-w-full items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 bg-cover bg-center bg-no-repeat px-4 py-5 sm:p-6 md:py-16"
          style={headerUrl ? { backgroundImage: `url(${headerUrl})` } : {}}
        >
          <div className="z-20 flex w-full flex-col place-items-center space-y-12">
            {logoUrl && (
              <img
                className={`${isMobile ? 'max-h-36 max-w-36' : 'h-36'}`}
                alt="Logo"
                src={logoUrl}
              />
            )}
            {!logoUrl && <p className="text-[40px] text-white">{name}</p>}
            {!globalSearchModernEnabled && (
              <GlobalSearch isMobile={isMobile} isHomePage />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default InformerHeader
