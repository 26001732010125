import { Dispatch, FC, SetStateAction } from 'react'

import { CheckCircleIcon } from '@heroicons/react/24/outline'

import Button from '../Library/Button/Button'

import { IntegrationLogo } from './IntegrationCard'
import { IntegrationConfig, IntegrationType } from './integrations'

interface IntegrationOptionCardProps {
  disabled: boolean
  integration: IntegrationConfig
  selectedProvider: IntegrationType
  integrationType: IntegrationType
  setSelectedProvider: Dispatch<SetStateAction<IntegrationType>>
}

const IntegrationOptionCard: FC<IntegrationOptionCardProps> = ({
  disabled,
  integration,
  selectedProvider,
  integrationType,
  setSelectedProvider,
}) => {
  const selectedIntegration = selectedProvider === integrationType

  return (
    <>
      <Button
        onClick={() => {
          setSelectedProvider(integrationType as IntegrationType)
        }}
        variant="text"
        className="h-full grow !bg-transparent p-0 text-left"
        disabled={disabled}
      >
        <div
          className={`relative flex h-full w-full grow flex-col gap-10 rounded-lg border p-4 hover:shadow-lg ${
            selectedIntegration
              ? 'border-indigo-500 bg-indigo-50'
              : 'border-gray-300 bg-white'
          } ${disabled ? 'opacity-40' : ''} `}
        >
          <div className="flex items-start justify-between gap-4">
            <div className="flex items-center gap-4">
              <IntegrationLogo integrationType={integrationType} />
              <p className="text-lg text-gray-800">
                {integration.integrationName}
              </p>
            </div>
            <p>
              <CheckCircleIcon
                className={`h-10 w-10 ${
                  selectedIntegration ? 'text-indigo-500' : 'text-gray-300'
                }`}
              />
            </p>
          </div>

          <div className="flex items-center justify-between gap-4">
            <ul className="text-sm">
              <p className="text-xs font-bold text-gray-500">
                Integrates with:
              </p>
              <div className="mt-2 flex flex-wrap gap-2">
                {integration?.features.map((feature, index) => (
                  <li
                    key={index}
                    className={`rounded px-3 py-1 text-xs ${
                      selectedIntegration
                        ? 'bg-indigo-200 text-indigo-600'
                        : 'bg-gray-100 text-gray-500'
                    }`}
                  >
                    {feature}
                  </li>
                ))}
              </div>
            </ul>
          </div>
        </div>
      </Button>
    </>
  )
}

export default IntegrationOptionCard
