import * as React from 'react'
import { useState } from 'react'

import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import { Alert, InputAdornment, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useCopyToClipboard } from 'usehooks-ts'

import { toast } from '@redwoodjs/web/toast'

import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'
import Divider from 'src/components/Library/Divider/Divider'
import IconButton from 'src/components/Library/IconButton'
import { fieldTypes } from 'src/components/PropertyGenie/helper'

const ExamplePropertyTemplate =
  'Introducing our brand new listing at {address}, {Information about the property}, {information about the area}, {features}'

const PropertyTemplateForm = ({ onSave }) => {
  const [templateContent, setTemplateContent] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [templateFooter, setTemplateFooter] = useState('')
  const [templateError, setTemplateError] = useState('')
  const [_, copy] = useCopyToClipboard()

  const validate = () => {
    if (templateName === '') {
      toast.error('Template name is required')
      setTemplateError('Template name is required')
      return false
    }
    if (templateContent === '') {
      toast.error('Template content is required')
      setTemplateError('Template content is required')
      return false
    }
    return true
  }
  return (
    <div className={'w-full p-8'}>
      <div className={'flex w-full justify-end'}>
        <Button
          fullWidth={false}
          onClick={() => {
            if (validate()) {
              onSave({
                name: templateName,
                content: templateContent,
                footer: templateFooter,
              })
            }
          }}
        >
          Create Template
        </Button>
      </div>
      <Typography color={'error'}>{templateError}</Typography>
      <Divider className={'my-4'} label={'Template Name'} chip />
      <TextField
        required
        onChange={(e) => {
          setTemplateName(e.target.value)
        }}
        value={templateName}
        fullWidth
      />
      <Divider className={'my-4'} label={'Content'} chip />
      <Alert className={'mt-1'} severity="info">
        {
          'Tags can be added manually using the format {tag}, or by clicking the + button.'
        }
      </Alert>
      <Autocomplete
        fullWidth
        className={'my-4'}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Predefined Tags"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position={'end'}>
                  {params.InputProps.endAdornment}
                  {/*  Pluss button */}
                  <IconButton
                    className={'h-10 w-10'}
                    onClick={() => {
                      setTemplateContent(
                        templateContent + params.inputProps.value,
                      )
                    }}
                  >
                    +
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        options={fieldTypes}
      />
      <TextareaAutosize
        required
        onChange={(e) => {
          setTemplateContent(e.target.value)
        }}
        value={templateContent}
        className={'w-full rounded border-2 border-gray-300 p-2'}
      />

      <Divider className={'my-4'} label={'Footer'} chip />
      <TextareaAutosize
        onChange={(e) => {
          setTemplateFooter(e.target.value)
        }}
        value={templateFooter}
        className={'w-full rounded border-2 border-gray-300 p-2'}
      />
      <Alert className={'mt-1'} severity="info">
        Use the footer to include standard details. For example, a call to
        action or any disclaimers
      </Alert>
    </div>
  )
}

export default PropertyTemplateForm
