import { FC } from 'react'

const AutomationTrackConditional: FC<{
  text: string
  compressed?: boolean
}> = ({ text, compressed = false }) => {
  return (
    <p
      className={`m-0 py-1 text-center text-sm capitalize text-gray-500 ${
        compressed ? '!my-1' : 'my-2'
      }`}
    >
      {text}
    </p>
  )
}

export default AutomationTrackConditional
