import dayjs from 'dayjs'

export const generateCSVData = (reportData, memberships) => {
  // React CSV
  // https://github.com/react-csv/react-csv

  // Empty Array to start
  const csvArray = []

  // Set Headings
  const csvHeadings = [
    'categoryName',
    'courseName',
    'learnerName',
    'learnerCompletionPercent',
    'learnerAttemptStartDate',
    'learnerAttemptCompletionDate',
  ]

  // Push Headings
  csvArray.push(csvHeadings)

  // Loop through reportData and fill rows for each record
  for (
    let categoryIndex = 0;
    categoryIndex < reportData.length;
    categoryIndex++
  ) {
    for (
      let courseIndex = 0;
      courseIndex < reportData[categoryIndex].learnerCourses.length;
      courseIndex++
    ) {
      for (
        let recordIndex = 0;
        recordIndex <
        reportData[categoryIndex].learnerCourses[courseIndex].reporting.attempts
          .length;
        recordIndex++
      ) {
        csvArray.push([
          reportData[categoryIndex].name,
          reportData[categoryIndex].learnerCourses[courseIndex].name,
          memberships.find(
            (membership) =>
              membership.id ===
              reportData[categoryIndex].learnerCourses[courseIndex].reporting
                .attempts[recordIndex].relatedTracking.membershipId,
          )?.name,
          reportData[categoryIndex].learnerCourses[courseIndex].reporting
            .attempts[recordIndex].percentage,
          dayjs(
            reportData[categoryIndex].learnerCourses[courseIndex].reporting
              .attempts[recordIndex].relatedTracking.createdAt,
          ).format('YYYY-MM-DD HH:mm:ss'),
          reportData[categoryIndex].learnerCourses[courseIndex].reporting
            .attempts[recordIndex].status === 'COMPLETED'
            ? dayjs(
                reportData[categoryIndex].learnerCourses[courseIndex].reporting
                  .attempts[recordIndex].relatedTracking.updatedAt,
              ).format('YYYY-MM-DD HH:mm:ss')
            : '',
        ])
      }
    }
  }

  return csvArray
}

/**
 *
 * @param {number} value number percentage value
 * @returns {string}  css class for status colour
 */
export const getStatusColour = (value: number): string => {
  let progressColour = 'text-gray-600'

  if (value === 0) {
    progressColour = 'text-red-600'
  }
  if (value > 0 && value < 100) {
    progressColour = 'text-blue-600'
  }
  if (value === 100) {
    progressColour = 'text-green-600'
  }
  return progressColour
}

export const NoItemsFound = () => (
  <div className="grid h-full w-full place-items-center py-10">
    <p className="text-gray-400">No Category Items Found</p>
  </div>
)
