import { useEffect, useState } from 'react'

import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'

const SelectableOption = ({
  choice,
  selectedChoice,
  onSelectChoice,
  setLastSelectedChoice,
  isMarking,
  showAnswers,
}) => {
  const [themeColor, setThemeColor] = useState('green')
  const [showCorrect, setShowCorrect] = useState(false)

  const getBorderColorClasses = (isSelected: boolean, themeColor: string) => {
    if (!isSelected) return 'border-gray-300'
    return themeColor === 'green' ? 'border-green-600' : 'border-red-600'
  }

  const getBackgroundColorClasses = (
    isSelected: boolean,
    themeColor: string,
  ) => {
    if (!isSelected) return 'bg-white'
    return themeColor === 'green' ? 'bg-green-600' : 'bg-red-600'
  }

  useEffect(() => {
    if (isMarking) {
      if (!showAnswers) {
        setShowCorrect(false)
      } else {
        setShowCorrect(
          (choice.isAnswer && selectedChoice) ||
            (choice.isAnswer && showAnswers && !selectedChoice),
        )
      }

      if (selectedChoice && !choice.isAnswer && showAnswers) {
        setThemeColor('red')
      } else {
        setThemeColor('green')
      }
    }
  }, [choice, isMarking, showAnswers, selectedChoice])

  return (
    <div
      role="button"
      tabIndex={0}
      className={`mr-8 mt-2 flex min-h-10 w-full flex-1 cursor-pointer justify-start rounded border bg-white ${getBorderColorClasses(
        selectedChoice,
        themeColor,
      )}`}
      onKeyDown={() => {}}
      onClick={() => {
        if (isMarking) return
        setLastSelectedChoice(choice.rank)
        onSelectChoice()
      }}
    >
      <div
        className={`m-2.5 h-[18px] w-[18px] rounded-xl border ${getBorderColorClasses(
          selectedChoice,
          themeColor,
        )}`}
      >
        <div
          className={`m-1 h-2 w-2 rounded-xl ${getBackgroundColorClasses(
            selectedChoice,
            themeColor,
          )}`}
        />
      </div>
      <div className="my-auto ml-1 flex min-h-5 flex-1 break-normal py-2.5 text-sm text-gray-700">
        {choice.text}
      </div>
      {showCorrect && (
        <div className="mr-1 mt-2 h-6 min-h-4 w-6 min-w-4">
          <CheckCircleIcon className={'h-full w-full text-green-500'} />
        </div>
      )}
      {themeColor === 'red' && (
        <div className="mr-1 mt-2 h-6 min-h-4 w-6 min-w-4">
          <XCircleIcon className={'h-full w-full text-red-500'} />
        </div>
      )}
    </div>
  )
}

export default SelectableOption
