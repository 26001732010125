import {
  useEffect,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'

import {
  PlusCircleIcon,
  PlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'

import {
  getRecordById,
  getRecordsForTable,
  getRecordsForView,
  getTableById,
} from 'src/components/HubDash/lib/baserow/baserowApi'
import RecordExpandWrapper from 'src/components/HubDash/RecordExpand/RecordExpandWrapper'
import Loading from 'src/components/Library/Loading/Loading'
import { SearchField } from 'src/components/Library/SearchField'
import useHubDashStore from 'src/lib/stores/hubDashStore'

interface CellLinkRowRenderProps {
  field: any // not typed yet
  cellValue: any // not typed yet
  setCellValue: Dispatch<SetStateAction<any>> // not typed yet
  baseId: number
  workspaceId: number
}

// this is slow on a large table, need to implement pagination
export const CellLinkRowRender: FC<CellLinkRowRenderProps> = ({
  field,
  cellValue,
  setCellValue,
  baseId,
  workspaceId,
}) => {
  const [token] = useHubDashStore((state) => [state.token])
  const [linkSelectOpen, setLinkSelectOpen] = useState(false)
  const [linkSearchValue, setLinkSearchValue] = useState('')
  const [linkedTable, setLinkedTable] = useState({ name: '' })
  const [linkedTableRecords, setLinkedTableRecords] = useState([])
  const [linkedTableRecordsLoading, setLinkedTableRecordsLoading] =
    useState(false)
  const [wrappedRecord, setWrappedRecord] = useState<object>()
  const [wrappedTable, setWrappedTable] = useState({})

  const linkedRecordIds = cellValue.map((linkRow) => {
    return linkRow.id
  })

  useEffect(() => {
    const fetchLinkedTable = async () => {
      if (field.type === 'link_row' && linkSelectOpen) {
        const linkedTableId = field.link_row_table
        const linkedTable = await getTableById({
          token,
          tableId: linkedTableId,
        })
        setLinkedTable(linkedTable)
      }
    }
    fetchLinkedTable()
  }, [field, linkSelectOpen, token])

  useEffect(() => {
    const fetchLinkedTableRecords = async () => {
      setLinkedTableRecordsLoading(true)
      if (field.type === 'link_row' && linkSelectOpen) {
        const linkedTableId = field.link_row_table
        const linkedRowLimitSelectionViewId =
          field.link_row_limit_selection_view_id
        if (linkedRowLimitSelectionViewId) {
          const linkedViewRecordsResponse = await getRecordsForView({
            token,
            viewId: linkedRowLimitSelectionViewId,
          })
          setLinkedTableRecords(linkedViewRecordsResponse.results)
        } else {
          const linkedTableRecordsResponse = await getRecordsForTable({
            token,
            tableId: linkedTableId,
          })
          setLinkedTableRecords(linkedTableRecordsResponse.results)
        }
        setLinkedTableRecordsLoading(false)
      }
    }
    fetchLinkedTableRecords()
  }, [field, linkSelectOpen, token])

  const loadLinkedRecord = async (recordId: number) => {
    const linkedTableId = field.link_row_table
    const recordIdResult = await getRecordById({
      token,
      tableId: linkedTableId,
      recordId,
      workspaceId: workspaceId,
    })

    const { table, record } = recordIdResult ?? {}
    setWrappedTable(table)
    setWrappedRecord(record)
  }

  const truncateText = (text) => {
    const maxLength = 50
    if (text?.length <= maxLength) {
      return text
    }
    return text?.substring(0, maxLength) + '...'
  }

  return (
    <>
      <div className="flex w-full flex-col gap-2">
        <div className="flex w-full flex-wrap gap-1">
          {cellValue.map((linkRow, index) => (
            <div
              key={index}
              className="flex h-7 items-center justify-between gap-1 rounded border-2 border-indigo-500 bg-white pl-2 text-indigo-500 hover:bg-indigo-100"
              onClick={() => {
                loadLinkedRecord(linkRow.id)
              }}
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
            >
              <div className="truncate">
                <p
                  className="line-clamp-1 truncate"
                  data-testid={`link-row-value-${linkRow?.value}`}
                >
                  {linkRow?.value
                    ? truncateText(linkRow?.value)
                    : 'Unnamed Row'}
                </p>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  setCellValue(cellValue.filter((row) => row.id !== linkRow.id))
                }}
                className="mx-0 ml-1 rounded-full bg-white hover:bg-red-200"
              >
                <XCircleIcon className="h-6 w-6" />
              </button>
            </div>
          ))}
          <button
            className="flex h-7 items-center justify-between gap-1 rounded border-2 border-gray-200 bg-white p-2"
            onClick={() => {
              setLinkSelectOpen(!linkSelectOpen)
            }}
          >
            {!linkSelectOpen && (
              <>
                <PlusIcon className="h-4 w-4 text-gray-500" />
                <p className="text-gray-500">Edit</p>
              </>
            )}
            {linkSelectOpen && (
              <>
                <XMarkIcon className="h-4 w-4 text-gray-500" />
                <p className="text-gray-500">Close</p>
              </>
            )}
          </button>
        </div>
        {linkSelectOpen && (
          <div className="flex w-full flex-col gap-2 rounded border-2 border-gray-300 bg-white p-2">
            <div className="flex w-full items-center justify-between border-b-2 border-gray-100 pb-2">
              <p>Link Records - {linkedTable?.name}</p>
              <div>
                <SearchField
                  value={linkSearchValue}
                  onChange={setLinkSearchValue}
                />
              </div>
            </div>
            <div className="flex h-96 flex-col gap-2 overflow-scroll px-1">
              <>
                {linkedTableRecordsLoading && <Loading />}
                {!linkedTableRecordsLoading &&
                  linkedTableRecords?.map((linkedTableRecord, index) => {
                    const nameValue =
                      linkedTableRecord[Object.keys(linkedTableRecord)[2]]
                    const isLinked = linkedRecordIds.includes(
                      linkedTableRecord.id,
                    )
                    if (
                      linkSearchValue !== '' &&
                      !nameValue
                        ?.toLowerCase()
                        ?.includes(linkSearchValue.toLowerCase())
                    ) {
                      return null
                    } else {
                      return (
                        <div
                          key={index}
                          role={'button'}
                          tabIndex={0}
                          onKeyDown={() => {}}
                          className={`flex justify-between rounded border-2 p-2 ${isLinked ? 'border-indigo-500 text-indigo-500 hover:bg-indigo-100' : 'border-gray-200 text-gray-500 hover:bg-gray-100'}`}
                          onClick={() => {}}
                        >
                          <p className="truncate">
                            {nameValue
                              ? truncateText(nameValue)
                              : 'Unnamed Row'}
                          </p>
                          {isLinked && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation()
                                setCellValue(
                                  cellValue.filter(
                                    (row) => row.id !== linkedTableRecord.id,
                                  ),
                                )
                              }}
                              className="rounded-full bg-white hover:bg-red-200"
                            >
                              <XCircleIcon className="h-6 w-6" />
                            </button>
                          )}
                          {!isLinked && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation()
                                setCellValue([
                                  ...cellValue,
                                  {
                                    id: linkedTableRecord.id,
                                    value: nameValue,
                                  },
                                ])
                              }}
                              className="rounded-full bg-white hover:bg-indigo-200"
                            >
                              <PlusCircleIcon className="h-6 w-6" />
                            </button>
                          )}
                        </div>
                      )
                    }
                  })}
              </>
            </div>
          </div>
        )}
      </div>
      {wrappedRecord && (
        <RecordExpandWrapper
          baseId={baseId}
          wrappedTableName={linkedTable.name}
          wrappedTable={wrappedTable}
          wrappedRecord={wrappedRecord}
          clearWrappedRecord={() => setWrappedRecord(null)}
        />
      )}
    </>
  )
}
