import { FC } from 'react'

import { Square2StackIcon, PencilIcon } from '@heroicons/react/24/outline'

import useAnalytics from 'src/lib/hooks/useAnalytics'

import Button from '../../Library/Button/Button'
import { SingleLayoutCardProps } from '../SettingsLayoutCardStack/SettingsLayoutCardStackHelper'

import DoubleVerticalDotsIcon from './DoubleVerticalDotsIcon'

const SettingsLayoutCardStackSingle: FC<SingleLayoutCardProps> = ({
  cardIndex,
  card,
  setCardAdminModalOpen,
  setSelectedCard,
  setModalAction,
  disableDragDrop,
}) => {
  const { trackEvent } = useAnalytics()

  return (
    <div className="flex-grow p-1" data-action="drag-card">
      <div
        className={`group relative flex h-[180px] flex-col items-center justify-around overflow-hidden rounded-lg bg-white p-2 text-center shadow hover:cursor-grab hover:shadow-md ${
          disableDragDrop && 'hover:cursor-default'
        }`}
      >
        <div className="absolute top-0 m-1 block flex w-[95%] justify-between rounded group-hover:bg-gray-100">
          <div className="flex">
            <span className="p-1 px-2 text-gray-400 group-hover:text-gray-600">
              {cardIndex}
            </span>
            <span className="hidden opacity-0 group-hover:opacity-100 2xl:block">
              {!disableDragDrop && <DoubleVerticalDotsIcon />}
            </span>
          </div>
          <div className="top-0 flex flex-row opacity-0 group-hover:opacity-100">
            <Button
              variant="text"
              fullWidth={false}
              className="min-w-[0px] rounded-none"
              onClick={() => {
                setModalAction('duplicate')
                setSelectedCard(card)
                setCardAdminModalOpen(true)
                trackEvent('Settings', 'duplicate card', { card: card.name })
              }}
            >
              <Square2StackIcon className="h-4 w-4 text-gray-600" />
              <span className="sr-only">Duplicate</span>
            </Button>
            <Button
              variant="text"
              fullWidth={false}
              className="min-w-[0px] rounded-none !rounded-r"
              onClick={() => {
                setModalAction('edit')
                setSelectedCard(card)
                setCardAdminModalOpen(true)
                trackEvent('Settings', 'edit card', { card: card.name })
              }}
            >
              <PencilIcon className="h-4 w-4 text-gray-600" />
              <span className="sr-only">Edit</span>
            </Button>
          </div>
        </div>

        <div className="flex select-none flex-col items-center justify-center rounded pt-4">
          {card?.iconStorageObject?.downloadUrl ? (
            <div
              className="mx-auto h-12 w-12 rounded"
              style={{
                backgroundImage:
                  'url(' + card?.iconStorageObject?.downloadUrl + ')',
                backgroundPosition: 'center center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              }}
            ></div>
          ) : (
            <div className="mx-auto h-12 w-12 rounded bg-gray-200"></div>
          )}
          <p
            className="py-2 text-sm"
            data-testid={`settings-layout-card-stack-single-${card?.name}`}
          >
            {card?.name}
          </p>
        </div>
      </div>
    </div>
  )
}

export default SettingsLayoutCardStackSingle
