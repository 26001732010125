import { FC } from 'react'

import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'

interface StatusProps {
  published?: boolean
}

const Status: FC<StatusProps> = ({ published = false }) => {
  return (
    <>
      <span
        className={`text-md m-1 inline-flex items-center rounded-full px-3 py-0.5 font-medium ${
          published ? 'text-green-600' : 'text-red-500'
        }`}
      >
        {published ? (
          <>
            <CheckCircleIcon
              data-testid="published-circle-icon"
              className="mx-1 h-4 w-4 text-green-600"
            />
            Published
          </>
        ) : (
          <>
            <XCircleIcon
              className="mx-1 h-4 w-4 text-red-500"
              data-testid="unpublished-cross-icon"
            />
            Unpublished
          </>
        )}
      </span>
    </>
  )
}

export default Status
