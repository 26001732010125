import { SUPPORTED_FILE_MIME_TYPES } from './enums'

export const MIN_SIZE_TO_COMPRESS = 5_000_000
export const LAMBDA_MAX_RESPONSE_SIZE = 6_000_000
export const NO_LINKED_EXTUSER = 'NO LINKED EXTUSER'
export const BASEROW_TEMPLATE_BASE_ID_FOR_TESTING = 229
export const BILLING_SWAP_OVER_DATE = '2024-05-20'
export const BRISBANE_TZ = 'Australia/Brisbane'
export const E2E_USERS = {
  DEV_LOGIN_EMAIL_DEV: 'tech+hubs-dev@stafflink.com.au',
  DEV_LOGIN_EMAIL_STAGING: 'tech+hubs_staging@stafflink.com.au',
  DEV_LOGIN_EMAIL_PROD: 'tech+hubs-production@stafflink.com.au',

  E2E_MEMBER_LOGIN_EMAIL_DEV: 'tech+hubs-dev+2@stafflink.com.au',
  E2E_MEMBER_LOGIN_EMAIL_STAGING: 'tech+hubs_staging+2@stafflink.com.au',
  E2E_MEMBER_LOGIN_EMAIL_PROD: 'tech+hubs-production+2@stafflink.com.au',

  E2E_ADMIN_LOGIN_EMAIL_DEV: 'tech+tester-dev@stafflink.com.au',
  E2E_ADMIN_LOGIN_EMAIL_STAGING: 'tech+tester-staging@stafflink.com.au',
  E2E_ADMIN_LOGIN_EMAIL_PROD: 'tech+tester-production@stafflink.com.au',

  E2E_EXTERNAL_ADMIN_LOGIN_EMAIL_DEV:
    'tech+external-tester-dev@stafflink.com.au',
  E2E_EXTERNAL_ADMIN_LOGIN_EMAIL_STAGING:
    'tech+external-tester-staging@stafflink.com.au',
  E2E_EXTERNAL_ADMIN_LOGIN_EMAIL_PROD:
    'tech+external-tester-production@stafflink.com.au',

  E2E_AUTOMATION_LOGIN_EMAIL_DEV: 'tech+automation-tester-dev@stafflink.com.au',
  E2E_AUTOMATION_LOGIN_EMAIL_STAGING:
    'tech+automation-tester-staging@stafflink.com.au',
  E2E_AUTOMATION_LOGIN_EMAIL_PROD:
    'tech+automation-tester-production@stafflink.com.au',
}

export const AUTOMATION_LOG_LIMIT = 100

export const MEBIBYTE = 2 ** 20

export const MAX_FILE_SIZE_MIB = {
  [SUPPORTED_FILE_MIME_TYPES.IMAGE]: 10,
  [SUPPORTED_FILE_MIME_TYPES.VIDEO]: 50,
  [SUPPORTED_FILE_MIME_TYPES.TEXT]: 10,
  [SUPPORTED_FILE_MIME_TYPES.APPLICATION]: 10,
}

export const SUPPORTED_FILE_MIME_EXTENSION_TYPES = {
  [SUPPORTED_FILE_MIME_TYPES.IMAGE]: [
    'png',
    'jpg',
    'jpeg',
    'svg',
    'svg+xml',
    'gif',
    'webp',
    'avif',
  ],
  [SUPPORTED_FILE_MIME_TYPES.VIDEO]: [
    'webm',
    'mp4',
    'm4p',
    'm4v',
    'mpeg',
    'mpg',
    'mv2',
    'mov',
    'wmv',
    'avi',
  ],
  [SUPPORTED_FILE_MIME_TYPES.TEXT]: ['csv', 'css', 'html', 'plain', 'xml'],
  [SUPPORTED_FILE_MIME_TYPES.APPLICATION]: [
    'pdf',
    'json',
    'vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
}

export const BASEROW_FIELD_TYPES = {
  TEXT: 'text',
  LONG_TEXT: 'long_text',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  DATE: 'date',
  DATE_TIME: 'date_time',
  LINK_ROW: 'link_row',
  EMAIL: 'email',
  FILE: 'file',
  SINGLE_SELECT: 'single_select',
  MULTIPLE_SELECT: 'multiple_select',
  PHONE_NUMBER: 'phone_number',
  URL: 'url',
  FORMULA: 'formula',
  LOOKUP: 'lookup',
  RATING: 'rating',
  DURATION: 'duration',
  ROLLUP: 'rollup',
}

export const ERROR_MESSAGES = {
  NOT_ALLOWED_IN_SUPPORT_MODE: 'Not allowed in support mode',
}
