import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import {
  AcademicCapIcon,
  CheckCircleIcon,
  PencilIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'

import Filters from 'src/components/Learner/CourseReports/Filters/Filters'
import ReportsGrid from 'src/components/Learner/CourseReports/ReportsGrid/LearnerCourseReportsGrid'
import ReportsOverview from 'src/components/Learner/CourseReports/ReportsOverview/LearnerCourseReportsOverview'
import { toSecondDecimal } from 'src/lib/helpers'

import { CourseOption, Membership, MembershipGroup } from './interfaces'
import { LearnerCategoryType } from './types'

const getAverageOverCourses = (
  fieldName,
  learnerCategories,
  selectedCourses,
) => {
  let courseCount = 0,
    valueToAverage = 0

  // Create a set of selected course ids
  const selectedCourseIds = new Set(selectedCourses.map((course) => course.id))
  for (const category of learnerCategories) {
    for (const course of category.learnerCourses) {
      // Only process the course if it's selected or no courses are selected
      if (selectedCourseIds.size === 0 || selectedCourseIds.has(course.id)) {
        courseCount++
        valueToAverage += course.reporting.stats[fieldName]
      }
    }
  }

  return valueToAverage / courseCount
}

export interface CourseReportsProps {
  learnerCategories: LearnerCategoryType[]
  memberships: Membership[]
  membershipGroups: MembershipGroup[]
  selectedMembers: Membership[]
  setSelectedMembers: Dispatch<SetStateAction<Membership[]>>
  selectedGroups: MembershipGroup[]
  setSelectedGroups: Dispatch<SetStateAction<MembershipGroup[]>>
}

const CourseReports: FC<CourseReportsProps> = ({
  learnerCategories,
  memberships,
  membershipGroups,
  selectedMembers,
  setSelectedMembers,
  selectedGroups,
  setSelectedGroups,
}) => {
  //get stats from each learner course reporting
  // add values and then divide by number of learner courses to get the average value across all courses

  // FILTER STATES
  const [sortAscending, setSortAscending] = useState(true)
  const [selectedCourses, setSelectedCourses] = useState([])

  // DATA STATES
  const [categoryReports, setCategoryReports] = useState(learnerCategories)

  const averageCompletionRate = getAverageOverCourses(
    'completionRate',
    learnerCategories,
    selectedCourses,
  )

  // const averageGrade = getAverageOverCourses(
  //   'averageGrade',
  //   learnerCategories,
  //   selectedCourses,
  // )

  //TODO: re-enable when we have this data
  // const averageTime = getAverageOverCourses(
  //   'averageLearningTime',
  //   learnerCategories,
  // )

  const passingPercentage = getAverageOverCourses(
    'passingPercentage',
    learnerCategories,
    selectedCourses,
  )

  //total number of learning items

  let totalLearningItems = 0,
    courseMembers = []
  for (const learnerCategory of categoryReports) {
    for (const learnerCourse of learnerCategory.learnerCourses) {
      if (
        selectedCourses.length === 0 ||
        selectedCourses.some((selected) => selected.id === learnerCourse.id)
      ) {
        totalLearningItems += learnerCourse.reporting.stats.learningItems
        courseMembers = [
          ...new Set([
            ...learnerCourse.reporting.stats.assignedUsers,
            ...courseMembers, // Merge without wrapping in an array
          ]),
        ]
      }
    }
  }

  const iconProps = {
    className: 'text-gray-300 h-12 w-12',
  }

  const overview = [
    {
      title: 'Total Lessons',
      value: toSecondDecimal(totalLearningItems),
      icon: <AcademicCapIcon {...iconProps} />,
      tooltipTitle: 'Total number of lessons.',
      metricType: null,
    },
    {
      title: 'Participated',
      value: toSecondDecimal(passingPercentage),
      icon: <PencilIcon {...iconProps} />,
      tooltipTitle: 'Percentage of learners who have participated in a course.',
      metricType: 'percent',
    },
    {
      title: 'Assigned Users',
      value: toSecondDecimal(
        courseMembers.length ? courseMembers.length : memberships.length,
      ),
      icon: <UsersIcon {...iconProps} />,
      tooltipTitle: 'The number of users who have been assigned to courses.',
      metricType: null,
    },
    {
      title: 'Completion Rate',
      value: toSecondDecimal(averageCompletionRate),
      icon: <CheckCircleIcon {...iconProps} />,
      tooltipTitle: 'Percentage of learners who have completed courses.',
      metricType: 'percent',
    },
  ]

  // FILTER FUNCTION
  const filterLearnerCourses = (
    learnerCategories,
    selectedCourses,
    sortAscending,
  ) => {
    // FILTER COURSES BY SELECTED OPTIONS
    const selectedCourseIds = selectedCourses.map((course) => course.id)
    const coursesFilteredByCourseName = !selectedCourses.length
      ? learnerCategories
      : learnerCategories.map((category) => {
          const learnerCourses = category.learnerCourses.filter((course) =>
            selectedCourseIds.includes(course.id),
          )

          return {
            ...category,
            learnerCourses,
          }
        })

    // SORT COURSES BY ASC/DESC VALUE
    const coursesSortedByASC = sortAscending
      ? coursesFilteredByCourseName
      : [...coursesFilteredByCourseName].reverse()

    return coursesSortedByASC
  }

  const courseOptions: CourseOption[] = []
  for (const category of learnerCategories) {
    for (const course of category.learnerCourses) {
      courseOptions.push({
        id: course.id,
        name: course.name,
        category: category.name,
      })
    }
  }

  useEffect(() => {
    setCategoryReports(
      filterLearnerCourses(learnerCategories, selectedCourses, sortAscending),
    )
  }, [selectedCourses, learnerCategories, sortAscending])

  return (
    <div className="mb-14 grid min-h-[calc(100vh-77px)] grid-cols-none bg-white md:mb-0 xl:grid-cols-[70%_30%]">
      <div className="relative flex max-h-[calc(100vh-77px)] flex-col overflow-y-scroll border-r border-gray-300 bg-gray-50">
        <Filters
          csvData={categoryReports}
          sortAscending={sortAscending}
          setSortAscending={setSortAscending}
          courseOptions={courseOptions}
          selectedCourses={selectedCourses}
          setSelectedCourses={setSelectedCourses}
          membershipOptions={memberships}
          selectedMembers={selectedMembers}
          setSelectedMembers={setSelectedMembers}
          membershipGroupOptions={membershipGroups}
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
        />
        <ReportsGrid
          learnerCategories={categoryReports}
          memberships={memberships}
          selectedCourses={selectedCourses}
        />
      </div>
      <ReportsOverview overview={overview} />
    </div>
  )
}

export default CourseReports
