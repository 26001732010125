import { FC } from 'react'

import { SentimentAnalysisUserStatus } from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'

const MemberManagementStatusLabel: FC<{
  label: SentimentAnalysisUserStatus
}> = ({ label }) => {
  let className = 'bg-gray-100 text-gray-600'

  if (label === SentimentAnalysisUserStatus.notInvited) {
    className = 'bg-slate-100 text-slate-600'
  }
  if (label === SentimentAnalysisUserStatus.invited) {
    className = 'bg-amber-100 text-amber-600'
  }
  if (label === SentimentAnalysisUserStatus.active) {
    className = 'bg-green-100 text-green-600'
  }
  if (label === SentimentAnalysisUserStatus.disabled) {
    className = 'bg-stone-100 text-stone-600'
  }

  return (
    <p className={`inline rounded-full px-4 py-1 text-xs ${className}`}>
      {label}
    </p>
  )
}

export default MemberManagementStatusLabel
