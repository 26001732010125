import React, { memo } from 'react'

import { Handle, Position } from 'reactflow'

function GroupTreeCustomNodes({ data }) {
  return (
    <div className="rounded-md border-2 border-stone-400 bg-white px-4 py-2 shadow-md">
      <div className="flex">
        {data.avatar && (
          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
            {data.avatar}
          </div>
        )}
        <div className="ml-2 flex h-12 items-center justify-center">
          <div className="text-lg font-bold">{data.label}</div>
        </div>
      </div>
      {Position.Top && (
        <Handle
          type="target"
          position={Position.Top}
          className="w-16 rounded-none rounded-t-md !border-0 !bg-indigo-500"
        />
      )}
      {Position.Bottom && (
        <Handle
          type="source"
          position={Position.Bottom}
          className="w-16 rounded-none rounded-b-md !border-0 !bg-indigo-500"
        />
      )}
    </div>
  )
}

export default memo(GroupTreeCustomNodes)
