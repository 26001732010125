import { FC } from 'react'

import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'

import Button from '../Library/Button/Button'

interface OnboardingActionItemProps {
  title: string
  description: string
  buttonText: string
  buttonAction: () => void
  completed: boolean
  finished?: boolean
}

const OnboardingActionItem: FC<OnboardingActionItemProps> = ({
  title,
  description,
  buttonText,
  buttonAction,
  completed,
  finished = false,
}) => {
  return (
    <div
      className={`flex items-center justify-between border-b p-4 last-of-type:border-0 ${
        finished ? 'mt-2 rounded-lg bg-green-100' : ''
      }`}
    >
      <div className="flex items-center gap-4">
        <CheckCircleIcon
          className={`h-9 w-9 ${completed ? 'text-indigo-600' : 'text-gray-300'} ${finished ? 'text-green-600' : ''} `}
        />
        <div className="flex flex-col">
          <p
            className={`text-lg ${
              finished ? 'text-green-800' : 'text-gray-700'
            } `}
          >
            {title}
          </p>
          <p className="text-sm text-gray-400">{description}</p>
        </div>
      </div>
      <Button
        fullWidth={false}
        endIcon={<ChevronRightIcon className="h-5 w-5" />}
        variant={finished ? 'contained' : 'text'}
        color={finished ? 'success' : 'primary'}
        className={`px-4 ${
          finished ? 'bg-green-600 hover:bg-green-700' : 'hover:bg-indigo-100'
        }`}
        onClick={buttonAction}
      >
        <span className="pt-0.5">{buttonText}</span>
      </Button>
    </div>
  )
}

export default OnboardingActionItem
