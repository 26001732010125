import { FC } from 'react'

import { Tooltip } from '@mui/material'

import OverviewItem, { OverviewItemProps } from './OverviewItem'

export interface LearnerCourseReportsOverviewProps {
  overview: OverviewItemProps[]
}
const ReportsOverview: FC<LearnerCourseReportsOverviewProps> = ({
  overview,
}) => {
  return (
    <div className="order-1 grid place-items-start justify-items-center p-6">
      <div className="grid w-full grid-cols-2 gap-4">
        {overview.map((overviewItem, index) => {
          return (
            <OverviewItem
              key={index}
              title={overviewItem.title}
              value={overviewItem.value}
              icon={overviewItem.icon}
              tooltipTitle={overviewItem.tooltipTitle}
              metricType={overviewItem.metricType}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ReportsOverview
