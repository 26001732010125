import { useMediaQuery } from 'usehooks-ts'

import { Metadata } from '@redwoodjs/web'

import AnnouncerBarCell from 'src/components/Announcements/AnnouncerBarCell'
import InformerHeader from 'src/components/InformerHeader/InformerHeader'
import InformerSectionListCell from 'src/components/InformerSectionListCell'
import PageHeader from 'src/components/PageHeader/PageHeader'

const HomePage = () => {
  const isMobile = useMediaQuery('(max-width: 769px)')

  return (
    <>
      <Metadata title="Home" description="Home page" />
      <PageHeader title="" parentDataTestId="home-page-title">
        <AnnouncerBarCell />
      </PageHeader>
      <div className="space-y-8 bg-gray-50 lg:h-[calc(75vh-64px)]">
        <InformerHeader isMobile={isMobile} />
        <InformerSectionListCell isPinnedToSidebar={false} />
      </div>
    </>
  )
}

export default HomePage
