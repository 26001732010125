import React from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { DetailedViewRecord } from 'api/src/common/baserowImporterTypes'

import BaserowFilterChip from 'src/components/BaserowImporter/BaserowFilterChip'
import BaserowImporterComponentFieldDisplay from 'src/components/BaserowImporter/BaserowImporterComponentFieldDisplay'
import BaserowImporterComponentNestedFilter from 'src/components/BaserowImporter/BaserowImporterComponentNestedFilter'

interface BaserowImporterViewInformationModalProps {
  enrichedData: DetailedViewRecord
}

const BaserowImporterModalViewDetailsFilters = ({
  enrichedData,
}: BaserowImporterViewInformationModalProps) => {
  return (
    <Accordion>
      <AccordionSummary
        className="rounded border border-solid border-gray-300"
        expandIcon={<ChevronDownIcon className="h-5 w-5 cursor-pointer" />}
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'column', // Display details in a column
            alignItems: 'flex-start', // Align details to the left
          },
        }}
      >
        <Typography fontWeight={'bold'}>Filters</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1} className={'border p-2'}>
          {enrichedData?.details?.filters?.filterSet ? (
            <BaserowFilterChip
              className="w-[20%] bg-violet-100 text-violet-500"
              label={enrichedData.details.filters.conjunction.toUpperCase()}
            />
          ) : (
            <BaserowFilterChip
              className="w-[20%] bg-violet-100 text-violet-500"
              label={'No Filters'}
            />
          )}

          {enrichedData?.details?.filters?.filterSet?.map((filter, index) =>
            filter.type === 'nested' ? (
              <BaserowImporterComponentNestedFilter
                key={index}
                filter={filter}
              />
            ) : (
              <BaserowImporterComponentFieldDisplay
                key={index}
                filter={filter}
              />
            ),
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default BaserowImporterModalViewDetailsFilters
