import { FC } from 'react'

import RichTextEditor from 'src/components/Library/RichTextEditor/RichTextEditor'

const ObjectiveDescription: FC<{ description: string }> = ({ description }) => {
  return (
    <div className="description-container border-gray-300 py-4 pl-4 sm:border-l">
      <p className="pb-2 text-[10px] font-medium uppercase tracking-widest text-gray-600">
        Description
      </p>
      <div className="text-sm text-gray-700">
        <RichTextEditor readOnly defaultValue={description} />
      </div>
    </div>
  )
}

export default ObjectiveDescription
