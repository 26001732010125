import { useRef, type Dispatch, type FC, type SetStateAction } from 'react'

import { AnyExtension } from '@tiptap/core'
import { BulletList } from '@tiptap/extension-bullet-list'
import { Heading } from '@tiptap/extension-heading'
import { OrderedList } from '@tiptap/extension-ordered-list'
import { TaskItem } from '@tiptap/extension-task-item'
import { TaskList } from '@tiptap/extension-task-list'
import { StarterKit } from '@tiptap/starter-kit'
import {
  MenuButtonBulletedList,
  MenuButtonTaskList,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  RichTextEditor,
  type RichTextEditorRef,
} from 'mui-tiptap'
import { Markdown } from 'tiptap-markdown'

interface CellLongTextRenderProps {
  cellValue: any // not typed yet
  setCellValue: Dispatch<SetStateAction<any>> // not typed yet
  richTextEnabled: boolean
}

export const CellLongTextRender: FC<CellLongTextRenderProps> = ({
  cellValue,
  setCellValue,
  richTextEnabled = false,
}) => {
  const rteRef = useRef<RichTextEditorRef>(null)

  return (
    <>
      {richTextEnabled ? (
        <div className="bg-white">
          <RichTextEditor
            ref={rteRef}
            extensions={
              [
                StarterKit,
                Markdown,
                TaskItem,
                TaskList,
                Heading.configure({ levels: [1, 2, 3] }),
                BulletList.configure({ HTMLAttributes: { class: 'pl-4' } }),
                OrderedList.configure({ HTMLAttributes: { class: 'pl-4' } }),
                TaskList,
                TaskItem.configure({ nested: true }),
              ] as AnyExtension[]
            }
            content={cellValue}
            renderControls={() => (
              <MenuControlsContainer>
                <MenuSelectHeading
                  labels={{
                    paragraph: 'Paragraph',
                    heading1: 'Heading 1',
                    heading2: 'Heading 2',
                    heading3: 'Heading 3',
                  }}
                />
                <MenuDivider />
                <MenuButtonBulletedList />
                <MenuButtonTaskList />
              </MenuControlsContainer>
            )}
            onUpdate={({ editor }) => {
              const content = editor.storage.markdown.getMarkdown()
              setCellValue(content)
            }}
          />
        </div>
      ) : (
        <textarea
          className="h-52 w-full resize-none rounded border border-solid !border-[#0000003b] p-2"
          placeholder="Enter Text.."
          defaultValue={cellValue}
          onChange={(event) => {
            setCellValue(event.target.value)
          }}
        ></textarea>
      )}
    </>
  )
}
