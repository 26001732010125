import { FC } from 'react'

import Stack from '@mui/material/Stack'
import { Card } from 'types/graphql'

import Button from 'src/components/Library/Button/Button'
import { Amex, MasterCard, Visa } from 'web/src/lib/svg/SvgComponents'

enum creditCardBrands {
  AMERICAN_EXPRESS = 'American Express',
  VISA = 'visa',
  MASTERCARD = 'mastercard',
}
interface CreditCardProps {
  creditCard: Card
  updateCard: () => void
}

const CreditCard: FC<CreditCardProps> = ({ creditCard, updateCard }) => {
  let brandIcon = null
  const _overide = true
  if (creditCard.brand === creditCardBrands.MASTERCARD) {
    brandIcon = <MasterCard className={'h-14 w-14'} />
  } else if (creditCard.brand === creditCardBrands.VISA) {
    brandIcon = <Visa className={'h-14 w-14'} />
  } else if (creditCard.brand !== creditCardBrands.AMERICAN_EXPRESS) {
    brandIcon = <Amex className={'h-14 w-14'} />
  }

  return (
    <div className="relative h-52 w-96 transform rounded-xl bg-gradient-to-l from-indigo-400 to-indigo-300 text-white transition-transform hover:scale-105 hover:shadow-2xl">
      <div className="absolute top-8 w-full px-8">
        <Stack
          alignItems={'center'}
          justifyContent={'space-between'}
          direction={'row'}
        >
          <div>{brandIcon}</div>
          <Button
            variant={'outlined'}
            fullWidth={false}
            className={'h-1/2 border-white text-white hover:text-indigo-800'}
            onClick={updateCard}
          >
            Update
          </Button>
        </Stack>
        <div className="pt-4">
          <p className="text-xl font-medium tracking-widest">
            **** **** **** {creditCard.last4}
          </p>
        </div>
        <div className="pr-6 pt-6">
          <Stack spacing={1} alignItems={'center'} direction={'row'}>
            <p className="text-xs font-light">Expiry</p>
            <p className="text-sm font-medium tracking-wider">{`${creditCard.expMonth}/${creditCard.expYear}`}</p>
            <p className="text-xs font-light">CVV</p>
            <p className="tracking-more-wider text-sm font-bold">* * *</p>
          </Stack>
        </div>
      </div>
    </div>
  )
}

export default CreditCard
