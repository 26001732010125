import React from 'react'

import { Chip } from '@mui/material'
import { LeadStatus } from 'types/graphql'

interface Props {
  status: LeadStatus
}

export default function LeadStatusChip({ status }: Props): React.ReactElement {
  const statusClassNames: Record<LeadStatus, string> = {
    ['NEW']: 'bg-blue-100 text-blue-600',
    ['PENDING']: 'bg-blue-100 text-blue-600',
    ['WITH_REFERRER']: 'bg-blue-100 text-blue-600',
    ['CLOSED_WON']: 'bg-green-100 text-green-600',
    ['CLOSED_LOST']: 'bg-red-100 text-red-600',
    ['CANCELLED']: 'bg-red-100 text-red-600',
    ['BAD_DATA']: 'bg-red-100 text-red-600',
  }

  return (
    <Chip
      size={'small'}
      label={status}
      className={`rounded-xl px-1 py-2 ${statusClassNames[status] || 'bg-gray-300 text-black'}`}
    />
  )
}
