import React, { FC } from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'
import { PencilIcon } from '@heroicons/react/24/solid'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'

import useBoolean from 'src/lib/hooks/UseBoolean'

import Button from '../Button'
import IconButton from '../IconButton'

interface InPlaceEditorProps {
  id: string
  value: string
  onChange: (value: string) => void
  title?: string
}

const InPlaceEditor: FC<InPlaceEditorProps> = ({
  id,
  value,
  onChange,
  title = 'Change Text',
}) => {
  const isEditing = useBoolean(false)
  const isSaving = useBoolean(false)

  const handleSubmit = (newText: string) => {
    isSaving.setTrue()
    onChange(newText)
    handleClose()
  }

  const handleClickOpen = () => {
    isSaving.setFalse()
    isEditing.setTrue()
  }

  const handleClose = () => {
    isSaving.setFalse()
    isEditing.setFalse()
  }

  return (
    <div className="flex w-full max-w-[400px] grow items-center justify-between gap-3 rounded border border-white bg-white p-2 px-3 hover:border-gray-200 hover:bg-gray-50">
      <p className="line-clamp-1 text-left text-lg">{value || 'Empty'}</p>

      <IconButton
        className="p-1.5 hover:!bg-gray-200"
        onClick={handleClickOpen}
      >
        <PencilIcon className="h-4 w-4" />
      </IconButton>

      <Dialog
        open={isEditing.value}
        onClose={handleClose}
        PaperProps={{
          className: 'w-full max-w-[400px]',
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const formData = new FormData(event.currentTarget)
            const formJson = Object.fromEntries((formData as any).entries())
            const newText = formJson.text
            handleSubmit(newText)
          },
        }}
      >
        <DialogTitle className="flex items-center justify-between">
          {title}
          <IconButton
            className="p-1.5 hover:!bg-gray-200"
            onClick={handleClose}
            disabled={isSaving.value}
          >
            <XMarkIcon className="h-5 w-5" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pb-2">
          <TextField
            id={id}
            required
            defaultValue={value}
            size="small"
            margin="dense"
            name="text"
            type="text"
            placeholder="New Text"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions className="items-end px-6 pb-4">
          <Button
            type="submit"
            fullWidth={false}
            loading={isSaving.value}
            disabled={isSaving.value}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default InPlaceEditor
