import { ApolloLink, type FetchResult } from '@apollo/client'
import { captureMessage, setTag } from '@sentry/browser'
import { MIN_SIZE_TO_COMPRESS } from 'api/src/common/constants'
import { CUSTOM_HTTP_HEADERS } from 'api/src/common/enums'
import { compress } from 'brotli-compress'

import { type RedwoodApolloLinkFactory } from '@redwoodjs/web/apollo'

import { emitRefreshClient } from 'src/lib/hooks/useRefreshClient'
import { LocalStorageKeys } from 'src/types/enums'

const webCommitHash = process.env.GIT_COMMIT_HASH
const webCommitTime = process.env.GIT_COMMIT_TIME

const minCompressSize = (localStorage.getItem('minCompressSize') ||
  MIN_SIZE_TO_COMPRESS) as number //Fallback value is 5_000_000
const compressQuery = localStorage.getItem('compressQuery') === 'true'

export const apolloLinkFetch: WindowOrWorkerGlobalScope['fetch'] = async (
  input,
  init,
) => {
  // Set the following localstorage keys to enable request compression:
  /* Run this in the browser console:
    localStorage.setItem('compressQuery', true)
    localStorage.setItem('minCompressSize', 100)
   */
  // minCompressSize byte size is up to the developer's discretion,
  // fallback is 5_000_000, if not set
  if (compressQuery) {
    const bodyString = JSON.stringify(init.body)
    if (bodyString.length > minCompressSize) {
      const body = JSON.parse(bodyString)
      const compressedBody = await compress(new TextEncoder().encode(body))

      const logMessage = `Compressed query with payload size ${bodyString.length} down to ${compressedBody.length}`
      captureMessage(logMessage, 'log')

      init.body = JSON.stringify({
        data: compressedBody.toString(),
      })
      init.headers = {
        ...init.headers,
        'Content-Encoding': 'br',
        'Content-Length': compressedBody.length.toString(),
      }
    }
  }

  // user-client-id is set in Routes.tsx
  // userClientId is undefined if the user is not logged-in/authenticated
  const userClientId = localStorage.getItem(LocalStorageKeys.USER_CLIENT_ID)

  init.headers = {
    ...init.headers,
    [CUSTOM_HTTP_HEADERS.USER_CLIENT_ID]: userClientId,
  }

  return fetch(input, init)
}

// Exported just for testing purposes
export const refreshClientState = {
  value: false,
}

export const apolloGitCommitLinkHandler = (
  headers: Headers | null,
  response: FetchResult,
) => {
  if (headers) {
    const apiCommitHash = headers.get('Api-Git-Commit-Hash')
    const apiCommitTime = headers.get('Api-Git-Commit-Time')

    setTag('apiCommitHash', apiCommitHash)
    setTag('apiCommitTime', apiCommitTime)

    const commitMismatch =
      apiCommitHash !== webCommitHash || apiCommitTime !== webCommitTime

    if (commitMismatch && !refreshClientState.value) {
      captureMessage('Web & Api Commit hashes do not match', {
        level: 'warning',
        extra: {
          apiCommitHash,
          apiCommitTime,
          webCommitHash,
          webCommitTime,
        },
      })

      emitRefreshClient({ refreshClient: true })
      refreshClientState.value = true
      setTag('commitMismatch', 'true')
    } else if (!commitMismatch && refreshClientState.value) {
      emitRefreshClient({ refreshClient: false })
      refreshClientState.value = false
      setTag('commitMismatch', undefined)
    }
  }

  return response
}

const apolloGitCommitLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const ctx = operation.getContext()
    const headers: Headers = ctx?.response?.headers
    const result = apolloGitCommitLinkHandler(headers, response)
    return result
  })
})

export const apolloLinkFactory: RedwoodApolloLinkFactory = (rwApolloLinks) => {
  const existingLinks = rwApolloLinks.map(({ link }) => link)
  const newLinks = [apolloGitCommitLink, ...existingLinks]
  const result = ApolloLink.from(newLinks)

  return result
}
