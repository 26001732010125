import { useEffect, useCallback } from 'react'

import { useLocation, navigate, routes } from '@redwoodjs/router'

const useRedirectTo = () => {
  //redirect hook
  const { search } = useLocation()
  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const redirectTo = searchParams.get('redirectTo')
    if (redirectTo) {
      sessionStorage.setItem('redirectTo', redirectTo)
    }
  }, [search])
  const redirectToAfterLogin = useCallback(() => {
    const redirectToFromSession = sessionStorage.getItem('redirectTo')
    if (redirectToFromSession) {
      sessionStorage.removeItem('redirectTo') // Clean up after usage
      navigate(redirectToFromSession)
    } else {
      navigate(routes.home())
    }
  }, [])
  return { redirectToAfterLogin }
}
export default useRedirectTo
