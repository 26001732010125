import { FC } from 'react'

import { LearnerCategory, LearnerCourse, Membership } from 'types/graphql'

import RadialProgressGraph from 'src/components/RadialProgressGraph/RadialProgressGraph'

interface SummaryRadialProps {
  learnerCategory: LearnerCategory
  memberships: Membership[]
  stats: LearnerCourse['reporting']['stats']
}

const SummaryRadial: FC<SummaryRadialProps> = ({ stats }) => {
  const { statusStates } = stats

  const totalMemberships =
    statusStates.COMPLETED + statusStates.IN_PROGRESS + statusStates.NOT_STARTED

  const summary = {
    title: 'Staff Progress',
    value: Number(
      ((statusStates.COMPLETED / totalMemberships) * 100).toFixed(1) || 0,
    ),
    innerLabel: 'Completed',
  }
  const progressRings = [
    {
      title: 'Completed',
      value:
        +((statusStates.COMPLETED / totalMemberships) * 100).toFixed(1) || 0,
    },
    {
      title: 'Currently In Progress',
      value:
        +((statusStates.IN_PROGRESS / totalMemberships) * 100).toFixed(1) || 0,
    },
    {
      title: 'Not Started',
      value:
        +((statusStates.NOT_STARTED / totalMemberships) * 100).toFixed(1) || 0,
    },
  ]

  return (
    <div className="grid h-full place-items-center rounded-lg bg-gray-100 p-6">
      <RadialProgressGraph summary={summary} progressRings={progressRings} />
    </div>
  )
}
export default SummaryRadial
