import { FC } from 'react'

import { ClockIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { Chip } from '@mui/material'
import dayjs from 'dayjs'

interface ObjectiveDurationProps {
  strokeColorClass: string
  textColorClass: string
  isComplete: boolean
  diffDays: number
  dueDate: string
  completedDate: string
}

const ObjectiveDuration: FC<ObjectiveDurationProps> = ({
  strokeColorClass,
  textColorClass,
  isComplete,
  diffDays,
  dueDate,
  completedDate,
}) => {
  // Set default styling for label
  const defaultLabelStyling = {
    '& .MuiChip-avatar': {
      color: 'inherit!important',
      margin: 0,
    },
    '.MuiChip-label': {
      color: '#6b7280!important',
      padding: 0,
    },
    '.MuiChip-icon': {
      margin: 0,
    },
  }

  // Get the dates for comparison
  const objectiveDueDate = dayjs(dueDate)
  const objectiveCompletedDate = dayjs(completedDate)

  // Set label to duration
  let label = `${diffDays} Day${diffDays === 1 || diffDays === -1 ? '' : 's'} `

  // If the goal is complete and due date is after/equal to the completed date. Show goal "On Time"
  if (isComplete && !objectiveDueDate.isBefore(objectiveCompletedDate)) {
    label = 'On Time'
  }

  // Icon output component - don't show icon if goal is complete
  const durationIcon = !isComplete ? (
    <ClockIcon className={`h-5 ${strokeColorClass} !mr-1 w-6`} />
  ) : (
    <CheckCircleIcon className={`h-5 ${strokeColorClass} !mr-1 w-6`} />
  )

  return (
    <Chip
      label={label}
      avatar={durationIcon}
      className={`flex w-20 cursor-pointer bg-transparent ${textColorClass} text-xs font-medium !text-gray-700`}
      sx={defaultLabelStyling}
    />
  )
}

export default ObjectiveDuration
