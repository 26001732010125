import { FC } from 'react'

import { Box } from '@mui/material'

import Button from 'src/components/Library/Button/Button'
import TemplateCard from 'src/components/Library/TemplateCard/TemplateCard'
import Tag from 'src/components/Tag/Tag'

import { GoalTemplate } from './TemplatesCell'

interface TemplatesTrackListProps {
  templates: GoalTemplate[]
  selectedTemplateId?: number | null
  createGoalTemplate: (parentGoalId?: number) => void
  isLoading: boolean
  resetToTemplateList: () => void
  goToTemplate: (id: number) => void
}
const TemplatesTrackList: FC<TemplatesTrackListProps> = ({
  templates,
  selectedTemplateId,
  createGoalTemplate,
  isLoading,
  resetToTemplateList,
  goToTemplate,
}) => {
  return (
    <Box
      className="h-full w-full overflow-y-scroll px-2 pt-2"
      onClick={() => {
        resetToTemplateList()
      }}
    >
      <div className="mb-8 flex w-full flex-row flex-wrap items-center justify-between">
        <p className="bold text-lg">Templates</p>
        <Button
          fullWidth={false}
          onClick={() => {
            createGoalTemplate()
          }}
          loading={isLoading}
          buttonDataTestId="goal-templates-button"
        >
          Create a new Template
        </Button>
      </div>
      {!templates.length && (
        <div className="grid w-full place-items-center py-20">
          <p className="mb-2 text-gray-400">No Templates Found.</p>
          <p className="text-sm text-gray-400">
            Create a new template to get started.
          </p>
        </div>
      )}
      {templates.length > 0 && (
        <div className="grid gap-6 pb-12 xl:grid-cols-2 2xl:grid-cols-3">
          {templates.map((template) => (
            <TemplateCard
              key={template.id}
              title={
                <span className="flex items-center justify-between">
                  Template
                  {template?.isGlobal && (
                    <Tag color={'green'} globalThis>
                      Global
                    </Tag>
                  )}
                </span>
              }
              subheader={template?.goalTitle}
              author={template?.client?.name}
              isSelected={template.id === selectedTemplateId}
              onClick={(event) => {
                event.stopPropagation()
                if (template.id === selectedTemplateId) {
                  resetToTemplateList()
                } else {
                  goToTemplate(template.id)
                }
              }}
              createdTime={template?.createdAt}
              parentDataTestId={`template-card-${template.id}`}
            />
          ))}
        </div>
      )}
    </Box>
  )
}

export default TemplatesTrackList
