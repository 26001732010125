import AnnouncementItem from 'src/components/Announcements/AnnouncementItem/AnnouncementItem'

const AnnouncementList = ({
  events,
  selectedEvent,
  setSelectedEvent,
  isProcessing,
  isSearching,
}) => {
  return (
    <div
      className={
        'flex h-full flex-col gap-2 overflow-y-auto px-2 pb-20 md:px-4'
      }
    >
      {events.map((event) => (
        <AnnouncementItem
          key={event.id}
          event={event}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          isProcessing={isProcessing}
        />
      ))}
      {!isSearching && events.length === 0 && (
        <p className="text-sm text-gray-500">No events.</p>
      )}
      {isSearching && events.length === 0 && (
        <p className="text-sm text-gray-500">No events found in search.</p>
      )}
    </div>
  )
}

export default AnnouncementList
