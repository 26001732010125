import React from 'react'

interface Props {
  summary: string
}

const actionTypeStyles = {
  INSERT: { bg: 'bg-green-400', text: 'text-green-900' },
  UPDATE: { bg: 'bg-blue-400', text: 'text-blue-900' },
  DELETE: { bg: 'bg-red-400', text: 'text-red-900' },
}

export default function EventTypeDot({ summary }: Props) {
  // Normalize the ActionType string to lowercase to allow case-insensitive comparison
  const summaryLower = summary.toLowerCase()

  // Determine styles based on substring matches
  let styles = { bg: '', text: '' }

  if (summaryLower.includes('create')) {
    styles = actionTypeStyles.INSERT
  } else if (summaryLower.includes('update')) {
    styles = actionTypeStyles.UPDATE
  } else if (summaryLower.includes('delete')) {
    styles = actionTypeStyles.DELETE
  }

  const { bg, text } = styles

  return (
    <div className={`${bg} ${text} mr-2 inline-block h-2 w-2 rounded`}></div>
  )
}
