import React, { FC, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import {
  ArrowPathIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'
import {
  CreateClientIntegrationInput,
  CreateClientIntegrationMutation,
  CreateClientIntegrationMutationVariables,
  GeneratePMELoginUrl,
  GeneratePMELoginUrlVariables,
  getClientIntegrationNames,
  getClientIntegrationNamesVariables,
  IntegrationType,
  ValidateClientIntegrationMutation,
  ValidateClientIntegrationMutationVariables,
  ValidateIntegrationConnectionInput,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Modal from 'src/components/Modal/Modal'
import { openWindowWithBlockCheck } from 'src/lib/helpers'
import { PmeResponseStatus } from 'src/pages/PropertyMeAppAuthPage/PropertyMeAppAuthPage'
import { useAuth } from 'src/Providers'

import DebounceTextInput from '../DebounceTextInput/DebounceTextInput'
import Button from '../Library/Button/Button'

import CustomerIntegrationConnection from './CustomerIntegrationConnection'
import IntegrationOptionCard from './IntegrationOptionCard'
import {
  FieldErrorStatus,
  integrationOptions,
  integrationUseLevel,
} from './integrations'

enum IntegrationNameStatus {
  DEFAULT,
  ERROR,
  LOADING,
  SUCCESS,
}

const CREATE_CLIENT_INTEGRATION_MUTATION = gql`
  mutation CreateClientIntegrationMutation(
    $input: CreateClientIntegrationInput!
  ) {
    createClientIntegration(input: $input) {
      id
    }
  }
`

const VALIDATE_CLIENT_INTEGRATION_MUTATION = gql`
  mutation ValidateClientIntegrationMutation(
    $input: ValidateIntegrationConnectionInput!
  ) {
    validateIntegrationConnection(input: $input) {
      status
      message
    }
  }
`

const GENERATE_PME_LOGIN_URL = gql`
  mutation GeneratePMELoginUrl {
    pmeGenerateLoginUrl
  }
`

const GET_INTEGRATION_NAMES = gql`
  query getClientIntegrationNames {
    integrations: integrationsByClient {
      integrationName
    }
  }
`

const FormRowWrapper: FC<{
  title: string
  description: string
  children: JSX.Element
}> = ({ title, description, children }) => {
  return (
    <div className="mb-12 border-b pb-12">
      <p className="text-2xl">{title}</p>
      <p className="pt-2 text-lg text-gray-600">{description}</p>
      <div className="pt-8">{children}</div>
    </div>
  )
}

const FieldError: FC<{ fieldError: FieldErrorStatus }> = ({ fieldError }) => {
  if (!fieldError.error) {
    return null
  }
  return (
    <p className="inline rounded bg-red-100 p-1 px-4 text-xs text-red-500">
      {fieldError.message}
    </p>
  )
}

const NewCustomerIntegrationForm: FC<{ onboardingMode?: boolean }> = ({
  onboardingMode = false,
}) => {
  const { hasRole } = useAuth()
  const isSuperAdmin = hasRole(['SUPERADMIN'])

  const defaultErrorState: FieldErrorStatus = { error: false, message: '' }

  // Form State
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [pmeIsAuthenticated, setPmeIsAuthenticated] = useState<boolean>(false)
  const [pmeAuthError, setPmeAuthError] = useState<boolean>(false)

  const [integrationNameStatus, setIntegrationNameStatus] =
    useState<IntegrationNameStatus>(IntegrationNameStatus.DEFAULT)
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false)
  const [connectionSuccessful, setConnectionSuccessful] =
    useState<boolean>(false)

  // Integration Fields
  const [integrationName, setIntegrationName] = useState<string>('')
  const [integrationNameError, setIntegrationNameError] =
    useState<FieldErrorStatus>(defaultErrorState)

  const [integrationAccountKey, setIntegrationAccountKey] = useState<string>('')
  const [integrationAccountKeyError, setIntegrationAccountKeyError] =
    useState<FieldErrorStatus>(defaultErrorState)

  const [integrationAPIKey, setIntegrationAPIKey] = useState<string>('')
  const [integrationAPIKeyError, setIntegrationAPIKeyError] =
    useState<FieldErrorStatus>(defaultErrorState)

  const [integrationAPISecret, setIntegrationAPISecret] = useState<string>('')
  const [integrationAPISecretError, setIntegrationAPISecretError] =
    useState<FieldErrorStatus>(defaultErrorState)

  const [integrationExpirationDate, setIntegrationExpirationDate] =
    useState<Dayjs | null>(null)
  const [selectedProvider, setSelectedProvider] =
    useState<IntegrationType>(null)

  // Handle modal state
  const openConnectionModal = () => {
    setModalOpen(true)
  }

  const closeConnectionModal = () => {
    setModalOpen(false)
  }

  // Reset all secrets to empty
  const resetIntegrationSecretFields = () => {
    setIsSaving(false)
    setPmeIsAuthenticated(false)
    setPmeAuthError(false)

    setIntegrationAccountKey('')
    setIntegrationAPIKey('')
    setIntegrationAPISecret('')

    setIntegrationAccountKeyError(defaultErrorState)
    setIntegrationAPIKeyError(defaultErrorState)
    setIntegrationAPISecretError(defaultErrorState)
  }

  const validateFields = (): boolean => {
    let validity = true

    if (!integrationName) {
      setIntegrationNameError({
        error: true,
        message: 'Integration name cannot be empty',
      })
      validity = false
    }
    if (
      !integrationAccountKey &&
      integrationOptions[selectedProvider]?.formFields.apiAccountKey.value
    ) {
      setIntegrationAccountKeyError({
        error: true,
        message:
          integrationOptions[selectedProvider]?.formFields.apiAccountKey.value +
          ' cannot be empty',
      })
      validity = false
    }
    if (
      !integrationAPIKey &&
      integrationOptions[selectedProvider]?.formFields.apiKey.value
    ) {
      setIntegrationAPIKeyError({
        error: true,
        message:
          integrationOptions[selectedProvider]?.formFields.apiKey.value +
          ' cannot be empty',
      })
      validity = false
    }
    if (
      !integrationAPISecret &&
      integrationOptions[selectedProvider]?.formFields.apiSecret.value
    ) {
      setIntegrationAPISecretError({
        error: true,
        message:
          integrationOptions[selectedProvider]?.formFields.apiSecret.value +
          ' cannot be empty',
      })
      validity = false
    }

    return validity
  }

  const validateConnection = async (): Promise<boolean> => {
    const data: ValidateIntegrationConnectionInput = {
      integrationType: selectedProvider,
      apiAccountKey: integrationAccountKey,
      apiKey: integrationAPIKey,
      apiSecret: integrationAPISecret,
    }

    const validatedCredentials = await validateClientIntegration({
      variables: { input: data },
    })

    return (
      validatedCredentials.data.validateIntegrationConnection.status ===
      'SUCCESS'
    )
  }

  const saveIntegration = () => {
    const data: CreateClientIntegrationInput = {
      integrationName: integrationName,
      integrationType: selectedProvider,
      apiAccountKey: integrationAccountKey,
      apiKey: integrationAPIKey,
      apiSecret: integrationAPISecret,
      expirationDate: integrationExpirationDate
        ? integrationExpirationDate.toISOString()
        : null,
    }

    setTimeout(async () => {
      await createClientIntegration({ variables: { input: data } })
    }, 2500)
  }

  const handleIntegrationSubmit = async () => {
    setIsSaving(true)
    setInvalidCredentials(false)

    const allFieldsValid = validateFields()

    if (allFieldsValid) {
      openConnectionModal()

      const credentialsHaveSuccessfulConnection = await validateConnection()

      if (credentialsHaveSuccessfulConnection) {
        setConnectionSuccessful(true)

        saveIntegration()
      }
    } else {
      setIsSaving(false)
    }
  }

  const handlePMEAuthentication = async () => {
    setIsSaving(true)
    setPmeIsAuthenticated(false)
    setPmeAuthError(false)

    await pmeGenerateLoginUrl()
  }

  const [pmeGenerateLoginUrl] = useMutation<
    GeneratePMELoginUrl,
    GeneratePMELoginUrlVariables
  >(GENERATE_PME_LOGIN_URL, {
    onCompleted: (data) => {
      const processUrl = data?.pmeGenerateLoginUrl

      if (processUrl) {
        openWindowWithBlockCheck(processUrl, '_blank')
      } else {
        toast.error('Unable to connect with PropertyMe authentication.', {
          duration: 5000,
        })
      }
    },
  })

  const [validateClientIntegration] = useMutation<
    ValidateClientIntegrationMutation,
    ValidateClientIntegrationMutationVariables
  >(VALIDATE_CLIENT_INTEGRATION_MUTATION, {
    onCompleted: (data) => {
      const validatedClientIntegration = data?.validateIntegrationConnection

      if (validatedClientIntegration.status === 'FAIL') {
        toast.error(validatedClientIntegration.message, {
          duration: 5000,
        })
        setInvalidCredentials(true)
      }
    },
    onError: (error) => {
      setIsSaving(false)
      closeConnectionModal()
      toast.error('Unable to connect: ' + error.message, {
        duration: 5000,
      })
    },
  })

  const [createClientIntegration] = useMutation<
    CreateClientIntegrationMutation,
    CreateClientIntegrationMutationVariables
  >(CREATE_CLIENT_INTEGRATION_MUTATION, {
    onCompleted: () => {
      toast.success('Integration created')
      setIsSaving(false)

      if (onboardingMode) {
        navigate(routes.onboarding())
      } else {
        navigate(routes.customerIntegrations())
      }
    },
    onError: () => {
      toast.error(
        'There was an error creating your integration. Please try again or contact support.',
        {
          duration: 5000,
        },
      )
      closeConnectionModal()
      setIsSaving(false)
    },
  })

  const [getClientIntegrationNames] = useLazyQuery<
    getClientIntegrationNames,
    getClientIntegrationNamesVariables
  >(GET_INTEGRATION_NAMES, {
    onCompleted: (data) => {
      const allClientIntegrationNames = data?.integrations.map(
        (integration) => integration?.integrationName,
      )

      if (allClientIntegrationNames.includes(integrationName)) {
        setIntegrationNameStatus(IntegrationNameStatus.ERROR)
        setIntegrationNameError({
          error: true,
          message: 'This integration name is already in use.',
        })
      } else {
        setIntegrationNameStatus(IntegrationNameStatus.SUCCESS)
      }
    },
    onError: () => {
      toast.error(
        'Unable to check for existing integrations with the same name. Please make sure you use a unique name.',
        {
          duration: 5000,
        },
      )
      setIntegrationNameStatus(IntegrationNameStatus.DEFAULT)
    },
  })

  useEffect(() => {
    const confirmIntegrationNames = async () => {
      await getClientIntegrationNames()
    }

    setIntegrationNameError(defaultErrorState)

    if (integrationName) {
      setIntegrationNameStatus(IntegrationNameStatus.LOADING)
      confirmIntegrationNames()
    } else {
      setIntegrationNameStatus(IntegrationNameStatus.DEFAULT)
    }
  }, [integrationName])

  // Validate Account Key
  useEffect(() => {
    setIntegrationAccountKeyError(defaultErrorState)
    closeConnectionModal()
    if (
      !integrationAccountKey &&
      integrationOptions[selectedProvider]?.formFields.apiAccountKey.value
    ) {
      setIntegrationAccountKeyError({
        error: true,
        message:
          integrationOptions[selectedProvider]?.formFields.apiAccountKey.value +
          ' cannot be empty',
      })
    }
  }, [integrationAccountKey])

  // Validate API Key
  useEffect(() => {
    setIntegrationAPIKeyError(defaultErrorState)
    closeConnectionModal()
    if (
      !integrationAPIKey &&
      integrationOptions[selectedProvider]?.formFields.apiKey.value
    ) {
      setIntegrationAPIKeyError({
        error: true,
        message:
          integrationOptions[selectedProvider]?.formFields.apiKey.value +
          ' cannot be empty',
      })
    }
  }, [integrationAPIKey])

  // Validate API Secret
  useEffect(() => {
    setIntegrationAPISecretError(defaultErrorState)
    closeConnectionModal()
    if (
      !integrationAPISecret &&
      integrationOptions[selectedProvider]?.formFields.apiSecret.value
    ) {
      setIntegrationAPISecretError({
        error: true,
        message:
          integrationOptions[selectedProvider]?.formFields.apiSecret.value +
          ' cannot be empty',
      })
    }
  }, [integrationAPISecret])

  // Reset the integration field values each time the type changes
  useEffect(() => {
    resetIntegrationSecretFields()
  }, [selectedProvider])

  useEffect(() => {
    if (!isModalOpen) {
      setIsSaving(false)
      setConnectionSuccessful(false)
      setInvalidCredentials(false)
    }
  }, [isModalOpen])

  // LISTEN for event broadcast on new tab
  const pmeBroadcast = new BroadcastChannel(
    'integrations_propertyme_authorisation',
  )

  pmeBroadcast.onmessage = (event) => {
    const response = event.data

    if (response) {
      if (response === PmeResponseStatus.FAIL) {
        setPmeAuthError(true)
      } else {
        setIntegrationAccountKey(event.data)
        setIntegrationAPIKey('-') // Handled on API for PME
        setIntegrationAPISecret('-') // Handled on API for PME
        setPmeIsAuthenticated(true)
        setPmeAuthError(false)
      }
    } else {
      setPmeAuthError(true)
    }

    setIsSaving(false)
  }

  return (
    <div className="w-full">
      <FormRowWrapper title={'1. Setup'} description={'Enter your details.'}>
        <div className="flex flex-wrap gap-6">
          <div className="w-full max-w-[40%] grow">
            <label
              htmlFor="#outlined-controlled"
              className="block pb-1 text-gray-500"
            >
              Integration Name
            </label>
            <div className="flex items-start gap-2">
              <div className="grow">
                <DebounceTextInput
                  name="IntegrationName"
                  setKey="IntegrationName"
                  defaultValue={integrationName}
                  onChange={setIntegrationName}
                  className="bg-white"
                  error={integrationNameError.error}
                  disabled={isSaving}
                />
                {integrationNameError.error && (
                  <p className="p-0.5 text-xs text-red-600">
                    {integrationNameError.message}
                  </p>
                )}
              </div>
              {integrationNameStatus === IntegrationNameStatus.DEFAULT && (
                <CheckCircleIcon className="mt-1 h-8 w-8 text-gray-400" />
              )}
              {integrationNameStatus === IntegrationNameStatus.ERROR && (
                <XCircleIcon className="mt-1 h-8 w-8 text-red-600" />
              )}
              {integrationNameStatus === IntegrationNameStatus.LOADING && (
                <ArrowPathIcon className="mt-1 h-8 w-8 animate-spin text-gray-500" />
              )}
              {integrationNameStatus === IntegrationNameStatus.SUCCESS && (
                <CheckCircleIcon className="mt-1 h-8 w-8 text-green-500" />
              )}
            </div>
          </div>
          <div className="w-full max-w-[40%] grow">
            <label
              htmlFor="#expirationDate"
              className="block pb-1 text-gray-500"
            >
              Credential Expiration Date (optional)
            </label>
            <DatePicker
              disabled={isSaving}
              value={integrationExpirationDate}
              onChange={(newValue) => setIntegrationExpirationDate(newValue)}
              className="bg-white"
              slotProps={{
                actionBar: {
                  actions: ['clear'],
                },
                textField: {
                  size: 'small',
                  id: 'expirationDate',
                  name: 'expirationDate',
                },
              }}
            />
          </div>
        </div>
      </FormRowWrapper>
      <FormRowWrapper
        title={'2. Connection Type'}
        description={'Select a provider.'}
      >
        <div className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-6">
          {Object.keys(integrationOptions).map((integrationType) => {
            const integration = integrationOptions[integrationType]
            // Show if integration is visible OR if BASEROW and SUPERADMIN role
            if (
              (isSuperAdmin ||
                integration.use === integrationUseLevel.GLOBAL) &&
              (integrationType !== ('BASEROW' as IntegrationType) ||
                hasRole(['SUPERADMIN']))
            ) {
              return (
                <IntegrationOptionCard
                  key={integrationType}
                  integrationType={integrationType}
                  selectedProvider={selectedProvider}
                  setSelectedProvider={setSelectedProvider}
                  disabled={
                    isSaving ||
                    (!isSuperAdmin &&
                      integration.use === integrationUseLevel.DISABLED)
                  }
                  integration={integration}
                />
              )
            }
            return null
          })}
        </div>
      </FormRowWrapper>

      {selectedProvider && (
        <>
          {(integrationOptions[selectedProvider]?.formFields.apiAccountKey
            .value ||
            integrationOptions[selectedProvider]?.formFields.apiKey.value ||
            integrationOptions[selectedProvider]?.formFields.apiSecret
              .value) && (
            <FormRowWrapper
              title={'3. Credentials'}
              description={'Enter the connection credentials.'}
            >
              <>
                {selectedProvider === 'PME' && (
                  <div className="flex flex-wrap gap-4">
                    {!pmeIsAuthenticated && (
                      <Button
                        disabled={isSaving || pmeIsAuthenticated}
                        loading={isSaving}
                        fullWidth={false}
                        onClick={handlePMEAuthentication}
                      >
                        Connect PropertyMe
                      </Button>
                    )}
                    {pmeIsAuthenticated && (
                      <div className="flex max-w-fit grow items-center gap-2 rounded-lg border border-gray-300 bg-white px-6 py-2">
                        <CheckCircleIcon className="h-8 w-8 text-green-600" />
                        <p className="text-gray-600">PropertyMe Connected</p>
                      </div>
                    )}

                    {pmeAuthError && (
                      <div className="flex max-w-fit grow items-center gap-2 rounded-lg bg-red-100 px-4 py-1 text-red-600">
                        <XCircleIcon className="h-8 w-8 text-red-600" />
                        <p className="text-sm">
                          Unable to authenticate with PropertyMe. Please try
                          again.
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {selectedProvider !== 'PME' && (
                  <div className="flex flex-wrap gap-4">
                    {integrationOptions[selectedProvider]?.formFields
                      .apiAccountKey.value && (
                      <div className="w-full max-w-[40%] grow">
                        <label
                          htmlFor="#outlined-controlled"
                          className="block pb-1 text-gray-500"
                        >
                          {
                            integrationOptions[selectedProvider]?.formFields
                              .apiAccountKey.value
                          }
                        </label>
                        <div className="flex items-start gap-2">
                          <div className="grow">
                            <TextField
                              key={
                                integrationOptions[selectedProvider]?.formFields
                                  .apiAccountKey.value
                              }
                              type={
                                integrationOptions[selectedProvider]?.formFields
                                  .apiAccountKey.hide
                                  ? 'password'
                                  : 'text'
                              }
                              disabled={isSaving}
                              error={integrationAccountKeyError?.error}
                              fullWidth={false}
                              size="small"
                              id="outlined-controlled"
                              className="w-full bg-white"
                              value={integrationAccountKey}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                setIntegrationAccountKey(event.target.value)
                              }}
                            />
                            {integrationAccountKeyError.error && (
                              <p className="p-0.5 text-xs text-red-600">
                                {integrationAccountKeyError.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {integrationOptions[selectedProvider]?.formFields.apiKey
                      .value && (
                      <div className="w-full max-w-[40%] grow">
                        <label
                          htmlFor="#outlined-controlled"
                          className="block pb-1 text-gray-500"
                        >
                          {
                            integrationOptions[selectedProvider]?.formFields
                              .apiKey.value
                          }
                        </label>
                        <div className="flex items-start gap-2">
                          <div className="grow">
                            <TextField
                              key={
                                integrationOptions[selectedProvider]?.formFields
                                  .apiKey.value
                              }
                              type={
                                integrationOptions[selectedProvider]?.formFields
                                  .apiKey.hide
                                  ? 'password'
                                  : 'text'
                              }
                              disabled={isSaving}
                              error={integrationAPIKeyError?.error}
                              fullWidth={false}
                              size="small"
                              id="outlined-controlled"
                              className="w-full bg-white"
                              value={integrationAPIKey}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                setIntegrationAPIKey(event.target.value)
                              }}
                            />
                            {integrationAPIKeyError.error && (
                              <p className="p-0.5 text-xs text-red-600">
                                {integrationAPIKeyError.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {integrationOptions[selectedProvider]?.formFields.apiSecret
                      .value && (
                      <div className="w-full max-w-[40%] grow">
                        <label
                          htmlFor="#outlined-controlled"
                          className="block pb-1 text-gray-500"
                        >
                          {
                            integrationOptions[selectedProvider]?.formFields
                              .apiSecret.value
                          }
                        </label>
                        <div className="flex items-start gap-2">
                          <div className="grow">
                            <TextField
                              key={
                                integrationOptions[selectedProvider]?.formFields
                                  .apiSecret.value
                              }
                              type={
                                integrationOptions[selectedProvider]?.formFields
                                  .apiSecret.hide
                                  ? 'password'
                                  : 'text'
                              }
                              disabled={isSaving}
                              error={integrationAPISecretError.error}
                              fullWidth={false}
                              size="small"
                              id="outlined-controlled"
                              className="w-full bg-white"
                              value={integrationAPISecret}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                setIntegrationAPISecret(event.target.value)
                              }}
                            />
                            {integrationAPISecretError.error && (
                              <p className="p-0.5 text-xs text-red-600">
                                {integrationAPISecretError.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            </FormRowWrapper>
          )}
          <FormRowWrapper
            title={'4. Confirm and Connect'}
            description={
              'Save your integration and start connecting your data.'
            }
          >
            <>
              <div className="flex max-w-[350px] flex-col gap-1">
                <FieldError fieldError={integrationNameError} />

                {integrationOptions[selectedProvider].formFields.apiAccountKey
                  .value && (
                  <FieldError fieldError={integrationAccountKeyError} />
                )}
                {integrationOptions[selectedProvider].formFields.apiKey
                  .value && <FieldError fieldError={integrationAPIKeyError} />}
                {integrationOptions[selectedProvider].formFields.apiSecret
                  .value && (
                  <FieldError fieldError={integrationAPISecretError} />
                )}
              </div>

              <Button
                className="mt-8 block"
                fullWidth={false}
                disabled={
                  (!pmeIsAuthenticated && selectedProvider === 'PME') ||
                  isSaving ||
                  integrationNameError.error ||
                  integrationAccountKeyError.error ||
                  integrationAPIKeyError.error ||
                  integrationAPISecretError.error
                }
                loading={isSaving}
                onClick={handleIntegrationSubmit}
              >
                Save Integration
              </Button>
            </>
          </FormRowWrapper>
        </>
      )}

      <Modal
        open={isModalOpen}
        onClose={closeConnectionModal}
        dialogClassName="p-4 max-w-[550px] mt-32"
        closeButtonVisible={invalidCredentials}
        backDropClickDisable
      >
        <>
          <CustomerIntegrationConnection
            connectingToIntegration={
              selectedProvider ? integrationOptions[selectedProvider] : null
            }
            integrationType={selectedProvider}
            connectionStopped={invalidCredentials}
            connectionSuccessful={connectionSuccessful}
            retryAction={handleIntegrationSubmit}
            saveAction={saveIntegration}
            closeAction={closeConnectionModal}
          />
        </>
      </Modal>
    </div>
  )
}

export default NewCustomerIntegrationForm
