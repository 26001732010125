import { FC } from 'react'

import {
  DocumentTextIcon,
  MapIcon,
  PuzzlePieceIcon,
  VideoCameraIcon,
  CodeBracketIcon,
} from '@heroicons/react/24/outline'
import Typography from '@mui/material/Typography'

import { LessonType } from 'src/types/enums'

interface Props {
  type: LessonType
  title: string
}

const PlayerHeader: FC<Props> = ({ title, type }) => {
  return (
    <div className="flex flex-row items-center pb-2">
      <div className="flex h-12 w-12 items-center justify-center rounded-full bg-indigo-50">
        {type === LessonType.video || type === LessonType.videoWithText ? (
          <VideoCameraIcon className="h-8 w-8 stroke-1 text-indigo-600" />
        ) : type === LessonType.processMap ? (
          <MapIcon className="h-8 w-8 stroke-1 text-indigo-600" />
        ) : type === LessonType.embed || type === LessonType.embedWithText ? (
          <CodeBracketIcon className="h-8 w-8 stroke-1 text-indigo-600" />
        ) : type === LessonType.text ? (
          <DocumentTextIcon className="h-8 w-8 stroke-1 text-indigo-600" />
        ) : (
          <PuzzlePieceIcon className="h-8 w-8 stroke-1 text-indigo-600" />
        )}
      </div>
      <Typography component="div" className="m-4 text-lg leading-7 md:text-xl">
        {title}
      </Typography>
    </div>
  )
}

export default PlayerHeader
