import React from 'react'

import { Metadata } from '@redwoodjs/web'

import FeaturesCell from 'src/components/FeaturesCell'
import PageHeader from 'src/components/PageHeader/PageHeader'

const FeatureManagementPage = () => {
  return (
    <>
      <Metadata title="Feature Management" description="Feature Management" />
      <PageHeader
        title={'Feature Management'}
        parentDataTestId="feature-management-page-header"
      />
      <div className="flex h-[calc(100vh-5rem)] flex-1 justify-center">
        <div className="mb-2 h-full w-full overflow-y-auto px-8 pt-8">
          <FeaturesCell />
        </div>
      </div>
    </>
  )
}

export default FeatureManagementPage
