import { FC } from 'react'

import {
  DefaultHomeLinksQuery,
  DefaultHomeLinksQueryVariables,
} from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import DefaultHomeLinks from '../DefaultHomeLinks/DefaultHomeLinks'

export const QUERY = gql`
  query DefaultHomeLinksQuery(
    $clientId: Int!
    $isTemplate: Boolean!
    $fileNames: [String!]
  ) {
    defaultHomeLinks: informerListCardsByTemplateType(
      clientId: $clientId
      isTemplate: $isTemplate
    ) {
      id
      name
      url
      iconStorageObject {
        id
        downloadUrl
      }
      members {
        id
      }
      membershipGroups {
        id
      }
    }
    globalIcons: globalStorageObjects(fileNames: $fileNames) {
      id
      downloadUrl
    }
  }
`

export const Loading = () => <LoadingSpinner />
type Props = CellSuccessProps<
  DefaultHomeLinksQuery,
  DefaultHomeLinksQueryVariables
>
export const Empty: FC<Props> = ({
  defaultHomeLinks,
  globalIcons,
  queryResult: { refetch },
}) => (
  <DefaultHomeLinks
    cards={defaultHomeLinks}
    refetch={refetch}
    globalIcons={globalIcons}
  />
)

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success: FC<Props> = ({
  defaultHomeLinks,
  globalIcons,
  queryResult: { refetch },
}) => (
  <DefaultHomeLinks
    cards={defaultHomeLinks}
    refetch={refetch}
    globalIcons={globalIcons}
  />
)
