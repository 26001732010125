import React from 'react'

import { Chip } from '@mui/material'
import { LeadCompany } from 'types/graphql'

interface Props {
  partner: LeadCompany
}

export default function PartnerChip({ partner }: Props): React.ReactElement {
  const partnerClassNames: Record<LeadCompany, string> = {
    ['ON_COVER']: 'bg-orange-100 text-orange-600',
    ['COMPARE_CONNECT']: 'bg-green-100 text-green-600',
    ['RELLO']: 'bg-indigo-100 text-indigo-600',
    ['INNOVAYT']: 'bg-blue-100 text-blue-600',
    ['BOND']: 'bg-red-100 text-red-600',
  }

  const partnerHumanNames: Record<LeadCompany, string> = {
    ['ON_COVER']: 'On Cover Insurance',
    ['COMPARE_CONNECT']: 'Compare & Connect Connections',
    ['RELLO']: 'Rello Funding',
    ['INNOVAYT']: 'Innovayt Finance',
    ['BOND']: 'Bond Conveyancing',
  }

  return (
    <Chip
      size={'small'}
      label={partnerHumanNames[partner] || partner}
      className={`rounded-xl px-1 py-2 ${partnerClassNames[partner] || 'bg-gray-300 text-black'}`}
    />
  )
}
