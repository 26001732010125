import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react'

import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import { Drawer } from '@mui/material'
import { makeMultiTree } from 'api/src/common/collections'
import { useDebounceValue, useMediaQuery } from 'usehooks-ts'

import { navigate, routes } from '@redwoodjs/router'

import { KBItemSorted } from '../KnowledgeCell/types'
import Button from '../Library/Button'
import IconButton from '../Library/IconButton/IconButton'
import { SearchField } from '../Library/SearchField/SearchField'

import KBFolderList from './KBFolderList'
import type { CreateArgs } from './KnowledgeBase'
import { ModalState } from './Modals/CreateEditCategoryModal'

const filterKbRootItemsBySearchTerm = (searchTerm, items) => {
  if (!searchTerm || searchTerm.length <= 2) return items

  const lowerCaseTerm = searchTerm.toLowerCase()

  return items
    .map((item) => {
      const matchesTitle = item.title.toLowerCase().includes(lowerCaseTerm)

      // If the item has children, recursively filter them
      let filteredChildren = []
      if (item.children && item.children.length > 0) {
        filteredChildren = filterKbRootItemsBySearchTerm(
          searchTerm,
          item.children,
        )
      }

      // Return the item if it matches or has matching children
      if (matchesTitle || filteredChildren.length > 0) {
        return { ...item, children: filteredChildren }
      }

      // If no match is found, return null
      return null
    })
    .filter(Boolean) // Remove null values
}

interface KnowledgeBaseSidebarProps {
  sidebarOpen: boolean
  kbItems: KBItemSorted[]
  selectedKbItem: KBItemSorted
  setSelectedKbEditItem: Dispatch<SetStateAction<KBItemSorted>>
  setSelectedKbItem: Dispatch<SetStateAction<KBItemSorted>>
  handleSideBarEdit: (id: number) => void
  onCreateClick: (args: CreateArgs) => void
  onArchiveClick: (kbItem: KBItemSorted) => void
  toggleSidebar: () => void
  canEdit: boolean
  setCategoryModalOpen: (value: boolean) => void
  setModalMode: Dispatch<SetStateAction<ModalState>>
  isLoading: { id: number | null; status: boolean }
  hasChanges: boolean
}

const KnowledgeBaseSidebar: FC<KnowledgeBaseSidebarProps> = ({
  sidebarOpen,
  kbItems,
  selectedKbItem,
  setSelectedKbEditItem,
  handleSideBarEdit,
  onCreateClick,
  onArchiveClick,
  toggleSidebar,
  canEdit,
  setCategoryModalOpen,
  setModalMode,
  isLoading,
  hasChanges,
}) => {
  const isTablet = useMediaQuery('(max-width: 1023px)')
  const drawerWidth = isTablet ? '100%' : '450px'

  const [searchValue, setSearchValue] = useState<string>('')
  const [debouncedValue] = useDebounceValue<string>(searchValue, 800)

  const kbRootItems: KBItemSorted[] = useMemo(() => {
    const sortedKbItems = [...kbItems].sort((a, b) =>
      a.title.localeCompare(b.title),
    )
    return makeMultiTree({
      nodes: sortedKbItems,
      idFn: (item) => item.id,
      parentIdFn: (item) => item.parentId,
    })
  }, [kbItems])

  // Memoize the filtered items based on the search term
  const filteredKbItems = useMemo(() => {
    return filterKbRootItemsBySearchTerm(debouncedValue, kbRootItems)
  }, [debouncedValue, kbRootItems])

  const handleSetSelectedKbItem = (item: KBItemSorted) => {
    if (!hasChanges) {
      setModalMode(ModalState.EDIT) // Set the modal mode to edit always unless creating a new category
      navigate(routes.knowledgeWithId({ id: item.id }))
    }
    if (isTablet) {
      toggleSidebar()
    }
  }

  return (
    <div className="relative">
      <Drawer
        elevation={0}
        className="h-full !duration-300 !ease-in-out"
        sx={{
          width: sidebarOpen ? drawerWidth : 0,
          flexShrink: 0,
          zIndex: 50,
          transitionTimingFunction: 'ease-in-out',
          transitionDuration: '300ms',
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            position: isTablet ? 'absolute' : 'relative',
            boxSizing: 'border-box',
            transitionTimingFunction: 'ease-in-out!important',
            transitionDuration: '300ms!important',
            zIndex: 0,
          },
        }}
        variant={isTablet ? 'temporary' : 'persistent'}
        anchor={isTablet ? 'bottom' : 'left'}
        open={sidebarOpen}
      >
        <div className="sticky top-0 z-10 bg-white">
          <div className="flex h-[75px] items-center border-b p-4">
            <SearchField
              value={searchValue}
              onChange={setSearchValue}
              placeholder="Search Knowledge Base..."
            />
            {isTablet && (
              <div>
                <IconButton
                  className={
                    'ml-3 flex-shrink rounded-lg bg-gray-100 p-2 hover:!bg-gray-200'
                  }
                  onClick={() => {
                    toggleSidebar()
                  }}
                >
                  {sidebarOpen ? (
                    <ChevronDownIcon className="h-4 w-4" />
                  ) : (
                    <ChevronRightIcon className="h-4 w-4" />
                  )}
                </IconButton>
              </div>
            )}
          </div>
        </div>

        <div className="grow overflow-y-auto p-4">
          {filteredKbItems?.length === 0 && (
            <div className="p-4">
              <p className="mb-2 text-gray-600">No Results.</p>
              <p className="text-sm text-gray-400">
                No Knowledge Base items found.
              </p>
            </div>
          )}

          {filteredKbItems?.length > 0 && (
            <KBFolderList
              categories={filteredKbItems}
              selectedKbItem={selectedKbItem}
              handleSetSelectedKbItem={handleSetSelectedKbItem}
              handleSideBarEdit={handleSideBarEdit}
              onArchiveClick={onArchiveClick}
              onCreateClick={onCreateClick}
              userCanEdit={canEdit}
              isTablet={isTablet}
              isLoading={isLoading}
            />
          )}
        </div>
        {canEdit && (
          <div className="sticky bottom-0 w-full bg-white px-3 py-4">
            <Button
              onClick={() => {
                setModalMode(ModalState.CREATE)
                setSelectedKbEditItem(null)
                setCategoryModalOpen(true)
              }}
              startIcon={<PlusIcon className="h-4 w-4" />}
              buttonDataTestId="add-knowledgebase-category"
            >
              Add Category
            </Button>
          </div>
        )}
      </Drawer>
    </div>
  )
}

export default KnowledgeBaseSidebar
