import { FC } from 'react'

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'

import Button from '../Library/Button/Button'

interface OnboardingHelpItemProps {
  title: string
  description: string
  icon: JSX.Element
  onClick: () => void
}

const OnboardingHelpItem: FC<OnboardingHelpItemProps> = ({
  title,
  description,
  icon,
  onClick,
}) => {
  return (
    <Button
      variant="text"
      className="flex flex-1 flex-col items-start gap-4 rounded-lg border border-solid border-gray-200 bg-white p-6 text-start hover:bg-white hover:shadow-lg"
      onClick={onClick}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex w-full items-center gap-3">
          {icon}
          <p className="flex items-center text-lg text-gray-700">{title}</p>
        </div>
        <ArrowTopRightOnSquareIcon className="h-6 w-6 text-indigo-600" />
      </div>
      <p className="flex items-center text-sm text-gray-600">{description}</p>
    </Button>
  )
}

export default OnboardingHelpItem
