import React, { useEffect, useState } from 'react'

import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline'
import { UserIcon } from '@heroicons/react/24/solid'
import { CardContent, Tooltip } from '@mui/material'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'
import { FEATURE } from 'api/src/common/enums'
import { useBoolean } from 'usehooks-ts'

import { Metadata, useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import Empty from 'src/components/Library/Empty/Empty'
import IconButton from 'src/components/Library/IconButton'
import SimpleForm from 'src/components/Library/SimpleForm/SimpleForm'
import Modal from 'src/components/Modal/Modal'
import NPSAssessorsCell from 'src/components/Nps/NpsSettings/NPSAssessorsCell'
import NpsMeasurementCell from 'src/components/Nps/NpsSettings/NpsMeasurementCell'
import NpsMeasurementsListCell from 'src/components/Nps/NpsSettings/NpsMeasurementsListCell'
import NpsSubjectsCell from 'src/components/Nps/NpsSettings/NpsSubjectsCell'
import PageHeader from 'src/components/PageHeader/PageHeader'
import useFeature from 'src/lib/hooks/useFeature'
import {
  CREATE_NPS_MEASUREMENT,
  DELETE_NPS_MEASUREMENT,
  UPDATE_NPS_MEASUREMENT,
} from 'src/lib/queries/Settings/NPS/NpsMeasurement'
import { useAuth } from 'src/Providers'

enum NPSViews {
  EMPTY,
  SUBJECTS,
  ASSESSORTYPES,
  MEASUREMENTS,
}

const SettingsNpsPage = () => {
  const [activeView, setActiveView] = useState<NPSViews>(NPSViews.EMPTY)
  const { currentUser, hasRole } = useAuth()
  const clientId = currentUser?.parentData?.id
  const [myMap, setMyMap] = useState(new Map([['Name', '']]))
  const modalVisible = useBoolean(false)
  const [selectedMeasurementId, setSelectedMeasurementId] = useState<any>(0)
  const AssessorTypesFeatureFlag = useFeature(FEATURE.NPS_ASSESSOR_TYPES)
  const updateMap = (key, value) => {
    setMyMap(myMap.set(key, value))
  }

  const [createMeasurement] = useMutation(CREATE_NPS_MEASUREMENT, {
    onCompleted: () => {
      toast.success('Measurement Created', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['NpsMeasurementsList'],
    onError: (error) => toast.error(error.message),
  })

  const [updateMeasurement] = useMutation(UPDATE_NPS_MEASUREMENT, {
    onCompleted: () => {
      toast.success('Measurement Updated', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['NpsMeasurementsList'],
    onError: (error) => toast.error(error.message),
  })

  const [deleteMeasurement] = useMutation(DELETE_NPS_MEASUREMENT, {
    onCompleted: () => {
      toast.success('Measurement Deleted', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['NpsMeasurementsList'],
    onError: (error) => toast.error(error.message),
  })

  const handleCreateMeasurement = () => {
    const nameInvalid = myMap.get('Name') === ''
    if (nameInvalid) {
      toast.error('Category name is required')
      return
    }

    createMeasurement({
      variables: {
        input: {
          name: myMap.get('Name'),
        },
      },
    }).then(() => {
      modalVisible.setFalse()
    })
  }

  useEffect(() => {
    if (selectedMeasurementId === null) {
      setActiveView(NPSViews.EMPTY)
    }
  }, [selectedMeasurementId])

  return (
    <>
      <Metadata title="NPS Settings" description="NPS Settings page" />
      <div className={'h-screen'}>
        <PageHeader
          title={'Net Promoter Score Admin'}
          parentDataTestId="nps-admin-page-header"
        />
        <div className="flex h-[calc(100vh-5rem)] flex-row">
          <div
            className={'relative w-[350px] flex-grow overflow-y-auto border-r'}
          >
            <div className="max-h-[calc(100%-5rem)] overflow-y-auto p-2">
              <Stack spacing={1}>
                {AssessorTypesFeatureFlag && hasRole('SUPERADMIN') && (
                  <Button
                    startIcon={
                      <ClipboardDocumentCheckIcon className="h-4 w-4 stroke-2" />
                    }
                    variant={'outlined'}
                    size={'small'}
                    color={'primary'}
                    className={
                      'h-8 justify-start border-gray-200 bg-white text-gray-700 hover:!border-indigo-200 hover:!bg-indigo-50 hover:!text-indigo-600'
                    }
                    onClick={() => {
                      setActiveView(NPSViews.ASSESSORTYPES)
                      setSelectedMeasurementId(0)
                    }}
                    buttonDataTestId="nps-assessor-types-button"
                  >
                    NPS Assessor Types
                  </Button>
                )}
                <Card variant="outlined">
                  <CardHeader
                    className="border-b p-2 text-sm font-normal leading-5"
                    title={
                      <h2 className={'text-sm font-normal leading-5'}>
                        NPS Categories
                      </h2>
                    }
                    action={
                      <Tooltip title={'Performers'} placement={'top'} arrow>
                        <div>
                          <IconButton
                            size={'small'}
                            className={'mx-2 hover:!bg-indigo-300'}
                            onClick={() => {
                              setSelectedMeasurementId(0)
                              setActiveView(NPSViews.SUBJECTS)
                            }}
                          >
                            <UserIcon className={'h-4 w-4 stroke-2'} />
                          </IconButton>
                        </div>
                      </Tooltip>
                    }
                  />
                  <CardContent className={'flex justify-center p-1 !pb-2 pt-2'}>
                    <div className={'w-full'}>
                      <NpsMeasurementsListCell
                        setView={() => setActiveView(NPSViews.MEASUREMENTS)}
                        setMeasurement={setSelectedMeasurementId}
                        saveMeasurement={updateMeasurement}
                        deleteMeasurement={deleteMeasurement}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Stack>
            </div>
            <Modal
              onClose={modalVisible.setFalse}
              open={modalVisible.value}
              title={'Add Category'}
              dialogClassName={'!p-0 max-w-[720px]'}
              childClassName={'p-4'}
              footerVisible
              onCancel={modalVisible.setFalse}
              onConfirm={handleCreateMeasurement}
            >
              <SimpleForm records={myMap} handleChange={updateMap} />
            </Modal>
            <div className="absolute bottom-0 left-0 w-full border-t p-4">
              <Button
                className=""
                onClick={() => {
                  modalVisible.toggle()
                  updateMap('Name', '')
                }}
                buttonDataTestId="add-nps-measurement-button"
              >
                Add an NPS category
              </Button>
            </div>
          </div>
          <div className="mb-2 flex h-full w-full grow justify-center overflow-y-auto p-4 pt-8">
            {
              {
                [NPSViews.EMPTY]: (
                  <div className="grid grow place-items-center">
                    <Empty
                      title={'Nothing Selected'}
                      description={'Please Select An NPS Category'}
                    />
                  </div>
                ),
                [NPSViews.SUBJECTS]: <NpsSubjectsCell />,
                [NPSViews.ASSESSORTYPES]: <NPSAssessorsCell />,
                [NPSViews.MEASUREMENTS]: (
                  <NpsMeasurementCell id={selectedMeasurementId} />
                ),
              }[activeView]
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingsNpsPage
