import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import SyncLoader from 'react-spinners/SyncLoader'
const SavedStatus = (props) => {
  const { saving, editing } = props
  return (
    <span
      className={`m-1 inline-flex w-40 items-center rounded-full px-3 py-0.5 text-sm font-normal ${
        saving ? '' : editing ? 'text-red-500' : 'text-green-600'
      }`}
    >
      {saving ? (
        <SyncLoader
          className="ml-8"
          color="#34D399"
          size={5}
          speedMultiplier={0.6}
        />
      ) : editing ? (
        <>
          <XCircleIcon className="mx-1 h-4 w-4 text-red-500" />
          Editing
        </>
      ) : (
        <>
          <CheckCircleIcon
            data-testid="lesson-status-saved-indicator"
            className="mx-1 h-4 w-4 text-green-600"
          />
          Changes Saved
        </>
      )}
    </span>
  )
}

export default SavedStatus
