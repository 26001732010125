import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { themeQuartz } from '@ag-grid-community/theming'
import { Chip } from '@mui/material'
import {
  ColDef,
  type RowSelectionOptions,
  type SelectionChangedEvent,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import Button from 'src/components/Library/Button'
import Switch from 'src/components/Library/Switch/Switch'
import { SentimentAnalysisUserStatus } from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'

import type { Membership } from '../SettingsSentimentAnalysisCell'

import MemberManagementNotificationRecipientModal from './MemberManagementNotificationRecipientModal'
import SentimentAnalysisDataGridLabel from './MemberManagementStatusLabel'

interface MemberManagementGridProps {
  memberships: Membership[]
  setSelectedRows: (selectedRows: Membership[]) => void
  searchValue: string
  toggleUserAccess: (id: number, isDisabled: boolean) => void
}

const memberManagementGridTheme = themeQuartz.withParams({
  accentColor: '#6366F1',
  browserColorScheme: 'light',
  headerFontSize: 14,
  sidePanelBorder: true,
  wrapperBorder: true,
})

const MemberManagementGrid: FC<MemberManagementGridProps> = ({
  memberships,
  setSelectedRows,
  searchValue,
  toggleUserAccess,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
  const [notificationRecipients, setNotificationRecipients] = useState<
    { id: number; name: string }[]
  >([])

  useEffect(() => {
    if (selectedRowId) {
      const selectedMembership = memberships.find(
        (membership) => membership.id === selectedRowId,
      )

      const recipientIds =
        selectedMembership?.sentimentNotificationRecipients || []

      const matchingMemberships = memberships.filter((membership) =>
        recipientIds.some(
          (recipient) =>
            recipient.sentimentRecipientMembershipId === membership.id,
        ),
      )

      const recipients = matchingMemberships.map((membership) => ({
        id: membership.id,
        name: membership.user.name,
      }))

      setNotificationRecipients(recipients)
    }
  }, [selectedRowId, memberships])

  const customGroupsComponent = ({ value }) => {
    return (
      <>
        {value.map((groupName, index) => (
          <Chip size="small" className="mr-2" key={index} label={groupName} />
        ))}
      </>
    )
  }

  const customStatusComponent = ({ value }) => {
    return <SentimentAnalysisDataGridLabel label={value} />
  }

  const customAccessComponent = ({ value }) => {
    const isDisabled = value?.disabled
    const isInvited = value?.invited
    if (!value) return null
    else
      return (
        <Switch
          color="primary"
          checked={!isDisabled}
          disabled={!isInvited}
          onChange={() => {
            toggleUserAccess(value?.id, !isDisabled)
          }}
        />
      )
  }

  const customNotificationRecipientsComponent = (params) => {
    const { value, data } = params
    const rowId = data.id

    const handleClick = () => {
      setSelectedRowId(rowId)
      setIsDialogOpen(true)
    }

    return (
      <Button
        onClick={handleClick}
        variant="text"
        fullWidth={false}
        className="text-sm text-gray-400 hover:bg-transparent"
      >
        {value && value.length > 0 ? (
          value.map((name, index) => (
            <Chip size="small" className="mr-2" key={index} label={name} />
          ))
        ) : (
          <span>Click to Add Recipients</span>
        )}
      </Button>
    )
  }

  const [colDefs] = useState<ColDef[]>([
    { field: 'id', width: 100, headerName: 'ID', sort: 'asc', hide: true },
    { field: 'name', width: 250, headerName: 'Name' },
    { field: 'position', width: 250, headerName: 'Position' },
    {
      field: 'membershipGroups',
      cellRenderer: customGroupsComponent,
      headerName: 'Group/s',
      width: 250,
      valueGetter: (params) => {
        const groups = params.data.membershipGroups
        return groups.map((group) => group.name)
      },
    },
    {
      field: 'status',
      width: 150,
      cellRenderer: customStatusComponent,
      headerName: 'Status',
    },
    {
      field: 'sentimentAnalysisPermission',
      width: 120,
      cellRenderer: customAccessComponent,
      headerName: 'Access',
    },
    {
      field: 'notificationRecipients',
      headerName: 'Notification Recipients',
      flex: 1,
      cellRenderer: customNotificationRecipientsComponent,
      valueGetter: (params) => {
        const recipients = params.data.notificationRecipients || []
        return recipients
          .map((recipient) => {
            const membership = memberships.find(
              (membership) =>
                membership.id === recipient.sentimentRecipientMembershipId,
            )
            return membership ? membership.user.name : null
          })
          .filter(Boolean) // Filter out null values
      },
    },
  ])

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 180,
      resizable: true,
      filter: true,
      editable: false,
    }
  }, [])

  const formatData = (memberships) => {
    return memberships.map((membership) => {
      // Get membership access context
      const isInvited =
        membership?.sentimentAnalysisPermission?.invited ?? false
      const isDisabled =
        membership?.sentimentAnalysisPermission?.disabled ?? false

      const isActive =
        membership?.landlordsWithEmailsAndSentimentScores?.length > 0

      // Set the membership status
      let status = SentimentAnalysisUserStatus.notInvited
      if (isDisabled) {
        status = SentimentAnalysisUserStatus.disabled
      } else if (isActive) {
        status = SentimentAnalysisUserStatus.active
      } else if (isInvited) {
        status = SentimentAnalysisUserStatus.invited
      }
      const notificationRecipients = membership.sentimentNotificationRecipients

      return {
        id: membership.id,
        name: membership.user.name,
        position: membership.user.position,
        membershipGroups: membership.membershipGroups.map(
          (group) => group.membershipGroup,
        ),
        status: status,
        sentimentAnalysisPermission: membership.sentimentAnalysisPermission,
        notificationRecipients: notificationRecipients,
      }
    })
  }

  const rowData = useMemo(() => formatData(memberships), [memberships])

  const onSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      const selectedRows = event.api.getSelectedRows()
      setSelectedRows(selectedRows)
    },
    [setSelectedRows],
  )

  const rowSelection = useMemo<
    RowSelectionOptions | 'single' | 'multiple'
  >(() => {
    return {
      mode: 'multiRow',
      isRowSelectable: (rowNode) =>
        rowNode.data
          ? rowNode.data.status === SentimentAnalysisUserStatus.notInvited
          : false,
    }
  }, [])

  return (
    <>
      <div className="hide-scrollbar flex h-[calc(100vh-16rem)] flex-col gap-2 overflow-scroll">
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          quickFilterText={searchValue}
          onSelectionChanged={onSelectionChanged}
          rowSelection={rowSelection}
          theme={memberManagementGridTheme}
          getRowId={(params) => params.data.id.toString()}
          rowStyle={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
          loadThemeGoogleFonts={true}
        />
      </div>
      <MemberManagementNotificationRecipientModal
        memberships={memberships}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        selectedRowId={selectedRowId}
        notificationRecipients={notificationRecipients}
        setNotificationRecipients={setNotificationRecipients}
      />
    </>
  )
}

export default MemberManagementGrid
