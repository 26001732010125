import { FC, useEffect, useRef, useState } from 'react'

import { CheckIcon, PencilIcon } from '@heroicons/react/24/outline'
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material'
import { UpdateOrgChartLayoutMutationVariables } from 'types/graphql'

import { UpdateOrgChartLayoutType } from 'src/components/InformerOrgChart/InformerOrgChartCell/InformerOrgChartCell'
import useOrgChartFlowStore from 'src/lib/stores/orgChartFlowStores'

import Button from '../Library/Button/Button'

import InformerOrgChartAlertPopup from './InformerOrgChartAlertPopup'

interface InformerOrgChartTopMenuBarProps {
  structureList: { id: number; value: string }[]
  setStructureTitle: (title: string) => void
  setCurrentStructure: (structureId: number) => void
  currentStructure: number
  structureTitle: string
  updateOrgChartLayout: UpdateOrgChartLayoutType
  circularStructureReferences: string[]
  isAllowedToEdit: boolean
  published: boolean
  editTitleEnabled: boolean
  setEditTitleEnabled: (enabled: boolean) => void
  membersThatCanEdit: string[]
  groupsThatCanEdit: string[]
}

const InformerOrgChartTopMenuBar: FC<InformerOrgChartTopMenuBarProps> = ({
  structureList,
  setStructureTitle,
  setCurrentStructure,
  currentStructure,
  structureTitle,
  updateOrgChartLayout,
  circularStructureReferences,
  isAllowedToEdit,
  published,
  editTitleEnabled,
  setEditTitleEnabled,
  membersThatCanEdit,
  groupsThatCanEdit,
}) => {
  const availableUsers = useOrgChartFlowStore((state) => state.availableUsers) // Available staff, those who have yet to be allocated, which will appear at the top of the list

  const inputTitleRef = useRef<HTMLInputElement | null>(null)
  const [saveTitleLoading, setSaveTitleLoading] = useState(false) // This is used to determine if the user is saving the title or not

  const structureView = useOrgChartFlowStore((state) => state.structureView) // State of if the user is in structure view mode

  const setViewFromPosition = useOrgChartFlowStore(
    (state) => state.setViewFromPosition,
  ) // Function to set the state of viewFromPosition

  // This is used to update the title of the structure
  const handleSaveNewTitle = async (newTitle: string) => {
    setSaveTitleLoading(true)
    if (!currentStructure) {
      return
    }
    await updateOrgChartLayout({
      variables: {
        id: currentStructure,
        input: {
          name: newTitle,
        } as UpdateOrgChartLayoutMutationVariables['input'],
      },
    })
    setEditTitleEnabled(false)
    setSaveTitleLoading(false)
  }

  const handleSetEditTitleEnabled = () => {
    setEditTitleEnabled(true)
  }

  useEffect(() => {
    if (editTitleEnabled && inputTitleRef.current) {
      inputTitleRef.current.focus()
      inputTitleRef.current.select()
    }
  }, [editTitleEnabled])

  return (
    <div className="flex items-center justify-between gap-4">
      <div className="flex flex-row items-center gap-4">
        {(!structureView || (structureView && !editTitleEnabled)) &&
          structureList?.length > 0 && (
            <div>
              <FormControl variant="standard" size="small">
                <Select
                  inputProps={{
                    name: 'plan',
                    id: 'outlined-plan-native-simple',
                  }}
                  value={currentStructure ? String(currentStructure) : ''}
                  onChange={(e) => {
                    setViewFromPosition('ORG_NODE')

                    const value = Number(e.target.value)
                    setCurrentStructure(value)
                  }}
                  fullWidth
                  className="my-1 w-[250px]"
                  sx={{
                    '& .MuiSelect-select': {
                      background: 'white!important',
                    },
                  }}
                >
                  {structureList &&
                    structureList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}

        {/* // If we are in structure view, we need to show the title of the
        structure and allow the user to edit */}
        {editTitleEnabled && structureView && (
          <TextField
            variant="standard"
            className="w-[250px]"
            id="outlined-basic"
            value={structureTitle}
            size={'small'}
            onChange={(e) => setStructureTitle(e.target.value)}
            inputRef={inputTitleRef}
          />
        )}

        {structureView && (
          <>
            {/* Show edit and save icons for the title */}
            <Tooltip title={`${editTitleEnabled ? 'Save' : 'Edit'} title`}>
              <div>
                <Button
                  fullWidth={false}
                  variant="text"
                  loading={saveTitleLoading}
                  className={`min-w-[0] rounded-full p-2 text-gray-500 ${
                    editTitleEnabled ? 'bg-gray-100' : ''
                  }`}
                  onClick={() => {
                    editTitleEnabled
                      ? handleSaveNewTitle(structureTitle)
                      : handleSetEditTitleEnabled()
                  }}
                  disabled={!isAllowedToEdit}
                >
                  {editTitleEnabled ? (
                    <CheckIcon className="h-5 w-5 text-green-600" />
                  ) : (
                    <PencilIcon className="h-5 w-5" />
                  )}
                </Button>
              </div>
            </Tooltip>
          </>
        )}
      </div>

      <div className="flex flex-row gap-2">
        {structureView &&
          isAllowedToEdit &&
          circularStructureReferences?.length > 0 && (
            <InformerOrgChartAlertPopup
              variant="danger"
              title="You have circular references"
            >
              <>
                <p className="bg-gray-100 p-3 text-gray-600">
                  The following seats have circular references. Please
                  investigate and rectify to ensure the plan is accurate:
                </p>
                <ul className="list-disc pl-4 pt-2">
                  {circularStructureReferences.map((ref, index) => (
                    <li className="border-b p-2 px-3" key={index}>
                      {ref}
                    </li>
                  ))}
                </ul>
              </>
            </InformerOrgChartAlertPopup>
          )}
        {structureView && (
          <InformerOrgChartAlertPopup
            variant={published ? 'success' : 'info'}
            title={published ? 'PUBLISHED' : 'DRAFT'}
          ></InformerOrgChartAlertPopup>
        )}
        {structureView &&
          structureList &&
          isAllowedToEdit &&
          structureList.length > 0 &&
          availableUsers.length > 0 && (
            <InformerOrgChartAlertPopup
              variant="warning"
              title="You have unused members"
            >
              <>
                <p className="bg-gray-100 p-3 text-gray-600">
                  You have members that are not allocated to positions. The
                  following members need to be assigned:
                </p>
                <ul>
                  {availableUsers.map((ref, index) => (
                    <li className="border-b p-2 px-3" key={index}>
                      {ref.label}
                    </li>
                  ))}
                </ul>
              </>
            </InformerOrgChartAlertPopup>
          )}
        {structureView && (
          <InformerOrgChartAlertPopup
            variant={isAllowedToEdit ? 'success' : 'warning'}
            title={
              isAllowedToEdit
                ? 'You can edit and share this plan'
                : 'You do not have access to edit this plan'
            }
          >
            <>
              <p className="bg-gray-100 p-3 text-gray-600">
                The following users can edit and share access to this plan:
              </p>
              <ul>
                {membersThatCanEdit.map((ref, index) => (
                  <li className="border-b p-2 px-3" key={index}>
                    {ref}
                  </li>
                ))}
              </ul>
              <p className="bg-gray-100 p-3 text-gray-600">
                Members of these groups can also edit and share access to this
                plan:
              </p>
              {groupsThatCanEdit.length === 0 && (
                <ul>
                  <li className="border-b p-2 px-3">
                    No groups have access to edit this plan
                  </li>
                </ul>
              )}
              {groupsThatCanEdit.length > 0 && (
                <ul>
                  {groupsThatCanEdit.map((ref, index) => (
                    <li className="border-b p-2 px-3" key={index}>
                      {ref}
                    </li>
                  ))}
                </ul>
              )}
            </>
          </InformerOrgChartAlertPopup>
        )}
      </div>
    </div>
  )
}

export default InformerOrgChartTopMenuBar
