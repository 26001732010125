import { Chip } from '@mui/material'

interface BaserowImporterComponentFilterChipProps {
  className: string
  label: string
}
const BaserowFilterChip = ({
  className,
  label,
}: BaserowImporterComponentFilterChipProps) => (
  <Chip
    size="small"
    className={`rounded px-2 text-sm ${className}`}
    label={label}
  />
)

export default BaserowFilterChip
