import { FC, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import type {
  CreateSentimentAnalysisPermissionMutation,
  CreateSentimentAnalysisPermissionMutationVariables,
  UpdateSentimentAnalysisPermissionMutation,
  UpdateSentimentAnalysisPermissionMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import type { Membership } from '../SettingsSentimentAnalysisCell'
import {
  CREATE_SA_PERMISSION,
  UPDATE_SA_PERMISSION,
} from '../SettingsSentimentAnalysisQueries'

import MemberManagementGrid from './MemberManagementGrid'
import HeaderBar from './MemberManagementHeaderBar'

interface Props {
  memberships: Membership[]
}

const SentimentAnalysisMemberManagement: FC<Props> = ({ memberships }) => {
  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState<Membership[]>([])
  const apolloClient = useApolloClient()

  const [createSAPermission] = useMutation<
    CreateSentimentAnalysisPermissionMutation,
    CreateSentimentAnalysisPermissionMutationVariables
  >(CREATE_SA_PERMISSION)

  const inviteUsers = async () => {
    setIsLoading(true)
    const createPromises = selectedRows.map(async (membership) => {
      try {
        await createSAPermission({
          variables: {
            sendInvite: true,
            input: {
              membershipId: Number(membership.id),
              disabled: false,
              invited: true,
            },
          },
        })
      } catch (error) {
        toast.error(`Failed to invite ${membership.user.name}`, {
          duration: 5000,
        })
      }
    })

    await Promise.all(createPromises)

    await apolloClient.refetchQueries({
      include: ['FindSettingsSentimentAnalysisQuery'],
    })

    setIsLoading(false)
    toast.success('Invitations processed')
  }

  const [updateSAPermission] = useMutation<
    UpdateSentimentAnalysisPermissionMutation,
    UpdateSentimentAnalysisPermissionMutationVariables
  >(UPDATE_SA_PERMISSION, {
    refetchQueries: ['FindSettingsSentimentAnalysisQuery'],
    awaitRefetchQueries: true,
  })

  const toggleUserAccess = async (recordId: number, toggledValue: boolean) => {
    await toast.promise(
      updateSAPermission({
        variables: {
          id: recordId,
          input: {
            disabled: toggledValue,
          },
        },
      }),
      {
        loading: 'Updating user access',
        success: 'User access updated successfully',
        error: (error) => `Failed to update user access: ${error.message}`,
      },
    )
  }

  const defaultNotificationRecipientMembers = memberships.filter(
    (membership) => membership.isSentimentNotificationDefaultRecipient,
  )

  return (
    <div className="flex flex-col gap-4 py-4">
      <HeaderBar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        memberships={memberships}
        isLoading={isLoading}
        selectedRows={selectedRows}
        inviteUsers={inviteUsers}
        defaultNotificationRecipientMembers={
          defaultNotificationRecipientMembers
        }
      />
      <MemberManagementGrid
        memberships={memberships}
        setSelectedRows={setSelectedRows}
        searchValue={searchValue}
        toggleUserAccess={toggleUserAccess}
      />
    </div>
  )
}

export default SentimentAnalysisMemberManagement
