import { FC } from 'react'

import { Avatar, AvatarGroup } from '@mui/material'
import dayjs from 'dayjs'
export interface Author {
  id: number
  name?: string
  avatarUrl?: string
}

export interface AuthorsProps {
  createdByUser?: Author
  updatedByUser?: Author
  createdAt: string
  updatedAt: string
  byText?: string
}
export enum AuthorsDateFormat {
  day = 'DD/MM/YYYY',
}

const Authors: FC<AuthorsProps> = ({
  createdByUser,
  updatedByUser,
  createdAt,
  updatedAt,
  byText = 'Authored by',
}) => {
  if (!createdByUser && !updatedByUser) {
    return null
  }

  // Set Dates
  const createdDate = dayjs(createdAt, AuthorsDateFormat.day)
  const updatedDate = dayjs(updatedAt, AuthorsDateFormat.day)

  // Has it been updated
  const isUpdated = updatedDate.isAfter(createdDate, 'days')

  // Are created and updated by users the same?
  const authorsAreTheSame = createdByUser?.id === updatedByUser?.id

  // Do we show the updated user
  const showUpdatedUser = isUpdated && !authorsAreTheSame

  return (
    <div
      className="flex items-start space-x-1 p-0 py-3 text-sm font-normal"
      data-testid="authors-cell"
    >
      <AvatarGroup max={2}>
        {createdByUser && (
          <Avatar
            title={`Created by ${createdByUser.name} on ${createdAt}`}
            alt={createdByUser.name}
            src={createdByUser.avatarUrl}
            className="h-6 w-6"
          />
        )}
        {showUpdatedUser && updatedByUser && (
          <Avatar
            title={`Last updated by ${updatedByUser.name} on ${updatedAt}`}
            alt={updatedByUser.name}
            src={updatedByUser.avatarUrl}
            className="h-6 w-6"
          />
        )}
      </AvatarGroup>
      <p className="pt-[4px] text-gray-500" data-testid="authors-text">
        <span>{byText} </span>
        {createdByUser && (
          <span className="text-indigo-700">{createdByUser?.name} </span>
        )}
        {showUpdatedUser && updatedByUser && (
          <>
            and
            <span className="text-indigo-700"> {updatedByUser?.name}</span>
          </>
        )}
      </p>
    </div>
  )
}

export default Authors
