import React from 'react'

import { Tooltip } from '@mui/material'

interface UnderlinedValueProps {
  value: string | number
  className?: string
  color?: string
  title?: string | number
}

const UnderlinedValue: React.FC<UnderlinedValueProps> = ({
  value,
  className,
  color,
  title,
}) => {
  const colorClass = color ? `border-${color}-500` : 'text-gray-500'
  return (
    <Tooltip title={title} placement={'top'} arrow>
      <div
        className={`${className} inline-block flex w-8 justify-center border-b-2 px-0.5 ${colorClass}`}
      >
        <span className={'text-sm font-medium leading-4'}>{value}</span>
      </div>
    </Tooltip>
  )
}

export default UnderlinedValue
