import React, { useState } from 'react'

import { Box } from '@mui/material'
import { DetailedRequestRecord } from 'api/src/common/baserowImporterTypes'

import { RefetchQuery } from 'src/components/BaserowImporter/BaserowImporterCell/BaserowImporterCell'
import BaserowImporterRecordCell from 'src/components/BaserowImporter/BaserowImporterRecordCell'
import BaserowImporterHeaderSection from 'src/components/BaserowImporter/BaserowImporterSectionHeader'

interface BaserowImporterPageMainProps {
  importRequests: DetailedRequestRecord[]
  refetchQuery: RefetchQuery
}

const BaserowImporterPageMain = ({
  importRequests,
  refetchQuery,
}: BaserowImporterPageMainProps) => {
  const [selectedRequest, setSelectedRequest] =
    useState<DetailedRequestRecord>(null)

  return (
    <>
      <div className={'relative flex h-full w-full flex-row'}>
        <div className="h-full w-full justify-center overflow-y-auto p-5">
          <BaserowImporterHeaderSection
            importRequests={importRequests}
            refetchQuery={refetchQuery}
            setSelectedRequest={setSelectedRequest}
            selectedRequest={selectedRequest}
          />
          <Box className={'border-2 bg-white'}>
            {[
              'SCHEMA_READY',
              'PENDING_PROCESSING',
              'PROCESSING_TABLES',
              'PROCESSING_FIELDS',
              'PROCESSING_DEPENDENCIES',
              'PROCESSING_VIEWS',
              'PROCESSING_DATA',
              'PROCESSING_COMMENTS',
              'MIGRATION_COMPLETE',
              'FAILED',
            ].includes(selectedRequest?.status) && (
              <BaserowImporterRecordCell id={selectedRequest?.id} />
            )}
          </Box>
        </div>
      </div>
    </>
  )
}

export default BaserowImporterPageMain
