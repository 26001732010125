import { useState, useEffect, FC } from 'react'

import {
  ChevronDownIcon,
  TrashIcon,
  PlusSmallIcon,
} from '@heroicons/react/24/outline'
import { XCircleIcon } from '@heroicons/react/24/solid'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material'

import DragHandle from 'src/components/DragHandle/DragHandle'
import Button from 'src/components/Library/Button/Button'

import DebouncedTextArea from '../../../DebouncedTextArea'
import {
  LearnerQuestion,
  LearnerQuestionChoiceEditedable,
} from '../EditLearnerQuestionCell'
import EditOption from '../EditOption/EditOption'

interface EditQuestionProps {
  question: LearnerQuestion
  onDeleteQuestion: () => void
  onAddOption: () => void
  onSetQuestionText: (target: any) => void
  setOptionText: (target: any, optionIndex: number) => void
  markOption: (optionIndex: number) => void
  deleteOption: (optionIndex: number) => void
  isMultipleQuestions: boolean
  dragHandleProps: any
}

const EditQuestion: FC<EditQuestionProps> = ({
  question,
  onDeleteQuestion,
  onAddOption,
  onSetQuestionText,
  setOptionText,
  markOption,
  deleteOption,
  isMultipleQuestions,
  dragHandleProps,
}) => {
  const [expand, toggleExpand] = useState(true)
  const [errors, setErrors] = useState([])

  const validateQuiz = () => {
    const hasMarkedAnAnswer = (choices = []) =>
      choices.findIndex((choice) => choice.isAnswer && choice.text)

    const errorsToDisplay = []

    if (!question?.question) {
      errorsToDisplay.push('Please specify a question')
    }
    if (
      question?.choices?.length === 0 ||
      question?.choices.findIndex(
        (choice: LearnerQuestionChoiceEditedable) => choice.text !== '',
      ) === -1
    ) {
      errorsToDisplay.push('Please specify an option')
    }
    if (hasMarkedAnAnswer(question?.choices) === -1) {
      errorsToDisplay.push(
        'Please mark at least one filled option as the answer',
      )
    }

    setErrors(errorsToDisplay)
  }

  useEffect(() => {
    validateQuiz()
  }, [question])

  return (
    <div key={question.rank} className="mb-6 flex flex-1">
      <Accordion
        expanded={expand}
        className={`w-full rounded border bg-gray-100 max-w-[744px]${
          errors.length > 0 ? 'border-red-400' : 'border-gray-200'
        }`}
      >
        <AccordionSummary
          expandIcon={
            <ChevronDownIcon
              className="h-8 w-8"
              onClick={() => toggleExpand(!expand)}
            />
          }
          className={`h-auto rounded-t bg-white p-2 ${
            errors.length === 0 || (!expand && 'rounded-b')
          }`}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            '& .MuiAccordionSummary-content': {
              margin: 0,
              padding: 0,
            },
            '&.Mui-expanded': {
              minHeight: 0,
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: '0',
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'white',
            },
            '& .MuiAccordionSummary-expandIconWrapper': {
              alignSelf: 'start',
              height: '32px',
            },
          }}
        >
          <div className="ml-px flex flex-1 justify-start">
            <div className="flex h-8">
              <div
                className="flex flex-col justify-center"
                {...dragHandleProps}
              >
                <DragHandle className="ml-1.5 mr-3" size={13} />
              </div>
              <div className="mx-0 my-auto flex h-8 w-8 flex-col justify-center rounded border bg-gray-300">
                <Typography className="flex justify-center">Q</Typography>
              </div>
            </div>
            <DebouncedTextArea
              inputProps={{
                className:
                  'flex flex-1 rounded focus:outline-none h-8 my-1 mx-2.5 ml-4',
                placeholder: 'Specify a question',
                minLength: 1,
                style: {
                  resize: 'none',
                },
                defaultValue: question?.question,
              }}
              debounceTimeout={300}
              onChange={(value) => {
                // eslint-disable-next-line no-unused-expressions -- FIXME
                value !== question?.question ? onSetQuestionText(value) : null
              }}
            />
          </div>
          {isMultipleQuestions && (
            <div className="flex h-8">
              <Button
                fullWidth={false}
                variant="text"
                className="min-w-[0px]"
                onClick={onDeleteQuestion}
              >
                <TrashIcon className="h-5 w-5 text-red-500" />
              </Button>
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails className="p-0">
          {question?.choices?.map(
            (option: LearnerQuestionChoiceEditedable, oInd) => (
              <EditOption
                key={oInd}
                isDeletable={question?.choices.length > 1}
                option={option}
                onSetOptionText={(val) => {
                  setOptionText(val, oInd)
                }}
                onMarkOption={() => {
                  markOption(oInd)
                }}
                onDeleteOption={() => {
                  deleteOption(oInd)
                }}
              />
            ),
          )}
          <div
            className={`px-10 py-4 ${
              errors.length > 0 && 'border-b border-gray-200'
            }`}
          >
            <Button
              fullWidth={false}
              onClick={onAddOption}
              className="flex"
              endIcon={<PlusSmallIcon className="my-auto h-4 w-4" />}
            >
              Add Option
            </Button>
          </div>
          {errors.map((error, index) => (
            <div
              key={index}
              className="flex w-full flex-1 flex-col rounded-b bg-red-50"
            >
              <div className="flex flex-1 px-4 py-1">
                <XCircleIcon className="mr-3 h-5 w-5 text-red-400" />
                <span className="mr-8 max-w-[646px] text-sm text-red-800">
                  {error}
                </span>
              </div>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default EditQuestion
