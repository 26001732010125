import { FC } from 'react'

import { CubeTransparentIcon } from '@heroicons/react/24/outline'

const AutomationTrackEmpty: FC = () => {
  return (
    <div
      className={'grid w-full grow place-items-center'}
      style={{
        transitionTimingFunction: 'ease-in-out',
        transitionDuration: '300ms',
      }}
    >
      <div className="flex flex-col items-center justify-center gap-4">
        <CubeTransparentIcon className="h-20 w-20 text-gray-400" />
        <p className="text-gray-400">Nothing Selected</p>
      </div>
    </div>
  )
}

export default AutomationTrackEmpty
