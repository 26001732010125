// Imports

import {
  FC,
  Dispatch,
  SetStateAction,
  useState,
  SyntheticEvent,
  useEffect,
} from 'react'

import { Box } from '@mui/material'
import Chip from '@mui/material/Chip'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import useAnalytics, { GATrackerType } from 'src/lib/hooks/useAnalytics'

// FILTER NAV TABS
// Requires a navTabs object to display tabs. Count is optional and will display an number in a chip

interface FilterNavTabsProps {
  navTabs: NavTabs[]
  /**
   * Optional and will fire a function on the tab item click.
   * returns `key` (if provided) or `title` of the tab item selected
   */
  onTabClickCallBack?: Dispatch<SetStateAction<string>> | null | undefined
  /** Selected tab index */
  selectedTab?: number
  variant?: 'scrollable' | 'standard' | 'fullWidth'
  scrollButtons?: boolean | 'auto'
  allowScrollableButtonsMobile?: boolean
  GATracker?: GATrackerType
}

export interface NavTabs {
  /** When Key is passed, this is used for the callback, instead of the `title` */
  key?: string
  title: string
  count?: number
}

const FilterNavTabs: FC<FilterNavTabsProps> = ({
  navTabs,
  onTabClickCallBack,
  // TODO: would be better to use the `key` instead of an index
  selectedTab: selectedTabIndex,
  variant = 'scrollable',
  scrollButtons = 'auto',
  allowScrollableButtonsMobile = false,
  GATracker,
}) => {
  const [tabIndex, setTabIndex] = useState<number>(selectedTabIndex || 0)

  const { trackEvent } = useAnalytics()

  useEffect(() => {
    if (selectedTabIndex !== tabIndex) setTabIndex(selectedTabIndex)
  }, [selectedTabIndex])

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)

    if (!GATracker) {
      return
    }

    const eventName = GATracker.eventName || 'change tab'
    trackEvent(GATracker.category, eventName, {
      tabName: navTabs[newValue].title,
    })
  }

  return (
    <Box>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        aria-label="Navigation Filter Tabs"
        textColor="inherit"
        variant={variant}
        scrollButtons={scrollButtons}
        allowScrollButtonsMobile={allowScrollableButtonsMobile}
        TabIndicatorProps={{ style: { backgroundColor: 'rgb(99 102 241)' } }}
      >
        {navTabs.map((tabButton, index) => (
          <Tab
            key={tabButton.key ?? index}
            value={index}
            onClick={() => {
              onTabClickCallBack(tabButton.key ?? tabButton.title)
            }}
            sx={{ flexDirection: 'row' }}
            className={`px-3 transition-all ${
              index === tabIndex ? '' : 'hover:bg-neutral-100'
            } `}
            disableRipple
            disabled={
              tabButton.count > 0 || tabButton.count === undefined
                ? false
                : true
            }
            label={
              <div
                className={`flex items-center gap-2 text-[13px] capitalize ${
                  index === tabIndex ? 'text-indigo-600' : ''
                } `}
              >
                {tabButton.title}
                {tabButton.count !== undefined && (
                  <Chip
                    data-testid={`filter-nav-tabs-${tabButton.title.replaceAll(
                      /(\s|_)/g,
                      '-',
                    )}-${tabButton.count}`}
                    className={`cursor-pointer px-0.5 text-[11px] ${
                      index === tabIndex ? 'bg-indigo-100 text-indigo-600' : ''
                    } `}
                    label={tabButton.count}
                    size="small"
                  />
                )}
              </div>
            }
            data-testid={`filter-nav-tabs-${tabButton.title}`}
          />
        ))}
      </Tabs>
    </Box>
  )
}

export default FilterNavTabs
