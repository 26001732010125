import * as React from 'react'
import { Dispatch, FC, SetStateAction, useState } from 'react'

import { LinkIcon } from '@heroicons/react/24/outline'
import { TextField, Tooltip, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'

import Button from 'src/components/Library/Button/Button'
import ReactMapShapeFrame from 'src/components/ReactMap/ReactMapShapeFrame'
import { lineShapes } from 'src/components/ReactMap/utils'
import { openWindowWithBlockCheck } from 'src/lib/helpers'
import useReactFlowStore from 'src/lib/stores/reactMapStores'

export interface ReactMapShapeProps {
  width?: number
  height?: number
  shape?: string
  shapeColor?: string
  strokeColor?: string
  strokeWidth?: string
  onDragStart?: (event: React.DragEvent<HTMLDivElement>) => void
  draggable?: boolean
  labelText?: string
  labelColor?: string
  url?: string
  highlight?: boolean
  editMode?: boolean
  setEditMode?: Dispatch<SetStateAction<boolean>>
  onDoubleClick?: () => void
  nodeId?: string
  pulseStroke?: boolean
  fontSize?: number
}

const ReactMapShape: FC<ReactMapShapeProps> = ({
  width = 100,
  height = 100,
  shape = 'circle',
  shapeColor = 'white',
  strokeColor = 'black',
  strokeWidth = '1',
  fontSize = 12,
  onDragStart = null,
  draggable = false,
  labelText = '',
  labelColor = '#000000',
  url = '',
  highlight = false,
  editMode = false,
  setEditMode = null,
  onDoubleClick = null,
  nodeId = null,
  pulseStroke = false,
}) => {
  const shapeFrame = ReactMapShapeFrame({
    type: shape,
    width: width,
    height: height,
    shapeColor: shapeColor,
    strokeColor: strokeColor,
    strokeWidth: strokeWidth,
    pulseStroke: pulseStroke,
  })

  const [labelTextValue, setLabelTextValue] = useState(labelText)
  const handleSetNodeText = useReactFlowStore(
    (state) => state.handleSetNodeText,
  ) // State of the search text

  const handleUpdateTextForNode = () => {
    handleSetNodeText(labelTextValue, nodeId)
    setEditMode(false)
  }

  return (
    <div
      className={`relative text-center ${
        highlight
          ? 'cursor-grab bg-transparent hover:bg-gray-100 hover:shadow-xl'
          : ''
      }`}
      onDoubleClick={() => {
        if (onDoubleClick) {
          onDoubleClick()
        }
      }}
      onDragStart={(event) => {
        if (onDragStart) {
          onDragStart(event)
        }
      }}
      draggable={draggable}
    >
      <svg
        className="block overflow-visible"
        width={width || 100}
        height={height || 100}
      >
        {shapeFrame}
      </svg>
      <div className="absolute left-0 top-0 flex h-full w-full content-center items-center justify-center">
        <div className="self-center font-mono text-xs text-black">
          <Stack spacing={1} className="justify-center">
            {url && (
              <Tooltip title={'Open Link'}>
                <div>
                  <Button
                    fullWidth={false}
                    variant="contained"
                    color={'inherit'}
                    className={'min-w-[0] rounded-full p-1 text-black'}
                    onClick={() => {
                      if (url) {
                        const isAbsolute =
                          url.startsWith('http://') ||
                          url.startsWith('https://')
                        const fixedUrl = isAbsolute ? url : `http://${url}`
                        openWindowWithBlockCheck(fixedUrl, '_blank')
                      }
                    }}
                  >
                    <LinkIcon className="h-3 w-3" />
                  </Button>
                </div>
              </Tooltip>
            )}
            {!lineShapes.includes(shape) && (
              <div style={{ alignSelf: 'center' }}>
                {!editMode ? (
                  <Typography
                    className={`px-${strokeWidth ? strokeWidth : 2}`}
                    sx={{
                      color: labelColor ? labelColor : '#000000',
                      whiteSpace: 'pre-wrap',
                      fontSize: `${fontSize}px`,
                    }}
                  >
                    {labelText.split('\n').map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line}
                        {index !== array.length - 1 && <br />}{' '}
                      </React.Fragment>
                    ))}
                  </Typography>
                ) : (
                  <TextField
                    value={labelTextValue}
                    multiline={true}
                    onChange={(e) => setLabelTextValue(e.target.value)}
                    size="small"
                    InputProps={{
                      style: { fontSize: `${fontSize}px` }, // Adjust this value as needed
                    }}
                    onBlur={() => handleUpdateTextForNode()}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault()
                        handleUpdateTextForNode()
                      } else if (e.key === 'Escape') {
                        setEditMode(false)
                      }
                    }}
                  ></TextField>
                )}
              </div>
            )}
          </Stack>
        </div>
      </div>
    </div>
  )
}

export default ReactMapShape
