import type { FC } from 'react'

import {
  AncillaryLeadsCellQuery,
  AncillaryLeadsCellQueryVariables,
} from 'types/graphql'

import { type CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import AncilTable from 'src/components/Ancil/AncilTable'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const QUERY = gql`
  query AncillaryLeadsCellQuery {
    ancillaryLeads {
      id
      leadStatus
      leadCompany
      triggerType
      triggeredByUser {
        id
        name
      }
      decryptedName
      propertyAddress
      createdAt
      triggeredAt
      processedAt
      extPrimaryAgentName
      extSecondaryAgentName
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => (
  <div>
    <h1>Empty</h1>
  </div>
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

type SuccessProps = CellSuccessProps<
  AncillaryLeadsCellQuery,
  AncillaryLeadsCellQueryVariables
>

export type AncillaryLead = SuccessProps['ancillaryLeads'][0]

export const Success: FC<SuccessProps> = ({ ancillaryLeads }) => {
  return <AncilTable ancillaryLeads={ancillaryLeads} />
}
