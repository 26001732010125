import { FC } from 'react'

// import { LearnerCategory } from 'types/graphql'

import { Avatar, LinearProgress } from '@mui/material'

import { getStatusColour } from '../helpers'
import { Membership } from '../interfaces'
import { CourseAttemptType, RelatedTracking } from '../types'

interface GridItemRecordProps {
  attempt: CourseAttemptType
  memberships: Membership[]
}

const GridItemRecord: FC<GridItemRecordProps> = ({ attempt, memberships }) => {
  const membership = memberships.find(
    (membership) =>
      membership.id ===
      (attempt?.relatedTracking as RelatedTracking)?.membershipId,
  )
  return (
    <div className="flex items-center justify-between border-b border-gray-300 p-4 last:border-0">
      <div className="flex items-center gap-2">
        <Avatar
          alt={membership.name}
          sx={{ width: 24, height: 24 }}
          src={membership.avatarUrl}
          className="bg-gray-300 text-base text-gray-600"
        />
        <span>{membership.name}</span>
      </div>
      <div className="flex items-center gap-4">
        <LinearProgress
          variant="determinate"
          value={attempt.percentage}
          color="inherit"
          className={`${getStatusColour(attempt.percentage)} h-2 w-40 rounded`}
          data-testid={`course-reporting-progress-${Math.round(
            attempt.percentage,
          )}`}
        />
        <span className="w-20 text-right text-sm">
          {Math.round(attempt.percentage * 10) / 10}%
        </span>
      </div>
    </div>
  )
}

export default GridItemRecord
