import { FC, useState } from 'react'

import { LearnerCategory, LearnerCourse, Membership } from 'types/graphql'

import UserCourseSummaryCell from 'src/components/Learner/Courses/CourseSummary/UserCourseSummaryCell'
import SearchGroups from 'src/components/Learner/Library/SearchGroups/SearchGroups'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'src/Providers'

import FilterNavTabs from '../../../FilterNavTabs/FilterNavTabs'

import { ActivityFeed } from './ActivityFeed/ActivityFeed'
import Dashboard from './Dashboard/LearnerCourseSummaryDashboard'
import EngagementGraph from './EngagementGraph/EngagementGraph'
import { IndividualProgress } from './IndividualProgress/IndividualProgress'
import { SummarySelector } from './SummarySelector/SummarySelector'

interface CourseSummaryProps {
  learnerCategories: LearnerCategory[]
  currentCourse?: LearnerCourse
  memberships: Membership[]
}

// Nav Tab Items
const navTabItems = [
  { title: 'Activity Feed' },
  { title: 'Individual Progress' },
]

const CourseSummary: FC<CourseSummaryProps> = ({
  learnerCategories,
  currentCourse,
  memberships,
}) => {
  const [searchVal, setSearchVal] = useState('')
  const { currentUser } = useAuth()
  const clientId = currentUser.membershipData.clientId

  const { trackEvent } = useAnalytics()
  const [learnerSummaryModalOpen, setLearnerSummaryModalOpen] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState<number>(null)

  //set the selected intial tab to the selected one
  const [tabSelected, setTabSelected] = useState(
    navTabItems.findIndex((tabItem) => 'Activity Feed' === tabItem.title),
  )

  const currentCategory = learnerCategories.find((learnerCategory) =>
    learnerCategory.learnerCourses
      .map(({ id }) => id)
      .includes(currentCourse?.id),
  )

  //update current tab if tab is clicked
  const setTabSelectedCallBack = (tabTitle) => {
    setTabSelected(
      navTabItems.findIndex((tabItem) => tabTitle === tabItem.title),
    )
    trackEvent('Course Reporting', 'side bar tab select', {
      selectedTab: tabTitle,
    })
  }

  return (
    <>
      {!currentCourse || !currentCategory ? (
        <div className="grid h-screen w-full grid-cols-1 place-items-center">
          <p className="text-lg text-gray-400">No Learner Course Data Found</p>
        </div>
      ) : (
        <>
          <div className="mb-14 grid min-h-[calc(100vh-77px)] grid-cols-none bg-white md:mb-0 xl:grid-cols-[60%_40%]">
            <div className="relative flex max-h-[calc(100vh-77px)] flex-col overflow-y-scroll border-r border-gray-300">
              <SummarySelector
                learnerCategories={learnerCategories}
                currentCourse={currentCourse}
              />
              <Dashboard
                learnerCategory={currentCategory}
                learnerCourse={currentCourse}
                memberships={memberships}
              />
              <EngagementGraph
                currentCourse={currentCourse}
                memberships={memberships}
              />
            </div>
            <div>
              <div className="flex w-full items-end justify-between border-b">
                <FilterNavTabs
                  navTabs={navTabItems}
                  onTabClickCallBack={setTabSelectedCallBack}
                  selectedTab={tabSelected}
                  GATracker={{
                    category: 'Course Reporting',
                    eventName: 'change tab course reporting',
                  }}
                ></FilterNavTabs>
                <div className="py-3 pr-3">
                  <SearchGroups
                    searchVal={searchVal}
                    setSearchVal={setSearchVal}
                    specifiedMembershipGroups={[]}
                    placeholder={`${
                      navTabItems[tabSelected].title === 'Activity Feed'
                        ? 'Search by User, Task or Date'
                        : 'Search by User or Date'
                    }`}
                  />
                </div>
              </div>
              <p className="p-4 font-bold">{navTabItems[tabSelected].title}</p>

              {navTabItems[tabSelected].title === 'Activity Feed' ? (
                <ActivityFeed
                  courseId={currentCourse.id}
                  learnerCourseTrackings={currentCourse.reporting.attempts}
                  learnerTasks={currentCourse.learnerActivities.flatMap(
                    (learnerActivity) => learnerActivity.learnerTasks,
                  )}
                  memberships={memberships}
                  setLearnerSummaryModalOpen={setLearnerSummaryModalOpen}
                  searchVal={searchVal}
                  setSelectedUserId={setSelectedUserId}
                />
              ) : (
                <IndividualProgress
                  courseId={currentCourse.id}
                  learnerCourseTrackings={currentCourse.reporting.attempts}
                  memberships={memberships}
                  setLearnerSummaryModalOpen={setLearnerSummaryModalOpen}
                  searchVal={searchVal}
                  setSelectedUserId={setSelectedUserId}
                />
              )}
            </div>
          </div>
        </>
      )}
      {learnerSummaryModalOpen && selectedUserId && (
        <UserCourseSummaryCell
          id={currentCourse.id}
          clientId={clientId}
          membershipIds={[selectedUserId]}
          learnerSummaryModalOpen={learnerSummaryModalOpen}
          setLearnerSummaryModalOpen={setLearnerSummaryModalOpen}
          reportingFlags={{
            includeCourseStats: false,
            includeCourseAttempts: true,
            includeTaskStats: true,
            includeTaskType: false,
            lastAttempt: false,
            includeArchived: false,
          }}
        />
      )}
    </>
  )
}

export default CourseSummary
