import { FC } from 'react'

import BullseyeIcon from 'src/components/BullseyeIcon/BullseyeIcon'
import MilestoneArrow from 'src/components/MilestoneArrow/MilestoneArrow'

interface TemplatesTypeIndicatorProps {
  isMilestone: boolean
  className?: string
}
const TemplatesTypeIndicator: FC<TemplatesTypeIndicatorProps> = ({
  isMilestone = false,
  className,
}) => {
  if (isMilestone) {
    return (
      <>
        <MilestoneArrow className={`h-5 w-5 text-gray-400 ${className}`} />
        Milestone Template
      </>
    )
  }

  return (
    <>
      <BullseyeIcon className={`h-5 w-5 text-gray-400 ${className}`} />
      Goal Template
    </>
  )
}

export default TemplatesTypeIndicator
