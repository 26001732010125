import { useEffect, useState } from 'react'

import {
  ReceivedAzureTokenMutation,
  ReceivedAzureTokenMutationVariables,
} from 'types/graphql'

import { Metadata, useMutation } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

/**
 * Ensure the URI for the domain is registered in the Azure App Registration
 * https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Authentication/appId/6df454c8-aede-4ca5-bc99-bc72799336d6/isMSAApp~/false
 */
const AzureAppAuthPage = () => {
  const [saved, setSaved] = useState(false)
  const [showError, setShowError] = useState(false)

  const urlParams = new URLSearchParams(window.location.search)
  const code = urlParams.get('code')
  const clientInfo = urlParams.get('client_info')
  const state = urlParams.get('state')

  const bc = new BroadcastChannel('sentiment_analysis_azure_authorisation')

  const sendBroadcast = async (status) => {
    bc.postMessage(status)
  }

  const RECEIVED_AZURE = gql`
    mutation ReceivedAzureTokenMutation($input: AzureReceivedAppCodeInput!) {
      azureReceivedAppCode(input: $input)
    }
  `

  const [receivedAzureToken] = useMutation<
    ReceivedAzureTokenMutation,
    ReceivedAzureTokenMutationVariables
  >(RECEIVED_AZURE, {
    onCompleted: async () => {
      setSaved(true)
      await sendBroadcast('Success')
      window.close()
    },
    onError: async () => {
      setSaved(true)
      setShowError(true)
      await sendBroadcast('Failed')
      window.close()
    },
  })

  useEffect(() => {
    const saveToken = async () => {
      await receivedAzureToken({
        variables: {
          input: {
            code,
            clientInfo: clientInfo,
            state,
          },
        },
      })
    }

    if (!saved) {
      saveToken()
    }
  }, [saved])

  return (
    <>
      <Metadata title="AzureResponse" description="AzureResponse page" />

      <div className="grid min-h-screen place-items-center">
        {showError && (
          <p className="text-center">
            There was an error processing your authentication request.
            <br />
            Please close this tab and try again.
          </p>
        )}
        {!showError && <LoadingSpinner />}
      </div>
    </>
  )
}

export default AzureAppAuthPage
