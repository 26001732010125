import { FC, Fragment } from 'react'

import { BoltSlashIcon } from '@heroicons/react/24/outline'
import { FlagIcon } from '@heroicons/react/24/solid'
import { Step, StepContent, StepLabel } from '@mui/material'
import Stack from '@mui/material/Stack'

import Button from 'src/components/Library/Button/Button'

import { CellAutomation } from '../AutomationCell'
import AutomationEmptyDisplay from '../AutomationEmptyDisplay'
import { SettingsSidebarEditState } from '../AutomationLayout'
import { AutomationTriggerType } from '../utils'

import AutomationTrackConditional from './AutomationTrackConditional'
import AutomationTrackTrigger, {
  AutomationTriggerType as AutomationTrigger,
} from './AutomationTrackTrigger'
import { ValueChip, ValueChipTheme } from './AutomationTrackValueChip'

interface AutomationTrackTriggerStepProps {
  automation: CellAutomation
  handleOpenEdit: (editMode: SettingsSidebarEditState) => void
}

const AutomationTrackTriggerStep: FC<AutomationTrackTriggerStepProps> = ({
  automation,
  handleOpenEdit,
}) => {
  // Define the triggers in an array
  const automationTriggers = [
    { label: AutomationTrigger.CREATE, active: automation.onCreateTrigger },
    { label: AutomationTrigger.UPDATE, active: automation.onUpdateTrigger },
  ]

  // Get the active ones to loop through
  const activeAutomationTriggers = automationTriggers.filter(
    (trigger) => trigger.active,
  )

  const automationHasTriggers =
    activeAutomationTriggers?.length > 0 ||
    automation?.matchCriteria?.['FIELD']?.fieldsToWatch?.length > 0

  return (
    <Step active expanded className="">
      <StepLabel
        className="px-0"
        icon={<FlagIcon className="ml-1 h-5 w-5 text-gray-400" />}
      >
        <p className="ml-1">
          <span className="px-3 py-1 text-xl text-gray-500">Triggers</span>
        </p>
      </StepLabel>
      <StepContent className="pb-6">
        <div className="pl-4">
          <Stack
            direction="row"
            spacing={2}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <p className="pb-2 pt-2 font-light italic text-gray-500">
              Run the automation..
            </p>
            {automation.automationTimeZone && (
              <ValueChip
                value={`Timezone: ${automation.automationTimeZone}`}
                theme={ValueChipTheme.ORANGE}
                size={'small'}
              />
            )}
          </Stack>
          {automationHasTriggers ? (
            <div className="pt-2">
              <div>
                <AutomationTrackTrigger>
                  <span className="flex items-center font-light">
                    <span className="mr-2">When a</span>
                    <ValueChip
                      value={automation?.triggerType}
                      theme={ValueChipTheme.BLUE}
                    />
                    <span className="mr-2">is</span>
                    {activeAutomationTriggers.map((trigger, index) => (
                      <Fragment key={index}>
                        <ValueChip
                          key={index}
                          value={trigger.label + 'd'}
                          theme={ValueChipTheme.GREEN}
                        />
                        {index + 1 !== activeAutomationTriggers.length && (
                          <span className="mr-2">or</span>
                        )}
                      </Fragment>
                    ))}
                  </span>
                </AutomationTrackTrigger>
              </div>
              {automation?.triggerType === AutomationTriggerType.FIELD && (
                <>
                  <AutomationTrackConditional text={'AND'} />
                  <AutomationTrackTrigger>
                    <span className="flex flex-wrap items-center font-light">
                      <span className="mr-2">When a value in field</span>
                      {automation.matchCriteria['FIELD']?.fieldsToWatch?.map(
                        (field) => (
                          <ValueChip
                            key={field?.name}
                            value={field?.name}
                            theme={ValueChipTheme.BLUE}
                          />
                        ),
                      )}
                      <span className="mr-0.5">changes&nbsp;</span>
                      {automation.matchCriteria['FIELD']?.restrictToView
                        ?.label && (
                        <>
                          <span className="mr-2">in view</span>
                          <ValueChip
                            value={
                              automation.matchCriteria['FIELD']?.restrictToView
                                ?.label
                            }
                            theme={ValueChipTheme.VIOLET}
                          />
                        </>
                      )}
                    </span>
                  </AutomationTrackTrigger>
                </>
              )}
            </div>
          ) : (
            <AutomationEmptyDisplay
              title="No Triggers Set"
              subtitle="This automation will not run without a trigger."
              icon={<BoltSlashIcon className="mx-auto h-16 w-16 pb-4" />}
            />
          )}
          <Button
            disabled={automation?.automationCategoryId === null}
            variant="outlined"
            className="mt-3 min-w-[0] !border-dashed"
            onClick={(event) => {
              event.stopPropagation()
              handleOpenEdit(SettingsSidebarEditState.TRIGGER)
            }}
            buttonDataTestId="edit-triggers-button"
          >
            Edit Triggers
          </Button>
        </div>
      </StepContent>
    </Step>
  )
}

export default AutomationTrackTriggerStep
