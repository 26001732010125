import { FC } from 'react'

import {
  ChevronRightIcon,
  CircleStackIcon,
  DocumentTextIcon,
  FolderIcon,
  LinkIcon,
  NewspaperIcon,
} from '@heroicons/react/24/outline'
import { Chip } from '@mui/material'
import dayjs from 'dayjs'

import { navigate, routes } from '@redwoodjs/router'

import { KB_ITEM_TYPE } from 'src/components/KnowledgeCell'
import { KBItemSorted } from 'src/components/KnowledgeCell/types'
import Button from 'src/components/Library/Button/Button'
import useAnalytics from 'src/lib/hooks/useAnalytics'

export const KbItemIcon = (kbItem: KBItemSorted) => {
  const iconClasses = 'w-5 h-5 text-gray-600 group-hover:!text-indigo-500'

  switch (kbItem.type) {
    case KB_ITEM_TYPE.ARTICLE:
      return <NewspaperIcon className={iconClasses} />
    case KB_ITEM_TYPE.STORAGE_OBJECT:
      return <CircleStackIcon className={iconClasses} />
    case KB_ITEM_TYPE.URL:
      return <LinkIcon className={iconClasses} />
    case KB_ITEM_TYPE.DOCUMENT:
      return <DocumentTextIcon className={iconClasses} />
    case KB_ITEM_TYPE.CATEGORY:
      return <FolderIcon className={iconClasses} />
  }
}

interface KnowledgeBaseArticleLinkProps {
  kbItem: KBItemSorted
  index: number
  showNewBadge?: boolean
  linkType: string
}

const KnowledgeBaseArticleLink: FC<KnowledgeBaseArticleLinkProps> = ({
  kbItem,
  index,
  showNewBadge,
  linkType,
}) => {
  // Check if KbItem is "new" - created within the last month
  const createdDate = dayjs(kbItem.createdAt)
  const oneMonthAgo = dayjs().subtract(1, 'month')
  const createdWithinAMonthAgo = createdDate.isAfter(oneMonthAgo)

  const { trackEvent } = useAnalytics()

  return (
    <Button
      variant="text"
      className="group w-full justify-start !border !border-solid !border-gray-300 bg-white px-6 py-4 text-sm hover:bg-gray-50"
      onClick={() => {
        navigate(routes.knowledgeWithId({ id: kbItem.id }))
        trackEvent('Knowledge Base', linkType + ' Click', {
          // listRank being the order of the item in the list
          listRank: index + 1,
        })
      }}
      startIcon={KbItemIcon(kbItem)}
    >
      <div className="flex w-full items-center justify-between gap-4">
        <div className="flex items-center gap-3">
          <div
            className="font-sm ml-2 line-clamp-1 text-left normal-case !tracking-normal text-gray-600 group-hover:!text-indigo-500"
            data-testid={`kb-latest-content-${kbItem.type}-${kbItem.id}`}
          >
            {kbItem.title}
          </div>
          {createdWithinAMonthAgo && showNewBadge && (
            <Chip
              size="small"
              className="bg-emerald-600 pt-0.5 text-emerald-50"
              label="New"
            />
          )}
        </div>

        <p className="flex items-center justify-between gap-1 text-gray-600 group-hover:!text-indigo-500">
          <ChevronRightIcon className="h-4 w-4" />
        </p>
      </div>
    </Button>
  )
}

export default KnowledgeBaseArticleLink
