import { FC } from 'react'

import TextField from '@mui/material/TextField'
import { LearnerCategory } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import useAnalytics from 'src/lib/hooks/useAnalytics'
interface SummarySelectorProps {
  learnerCategories: LearnerCategory[]
  currentCourse: {
    id: number
    name?: string
  }
}

export const SummarySelector: FC<SummarySelectorProps> = ({
  learnerCategories,
  currentCourse,
}) => {
  // Select Options
  const selectOptions = []
  for (const category of learnerCategories) {
    for (const course of category.learnerCourses) {
      selectOptions.push({
        id: course.id,
        name: course.name,
        category: category.name,
      })
    }
  }

  const handleChange = (selectedOption) => {
    navigate(routes.learnerCourseSummary({ id: selectedOption.id }))
    trackEvent('Course Reporting', 'course search', {
      courseName: selectedOption.name,
    })
  }

  const { trackEvent } = useAnalytics()

  return (
    <div className="bg-white p-6">
      <Autocomplete
        id="tags-outlined"
        className="w-full max-w-[400px] rounded bg-white"
        size="small"
        options={selectOptions}
        groupBy={(option) => option.category}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        disableClearable
        renderInput={(params) => <TextField {...params} placeholder="Search" />}
        value={{ id: currentCourse.id, name: currentCourse.name }}
        onChange={(_, newValue) => {
          handleChange(newValue)
        }}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id
        }}
      />
    </div>
  )
}
