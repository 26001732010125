import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { Metadata } from '@redwoodjs/web'

import TheHubIcon from 'src/lib/icons/TheHubIcon/TheHubIcon'
import privacyPolicyText from 'src/lib/privacyPolicyText'

const PrivacyPolicyPage = () => {
  return (
    <>
      <Metadata title="Privacy Policy" description="Privacy Policy" />
      <div
        style={{
          // eslint-disable-next-line quotes
          backgroundImage: "url('/default-gradient.png')",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div
          className={'relative flex h-screen flex-col place-items-center p-8'}
        >
          <div className={'h-12 w-60'}>
            <TheHubIcon variant="white" />
          </div>
          <div className="h-3/4 w-[800px] rounded-lg bg-transparent px-12 pt-12 text-center">
            <h2 className={'text-3xl font-extrabold text-white'}>
              Privacy Policy
            </h2>
            <br />
            <div
              className={
                'border-1 h-full overflow-y-auto rounded-lg border border-white bg-[#038EFD] p-4'
              }
            >
              <ReactMarkdown
                className={
                  'prose prose-sm max-w-none text-left text-white prose-headings:text-white prose-strong:text-white'
                }
                remarkPlugins={[remarkGfm]}
              >
                {privacyPolicyText}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicyPage
