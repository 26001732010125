import React from 'react'

import { Chip } from '@mui/material'

interface Props {
  chip?: boolean
  label?: string
  className?: string
  size?: string
}

const Divider: React.FC<Props> = ({ chip = false, label, className, size }) => {
  return (
    <div className={`${className || 'px-4'} flex w-full items-center`}>
      {chip ? (
        <Chip
          className={'border-gray-400 uppercase text-gray-400'}
          variant={'outlined'}
          label={label}
        />
      ) : (
        <h1 className={'mr-1 uppercase text-gray-400'}>{label}</h1>
      )}
      <div className={` ${size || 'h-[2px]'} w-full bg-gray-300`}></div>
    </div>
  )
}

export default Divider
