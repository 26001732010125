import { FC, useEffect, useState } from 'react'

import { CheckmarkIcon, ErrorIcon } from '@redwoodjs/web/toast'

import { useAuth } from 'src/Providers'

import Button from '../Library/Button/Button'

import { IntegrationLogo } from './IntegrationCard'
import { IntegrationConfig, IntegrationType } from './integrations'

interface CustomerIntegrationConnectionProps {
  connectingToIntegration: IntegrationConfig | null
  integrationType: IntegrationType
  connectionStopped: boolean
  connectionSuccessful: boolean
  retryAction: () => void
  saveAction: () => void
  closeAction: () => void
}

const CustomerIntegrationConnection: FC<CustomerIntegrationConnectionProps> = ({
  connectingToIntegration,
  integrationType,
  connectionStopped,
  connectionSuccessful,
  retryAction,
  saveAction,
  closeAction,
}) => {
  const { currentUser } = useAuth()

  const clientLogomark = currentUser?.parentData?.logomarkUrl
  const clientName = currentUser?.parentData?.name

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const retry = () => {
    setIsSaving(true)
    retryAction()
  }

  const save = () => {
    setIsSaving(true)
    saveAction()
  }

  const close = () => {
    closeAction()
  }

  useEffect(() => {
    if (connectionStopped) {
      setIsSaving(false)
    }
  }, [connectionStopped])

  return (
    <div className="relative flex flex-col gap-20 overflow-hidden p-20 text-center">
      <div className="relative flex items-center justify-between">
        <div
          className={`animated-dotted-line ${
            connectionStopped ? 'stop-animation' : ''
          }`}
        ></div>
        <div className="z-[2] border-8 border-white">
          <div
            className="h-16 w-16 rounded-lg bg-gray-200 bg-cover bg-center shadow-md"
            style={{ backgroundImage: 'url(' + clientLogomark + ')' }}
          ></div>
        </div>
        <div className="z-[2] border-8 border-white">
          <IntegrationLogo integrationType={integrationType} />
        </div>
      </div>
      <div className="flex flex-col">
        <div>
          {connectionStopped && !connectionSuccessful && (
            <p className="text-lg text-gray-500">
              <span className="mb-2 grid place-items-center">
                {connectionStopped && <ErrorIcon />}
              </span>
              Unable to connect to&nbsp;
              <span className="font-bold text-gray-700">
                {connectingToIntegration?.integrationName}
              </span>
              <span className="block pt-1 text-sm text-gray-400">
                Please check your credentials and try again, or contact support
                for assistance.
              </span>
            </p>
          )}
          {!connectionStopped && !connectionSuccessful && (
            <p className="text-lg text-gray-500">
              <span className="mb-2 h-[20px]"></span>
              Connecting{' '}
              <span className="font-bold text-gray-700">{clientName}</span>
              &nbsp;to&nbsp;
              <span className="font-bold text-gray-700">
                {connectingToIntegration?.integrationName}
              </span>
            </p>
          )}
          {connectionSuccessful && (
            <p className="text-lg text-gray-500">
              <span className="mb-2 grid place-items-center">
                {connectionSuccessful && <CheckmarkIcon />}
              </span>
              Connected to&nbsp;
              <span className="font-bold text-gray-700">
                {connectingToIntegration?.integrationName}
              </span>
              <span className="block pt-1 text-sm text-gray-400">
                We are setting up your integration.
              </span>
            </p>
          )}
          {connectionStopped && !connectionSuccessful && (
            <div className="flex flex-col gap-3 pt-10">
              <Button disabled={isSaving} onClick={() => retry()}>
                Retry Connection Test
              </Button>
              <Button
                disabled={isSaving}
                variant="outlined"
                onClick={() => close()}
              >
                Change Credentials
              </Button>
              <Button
                loading={isSaving}
                disabled={isSaving}
                variant="text"
                onClick={() => save()}
                className="mt-6"
              >
                Continue and Save integration..
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomerIntegrationConnection
