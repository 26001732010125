import React from 'react'

import { InboxArrowDownIcon } from '@heroicons/react/24/solid'
import { Chip, Avatar, Card, CardHeader, Stack, Tooltip } from '@mui/material'

import type { CellFailureProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import Modal from 'src/components/Modal/Modal'
import NpsSubjectCard from 'src/components/Nps/NpsSubjectCard/NpsSubjectCard'
import CrownIcon from 'src/lib/icons/CrownIcon/CrownIcon'
import { convertNumberToShortForm } from 'src/Util'

export const QUERY = gql`
  query FindNpsLeaderboardQuery($assessorIds: [Int!], $campaignIds: [Int!]) {
    leaderboard: npsLeaderboard(
      assessorIds: $assessorIds
      campaignIds: $campaignIds
    ) {
      subject {
        id
        name
        membership {
          id
          user {
            id
            name
            avatarUrl
          }
        }
      }
      score
      promoterCount
      detractorCount
      neutralCount
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({ leaderboard, showLeaderboard }) => {
  const filteredLeaderboard = leaderboard.filter(
    (item) => item.subject?.membership?.user?.name !== undefined,
  )

  return (
    <>
      <Stack spacing={-1}>
        {filteredLeaderboard.slice(0, 3).map((item, index) => (
          <NpsSubjectCard result={item} index={index} key={item.subject.id} />
        ))}
      </Stack>
      <Modal
        onClose={() => {
          showLeaderboard.setFalse()
        }}
        title={'Leaderboard'}
        open={showLeaderboard.value}
        className={'!w-[500px]'}
        dialogClassName={'h-full'}
        childClassName={'!bg-gray-100 h-[calc(100%-63px)] overflow-y-auto'}
      >
        <div>
          <Stack spacing={1}>
            {/*TEMP HIDDEN TILL SIZING FIX FOUND*/}
            <Stack
              className={'my-12 h-56'}
              direction={'row'}
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <div className={'relative'}>
                <Avatar
                  className={'ring-4 ring-zinc-500'}
                  sx={{ width: '8rem', height: '8rem' }}
                  src={
                    filteredLeaderboard[1]?.subject?.membership?.user?.avatarUrl
                  }
                />
                <Avatar
                  className={
                    'w-50 h-50 absolute -bottom-5 left-[30px] bg-white text-gray-800 drop-shadow'
                  }
                >
                  2
                </Avatar>
              </div>
              <div className={'relative'}>
                <Avatar
                  sx={{ width: '10rem', height: '10rem' }}
                  className={'ring-4 ring-amber-400'}
                  src={
                    filteredLeaderboard[0]?.subject?.membership?.user?.avatarUrl
                  }
                />
                <Avatar
                  className={
                    'w-50 h-50 absolute -bottom-5 left-[55px] bg-white text-gray-800 drop-shadow'
                  }
                >
                  1
                </Avatar>
                <Avatar
                  className={
                    'absolute -top-10 left-[45px] h-16 w-16 bg-transparent text-gray-800'
                  }
                >
                  <CrownIcon className={'h-[125px] w-[125px]'} />
                </Avatar>
              </div>
              <div className={'relative'}>
                <Avatar
                  className={'ring-4 ring-amber-700'}
                  sx={{ width: '8rem', height: '8rem' }}
                  src={
                    filteredLeaderboard[2]?.subject?.membership?.user?.avatarUrl
                  }
                />
                <Avatar
                  className={
                    'w-50 h-50 absolute -bottom-5 left-[30px] bg-white text-gray-800 drop-shadow'
                  }
                >
                  3
                </Avatar>
              </div>
            </Stack>
            <div className={'h-full overflow-y-auto p-4'}>
              <Stack spacing={2}>
                {filteredLeaderboard.map((item, index) => (
                  <Card
                    key={item.subject.id}
                    elevation={0}
                    className={`rounded-lg ${
                      index === 0 && 'ring-4 ring-amber-400'
                    } ${index === 1 && 'ring-4 ring-zinc-500'} ${
                      index === 2 && 'ring-4 ring-amber-700'
                    }`}
                  >
                    <CardHeader
                      avatar={
                        <Stack
                          direction={'row'}
                          spacing={2}
                          className="items-center"
                        >
                          <span className={'align-middle'}>{index + 1}</span>
                          <Avatar
                            src={item.subject?.membership?.user?.avatarUrl}
                          />
                        </Stack>
                      }
                      title={
                        <Stack
                          direction={'row'}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {item.subject?.name ||
                            item.subject?.membership?.user?.name}
                          <Stack
                            direction={'row'}
                            spacing={3}
                            alignItems="center"
                          >
                            <Tooltip title="Responses Recieved">
                              <Chip
                                className={'!w-24'}
                                icon={
                                  <InboxArrowDownIcon className="h-5 w-5 fill-gray-700" />
                                }
                                size={'small'}
                                label={convertNumberToShortForm(
                                  item.promoterCount +
                                    item.neutralCount +
                                    item.detractorCount,
                                )}
                              />
                            </Tooltip>
                            <span
                              className={
                                'w-9 text-right text-lg font-normal leading-none text-indigo-500'
                              }
                            >
                              {item.score}
                            </span>
                          </Stack>
                        </Stack>
                      }
                    />
                  </Card>
                ))}
              </Stack>
            </div>
          </Stack>
        </div>
      </Modal>
    </>
  )
}
