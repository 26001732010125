import { useState } from 'react'

import { FormHelperText } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { PROD_CLIENT } from 'api/src/common/enums'
import { TableName } from 'types/graphql'
import { useBoolean } from 'usehooks-ts'

import Button from 'src/components/Library/Button/Button'
import Switch from 'src/components/Library/Switch/Switch'
import useACL from 'src/lib/hooks/ACL/useACL'
import { useAuth } from 'web/src/Providers'

const LearnerNewCategoryForm = ({ save }) => {
  const { hasRole, currentUser } = useAuth()
  const [itemIsGlobal, setItemIsGlobal] = useState(false)
  const { AccessControlList, saveACL } = useACL({
    resourceType: 'LearnerCategory' as TableName,
    principalTypes: hasRole(['SUPERADMIN'])
      ? ['MEMBERSHIP', 'MEMBERSHIPGROUP', 'CLIENT']
      : ['MEMBERSHIP', 'MEMBERSHIPGROUP'],
  })

  const canSetGlobal =
    currentUser.roles.includes('SUPERADMIN') &&
    currentUser.parentData.id === PROD_CLIENT.STAFFLINK

  const displayError = useBoolean(false)
  const [name, setName] = useState('')
  const handleSave = async () => {
    if (!displayError.value && name !== '') {
      // save
      const category = await save({ name: name, isGlobal: itemIsGlobal })
      //return id here before using
      await saveACL({ resourceId: category.id })
    } else {
      displayError.setTrue()
    }
  }

  return (
    <div className="flex h-full w-full flex-col p-6">
      <Stack spacing={2}>
        <FormControl error={displayError.value} variant="standard">
          <TextField
            id="component-error"
            className="mb-2"
            data-testid="add-new-category-input"
            label="Category Name"
            fullWidth
            error={displayError.value}
            onChange={(event) => {
              if (event.target.value !== '') {
                displayError.setFalse()
                setName(event.target.value)
              } else {
                displayError.setTrue()
                setName(event.target.value)
              }
            }}
          />
          {canSetGlobal && (
            <div className="mb-2 flex">
              <span className="my-auto mr-auto flex">
                Global (Share with all clients)
              </span>
              <Switch
                data-testid="access-control-list-restrict-is-global"
                className="my-auto flex"
                checked={itemIsGlobal}
                onChange={(e) => setItemIsGlobal(e.target.checked)}
              />
            </div>
          )}
          {!itemIsGlobal && <AccessControlList />}
          {displayError.value && (
            <FormHelperText id="component-error-text">
              Please Enter The Category Name
            </FormHelperText>
          )}
        </FormControl>
        {/*<TextField error={displayError.value} id="outlined-error-helper-text" />*/}
        <Button onClick={handleSave} buttonDataTestId="add-new-category-button">
          Create
        </Button>
      </Stack>
    </div>
  )
}

export default LearnerNewCategoryForm
