import React from 'react'

import { useBoolean } from 'usehooks-ts'

import Button from 'src/components/Library/Button/Button'

interface ToggleButtonProps {
  leftStart?: boolean
  buttonProps: {
    leftButton: {
      leftText: string
      leftOnClick?: () => void
    }
    rightButton: {
      rightText: string
      rightOnClick?: () => void
    }
  }
}
const ToggleButton: React.FC<ToggleButtonProps> = ({
  leftStart = true,
  buttonProps,
}) => {
  const leftOn = useBoolean(leftStart)
  return (
    <div className="w-full items-center rounded-md bg-gray-100 p-1 transition-all duration-300 ease-in-out">
      <Button
        className={`w-1/2 text-gray-600 ${
          leftOn.value
            ? 'bg-white shadow hover:bg-white'
            : 'hover:bg-transparent'
        }`}
        fullWidth={false}
        value={'Draft'}
        size={'small'}
        variant={'text'}
        onClick={() => {
          leftOn.setTrue()
          buttonProps.leftButton.leftOnClick()
        }}
      >
        {buttonProps.leftButton.leftText}
      </Button>
      <Button
        className={`w-1/2 text-gray-600 transition-all duration-300 ease-in-out ${
          !leftOn.value
            ? 'bg-white shadow hover:bg-white'
            : 'hover:bg-transparent'
        }`}
        fullWidth={false}
        value={'PUBLISHED'}
        size={'small'}
        variant={'text'}
        onClick={() => {
          leftOn.setFalse()
          buttonProps.rightButton.rightOnClick()
        }}
      >
        {buttonProps.rightButton.rightText}
      </Button>
    </div>
  )
}

export default ToggleButton
