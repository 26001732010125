import React, { useEffect, useState } from 'react'

import { Draggable } from 'react-beautiful-dnd'

import DragHandle from 'src/components/DragHandle/DragHandle'
import Button from 'src/components/Library/Button/Button'
import DraggableTask from 'src/components/Settings/LearnerCourse/EditLearnerCourse/DraggableTask/DraggableTask'
import Tag from 'src/components/Tag/Tag'
import { getDraggableItemStyle, published } from 'src/Util'

import { LearnerCourseRefetch } from '../../EditLearnerCourseCell'

interface Props {
  chapter: any
  chapterIndex: number
  selected?: any
  refetch: LearnerCourseRefetch
  onSelectedChapter?(chapter: any)
  onNewLesson(chapter: any)
  onSelectedLesson?(lesson: any, chapter: any)
  view: any
}

const DraggableChapter: React.FC<Props> = ({
  refetch,
  chapter,
  chapterIndex,
  selected,
  onSelectedChapter,
  onNewLesson,
  onSelectedLesson,
  view,
}) => {
  const [border, setBorder] = useState('border border-gray-200')

  useEffect(() => {
    if (selected) {
      if (selected.id === chapter.id && view !== 0) {
        setBorder('border-2 border-indigo-600')
      } else setBorder('border-2 border-gray-200')
    }
  }, [selected, chapter, view])

  return (
    <>
      <Draggable
        key={chapter.id}
        draggableId={chapter.id + ''}
        index={chapterIndex}
      >
        {(provided, snapshot) => (
          <>
            <div
              className={
                snapshot.isDragging
                  ? 'container mb-4 overflow-hidden rounded-md border-2 border-indigo-600 bg-gray-100'
                  : `mb-4 bg-white ${border} container overflow-hidden rounded hover:border-gray-400`
              }
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={getDraggableItemStyle(provided.draggableProps.style)}
            >
              <div>
                <div
                  className="sl-drag-default group flex items-center justify-between border-b border-gray-200 py-2 hover:bg-gray-100"
                  onKeyDown={(event) =>
                    event.key === 'Enter' && onSelectedChapter(chapter)
                  }
                  onClick={() => {
                    onSelectedChapter(chapter)
                  }}
                  tabIndex={0}
                  role="button"
                >
                  <span className="inline-flex">
                    <div {...provided.dragHandleProps}>
                      <DragHandle className="sl-drag-icon ml-3 mt-2.5" />
                    </div>
                    <h3
                      data-testid={`draggable-chapter-card-h3-${chapter.name}`}
                      className="ml-1 mt-0.5 text-sm font-medium leading-tight tracking-tight"
                    >
                      {chapter?.name}
                    </h3>
                  </span>
                  <div className="flex flex-row justify-end">
                    {published(chapter) ? (
                      <Tag color={'green'} specId="draggable-chapter-published">
                        Published
                      </Tag>
                    ) : (
                      <Tag className={'text-orange-500'} />
                    )}
                  </div>
                </div>
              </div>
              <DraggableTask
                chapter={chapter}
                onSelectedLesson={onSelectedLesson}
                refetch={refetch}
              />
              <div className="flex justify-center p-1">
                <Button
                  variant="text"
                  buttonDataTestId={`add-lesson-to-chapter-${chapter.name}`}
                  onClick={() => {
                    onNewLesson(chapter)
                  }}
                >
                  New Lesson +
                </Button>
              </div>
            </div>
          </>
        )}
      </Draggable>
    </>
  )
}

export default DraggableChapter
