import {
  AIFieldType,
  AutonumberFieldType,
  BooleanFieldType,
  CountFieldType,
  CreatedByFieldType,
  CreatedOnFieldType,
  CreatedOnLastModifiedBaseFieldType,
  DateFieldType,
  DurationFieldType,
  EmailFieldType,
  FileFieldType,
  FormulaFieldType,
  LastModifiedByFieldType,
  LastModifiedFieldType,
  LinkRowFieldType,
  LongTextFieldType,
  LookupFieldType,
  MultipleCollaboratorsFieldType,
  MultipleSelectFieldType,
  NumberFieldType,
  PasswordFieldType,
  PhoneNumberFieldType,
  RatingFieldType,
  RollupFieldType,
  SingleSelectFieldType,
  TextFieldType,
  URLFieldType,
  UUIDFieldType,
} from 'src/lib/baserow/modules/database/fieldTypes'
import {
  BooleanViewFilterType,
  ContainsNotViewFilterType,
  ContainsViewFilterType,
  ContainsWordViewFilterType,
  DateAfterDaysAgoViewFilterType,
  DateAfterOrEqualViewFilterType,
  DateAfterTodayViewFilterType,
  DateAfterViewFilterType,
  DateBeforeOrEqualViewFilterType,
  DateBeforeTodayViewFilterType,
  DateBeforeViewFilterType,
  DateCompareTodayViewFilterType,
  DateEqualViewFilterType,
  DateEqualsCurrentMonthViewFilterType,
  DateEqualsCurrentWeekViewFilterType,
  DateEqualsCurrentYearViewFilterType,
  DateEqualsDayOfMonthViewFilterType,
  DateEqualsDaysAgoViewFilterType,
  DateEqualsMonthsAgoViewFilterType,
  DateEqualsTodayViewFilterType,
  DateEqualsYearsAgoViewFilterType,
  DateIsAfterMultiStepViewFilterType,
  DateIsBeforeMultiStepViewFilterType,
  DateIsEqualMultiStepViewFilterType,
  DateIsNotEqualMultiStepViewFilterType,
  DateIsOnOrAfterMultiStepViewFilterType,
  DateIsOnOrBeforeMultiStepViewFilterType,
  DateIsWithinMultiStepViewFilterType,
  DateNotEqualViewFilterType,
  DateWithinDaysViewFilterType,
  DateWithinMonthsViewFilterType,
  DateWithinWeeksViewFilterType,
  DoesntContainWordViewFilterType,
  EmptyViewFilterType,
  EqualViewFilterType,
  FilenameContainsViewFilterType,
  FilesLowerThanViewFilterType,
  HasFileTypeViewFilterType,
  HigherThanOrEqualViewFilterType,
  HigherThanViewFilterType,
  IsEvenAndWholeViewFilterType,
  LengthIsLowerThanViewFilterType,
  LinkRowContainsFilterType,
  LinkRowHasFilterType,
  LinkRowHasNotFilterType,
  LinkRowNotContainsFilterType,
  LocalizedDateCompareViewFilterType,
  LowerThanOrEqualViewFilterType,
  LowerThanViewFilterType,
  MultipleCollaboratorsHasFilterType,
  MultipleCollaboratorsHasNotFilterType,
  MultipleSelectHasFilterType,
  MultipleSelectHasNotFilterType,
  NotEmptyViewFilterType,
  NotEqualViewFilterType,
  SingleSelectEqualViewFilterType,
  SingleSelectIsAnyOfViewFilterType,
  SingleSelectIsNoneOfViewFilterType,
  SingleSelectNotEqualViewFilterType,
  UserIsFilterType,
  UserIsNotFilterType,
} from 'src/lib/baserow/modules/database/viewFilters'

const getFilterType = (filterValue) => {
  switch (filterValue) {
    case 'user_is':
      return new UserIsFilterType()
    case 'user_is_not':
      return new UserIsNotFilterType()
    case 'single_select_is_none_of':
      return new SingleSelectIsNoneOfViewFilterType()
    case 'single_select_is_any_of':
      return new SingleSelectIsAnyOfViewFilterType()
    case 'lower_than_or_equal':
      return new LowerThanOrEqualViewFilterType()
    case 'is_even_and_whole':
      return new IsEvenAndWholeViewFilterType()
    case 'higher_than_or_equal':
      return new HigherThanOrEqualViewFilterType()
    case 'files_lower_than':
      return new FilesLowerThanViewFilterType()
    case 'date_is_within':
      return new DateIsWithinMultiStepViewFilterType()
    case 'date_is_on_or_before':
      return new DateIsOnOrBeforeMultiStepViewFilterType()
    case 'date_is_on_or_after':
      return new DateIsOnOrAfterMultiStepViewFilterType()
    case 'date_is_not':
      return new DateIsNotEqualMultiStepViewFilterType()
    case 'date_is':
      return new DateIsEqualMultiStepViewFilterType()
    case 'date_is_before':
      return new DateIsBeforeMultiStepViewFilterType()
    case 'date_is_after':
      return new DateIsAfterMultiStepViewFilterType()
    case 'date_after_days_ago':
      return new DateAfterDaysAgoViewFilterType()
    case 'date_after_or_equal':
      return new DateAfterOrEqualViewFilterType()
    case 'date_before_or_equal':
      return new DateBeforeOrEqualViewFilterType()
    case 'equal':
      return new EqualViewFilterType()
    case 'not_equal':
      return new NotEqualViewFilterType()
    case 'filename_contains':
      return new FilenameContainsViewFilterType()
    case 'has_file_type':
      return new HasFileTypeViewFilterType()
    case 'contains':
      return new ContainsViewFilterType()
    case 'contains_not':
      return new ContainsNotViewFilterType()
    case 'contains_word':
      return new ContainsWordViewFilterType()
    case 'doesnt_contain_word':
      return new DoesntContainWordViewFilterType()
    case 'length_is_lower_than':
      return new LengthIsLowerThanViewFilterType()
    case 'date_equal':
      return new DateEqualViewFilterType()
    case 'date_not_equal':
      return new DateNotEqualViewFilterType()
    case 'date_before':
      return new DateBeforeViewFilterType()
    case 'date_after':
      return new DateAfterViewFilterType()
    case 'date_compare_today':
      return new DateCompareTodayViewFilterType()
    case 'date_equals_today':
      return new DateEqualsTodayViewFilterType()
    case 'date_before_today':
      return new DateBeforeTodayViewFilterType()
    case 'date_after_today':
      return new DateAfterTodayViewFilterType()
    case 'date_equals_current_week':
      return new DateEqualsCurrentWeekViewFilterType()
    case 'date_equals_current_month':
      return new DateEqualsCurrentMonthViewFilterType()
    case 'date_equals_current_year':
      return new DateEqualsCurrentYearViewFilterType()
    case 'localized_date_compare':
      return new LocalizedDateCompareViewFilterType()
    case 'date_within_days':
      return new DateWithinDaysViewFilterType()
    case 'date_within_weeks':
      return new DateWithinWeeksViewFilterType()
    case 'date_within_months':
      return new DateWithinMonthsViewFilterType()
    case 'date_equals_days_ago':
      return new DateEqualsDaysAgoViewFilterType()
    case 'date_equals_months_ago':
      return new DateEqualsMonthsAgoViewFilterType()
    case 'date_equals_years_ago':
      return new DateEqualsYearsAgoViewFilterType()
    case 'date_equals_day_of_month':
      return new DateEqualsDayOfMonthViewFilterType()
    case 'higher_than':
      return new HigherThanViewFilterType()
    case 'lower_than':
      return new LowerThanViewFilterType()
    case 'single_select_equal':
      return new SingleSelectEqualViewFilterType()
    case 'single_select_not_equal':
      return new SingleSelectNotEqualViewFilterType()
    case 'multiple_select_has':
      return new MultipleSelectHasFilterType()
    case 'multiple_select_has_not':
      return new MultipleSelectHasNotFilterType()
    case 'multiple_collaborators_has':
      return new MultipleCollaboratorsHasFilterType()
    case 'multiple_collaborators_has_not':
      return new MultipleCollaboratorsHasNotFilterType()
    case 'boolean':
      return new BooleanViewFilterType()
    case 'link_row_has':
      return new LinkRowHasFilterType()
    case 'link_row_has_not':
      return new LinkRowHasNotFilterType()
    case 'link_row_contains':
      return new LinkRowContainsFilterType()
    case 'link_row_not_contains':
      return new LinkRowNotContainsFilterType()
    case 'empty':
      return new EmptyViewFilterType()
    case 'not_empty':
      return new NotEmptyViewFilterType()
    default:
      return null
  }
}

const getFieldType = (fieldTypeString) => {
  switch (fieldTypeString) {
    case 'text':
      return new TextFieldType()
    case 'long_text':
      return new LongTextFieldType()
    case 'link_row':
      return new LinkRowFieldType()
    case 'number':
      return new NumberFieldType()
    case 'rating':
      return new RatingFieldType()
    case 'boolean':
      return new BooleanFieldType()
    case 'date':
      return new DateFieldType()
    case 'created_on_last_modified':
      return new CreatedOnLastModifiedBaseFieldType()
    case 'last_modified':
      return new LastModifiedFieldType()
    case 'created_on':
      return new CreatedOnFieldType()
    case 'url':
      return new URLFieldType()
    case 'email':
      return new EmailFieldType()
    case 'file':
      return new FileFieldType()
    case 'single_select':
      return new SingleSelectFieldType()
    case 'multiple_select':
      return new MultipleSelectFieldType()
    case 'phone_number':
      return new PhoneNumberFieldType()
    case 'formula':
      return new FormulaFieldType()
    case 'lookup':
      return new LookupFieldType()
    case 'multiple_collaborators':
      return new MultipleCollaboratorsFieldType()
    case 'rollup':
      return new RollupFieldType()
    case 'created_by':
      return new CreatedByFieldType()
    case 'last_modified_by':
      return new LastModifiedByFieldType()
    case 'uuid':
      return new UUIDFieldType()
    case 'autonumber':
      return new AutonumberFieldType()
    case 'duration':
      return new DurationFieldType()
    case 'count':
      return new CountFieldType()
    case 'password':
      return new PasswordFieldType()
    case 'ai':
      return new AIFieldType()
    default:
      return null
  }
}

const filterRecord = (
  record,
  filterType,
  filterValue,
  filterField,
  fullField,
) => {
  function convertIfNumber(str) {
    if (!isNaN(parseFloat(str)) && isFinite(str)) {
      return parseFloat(str)
    }
    return str
  }

  let recordField = null
  for (const property in record.fields) {
    if (record.fields[property]?.id === filterField) {
      recordField = record.fields[property]
      break
    }
  }

  if (recordField.type === 'formula') {
    recordField.value = convertIfNumber(recordField.value)
    filterValue = convertIfNumber(filterValue)
  }

  const fieldType = getFieldType(recordField.type)

  const result = filterType.matches(
    recordField.value,
    filterValue,
    recordField.type === 'formula' ? fullField : recordField,
    fieldType,
  )

  return result
}

const evaluateFilters = (record, filters, filterGroups, tableFieldsData) => {
  let totalFilterCount = 0
  let totalFilterMatchCount = 0

  if (filters.length === 0) {
    totalFilterMatchCount++
  }

  const evaluateGroup = (group) => {
    let groupFilters = filters.filter((filter) => filter.group === group.id)
    let groupMatchCount = 0

    const subgroups = filterGroups.filter(
      (subgroup) => subgroup.parent_group === group.id,
    )

    for (const subgroup of subgroups) {
      const subgroupMatch = evaluateGroup(subgroup)
      if (subgroupMatch) {
        groupMatchCount++
      }
    }

    let filtersMatchCount = 0

    for (let groupFilter of groupFilters) {
      const filterType = getFilterType(groupFilter.type)

      if (filterType) {
        const tableField = tableFieldsData.find(
          (field) => field.id === groupFilter.field,
        )
        const isMatch = filterRecord(
          record,
          filterType,
          groupFilter.value,
          groupFilter.field,
          tableField,
        )
        if (isMatch) {
          filtersMatchCount++
        }
      }
    }

    if (
      (group.filter_type === 'AND' &&
        filtersMatchCount >= groupFilters.length &&
        groupMatchCount >= subgroups.length) ||
      (group.filter_type === 'OR' &&
        (filtersMatchCount > 0 || groupMatchCount > 0))
    ) {
      return true
    }

    return false
  }

  if (filterGroups) {
    for (const filterGroup of filterGroups.filter((fg) => !fg.parent_group)) {
      totalFilterCount++
      const groupMatch = evaluateGroup(filterGroup)
      if (groupMatch) {
        totalFilterMatchCount++
      }
    }
  }

  for (const filter of filters) {
    if (filter.group === null) {
      totalFilterCount++
      const filterType = getFilterType(filter.type)
      if (filterType) {
        const tableField = tableFieldsData.find(
          (field) => field.id === filter.field,
        )
        const isMatch = filterRecord(
          record,
          filterType,
          filter.value,
          filter.field,
          tableField,
        )
        if (isMatch) {
          totalFilterMatchCount++
        }
      }
    }
  }

  return { totalFilterCount, totalFilterMatchCount }
}

export const getRecordDecorationsInView = (
  records,
  viewData,
  tableFieldsData,
) => {
  const decorations = viewData.decorations || []

  for (const record of records) {
    record.decorators = {}
    record.decorators.left_border_color = []

    for (const decoration of decorations) {
      record.decorators[decoration.type] = []
      if (decoration.value_provider_type === 'single_select_color') {
        const fieldId = decoration.value_provider_conf.field_id
        const colorObjects = []
        // Get the color objects for the field
        for (const field of tableFieldsData) {
          if (field.id === fieldId) {
            for (const colorObject of field.select_options) {
              colorObjects.push(colorObject)
            }
          }
        }
        // Check if the record field value matches any of the color values
        for (const recordField of record.fields) {
          if (recordField.id === fieldId) {
            for (const colorObject of colorObjects) {
              if (recordField.value?.value === colorObject.value) {
                record.decorators[decoration.type].push({
                  color: colorObject.color,
                  id: colorObject.id,
                })
              }
            }
          }
        }
      } else {
        for (const colorObject of decoration.value_provider_conf.colors) {
          const { totalFilterCount, totalFilterMatchCount } = evaluateFilters(
            record,
            colorObject.filters,
            colorObject.filter_groups,
            tableFieldsData,
          )

          // Push to decorators if the filter conditions are met
          if (
            (colorObject.operator === 'AND' &&
              totalFilterMatchCount >= totalFilterCount) ||
            ((colorObject.operator === 'OR' ||
              colorObject.operator === null ||
              !colorObject.operator) &&
              totalFilterMatchCount > 0)
          ) {
            record.decorators[decoration.type].push({
              color: colorObject.color,
              id: colorObject.id,
            })
          }
        }
      }
    }
  }
  return records
}

export const filterRecordsInView = (records, viewData, tableFieldsData) => {
  if (viewData?.filters_disabled || !viewData) {
    return records
  }
  const filteredRecords = []
  const filters = viewData.filters
  const filterOperator = viewData.filter_type

  for (const record of records) {
    const { totalFilterCount, totalFilterMatchCount } = evaluateFilters(
      record,
      filters,
      viewData.filter_groups,
      tableFieldsData,
    )

    // Push to filteredRecords if the filter conditions are met
    if (
      (filterOperator === 'AND' && totalFilterMatchCount >= totalFilterCount) ||
      ((filterOperator === 'OR' ||
        filterOperator === null ||
        !filterOperator) &&
        totalFilterMatchCount > 0)
    ) {
      filteredRecords.push(record)
    }
  }

  return filteredRecords
}
