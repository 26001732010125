import { Dispatch, FC, SetStateAction } from 'react'

import Button from 'src/components/Library/Button/Button'
import { useAuth } from 'src/Providers'

import Empty from '../../Library/Empty/Empty'
import { EditFormStates } from '../constants'
import { GoalListCellItemType, GoalOption, ViewAsOption } from '../interfaces'

import EditObjectiveForm from './EditObjective/EditObjectiveForm'

interface SidePanelProps {
  sidebarState: EditFormStates.empty | EditFormStates.edit | EditFormStates.new
  setSidebarState: Dispatch<
    SetStateAction<
      EditFormStates.empty | EditFormStates.edit | EditFormStates.new
    >
  >
  goals?: GoalOption<GoalListCellItemType>
  refetch: () => Promise<unknown>
  selectedObjective: number | null
  setSelectedObjective: Dispatch<SetStateAction<number | null>>
  creatingMilestone?: boolean
  setCreatingMilestone?: Dispatch<SetStateAction<boolean>>
  createGoalFromTemplate?: () => void
  adminEditMode: boolean
  viewAsOption: ViewAsOption
  formFieldsChanged: boolean
  setFormFieldsChanged: Dispatch<SetStateAction<boolean>>
  saveGoal: boolean
  setSaveGoal: Dispatch<SetStateAction<boolean>>
}
const SidePanel: FC<SidePanelProps> = ({
  sidebarState,
  setSidebarState,
  goals,
  selectedObjective,
  setSelectedObjective,
  creatingMilestone,
  setCreatingMilestone,
  refetch,
  createGoalFromTemplate,
  adminEditMode,
  viewAsOption,
  formFieldsChanged,
  setFormFieldsChanged,
  saveGoal,
  setSaveGoal,
}) => {
  // Authenticate and check permission level
  const { hasRole } = useAuth()

  const isSuperAdmin = hasRole(['SUPERADMIN'])
  const isAdminAccess = hasRole(['ADMIN', 'OWNER', 'EDITOR'])

  const sidebarContainerClasses = `w-full xl:h-calc[100vh - 112px] bg-white overflow-y-auto overflow-x-hidden border-l px-0 py-0 sticky top-0 hidden justify-around xl:flex ${
    sidebarState === EditFormStates.empty ? 'flex-col ' : 'flex-row '
  }`

  return (
    <div className={sidebarContainerClasses}>
      {sidebarState === EditFormStates.empty && (
        <Empty titlePosition="top" title="Nothing Selected">
          <div className="flex flex-col gap-4 px-10">
            <Button
              id="goals-sidebar-add-new-obj-button"
              buttonDataTestId="createGoalFromSidebar"
              className="mt-6 px-6"
              onClick={() => {
                setSidebarState(EditFormStates.new)
              }}
              fullWidth={false}
            >
              <span className="pt-1">Create a new goal</span>
            </Button>
            {(isAdminAccess || isSuperAdmin) && (
              <Button
                id="goals-sidebar-add-new-template-button"
                buttonDataTestId="createGoalFromTemplate"
                className="px-6"
                onClick={() => {
                  createGoalFromTemplate()
                }}
                fullWidth={false}
                variant="outlined"
              >
                <span
                  data-testid="goals-sidebar-create-from-template"
                  className="pt-1"
                >
                  Create from a template
                </span>
              </Button>
            )}
          </div>
        </Empty>
      )}
      {sidebarState !== EditFormStates.empty && (
        <EditObjectiveForm
          goals={goals}
          sidebarState={sidebarState}
          setSidebarState={setSidebarState}
          selectedObjective={selectedObjective}
          setSelectedObjective={setSelectedObjective}
          creatingMilestone={creatingMilestone}
          setCreatingMilestone={setCreatingMilestone}
          refetch={refetch}
          adminEditMode={adminEditMode}
          viewAsOption={viewAsOption}
          formFieldsChanged={formFieldsChanged}
          setFormFieldsChanged={setFormFieldsChanged}
          saveGoal={saveGoal}
          setSaveGoal={setSaveGoal}
        />
      )}
    </div>
  )
}

export default SidePanel
