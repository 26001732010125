import { Typography } from '@mui/material'
import Confetti from 'react-confetti'

import { TaskType } from '../../types'
import ViewQuestion from '../Question/ViewQuestion'

interface QuizSummary {
  questions: TaskType['quiz']
  results: boolean[][]
  submitAnswer?: (results: boolean[][]) => void
  correct: number
  showAnswers?: boolean
}

const Summary = ({
  questions,
  results,
  submitAnswer,
  correct,
  showAnswers,
}: QuizSummary) => {
  const score = correct / questions.length
  const total = questions.length

  const GetViewHeight = (vh = 100) => {
    const oneVhInPx = window.innerHeight / 100
    return oneVhInPx * vh
  }
  const getViewWidth = (vw = 100) => {
    const oneVwInPx = window.innerWidth / 100
    return oneVwInPx * vw
  }

  return (
    <>
      {score < 1 ? (
        <div className="w-full">
          <div className="pb-4.5 text-center">
            <p className="text-sm text-gray-900">Your Score</p>
            <h1
              className={`${
                score < 0.75 ? 'text-yellow-600' : 'text-green-600'
              } text-5xl`}
            >
              {Math.round(score * 100 * 100) / 100}%
            </h1>
            <h2 className="mb-4.5 text-center text-lg text-gray-900">
              You answered {correct} out of {total} questions correctly
            </h2>
          </div>

          {questions.map((question, questionIndex) => (
            <ViewQuestion
              key={question.id}
              question={question}
              questionIndex={questionIndex}
              isExpandable={true}
              isExpanded={true}
              results={results}
              isMarking={true}
              onSubmit={submitAnswer}
              showAnswers={showAnswers}
            />
          ))}
        </div>
      ) : (
        <div className="flex w-full flex-col items-center justify-center">
          <Confetti
            width={getViewWidth()}
            height={GetViewHeight()}
            gravity={0.03}
            numberOfPieces={300}
          />
          <div className="h-48 w-48 flex-col items-center justify-center rounded-full border-8 border-green-600">
            <div className="flex h-full w-full flex-col items-center justify-center">
              <Typography
                className="text-sm font-normal leading-5 text-gray-900"
                variant="body1"
              >
                Your Score
              </Typography>
              <Typography
                variant="h6"
                component="div"
                className="text-5xl font-normal leading-none text-green-600"
              >
                100%
              </Typography>
            </div>
          </div>
          <div className="mt-16">
            <Typography
              variant="h6"
              component="div"
              className="text-2xl font-normal leading-none text-gray-900"
            >
              Congratulations you answered all questions correctly
            </Typography>
          </div>
        </div>
      )}
    </>
  )
}

export default Summary
