import { FC, useEffect, useState } from 'react'

import { AcademicCapIcon, LifebuoyIcon } from '@heroicons/react/24/outline'

import { navigate, routes } from '@redwoodjs/router'

import { openWindowWithBlockCheck } from 'src/lib/helpers'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import TheHubIcon from 'src/lib/icons/TheHubIcon/TheHubIcon'

import OnboardingActionItem from './OnboardingActionItem'
import { OnboardingProgress } from './OnboardingCell'
import OnboardingHelpItem from './OnboardingHelpItem'

interface OnboardingProps {
  onboardingProgress: OnboardingProgress
  billing: boolean
}

const Onboarding: FC<OnboardingProps> = ({ onboardingProgress }) => {
  const onboardingComplete = onboardingProgress.completed

  // Added Intercom Chat
  const chatId = process.env.FRONT_THE_HUB_CHAT_ID
  const [openChat, setOpenChat] = useState(false)

  const { trackEvent } = useAnalytics()

  useEffect(() => {
    if (chatId && openChat && window.Intercom) {
      window.Intercom('show')

      window.Intercom('onHide', function () {
        setOpenChat(false)
      })
    }
  }, [openChat, chatId])

  return (
    <div className="mx-auto max-w-6xl p-4 py-24">
      <div className="flex flex-col gap-8">
        <div className="mx-auto max-w-[300px]">
          <TheHubIcon />
        </div>
        <div>
          <h1 className="pb-3 text-center text-2xl">Quick Start Setup</h1>
          <p className="mx-auto max-w-[600px] text-center text-sm text-gray-500">
            Welcome to The Hub! Get started by customising your settings, manage
            members and groups, create home links, and connect your data with an
            integration.
          </p>
        </div>

        <div className="rounded-lg border bg-white p-3">
          <OnboardingActionItem
            title="Client Settings"
            description="Customise The Hub to match your company brand."
            buttonText="Edit Settings"
            buttonAction={() => {
              navigate(routes.onboardingClientSettings())
              trackEvent('Settings', 'onboarding - edit settings')
            }}
            completed={onboardingProgress.clientSettings}
          />
          <OnboardingActionItem
            title="Members"
            description="Invite team members to start collaborating."
            buttonText="Invite Members"
            buttonAction={() => {
              navigate(routes.onboardingMembers())
              trackEvent('Settings', 'onboarding - invite members')
            }}
            completed={onboardingProgress.memberships}
          />
          <OnboardingActionItem
            title="Groups"
            description="Create Groups to control what content members can access."
            buttonText="Create Groups"
            buttonAction={() => {
              navigate(routes.onboardingMembershipGroups())
              trackEvent('Settings', 'onboarding - create groups')
            }}
            completed={onboardingProgress.membershipGroups}
          />
          <OnboardingActionItem
            title="Home Links"
            description="Start adding Home Links for quick access to your Tech Stack."
            buttonText="Create Home Links"
            buttonAction={() => {
              navigate(routes.onboardingHomeLinks())
              trackEvent('Settings', 'onboarding - home links')
            }}
            completed={onboardingProgress.homeLinks}
          />
          <OnboardingActionItem
            title="Integrations"
            description="Connect with one of our integrations to start using more Hub features."
            buttonText="Connect an Integration"
            buttonAction={() => {
              navigate(routes.onboardingIntegrations())
              trackEvent('Settings', 'onboarding - connect integration')
            }}
            completed={onboardingProgress.integrations}
          />
          {onboardingComplete && (
            <OnboardingActionItem
              title="Setup Complete!"
              description=""
              buttonText="Continue to The Hub"
              buttonAction={() => {
                navigate(routes.home())
                trackEvent('Settings', 'onboarding - home page')
              }}
              completed={false}
              finished={true}
            />
          )}
        </div>

        <div className="flex flex-wrap gap-8">
          <OnboardingHelpItem
            onClick={() => {
              openWindowWithBlockCheck(
                'https://support.stafflink.com.au',
                '_blank',
              )
              trackEvent('Settings', 'support center')
            }}
            title="Support Center"
            description="Follow our tutorials to familiarise yourself with The Hub."
            icon={
              <AcademicCapIcon className="h-10 w-10 rounded-full bg-blue-100 p-2 text-blue-600" />
            }
          />
          <OnboardingHelpItem
            onClick={() => {
              setOpenChat(true)
              trackEvent('Settings', 'onboarding - help & support')
            }}
            title="Help & Support"
            description="Get in contact with us for assistance with The Hub."
            icon={
              <LifebuoyIcon className="h-10 w-10 rounded-full bg-orange-100 p-2 text-orange-600" />
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Onboarding
