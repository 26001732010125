export enum BaserowFieldType {
  AI = 'ai',
  AUTONUMBER = 'autonumber',
  BOOLEAN = 'boolean',
  COUNT = 'count',
  CREATED_BY = 'created_by',
  CREATED_ON = 'created_on',
  DATE = 'date',
  DURATION = 'duration',
  EMAIL = 'email',
  FILE = 'file',
  FORMULA = 'formula',
  LAST_MODIFIED = 'last_modified',
  LAST_MODIFIED_BY = 'last_modified_by',
  LINK_ROW = 'link_row',
  LONG_TEXT = 'long_text',
  LOOKUP = 'lookup',
  MULTIPLE_COLLABORATORS = 'multiple_collaborators',
  MULTIPLE_SELECT = 'multiple_select',
  NUMBER = 'number',
  PASSWORD = 'password',
  PHONE_NUMBER = 'phone_number',
  RATING = 'rating',
  ROLLUP = 'rollup',
  SINGLE_SELECT = 'single_select',
  TEXT = 'text',
  URL = 'url',
  UUID = 'uuid',
}

export enum BaserowFormulaType {
  ARRAY = 'array',
  BUTTON = 'button',
  BOOLEAN = 'boolean',
  CHAR = 'char',
  DATE = 'date',
  DATE_INTERVAL = 'date_interval',
  DURATION = 'duration',
  INVALID = 'invalid',
  LINK = 'link',
  MULTIPLE_SELECT = 'multiple_select',
  NUMBER = 'number',
  SINGLE_FILE = 'single_file',
  SINGLE_SELECT = 'single_select',
  TEXT = 'text',
  URL = 'url',
}

export enum CardType {
  HEADING = 'heading',
  COUNTER = 'counter',
  CHART_BAR = 'chartBar',
  CHART_PIE = 'chartPie',
}

export enum BucketByValues {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  DAY_OF_WEEK = 'dayOfWeek',
  MONTH_OF_YEAR = 'monthOfYear',
  QUARTER_OF_YEAR = 'quarterOfYear',
}

export enum RecordNotificationType {
  ONLY = 'only',
  ALL = 'all',
}
export enum NumberInputSeparator {
  SPACE_COMMA = 'SPACE_COMMA',
  SPACE_PERIOD = 'SPACE_PERIOD',
  COMMA_PERIOD = 'COMMA_PERIOD',
  PERIOD_COMMA = 'PERIOD_COMMA',
}
