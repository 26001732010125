import { useState } from 'react'

import { Drawer } from '@mui/material'
import { useMediaQuery } from 'usehooks-ts'

import AnnouncementPanel from 'src/components/Announcements/AnnouncementPanel'
import AnnouncementsCell from 'src/components/Announcements/AnnouncementsCell'
import PageHeader from 'src/components/PageHeader/PageHeader'

const AnnouncementLayout = () => {
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const isMobile = useMediaQuery('(max-width: 640px)')
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)

  const handleDrawerOpen = () => {
    setMobileDrawerOpen(!mobileDrawerOpen)
  }

  // if event selected and ismobile open drawer
  if (selectedEvent && isMobile && !mobileDrawerOpen) {
    setMobileDrawerOpen(true)
  }

  return (
    <div className="h-screen w-full overflow-hidden">
      <PageHeader
        title="Announcements"
        parentDataTestId="announcement-settings-page"
      />
      <div className="flex h-full w-full">
        {isMobile ? (
          <>
            <div className="flex w-full flex-col">
              <AnnouncementsCell
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                isProcessing={isProcessing}
              />
              <Drawer
                anchor={'bottom'}
                open={mobileDrawerOpen}
                onClose={handleDrawerOpen}
              >
                <AnnouncementPanel
                  event={selectedEvent}
                  setSelectedEvent={setSelectedEvent}
                  isProcessing={isProcessing}
                  setIsProcessing={setIsProcessing}
                  isMobile={isMobile}
                  handleDrawerOpen={handleDrawerOpen}
                  mobileDrawerOpen={mobileDrawerOpen}
                />
              </Drawer>
            </div>
          </>
        ) : (
          <>
            <div className="h-full w-1/2 flex-grow self-start">
              <AnnouncementsCell
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                isProcessing={isProcessing}
              />
            </div>
            <div className="w-[400px] overflow-hidden border-l-2 border-gray-100 bg-white xl:w-[600px]">
              <AnnouncementPanel
                event={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                isProcessing={isProcessing}
                setIsProcessing={setIsProcessing}
                isMobile={isMobile}
                handleDrawerOpen={handleDrawerOpen}
                mobileDrawerOpen={mobileDrawerOpen}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AnnouncementLayout
