import React, { useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import Stack from '@mui/material/Stack'
import { captureException } from '@sentry/browser'
import { BILLING_CYCLE } from 'api/src/common/enums'
import {
  GetUpgradeSubscriptionData,
  GetUpgradeSubscriptionDataVariables,
  ProductPlan,
  UpgradeSubscription,
  UpgradeSubscriptionVariables,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import EnterpriseProduct from 'src/components/Billing/EnterpriseProduct/EnterpriseProduct'
import Product from 'src/components/Billing/Product/Product'
import { default as EmptyData } from 'src/components/Library/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import Modal from 'src/components/Modal/Modal'
import {
  PREVIEW_PRORATION,
  UPGRADE_SUBSCRIPTION,
} from 'src/lib/queries/Settings/Billing/stripeSubscriptions'
import { formatCurrency } from 'src/Util'

export const QUERY = gql`
  query GetUpgradeSubscriptionData {
    products: productPlans {
      productPlans: products {
        id
        name
        description
        defaultPrice {
          id
          currency
          unitAmount
        }
        yearlyPrice {
          id
          currency
          unitAmount
        }
        metadata {
          tier
        }
        features {
          name
        }
      }
    }
    currentProducts: getCurrentSubscriptionProducts {
      id
      tier
      billingCycle
    }
    currentMaxUsers: getCurrentSubscriptionMaxUsers
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => <EmptyData title={'No Products Found'} />

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({
  products,
  currentProducts,
  currentMaxUsers,
}: CellSuccessProps<
  GetUpgradeSubscriptionData,
  GetUpgradeSubscriptionDataVariables
>) => {
  const { productPlans } = products
  const [selectedProduct, setSelectedProduct] = useState<ProductPlan>(null)
  const [prorationAmount, setProrationAmount] = useState(0)
  const [billingPeriod, setBillingPeriod] = useState<BILLING_CYCLE>(
    currentProducts[0]?.billingCycle === 'month'
      ? BILLING_CYCLE.MONTHLY
      : BILLING_CYCLE.YEARLY,
  )
  const currentProductInList = !!productPlans.find(
    (productPlan) => productPlan.id === currentProducts[0].id,
  )

  const [
    previewProration,
    {
      called: prorationCalled,
      loading: prorationLoading,
      error: prorationError,
    },
  ] = useLazyQuery(PREVIEW_PRORATION, {
    onCompleted: ({ previewProration }) => {
      setProrationAmount(previewProration / 100)
    },
    onError: (error) => {
      captureException(error)
      toast.error('unable to retrieve proration amount')
    },
  })

  const [upgradeSubscription, { called, loading, error }] = useMutation<
    UpgradeSubscription,
    UpgradeSubscriptionVariables
  >(UPGRADE_SUBSCRIPTION, {
    onCompleted: () => {},
  })

  const onConfirm = () => {
    const priceId =
      billingPeriod === 'monthly'
        ? selectedProduct.defaultPrice.id
        : selectedProduct.yearlyPrice.id

    upgradeSubscription({
      variables: {
        stripePriceId: priceId,
      },
    }).then(() => {
      navigate(routes.clientBillingConfirmation({ status: 'success' }))
    })
  }

  return (
    <>
      <Stack spacing={3} justifyContent={'center'} alignItems={'center'}>
        <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Upgrade Your Hub Subscription Plan
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Pick the best plan based on your business needs
        </p>
        <Stack spacing={1} direction={'row'}>
          {productPlans.map((productPlan: ProductPlan, index) => (
            <Product
              key={productPlan.id}
              product={productPlan}
              onClick={() => {
                setSelectedProduct(productPlan)
                previewProration({
                  variables: {
                    stripeProductPriceId:
                      billingPeriod === 'monthly'
                        ? productPlan?.defaultPrice.id
                        : productPlan?.yearlyPrice?.id,
                  },
                })
              }}
              currentProduct={currentProducts[0]}
              isEnterprise={!currentProductInList}
              billingPeriod={billingPeriod}
              index={index}
            />
          ))}
          <EnterpriseProduct isEnterprise={!currentProductInList} />
        </Stack>
      </Stack>
      <Modal
        title={'Order Summary'}
        open={selectedProduct ? true : false}
        footerVisible
        onClose={() => {
          setSelectedProduct(null)
        }}
        onCancel={() => {
          setSelectedProduct(null)
        }}
        confirmText={'Upgrade'}
        onConfirm={onConfirm}
        loading={loading}
      >
        <div className="mx-auto my-12 w-full max-w-lg">
          <div className="flow-root">
            <ul className="-my-6 divide-y divide-gray-200">
              <li className="flex space-x-6 py-6">
                <div className="flex-auto">
                  <div className="space-y-1 sm:flex sm:items-start sm:justify-between sm:space-x-6">
                    <div className="flex-auto space-y-1 text-sm font-medium">
                      <h3 className="text-gray-900">{selectedProduct?.name}</h3>
                      <p className="hidden text-gray-500 sm:block">
                        Qty: {currentMaxUsers}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
            <div className="flex justify-between">
              <dt>Subtotal</dt>
              <dd className="text-gray-900">
                {selectedProduct &&
                  formatCurrency(
                    (billingPeriod === 'monthly'
                      ? selectedProduct?.defaultPrice.unitAmount
                      : selectedProduct?.yearlyPrice?.unitAmount) / 100,
                    'en-AU',
                    'AUD',
                  )}
              </dd>
            </div>
            <div className="flex justify-between">
              <dt>Seats</dt>
              <dd className="text-gray-900"> {currentMaxUsers}</dd>
            </div>
            <div className="flex justify-between">
              <dt>GST</dt>
              <dd className="text-gray-900">not included</dd>
            </div>
            <div className="flex justify-between border-t border-gray-200 pt-6 text-gray-900">
              <dt className="text-base">Total</dt>
              <dd className="text-base">
                {selectedProduct &&
                  formatCurrency(
                    ((billingPeriod === 'monthly'
                      ? selectedProduct?.defaultPrice.unitAmount
                      : selectedProduct?.yearlyPrice?.unitAmount) /
                      100) *
                      currentMaxUsers,
                    'en-AU',
                    'AUD',
                  )}
              </dd>
            </div>
            <div className="flex justify-between border-t border-gray-200 pt-6 text-gray-900">
              <dt className="text-base">Proration Charged Today</dt>
              <dd className="text-base">
                {prorationLoading ? <LoadingSpinner /> : `$ ${prorationAmount}`}
              </dd>
            </div>
          </dl>
        </div>
      </Modal>
    </>
  )
}
