import React from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  DetailedViewRecord,
  FilterSetItem,
} from 'api/src/common/baserowImporterTypes'
import dayjs from 'dayjs'

import {
  getColourFromAirtableColour,
  splitByCapitals,
} from 'src/components/BaserowImporter/utils'

interface BaserowImporterViewInformationModalProps {
  enrichedData: DetailedViewRecord
}

const BaserowImporterModalViewDetailsFlags = ({
  enrichedData,
}: BaserowImporterViewInformationModalProps) => {
  const getListOfFieldRequirements = (filter: FilterSetItem) => {
    const filterType = filter.type
    const operator = filter.operator
    switch (operator) {
      case 'isAnyOf':
        return filter?.value?.map((option) => {
          const matchingOption = filter.options?.choices?.find(
            (choice) => choice.id === option,
          )
          return matchingOption?.name
        })
      case 'contains':
        return [filter.value]
      case '>=':
        if (filterType === 'lastModifiedTime') {
          return [dayjs(filter.value.exactDate).format('DD/MM/YYYY')]
        }
    }
  }

  return (
    <Accordion>
      <AccordionSummary
        className="rounded border border-solid border-gray-300"
        expandIcon={<ChevronDownIcon className="h-5 w-5 cursor-pointer" />}
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'column', // Display details in a column
            alignItems: 'flex-start', // Align details to the left
          },
        }}
      >
        <Typography fontWeight={'bold'}>Colour Flags</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          <Stack direction={'row'} spacing={1}>
            <Typography>Default Colour</Typography>
            <Chip
              size="small"
              className={'rounded px-2 text-sm'}
              label={enrichedData?.details?.colorConfig?.defaultColor ?? 'None'}
              sx={getColourFromAirtableColour(
                enrichedData?.details?.colorConfig?.defaultColor,
              )}
            />
          </Stack>
          {enrichedData?.details?.colorConfig?.colorDefinitions?.map(
            (colourDefinition, definitionIndex) => {
              return (
                <Stack
                  key={definitionIndex}
                  className={'rounded border p-2'}
                  spacing={2}
                >
                  <Stack direction={'row'} spacing={1}>
                    <Chip
                      size="small"
                      className="rounded px-2 text-sm"
                      sx={getColourFromAirtableColour(colourDefinition?.color)}
                    />
                  </Stack>
                  <Stack
                    key={definitionIndex}
                    className={'rounded border p-2'}
                    spacing={2}
                  >
                    <Stack direction={'row'} spacing={1}>
                      <Chip
                        size="small"
                        className="w-[20%] rounded bg-violet-100 px-2 text-sm text-violet-500"
                        label={colourDefinition?.conjunction
                          .toString()
                          .toUpperCase()}
                      />
                    </Stack>

                    {colourDefinition?.filterSet.map((filter, index) => {
                      return (
                        <Stack key={index} spacing={3}>
                          {filter.type === 'nested' ? (
                            <Stack spacing={1} className={'border p-2'}>
                              <Chip
                                size="small"
                                className="w-[20%] rounded bg-violet-100 px-2 text-sm text-violet-500"
                                label={filter?.conjunction
                                  .toString()
                                  .toUpperCase()}
                              />

                              {filter?.filterSet.map(
                                (filterSecondLevel, indexSecondLevel) => {
                                  return (
                                    <Stack key={indexSecondLevel} spacing={3}>
                                      {filterSecondLevel.type === 'nested' ? (
                                        <Stack
                                          spacing={1}
                                          className={'border p-2'}
                                        >
                                          <Chip
                                            size="small"
                                            className="w-[20%] rounded bg-violet-100 px-2 text-sm text-violet-500"
                                            label={filterSecondLevel?.conjunction
                                              .toString()
                                              .toUpperCase()}
                                          />

                                          {filterSecondLevel?.filterSet.map(
                                            (
                                              filterThirdLevel,
                                              indexThirdLevel,
                                            ) => {
                                              return (
                                                <Stack
                                                  key={indexThirdLevel}
                                                  spacing={1}
                                                >
                                                  <Stack
                                                    direction={'row'}
                                                    key={
                                                      filterThirdLevel.columnId
                                                    }
                                                    spacing={1}
                                                  >
                                                    <Typography>
                                                      Field
                                                    </Typography>

                                                    <Chip
                                                      size="small"
                                                      className="rounded bg-blue-100 px-2 text-sm text-blue-500"
                                                      label={
                                                        filterThirdLevel.name
                                                      }
                                                    />
                                                    <Typography
                                                      className={'capitalize'}
                                                    >
                                                      {splitByCapitals(
                                                        filterThirdLevel.operator,
                                                      )}
                                                    </Typography>
                                                    <Chip
                                                      size="small"
                                                      className="rounded bg-emerald-100 px-2 text-sm text-emerald-500"
                                                      label={getListOfFieldRequirements(
                                                        filterThirdLevel,
                                                      )}
                                                    />
                                                  </Stack>
                                                </Stack>
                                              )
                                            },
                                          )}
                                        </Stack>
                                      ) : (
                                        <Stack
                                          direction={'row'}
                                          key={filterSecondLevel.columnId}
                                          spacing={1}
                                        >
                                          <Typography>Field</Typography>

                                          <Chip
                                            size="small"
                                            className="rounded bg-blue-100 px-2 text-sm text-blue-500"
                                            label={filterSecondLevel.name}
                                          />
                                          <Typography className={'capitalize'}>
                                            {splitByCapitals(
                                              filterSecondLevel.operator,
                                            )}
                                          </Typography>
                                          <Chip
                                            size="small"
                                            className="rounded bg-emerald-100 px-2 text-sm text-emerald-500"
                                            label={getListOfFieldRequirements(
                                              filterSecondLevel,
                                            )}
                                          />
                                        </Stack>
                                      )}
                                    </Stack>
                                  )
                                },
                              )}
                            </Stack>
                          ) : (
                            <Stack
                              direction={'row'}
                              key={filter.columnId}
                              spacing={1}
                            >
                              <Typography>Field</Typography>

                              <Chip
                                size="small"
                                className="rounded bg-blue-100 px-2 text-sm text-blue-500"
                                label={filter.name}
                              />
                              <Typography className={'capitalize'}>
                                {splitByCapitals(filter.operator)}
                              </Typography>
                              {getListOfFieldRequirements(filter)?.map(
                                (option) => {
                                  return (
                                    <Chip
                                      key={option}
                                      size="small"
                                      className="rounded bg-emerald-100 px-2 text-sm text-emerald-500"
                                      label={option}
                                    />
                                  )
                                },
                              )}
                            </Stack>
                          )}
                        </Stack>
                      )
                    })}
                  </Stack>
                </Stack>
              )
            },
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default BaserowImporterModalViewDetailsFlags
