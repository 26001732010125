import React, { FC } from 'react'

import { TextField } from '@mui/material'

import Switch from 'src/components/Library/Switch'

import { GoalModalFilterBarProps } from '../interfaces'

export const GoalModalFilterBar: FC<GoalModalFilterBarProps> = ({
  showMilestones,
  setShowMilestones,
  searchValue,
  setSearchValue,
}) => {
  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchValue(event.target.value)
  }

  const handleMilestoneToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShowMilestones(event.target.checked)
  }

  return (
    <div className="mb-4 flex w-full items-end justify-between gap-4 border-b border-gray-200 px-6 pb-6 pt-2">
      <div className="search-text grow">
        <p className="mb-1 text-sm text-gray-500">Search Templates:</p>
        <TextField
          size="small"
          fullWidth={true}
          placeholder="e.g. Onboarding"
          value={searchValue}
          onChange={handleSearchValueChange}
        />
      </div>
      <div className="toggle-milestones flex flex-row items-center">
        <Switch checked={showMilestones} onChange={handleMilestoneToggle} />
        <p className="pb-1 text-sm">Show Milestones</p>
      </div>
    </div>
  )
}
