import React, { FC, useEffect, useState } from 'react'

import { FindSentimentAnalysisQuery, type LandlordEmail } from 'types/graphql'

import Empty from 'src/components/Library/Empty'
import { SelectionType } from 'src/components/Library/SelectWithSort/SelectWithSort'
import {
  filterEmailsByFlag,
  filterESIRecords,
  handleFilterEmailByRating,
  sortActiveESIRecords,
} from 'src/components/SentimentAnalysis/SentimentAnalysisUtils'
import useLoadingState from 'src/lib/hooks/useLoadingState'
import useLocalStorage from 'src/lib/hooks/UseLocalStorage'
import useSentimentStore from 'src/lib/stores/sentimentStore'
import { useAuth } from 'src/Providers'

import { Accordion } from '../../Library/Accordion/Accordion'
import type { LandlordWithFlaggedEmails } from '../Fragments'
import SendToHubdashConfirmDialog from '../Modals/SendToHubdashConfirmDialog'
import SentimentAnalysisGraph from '../SentimentAnalysisGraph/SentimentAnalysisGraph'
import {
  FilterByOption,
  SADateFilterOptions,
  SentimentAnalysisRatingOptions,
  SentimentAnalysisFlagOptions,
  SentimentStatusSelectOptions,
} from '../SentimentAnalysisHelper'
import SentimentAnalysisOverviewModal from '../SentimentAnalysisOverviewModal'

import SentimentAnalysisItemHeader from './SentimentAnalysisItemHeader'
import SentimentAnalysisPaginatedList from './SentimentAnalysisPaginatedList'

export interface SentimentAnalysisListProps {
  landlords: LandlordWithFlaggedEmails[]
  memberships?: FindSentimentAnalysisQuery['memberships']
  sortByValues: SelectionType
  filterByLandlord: FilterByOption[]
  filterDateRange: SADateFilterOptions
  filterESIRating: SentimentAnalysisRatingOptions
  filterByFlag: SentimentAnalysisFlagOptions
  filterByClientStatus: SentimentStatusSelectOptions
  setTotalFilteredLandlordsCount: (count: number) => void
}

const SentimentAnalysisList: FC<SentimentAnalysisListProps> = ({
  landlords,
  sortByValues,
  filterByLandlord,
  filterDateRange,
  filterESIRating,
  filterByFlag,
  filterByClientStatus,
  memberships,
  setTotalFilteredLandlordsCount,
}) => {
  const { currentUser } = useAuth()

  // Keep Accordions Open on reload/refetch
  const [expandedContactIds, setExpandedContactIds] = useLocalStorage<number[]>(
    `sentiment-expanded-contacts-${currentUser.parentData.id}-${currentUser.userData.id}`,
    [],
  )

  const handleExpand = (id: number) => {
    if (!expandedContactIds.includes(id)) {
      setExpandedContactIds([...expandedContactIds, id])
    }
  }

  const handleCollapse = (id: number) => {
    setExpandedContactIds([
      ...expandedContactIds.filter((savedId) => savedId !== id),
    ])
  }

  // Set the list of landlords to display
  const [activeSentimentList, setActiveSentimentList] = useState(
    filterESIRecords(
      landlords,
      filterByLandlord,
      sortByValues,
      filterESIRating,
      filterByFlag,
      filterByClientStatus,
      true,
    ),
  )

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false)
  const [overviewModalOpen, setOverviewModalOpen] = useState<boolean>(false)

  // local storage of bucket options
  const [selectedBucket] = useSentimentStore((state) => [state.bucketOption])

  // Set component ready to apply sort/filter
  const [isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
    if (landlords) {
      setActiveSentimentList(
        filterESIRecords(
          landlords,
          filterByLandlord,
          sortByValues,
          filterESIRating,
          filterByFlag,
          filterByClientStatus,
          true,
        ),
      )
    }
  }, [landlords])

  // Sort landlords
  useEffect(() => {
    if (isReady) {
      setActiveSentimentList(
        sortActiveESIRecords(activeSentimentList, sortByValues),
      )
    }
  }, [sortByValues])

  useEffect(() => {
    setIsReady(true)
    setTotalFilteredLandlordsCount(activeSentimentList.length)
  }, [activeSentimentList])

  const { loadingState, setLoadingStatus } = useLoadingState()

  const handleClickOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    setSelectedPropertyOwnerId(id)
    event.preventDefault()
    event.stopPropagation()
    setOpenConfirmDialog(true)
  }

  const [selectedPropertyOwnerId, setSelectedPropertyOwnerId] = useState<
    number | null
  >(null)

  return (
    <div className="w-full">
      <div className="flex flex-col gap-2">
        {activeSentimentList?.length === 0 && (
          <Empty title="No Contacts found" className="mt-10" />
        )}

        {activeSentimentList?.length > 0 &&
          activeSentimentList.map((propertyOwner, index) => {
            const filteredEmails = propertyOwner?.emails
              ?.filter((email) =>
                handleFilterEmailByRating(email, filterESIRating),
              )
              ?.filter(
                (email) =>
                  filterEmailsByFlag(filterByFlag, [
                    {
                      emails: [email],
                      id: 0,
                    },
                  ]).length > 0,
              )

            return (
              <Accordion
                expanded={expandedContactIds.includes(propertyOwner.id)}
                key={index + propertyOwner.name}
                title={
                  <SentimentAnalysisItemHeader
                    propertyOwner={propertyOwner}
                    totalEmails={filteredEmails?.length}
                    isClient
                    handleClickOpen={handleClickOpen}
                    isLoading={loadingState}
                  />
                }
                onExpand={() => {
                  handleExpand(propertyOwner.id)
                }}
                onCollapse={() => {
                  handleCollapse(propertyOwner.id)
                }}
                removeDetailsPadding={true}
                GATrackerCategory="Sentiment Analysis"
                GATrackerEventName="click insights accordion"
              >
                <div className="flex w-full grow flex-row-reverse gap-0">
                  <SentimentAnalysisGraph
                    emails={filteredEmails}
                    selectedBucket={selectedBucket}
                    filterDateRange={filterDateRange}
                    unprocessedEmailsCount={
                      propertyOwner?.unprocessedEmailsCount
                    }
                  />
                  <SentimentAnalysisPaginatedList
                    emails={filteredEmails as LandlordEmail[]}
                    memberships={memberships}
                  />
                </div>
              </Accordion>
            )
          })}
        <SendToHubdashConfirmDialog
          id={selectedPropertyOwnerId}
          openConfirmDialog={openConfirmDialog}
          setOpenConfirmDialog={setOpenConfirmDialog}
          setLoadingStatus={setLoadingStatus}
        />
        <SentimentAnalysisOverviewModal
          setOverviewModalOpen={setOverviewModalOpen}
          overviewGraphData={activeSentimentList}
          overviewModalOpen={overviewModalOpen}
          filterByLandlord={filterByLandlord}
          filterDateRange={filterDateRange}
        />
      </div>
    </div>
  )
}

export default SentimentAnalysisList
