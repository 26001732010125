import { FC } from 'react'

import { PlusCircleIcon } from '@heroicons/react/24/outline'

import { KB_ITEM_TYPE } from 'src/components/KnowledgeCell'
import { KBItemSorted } from 'src/components/KnowledgeCell/types'
import Button from 'src/components/Library/Button'
import Tag from 'src/components/Tag/Tag'

import KBStatusChip from '../KBStatusChip'

import KnowledgeBaseItemLink from './KnowledgeBaseItemLink'

type ExtendedKbItemSorted = KBItemSorted & { children: KBItemSorted[] }

interface KnowledgeBaseCategoryProps {
  selectedKbItem: KBItemSorted
  kbItems: KBItemSorted[]
  onCreateClick: (args: any) => Promise<{ id: number }>
  isLoading: { id: number | null; status: boolean }
  canEdit: boolean
}

const KnowledgeBaseCategory: FC<KnowledgeBaseCategoryProps> = ({
  selectedKbItem,
  kbItems,
  onCreateClick,
  isLoading,
  canEdit,
}) => {
  const immediateCategoryItems = kbItems
    ?.filter(
      (kbItem) =>
        kbItem?.parentId === selectedKbItem?.id &&
        kbItem?.type !== KB_ITEM_TYPE.CATEGORY,
    )
    .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))

  const immediateCategorySubcategories: ExtendedKbItemSorted[] = kbItems
    ?.filter(
      (kbItem) =>
        kbItem?.parentId === selectedKbItem?.id &&
        kbItem?.type === KB_ITEM_TYPE.CATEGORY,
    )
    ?.map((kbItem) => {
      return {
        ...kbItem,
        children: kbItems.filter((subItem) => subItem.parentId === kbItem.id),
      }
    })
    .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))

  return (
    <>
      <div className="mx-auto w-full max-w-[100ch] px-6 pb-16">
        <div className="pt-8">
          <div className="flex items-center border-b border-gray-300 pb-4 text-3xl">
            {selectedKbItem?.title}
            {canEdit && (
              <KBStatusChip status={selectedKbItem?.status} size="small" />
            )}
            {canEdit && selectedKbItem?.isGlobal && (
              <Tag color={'green'} globalThis className="ml-2 py-1">
                Global
              </Tag>
            )}
          </div>
        </div>

        {immediateCategoryItems?.length === 0 && (
          <>
            <p className="pt-6 text-sm font-light text-gray-400">
              There are no articles in this category.
            </p>
            {canEdit && (
              <Button
                className="ml-1 mt-4"
                variant="text"
                fullWidth={false}
                startIcon={<PlusCircleIcon className="h-5 w-5" />}
                onClick={() =>
                  onCreateClick({
                    parent: selectedKbItem,
                    createType: KB_ITEM_TYPE.DOCUMENT,
                  })
                }
                loading={isLoading.id === selectedKbItem.id}
                buttonDataTestId="add-new-article-1"
              >
                Add new article
              </Button>
            )}
          </>
        )}

        {immediateCategoryItems?.length > 0 && (
          <div className="grid grid-cols-1 gap-10 gap-y-3 py-6 xl:grid-cols-2">
            {immediateCategoryItems?.map((kbItem, index) => (
              <KnowledgeBaseItemLink
                key={kbItem.id}
                kbItem={kbItem}
                linkType="Category Item"
                index={index}
                canEdit={canEdit}
              />
            ))}

            {canEdit && (
              <div>
                <Button
                  className="ml-1"
                  variant="text"
                  fullWidth={false}
                  startIcon={<PlusCircleIcon className="h-5 w-5" />}
                  onClick={() =>
                    onCreateClick({
                      parent: selectedKbItem,
                      createType: KB_ITEM_TYPE.DOCUMENT,
                    })
                  }
                  loading={isLoading.id === selectedKbItem.id}
                  buttonDataTestId="add-new-article-2"
                >
                  Add new article
                </Button>
              </div>
            )}
          </div>
        )}

        {immediateCategorySubcategories?.length > 0 && (
          <div className="grid grid-cols-1 gap-10 py-6 xl:grid-cols-2">
            {immediateCategorySubcategories?.map((kbItem, index) => (
              <div key={kbItem.id}>
                <KnowledgeBaseItemLink
                  key={kbItem.id}
                  kbItem={kbItem}
                  linkType="Sub Category"
                  index={index}
                  isHeading
                  canEdit={canEdit}
                />
                {kbItem?.children?.length === 0 && (
                  <>
                    <p className="pt-2 text-sm font-light text-gray-400">
                      There are no articles in this category.
                    </p>
                    {canEdit && (
                      <Button
                        className="ml-1 mt-3"
                        variant="text"
                        fullWidth={false}
                        startIcon={<PlusCircleIcon className="h-5 w-5" />}
                        onClick={() =>
                          onCreateClick({
                            parent: kbItem,
                            createType: KB_ITEM_TYPE.DOCUMENT,
                          })
                        }
                        loading={isLoading.id === kbItem.id}
                        buttonDataTestId="add-new-article-3"
                      >
                        Add new article
                      </Button>
                    )}
                  </>
                )}
                <div className="grid grid-cols-1 gap-y-3">
                  {kbItem?.children
                    ?.sort((a, b) =>
                      a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
                    )
                    ?.map((childItem) => (
                      <KnowledgeBaseItemLink
                        key={childItem.id}
                        kbItem={childItem}
                        linkType="Sub Category Item"
                        index={index}
                        canEdit={canEdit}
                      />
                    ))}
                </div>
                {kbItem?.children?.length > 0 && canEdit && (
                  <Button
                    className="ml-1 mt-3"
                    variant="text"
                    fullWidth={false}
                    startIcon={<PlusCircleIcon className="h-5 w-5" />}
                    onClick={() =>
                      onCreateClick({
                        parent: kbItem,
                        createType: KB_ITEM_TYPE.DOCUMENT,
                      })
                    }
                    loading={isLoading.id === kbItem.id}
                  >
                    Add new article
                  </Button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default KnowledgeBaseCategory
