import { FC } from 'react'

import {
  PuzzlePieceIcon,
  VideoCameraIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline'

import { LearnerCourseWithStorageObject } from 'src/lib/interfaces'

import { CoursePreviewImage } from './CoursePreviewImage'
import { LearnerStatItem } from './StatItem'

const CoursePreview: FC<{
  learnerCourse: LearnerCourseWithStorageObject
}> = ({ learnerCourse }) => {
  return (
    <div className="flex flex-row gap-4">
      <CoursePreviewImage
        imageUrl={learnerCourse?.heroStorageObject?.downloadUrl}
        variant="small"
      />
      <div className="flex grow flex-row gap-2">
        <LearnerStatItem
          icon={<PuzzlePieceIcon className="h-5 w-5 text-gray-400" />}
          value={learnerCourse?.courseTypeCounts?.quiz}
          label="Quizzes"
        />
        <LearnerStatItem
          icon={<VideoCameraIcon className="h-5 w-5 text-gray-400" />}
          value={learnerCourse?.courseTypeCounts?.video}
          label="Videos"
        />
        <LearnerStatItem
          icon={<DocumentTextIcon className="h-5 w-5 text-gray-400" />}
          value={learnerCourse?.courseTypeCounts?.text}
          label="Text"
        />
      </div>
    </div>
  )
}

export default CoursePreview
