import React, { FC, useState } from 'react'

import Stack from '@mui/material/Stack'
import { ReactFlowProvider } from 'reactflow'

import AuthorsCell from 'src/components/AuthorsCell'
import Button from 'src/components/Library/Button/Button'
import ReactMapPreview from 'src/components/ReactMapPreview/ReactMapPreview'

import Modal from '../Modal/Modal'

interface Props {
  mapItem: any
  isPlayerView?: boolean
  isMobile?: boolean
}

const ProcessMapViewer: FC<Props> = ({ mapItem, isPlayerView, isMobile }) => {
  const [previewMap, setPreviewMap] = useState(false)

  if (isPlayerView) {
    return (
      <div className={'h-full w-full'}>
        <ReactFlowProvider>
          <ReactMapPreview mapItem={mapItem} isMobile={isMobile} />
        </ReactFlowProvider>
      </div>
    )
  }

  return (
    <>
      <Stack>
        <Stack
          direction={'row'}
          justifyItems={'center'}
          alignItems={'center'}
          spacing={3}
        >
          <p> {mapItem.name}</p>
          <AuthorsCell
            createdById={mapItem.createdBy}
            updatedById={mapItem.updatedBy}
            createdAt={mapItem.createdAt}
            updatedAt={mapItem.updatedAt}
          />
        </Stack>
        <Button
          onClick={() => setPreviewMap(true)}
          buttonDataTestId="preview-map-button"
        >
          Preview Map
        </Button>
      </Stack>
      <Modal
        open={previewMap}
        onClose={() => setPreviewMap(false)}
        dialogClassName={'h-2/3'}
        childClassName={'h-full w-full'}
        title={mapItem.name}
        closeButtonVisible={false}
      >
        <div className={'h-full w-full'}>
          <ReactFlowProvider>
            <ReactMapPreview mapItem={mapItem} isMobile={false} />
          </ReactFlowProvider>
        </div>
      </Modal>
    </>
  )
}

export default ProcessMapViewer
