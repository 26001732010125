import { useCallback, useEffect } from 'react'

import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import CircularProgress from '@mui/material/CircularProgress'
import { SUPPORTED_FILE_MIME_TYPES } from 'api/src/common/enums'

import { toast } from '@redwoodjs/web/toast'

import useUploadStorageObject from 'src/lib/hooks/StorageObject/useUploadStorageObject'
import { useAuth } from 'web/src/Providers'

const AssetLibraryToolbar = ({ refetch }) => {
  const { currentUser } = useAuth()
  const clientId = currentUser.parentData.id

  // UPLOAD FUNCTION
  const [uploadIcon, { result, error, loading: itemIconUploading }] =
    useUploadStorageObject({
      prefixes: [`${clientId}`, 'informer', 'technology'],
      fileName: 'asset-library-icon',
      isGlobal: true,
      allowedFileMimeTypes: [SUPPORTED_FILE_MIME_TYPES.IMAGE],
    })

  // UPLOAD ACTION
  const uploadIconHandler = useCallback(
    (event) => {
      if (!event.target.files || event.target.files.length === 0) {
        toast.error('No file selected')
      }

      const file: File = event.target.files[0]
      uploadIcon({ file })
    },
    [uploadIcon],
  )

  // UPLOAD SUCCESS
  useEffect(() => {
    result && refetch()
    const file = document.getElementById(
      'fileIconCustomUpload',
    ) as HTMLInputElement

    file.value = ''
  }, [result])

  // UPLOAD ERROR
  useEffect(() => {
    if (error) {
      toast.error(
        error.message + '\n \n Only SUPERADMIN in Stafflink can set isGlobal',
        {
          duration: 5000,
        },
      )
    }
  }, [error])

  return (
    <div className="border-1 mb-4 flex items-end justify-between border-b border-gray-300 pb-4">
      <h3 className="text-xl font-medium leading-6 text-gray-900">
        All Assets
      </h3>
      <label className="flex cursor-pointer items-center justify-center gap-2 rounded border border-indigo-600 bg-indigo-600 px-4 py-3 text-center text-sm font-medium text-white hover:bg-indigo-500 focus:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500">
        {itemIconUploading ? (
          <CircularProgress size={20} className="text-white" />
        ) : (
          <>
            <ArrowUpTrayIcon className="h-4 w-4" />
            Upload File
          </>
        )}
        <input
          type="file"
          id="fileIconCustomUpload"
          name="icon"
          accept="image/*, video/*"
          disabled={itemIconUploading}
          onChange={(e) => uploadIconHandler(e)}
          className="hidden"
        />
      </label>
    </div>
  )
}

export default AssetLibraryToolbar
