import { FC } from 'react'

const ToastMessage: FC<{ message?: string; description?: string }> = ({
  message = '',
  description = '',
}) => {
  return (
    <div>
      <small className="mb- block">
        <b>{message}</b>
      </small>
      <small className="w-full">{description}</small>
    </div>
  )
}

export default ToastMessage
