import { useEffect, useState } from 'react'

import { captureEvent } from '@sentry/browser'
import type { DetailedTableRecord } from 'api/src/common/baserowImporterTypes'
import useWebSocket, { ReadyState } from 'react-use-websocket'

import { toast } from '@redwoodjs/web/toast'

import {
  createRecord,
  enrichRecords,
  updateRecord,
} from 'src/components/HubDash/lib/baserow/baserowApi'
import {
  filterRecordsInView,
  getRecordDecorationsInView,
} from 'src/components/HubDash/lib/baserow/brViewFilter'
import useHubDashStore from 'src/lib/stores/hubDashStore'

const handleMessage = (params: {
  token: string
  lastMessage: { data: string }
  setValue
  records: { id: number; commentCount: number; notificationMode }[]
  activityValue
  setActivityValue
  viewData
  tableData: DetailedTableRecord
  workspaceId: number
  socketType: string
}) => {
  const {
    token,
    lastMessage,
    setValue,
    records,
    activityValue,
    setActivityValue,
    viewData,
    tableData,
    workspaceId,
    socketType,
  } = params

  const processRecords = (
    records: { id: number; commentCount: number; notificationMode: string }[],
    viewData,
    tableData,
    tableId: number,
  ) => {
    const enrichedRecords = enrichRecords({
      token,
      records,
      tableFieldsData: tableData,
      tableId: tableId,
      workspaceId: workspaceId,
    })
    let decoratedRecords = []
    if (viewData) {
      decoratedRecords = getRecordDecorationsInView(
        enrichedRecords,
        viewData,
        tableData,
      )
    } else {
      decoratedRecords = enrichedRecords
    }

    return decoratedRecords
  }

  const message = JSON.parse(lastMessage.data)
  switch (message.type) {
    case 'rows_updated': {
      const updatedRows = message.rows
      const updateData = records
        .map((row) => {
          const updatedRow = updatedRows.find((r) => r?.id === row?.id)
          if (updatedRow) {
            const processedRow = processRecords(
              [updatedRow],
              viewData,
              tableData.fields,
              tableData.id,
            )
            if (socketType === 'record') {
              return processedRow[0]
            }

            const processedRowInView = filterRecordsInView(
              processedRow,
              viewData,
              tableData.fields,
            )
            return processedRowInView[0]
          } else {
            return row
          }
        })
        .filter((row) => row !== null && row !== undefined)
      setValue(updateData)
      break
    }
    case 'rows_deleted': {
      const deletedRows = message.rows
      const deleteData = records.filter((row) => {
        const deletedRow = deletedRows.find((r) => r?.id === row?.id)
        if (deletedRow) {
          return false
        } else {
          return true
        }
      })
      setValue(deleteData)
      break
    }
    case 'rows_created': {
      if (socketType === 'card') {
        const createdRows = message.rows
        const processedRows = processRecords(
          createdRows,
          viewData,
          tableData.fields,
          tableData.id,
        )
        const processedRowsInView = filterRecordsInView(
          processedRows,
          viewData,
          tableData.fields,
        )
        const createdData = records.concat(processedRowsInView)
        setValue(createdData)
      }
      break
    }
    case 'row_comment_created':
    case 'row_comment_updated':
    case 'row_comment_deleted':
      if (socketType === 'record') {
        if (message.row_comment.table_id === tableData.id) {
          for (const record of records) {
            if (record?.id === message.row_comment.row_id) {
              setActivityValue([
                ...activityValue,
                {
                  ...message.row_comment,
                  type: 'comment',
                },
              ])
            }
          }
        }
      }
      break
    case 'row_history_updated':
      if (socketType === 'record') {
        for (const record of records) {
          if (record?.id === message.row_id) {
            setActivityValue([
              ...activityValue,
              {
                ...message.row_history_entry,
                type: 'revision',
              },
            ])
          }
        }
      }
      break
    case 'row_comments_notification_mode_updated':
      //update card and record in real time when notification mode is updated
      if (socketType === 'card' || socketType === 'record') {
        const updatedRow = []
        for (const record of records) {
          if (record?.id === message.row_id) {
            record.notificationMode =
              message.mode === 'mentions' ? 'only' : 'all'
          }
          updatedRow.push(record)
        }
        setValue(updatedRow)
      }

      break
    default:
      break
  }
}

export const useBaserowRecordSocket = ({
  tableId,
  recordId,
  recordData,
  tableData,
  viewData,
}) => {
  const token = useHubDashStore((state) => state.token)
  const [record, setRecord] = useState([recordData])
  const [activityValue, setActivityValue] = useState([])

  const socketUrl =
    `wss://${process.env.BASEROW_URL.replace('https://', '')}/ws/core/?jwt_token=` +
    token
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(socketUrl)

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]

  useEffect(() => {
    if (token && tableId && recordId) {
      sendJsonMessage({
        page: 'row',
        table_id: tableId,
        row_id: recordId,
      })
      sendJsonMessage({
        page: 'table',
        table_id: tableId,
      })
    }
  }, [token, tableId, recordId])

  useEffect(() => {
    if (lastMessage !== null) {
      try {
        handleMessage({
          token,
          lastMessage,
          setValue: setRecord,
          records: record,
          viewData,
          tableData,
          workspaceId: null,
          socketType: 'record',
          activityValue,
          setActivityValue,
        })
      } catch (error) {
        captureEvent({
          message: 'handleMessage: Unhandled error',
          extra: {
            error,
            tableId,
            recordId,
            socketType: 'record',
          },
        })
        toast.error(
          'An unexpected error occurred. Please refresh the page at your earliest convenience.',
        )
      }
    }
  }, [lastMessage])

  return {
    record: record[0],
    activityUpdates: activityValue,
    table: tableData,
    connectionStatus,
    lastMessage,
  }
}

export type CardSocket = {
  records: any[]
  table: { id: number; fields: any[] }
  view: any
  base: any
  activeRecords?: any[]
  inactiveRecords?: any[]
  connectionStatus?: string
  lastMessage?: MessageEvent<any>
  createRecord?: typeof createRecord
  updateRecordById?: typeof updateRecord
}

export const useBaserowViewSocket = (params: {
  tableData: DetailedTableRecord
  viewData
  baserowData
}) => {
  const { tableData, viewData, baserowData } = params
  const token = useHubDashStore((state) => state.token)
  const [records, setRecords] = useState([])

  useEffect(() => {
    setRecords(baserowData?.records || [])
  }, [baserowData?.records])

  const socketUrl =
    `wss://${process.env.BASEROW_URL.replace('https://', '')}/ws/core/?jwt_token=` +
    token
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl)

  useEffect(() => {
    if (token && tableData?.id) {
      sendMessage(`{"page":"table","table_id":${tableData?.id}}`)
    }
  }, [token, tableData?.id])

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]

  useEffect(() => {
    if (lastMessage !== null) {
      try {
        handleMessage({
          token,
          lastMessage,
          setValue: setRecords,
          records,
          viewData,
          tableData,
          workspaceId: baserowData?.config?.cardData?.workspace?.id,
          socketType: 'card',
          activityValue: [],
          setActivityValue: () => {},
        })
      } catch (error) {
        captureEvent({
          message: 'handleMessage: Unhandled error',
          extra: {
            error,
            tableId: tableData?.id,
            workspaceId: baserowData?.config?.cardData?.workspace?.id,
            socketType: 'card',
          },
        })
        toast.error(
          'An unexpected error occurred. Please refresh the page at your earliest convenience.',
        )
      }
    }
  }, [lastMessage])

  const result: CardSocket = {
    records,
    activeRecords: [],
    inactiveRecords: [],
    connectionStatus,
    lastMessage,
    createRecord,
    updateRecordById: updateRecord,
    table: baserowData?.table,
    view: baserowData?.view,
    base: baserowData?.base,
  }

  return result
}
