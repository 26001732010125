import React from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { DetailedViewRecord } from 'api/src/common/baserowImporterTypes'

interface BaserowImporterViewInformationModalProps {
  enrichedData: DetailedViewRecord
}

const BaserowImporterModalViewGrouping = ({
  enrichedData,
}: BaserowImporterViewInformationModalProps) => {
  return (
    <Accordion>
      <AccordionSummary
        className="rounded border border-solid border-gray-300"
        expandIcon={<ChevronDownIcon className="h-5 w-5 cursor-pointer" />}
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'column', // Display details in a column
            alignItems: 'flex-start', // Align details to the left
          },
        }}
      >
        <Typography fontWeight={'bold'}>Grouping</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1} className={'border p-2'}>
          {enrichedData?.details?.groupLevels?.map((group, index) => {
            return (
              <Stack key={index} spacing={1} direction={'row'}>
                <Typography>Field</Typography>
                <Chip
                  size="small"
                  className="w-[20%] rounded bg-blue-100 px-2 text-sm text-blue-500"
                  label={group.name}
                />
                <Typography>Sorted</Typography>
                <Chip
                  size="small"
                  className="w-[20%] rounded bg-violet-100 px-2 text-sm capitalize text-violet-500"
                  label={group.order}
                />
                <Typography>While</Typography>
                <Chip
                  size="small"
                  className="w-[20%] rounded bg-emerald-100 px-2 text-sm capitalize text-emerald-500"
                  label={
                    group.emptyGroupState === 'hidden' ? 'Hiding' : 'Showing'
                  }
                />
                <Typography>Empty Groups</Typography>
              </Stack>
            )
          })}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default BaserowImporterModalViewGrouping
