import React, { FC, useEffect, useState } from 'react'

import { XMarkIcon, ShareIcon } from '@heroicons/react/24/outline'
import { styled, Avatar, Box, LinearProgress, Tooltip } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { linearProgressClasses } from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import {
  GetAuthor,
  GetAuthorVariables,
  LearnerCourse,
  LearnerTask,
} from 'types/graphql'
import { useBoolean, useMediaQuery } from 'usehooks-ts'

import { useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Empty from 'src/components/Empty/Empty'
import CoursePlayerNavigation from 'src/components/Learner/Courses/CourseCell/CoursePlayer/CoursePlayerNavigation/CoursePlayerNavigation'
import TaskCell from 'src/components/Learner/Courses/CourseCell/CoursePlayer/TaskCell'
import useAnalytics from 'src/lib/hooks/useAnalytics'

import CourseChapterCard from '../../../Library/CourseChapterCard/CourseChapterCard'
import { GET_AUTHOR } from '../../queries'
import { TaskStatus } from '../../types'

interface Author {
  id: number
  name: string
  avatarUrl: string
}

interface CoursePlayerProps {
  course: LearnerCourse
  activityId?: number
  taskId?: number
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#10B981',
  },
}))

const CoursePlayer: FC<CoursePlayerProps> = ({
  course,
  activityId,
  taskId,
}) => {
  const courseTrackingId = course.learnerCourseTrackings?.[0]?.id
  const [author, setAuthor] = useState<Author | null>()
  const [task, setTask] = useState<LearnerTask>(
    course.learnerActivities[0].learnerTasks[0],
  )
  const isMobile = useMediaQuery('(max-width: 769px)')
  const { trackEvent } = useAnalytics()
  const [progress, setProgress] = useState<number>(0)
  const mobileNavigationOpen = useBoolean(false)
  const [index, setIndex] = useState(0)
  const [taskStatuses, setTaskStatus] = useState<TaskStatus[]>(
    course.learnerActivities.flatMap((learnerActivity) =>
      learnerActivity.learnerTasks.map((learnerTask) => {
        return {
          taskId: learnerTask.id,
          status: learnerTask.learnerTaskTrackings.find(
            (learnerTaskTracking) =>
              learnerTaskTracking.learnerCourseTrackingId === courseTrackingId,
          )?.status,
        }
      }),
    ),
  )

  useQuery<GetAuthor, GetAuthorVariables>(GET_AUTHOR, {
    variables: {
      searchId: course.createdBy,
    },
    onCompleted: (data) => {
      // @ts-expect-error: TODO: fix this
      setAuthor(data.user)
    },
  })
  const tasks: LearnerTask[] = []
  course.learnerActivities.every((activity) =>
    tasks.push(...activity.learnerTasks),
  )

  useEffect(() => {
    if (taskId) {
      const taskIndex = tasks.findIndex((item) => item.id === Number(taskId))
      if (taskIndex === -1) return
      setIndex(taskIndex)
      setTask(tasks[taskIndex])
      if (activityId) {
        // Do not run the following code if both taskId and activityId exist
        return
      }
    }

    if (activityId) {
      const activityIndex = course.learnerActivities.findIndex(
        (activity) => activity.id === activityId,
      )
      if (activityIndex === -1) return
      setTask(course.learnerActivities[activityIndex].learnerTasks[0])
      setIndex(activityIndex)
    }
  }, [taskId, activityId])

  useEffect(() => {
    const taskIndex = tasks.findIndex((item) => item.id === task?.id)
    if (taskIndex !== index) {
      setIndex(taskIndex)
      setTask(tasks[taskIndex])
      window.history.pushState(
        {},
        '',
        `/app/learn/course/${course?.id}/chapter/${tasks[taskIndex]?.learnerActivityId}/lesson/${tasks[taskIndex]?.id}`,
      )
    }
  }, [index, task, tasks])

  useEffect(() => {
    const totalCompleted = taskStatuses.reduce(
      (acc, taskWithStatus) =>
        acc + (taskWithStatus.status === 'COMPLETED' ? 1 : 0),
      0,
    )
    setProgress(Math.round((totalCompleted / tasks.length) * 100))
  }, [taskStatuses, tasks.length])

  useEffect(() => {
    setTaskStatus(
      course.learnerActivities.flatMap((learnerActivity) =>
        learnerActivity.learnerTasks.map((learnerTask) => {
          return {
            taskId: learnerTask.id,
            status: learnerTask.learnerTaskTrackings.find(
              (learnerTaskTracking) =>
                learnerTaskTracking.learnerCourseTrackingId ===
                courseTrackingId,
            )?.status,
          }
        }),
      ),
    )
  }, [course.learnerActivities, courseTrackingId])

  const onNext = () => {
    if (index + 1 < tasks.length) {
      setIndex(index + 1)
      setTask(tasks[index + 1])
    }
  }

  const onBack = () => {
    if (index - 1 >= 0) {
      setIndex(index - 1)
      setTask(tasks[index - 1])
    }
  }

  const copyToClipboard = () => {
    const hostname = window.location.hostname
    const path = hostname + '/app/learn/course/' + course.id
    navigator.clipboard
      .writeText(path)
      .then(() => toast.success('Course link copied to clipboard'))
      .catch(() => {})
    trackEvent('Uncategorized', 'copyUrl', {
      copyUrl: path,
    })
  }

  return (
    <div className="flex h-[calc(100vh-64px)] w-full grow flex-row border-t border-gray-200">
      {!isMobile && (
        <>
          <div className="flex h-full w-80 flex-col border-r border-gray-200 bg-gray-50 p-2">
            <header>
              <div className="max-h[160px] relative max-w-[305px] rounded-lg">
                {course.heroStorageObject?.downloadUrl ? (
                  <img
                    src={course.heroStorageObject?.downloadUrl}
                    alt="Course Hero"
                    className="h-[160px] w-[305px] rounded-lg bg-gray-200 object-cover object-center"
                  />
                ) : (
                  <Empty className="rounded-md bg-gray-200" image />
                )}

                <IconButton
                  className="absolute bottom-[0.5rem] right-[0.50rem] !border-gray-100 bg-white p-[5px] opacity-85 hover:bg-indigo-100"
                  onClick={copyToClipboard}
                  sx={{ border: 1 }}
                >
                  <Tooltip title="Share Course">
                    <ShareIcon className="h-6 w-6 pr-1 text-slate-500" />
                  </Tooltip>
                </IconButton>
              </div>

              <div className="border-b border-gray-200 p-2">
                <h3 className="mt-4 text-base font-normal leading-5">
                  {course.name}
                </h3>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%', mr: 1 }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={progress}
                      sx={{ height: 8 }}
                    />
                  </Box>
                  <Box sx={{ minWidth: 20 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                    >{`${progress}%`}</Typography>
                  </Box>
                </Box>
                <div className="mt-2 flex flex-row justify-between">
                  <div className="flex flex-row">
                    <Avatar
                      alt={author?.name}
                      src={author?.avatarUrl}
                      className="h-6 w-6"
                    />
                    <Typography
                      variant="caption"
                      className="ml-1 mt-0.5 text-gray-900"
                    >
                      {author?.name}
                    </Typography>
                  </div>
                  <Typography
                    variant="caption"
                    className="ml-1 mt-0.5 text-gray-600"
                  >
                    {course?.learnerActivities?.length !== 1
                      ? 'Chapters'
                      : 'Chapter'}
                  </Typography>
                </div>
              </div>
            </header>
            <main className="mx-1 max-w-[305px] overflow-y-auto">
              {course.learnerActivities.map((activity) => (
                <CourseChapterCard
                  key={activity.id}
                  chapter={activity && activity}
                  setTask={setTask}
                  selectedTask={task?.id}
                  taskStatuses={taskStatuses}
                  isSelected={task?.learnerActivityId === activity.id}
                />
              ))}
            </main>
          </div>
          <div className="flex h-full w-[calc(100%-20rem)] flex-col bg-gray-50">
            {task && (
              <>
                <main className="h-[calc(100%-4rem)] w-full overflow-y-auto bg-white">
                  <TaskCell id={task.id} setTaskStatus={setTaskStatus} />
                </main>
                <footer className="h-16 bg-white">
                  <CoursePlayerNavigation
                    index={index}
                    courseLength={tasks.length}
                    onNext={onNext}
                    onBack={onBack}
                  />
                </footer>
              </>
            )}
          </div>
        </>
      )}
      {isMobile && (
        <>
          <div className="relative z-0 flex max-h-screen flex-1 overflow-y-scroll">
            <Drawer
              anchor="bottom"
              open={mobileNavigationOpen.value}
              onClose={() => {
                mobileNavigationOpen.setFalse()
              }}
              sx={{
                flexShrink: 0,
                zIndex: 50,
                '& .MuiDrawer-paper': {
                  height: '100%',
                },
              }}
            >
              <div className="m-4 max-h-[calc(100vh-80px)] overflow-y-auto overflow-x-hidden">
                <div className="flex w-full justify-end">
                  <IconButton
                    aria-label="close"
                    onClick={mobileNavigationOpen.setFalse}
                  >
                    <XMarkIcon className="text h-6 w-6" />
                  </IconButton>
                </div>
                <header>
                  <div className="border-b border-gray-200 p-2">
                    <h3 className="mt-4 text-base font-normal leading-5">
                      {course.name}
                    </h3>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ width: '100%', mr: 1 }}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={progress}
                          sx={{ height: 8 }}
                        />
                      </Box>
                      <Box sx={{ minWidth: 20 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                        >{`${progress}%`}</Typography>
                      </Box>
                    </Box>
                    <div className="mt-2 flex flex-row justify-between">
                      <div className="flex flex-row">
                        <Avatar
                          alt={author?.name}
                          src={author?.avatarUrl}
                          className="h-6 w-6"
                        />
                        <Typography
                          variant="caption"
                          className="ml-1 mt-0.5 text-gray-900"
                        >
                          {author?.name}
                        </Typography>
                      </div>
                      <Typography
                        variant="caption"
                        className="ml-1 mt-0.5 text-gray-600"
                      >
                        {course.learnerActivities.length}{' '}
                        {course.learnerActivities.length !== 1
                          ? 'Chapters'
                          : 'Chapter'}
                      </Typography>
                    </div>
                  </div>
                </header>
                <main className="mx-1 overflow-y-auto">
                  {course.learnerActivities.map((activity) => (
                    <CourseChapterCard
                      key={activity.id}
                      chapter={activity && activity}
                      setTask={setTask}
                      selectedTask={task?.id}
                      taskStatuses={taskStatuses}
                      isSelected={activityId === activity.id}
                    />
                  ))}
                </main>
              </div>
            </Drawer>
          </div>
          <div className="flex h-full w-full flex-col bg-gray-50">
            {task && (
              <>
                <main className="h-[calc(100%-4rem)] w-full overflow-y-auto bg-white">
                  <TaskCell id={task.id} setTaskStatus={setTaskStatus} />
                </main>
                <footer className="h-16 bg-white">
                  <CoursePlayerNavigation
                    index={index}
                    courseLength={tasks.length}
                    onNext={onNext}
                    onBack={onBack}
                    isMobile={isMobile}
                    navigationToggle={mobileNavigationOpen.toggle}
                  />
                </footer>
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default CoursePlayer
