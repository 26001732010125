import React from 'react'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'

import Divider from 'src/components/Library/Divider/Divider'
import Switch from 'src/components/Library/Switch/Switch'

const TemplateSettings = ({ isTemplate, placeholderText }) => {
  return (
    <Stack spacing={2}>
      <h1 className="text-md text-base font-medium leading-6 text-gray-900">
        Template Settings
      </h1>
      <Divider size={'h-[1px]'} className={'px-0'} />
      <Card elevation={0} variant={'outlined'}>
        <CardHeader
          className={'bg-gray-50'}
          title={
            <div className="flex flex-row">
              <div className="flex w-1/2 flex-row items-center text-gray-600">
                <h3 className="text-base font-normal">Template</h3>
              </div>
              <div className="flex w-1/2 flex-row items-center justify-end">
                <Switch
                  checked={isTemplate.value}
                  onChange={() => {
                    isTemplate.toggle()
                  }}
                />
              </div>
            </div>
          }
          subheader={
            <p className="pt-3 text-xs text-gray-500">
              Allow this {placeholderText} to be used as a template when
              creating a new {placeholderText}.
            </p>
          }
        />
      </Card>
    </Stack>
  )
}

export default TemplateSettings
