import React, { useCallback, useState } from 'react'

import TextField from '@mui/material/TextField'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import debounce from 'lodash.debounce'
import {
  Membership,
  MembershipGroup,
  NpsAssessor,
  NpsMeasurement,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import FilterNavTabs from 'src/components/FilterNavTabs/FilterNavTabs'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import { CREATE_NPS_MEASUREMENT_ASSESSORS } from 'src/lib/queries/Settings/NPS/NpsMeasurementAssessors'
import { CREATE_NPS_MEASUREMENT_SUBJECTS } from 'src/lib/queries/Settings/NPS/NpsMeasurementSubjects'

enum NavTabs {
  SUBJECTS,
  ASSESSORTYPES,
}
const navTabItems = [{ title: 'Performers' }, { title: 'Assessor Types' }]

interface MeasurementSettingsProps {
  measurement: NpsMeasurement
  assessors: NpsAssessor[]
  memberships: Membership[]
  membershipGroups: MembershipGroup[]
}
interface SubjectProps {
  memberships: Membership[]
  membershipGroups: MembershipGroup[]
  saveGroups(values: MembershipGroup[])
  measurement: NpsMeasurement
}

interface AssessorProps {
  measurement: NpsMeasurement
  assessors: NpsAssessor[]
  saveAssessors(value: NpsAssessor[])
}

const headerStyle =
  'bg-gray-50 text-gray-500 text-xs font-medium tracking-wider leading-3 uppercase'

const subjectColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerClassName: headerStyle,
    flex: 1,
    minWidth: 150,
    valueGetter: (_value, row) => {
      return `${row.subject.membership.user.name}`
    },
  },
  {
    field: 'position',
    headerName: 'Position',
    headerClassName: headerStyle,
    flex: 1,
    minWidth: 150,
    valueGetter: (_value, row) => {
      return `${row.subject.membership.user.position || ''}`
    },
  },
]

const assessorColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerClassName: headerStyle,
    flex: 1,
    minWidth: 150,
    valueGetter: (_value, row) => {
      return `${row.assessor.name}`
    },
  },
]

const SubjectsView: React.FC<SubjectProps> = ({
  measurement,
  membershipGroups,
  saveGroups,
}) => {
  return (
    <div className={'mt-4 flex h-full flex-col'}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        limitTags={1}
        options={membershipGroups}
        size={'small'}
        renderInput={(params) => <TextField {...params} label="Groups" />}
        getOptionLabel={(option: MembershipGroup) => option.name}
        onChange={(_, value) => {
          saveGroups(value)
        }}
      />
      <div className={'mt-6 h-full w-full bg-white'}>
        <DataGrid
          columns={subjectColumns}
          rows={measurement.subjects}
          hideFooter
          rowHeight={35}
          columnHeaderHeight={25}
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
        />
      </div>
    </div>
  )
}
const AssessorView: React.FC<AssessorProps> = ({
  measurement,
  assessors,
  saveAssessors,
}) => {
  return (
    <div className={'mt-4 flex h-full flex-col'}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        limitTags={1}
        options={assessors}
        size={'small'}
        renderInput={(params) => <TextField {...params} label="Assessors" />}
        getOptionLabel={(option) => option.name}
        onChange={(_, value) => {
          saveAssessors(value)
          // debouncedSaveGroups()
        }}
      />
      <div className={'mt-6 h-full w-full bg-white'}>
        <DataGrid
          columns={assessorColumns}
          rows={measurement.assessors}
          hideFooter
          rowHeight={35}
          columnHeaderHeight={25}
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
        />
      </div>
    </div>
  )
}
const NpsSettingsView: React.FC<MeasurementSettingsProps> = ({
  measurement,
  membershipGroups,
  memberships,
  assessors,
}) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const onTabClickCallBack = (tabTitle) => {
    setSelectedTab(
      navTabItems.findIndex((tabItem) => tabTitle === tabItem.title),
    )
  }

  const [createNpsMeasurementSubjects] = useMutation(
    CREATE_NPS_MEASUREMENT_SUBJECTS,
    {
      onCompleted: () => {
        toast.success('Created', {
          duration: 2000,
          className: 'flex-column',
        })
      },
      refetchQueries: ['FindNpsMeasurementQuery'],
      onError: (error) => {
        toast.error(error.message, {
          duration: 2000,
          className: 'flex-column',
        })
      },
    },
  )

  const [createNpsMeasurementAssessors] = useMutation(
    CREATE_NPS_MEASUREMENT_ASSESSORS,
    {
      onCompleted: () => {
        toast.success('Created', {
          duration: 2000,
          className: 'flex-column',
        })
      },
      refetchQueries: ['FindNpsMeasurementQuery'],
      onError: (error) => {
        toast.error(error.message, {
          duration: 2000,
          className: 'flex-column',
        })
      },
    },
  )

  const saveGroups = (values) => {
    createNpsMeasurementSubjects({
      variables: {
        input: {
          measurementId: measurement.id,
          membershipGroupIds: values.map((group) => group.id),
        },
      },
    })
  }

  const saveAssessors = (values) => {
    createNpsMeasurementAssessors({
      variables: {
        input: values.map((assessor) => ({
          measurementId: measurement.id,
          assessorId: assessor.id,
        })),
      },
    })
  }

  const debouncedGroupSave = useCallback(debounce(saveGroups, 2000, true), [])
  const debouncedAssessorsSave = useCallback(
    debounce(saveAssessors, 2000, true),
    [],
  )

  return (
    <div>
      <FilterNavTabs
        navTabs={navTabItems}
        onTabClickCallBack={onTabClickCallBack}
        selectedTab={selectedTab}
        GATracker={{
          category: 'NPS',
        }}
      />
      {
        {
          [NavTabs.SUBJECTS]: (
            <SubjectsView
              membershipGroups={membershipGroups}
              memberships={memberships}
              saveGroups={debouncedGroupSave}
              measurement={measurement}
            />
          ),
          [NavTabs.ASSESSORTYPES]: (
            <AssessorView
              assessors={assessors}
              measurement={measurement}
              saveAssessors={debouncedAssessorsSave}
            />
          ),
        }[selectedTab]
      }
    </div>
  )
}

export default NpsSettingsView
