import { FC } from 'react'

export const CoursePreviewImage: FC<{
  imageUrl?: string
  variant?: 'small' | 'large'
}> = ({ imageUrl, variant = 'small' }) => {
  // Set tailwind classes for variants
  const height = variant === 'small' ? 'h-24' : 'h-40'
  const width = variant === 'small' ? 'w-32' : 'w-full'
  const border = variant === 'small' ? 'rounded border border-gray-200' : ''

  return (
    <div
      className={` ${height} ${width} ${border} grid place-items-center bg-gray-100 bg-cover bg-center bg-no-repeat p-3 text-center text-sm text-gray-400`}
      style={{
        backgroundImage: 'url(' + imageUrl + ')',
      }}
    >
      {!imageUrl && 'No Preview Image'}
    </div>
  )
}
