import type {
  FindHubDashDataQuery,
  FindHubDashDataQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps } from '@redwoodjs/web'

import HubDashHeader from 'src/components/HubDash/HubDashHeader/HubDashHeader'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const QUERY = gql`
  query FindHubDashDataQuery {
    hubDashLayouts {
      id
      name
      layoutData
      userFilterType
      allowUserFilterOverride
      HubDashLayoutToHubDashCard {
        id
        position
        hubDashCard {
          id
          name
          cardData
          cardSettings
          type
          source
          description
          hubDashColorMap {
            id
            name
            description
            colorName
            icon
          }
        }
        linkedResources {
          resourceName
          resourceId
          resourceType
          linkMetaData
        }
      }
    }
    membershipsByClient(activeOnly: true) {
      id
      clientId
      role
      membershipType
      division
      department
      team
      position
      user {
        id
        avatarUrl
        email
        name
        position
        baserowUserId
      }
      membershipGroups {
        id
        membershipGroup {
          id
          name
        }
      }
    }
    membershipGroupsByClient {
      id
      name
      parentGroup {
        id
        name
      }
      childGroup {
        id
        name
      }
    }
  }
`

type CellProps = CellSuccessProps<
  FindHubDashDataQuery,
  FindHubDashDataQueryVariables
>

export type HubDashLayout = CellProps['hubDashLayouts'][0]
export type HubDashLayoutToHubDashCard =
  CellProps['hubDashLayouts'][0]['HubDashLayoutToHubDashCard'][0]
export type HubDashCard =
  CellProps['hubDashLayouts'][0]['HubDashLayoutToHubDashCard'][0]['hubDashCard']
export type HubDashColorMap =
  CellProps['hubDashLayouts'][0]['HubDashLayoutToHubDashCard'][0]['hubDashCard']['hubDashColorMap']

export type HubDashLinkedContent =
  CellProps['hubDashLayouts'][0]['HubDashLayoutToHubDashCard'][0]['linkedResources'][0]

export type HubDashMembership = CellProps['membershipsByClient'][0]
export type HubDashMembershipGroup = CellProps['membershipGroupsByClient'][0]

export enum LinkedResourceType {
  KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
  PROCESS_MAP = 'PROCESS_MAP',
  LEARNER_COURSE = 'LEARNER_COURSE',
}

export const Loading = () => (
  <div>
    <LoadingSpinner size="small" />
  </div>
)

export const Empty = () => {
  return <HubDashHeader layouts={[]} memberships={[]} groups={[]} />
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({
  hubDashLayouts,
  membershipsByClient,
  membershipGroupsByClient,
}) => {
  return (
    <HubDashHeader
      layouts={hubDashLayouts}
      memberships={membershipsByClient}
      groups={membershipGroupsByClient}
    />
  )
}
