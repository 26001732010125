import * as React from 'react'

import { Autocomplete, TextField } from '@mui/material'

const InformerOrgChartSearchableDropDown = ({
  availUsers,
  allocUsers,
  initialValue,
  setSelectedUser,
}) => {
  const allUsers = [...availUsers, ...allocUsers]

  const groupedUsers = [
    { label: 'Unassigned Users', options: availUsers },
    { label: 'Assigned Users', options: allocUsers },
  ]

  const [selectedUser, setSelectedUserState] = React.useState(
    allUsers.find((user) => user.value === initialValue) || null,
  )

  const handleAddNewUser = () => {
    alert(
      'This feature is not available at this stage. To add a new user, please visit the member management section',
    )
    // After handling, you can clear the input box
    // setSelectedUser(1)
  }

  const handleAutoCompleteChange = (_event, value) => {
    if (value && value.isAddNew) {
      handleAddNewUser() // Call the handler when "Add New User" is selected
    } else if (value) {
      setSelectedUser(value.value) // Set the selected user
      setSelectedUserState(value) // Also update the local state
    }
  }

  return (
    <Autocomplete
      fullWidth
      value={selectedUser}
      options={groupedUsers.flatMap((group) =>
        group.options.map((option) => ({ groupLabel: group.label, ...option })),
      )}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      groupBy={(option) => option.groupLabel}
      getOptionLabel={(option) => option.label || ''}
      onChange={handleAutoCompleteChange}
      renderInput={(params) => <TextField {...params} label="Allocation" />}
      renderGroup={(params) => (
        <li key={params.key}>
          <p className="bg-gray-100 p-2 px-4 italic text-gray-600">
            {params.group}
          </p>
          <ul>{params.children}</ul>
        </li>
      )}
    />
  )
}

export default InformerOrgChartSearchableDropDown
