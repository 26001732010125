import { FC } from 'react'

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { without } from 'ramda'
import { useLocalStorage, useMediaQuery } from 'usehooks-ts'

import { navigate, routes } from '@redwoodjs/router'

import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'src/Providers'
import { checkUrl, classNames } from 'src/Util'

interface SingleGridCardProps {
  id: number
  sectionId?: number
  name: string
  iconStorageObject?: {
    id?: number
    downloadUrl?: string
  }
  url: string
  order?: number | null
  clientId: number
  isAll: boolean
  isHidden: boolean
  isEmbed: boolean
  members?: Membership[]
  membershipGroups?: MembershipGroup[]
}

// Membership Group
interface MembershipGroup {
  id: number
  membershipGroupId: number
  membershipGroup?: {
    name?: string
  }
}

// Membership
interface Membership {
  id?: number
  informerListCardId?: number
  membershipId?: number
}

interface InformerGridCardProps {
  gridItem: SingleGridCardProps
  sectionName: string
}

const InformerGridCard: FC<InformerGridCardProps> = ({
  gridItem,
  sectionName = 'Tech Stack / Form',
}) => {
  const isMobile = useMediaQuery('(max-width: 640px)')
  const { trackEvent } = useAnalytics()

  const { currentUser } = useAuth()

  const [recentCardIds, setRecentCards] = useLocalStorage(
    `memberId-${currentUser.membershipData?.id}-recentCardIds`,
    [],
  )

  const safeUrl = gridItem?.isEmbed ? '#' : checkUrl(gridItem?.url || '/')

  return (
    <div
      key={gridItem.id}
      className={classNames(
        'group flex h-full items-center justify-center rounded-md bg-white p-3 ring-1 ring-gray-300 ring-opacity-25 hover:shadow-xl hover:ring-opacity-80',
      )}
    >
      <a
        href={safeUrl}
        className={classNames(
          isMobile ? 'flex flex-col justify-center' : 'flex justify-start',
          'w-full items-center focus:outline-none',
        )}
        {...(!gridItem?.isEmbed && { target: '_blank' })}
        rel="noopener noreferrer"
        onClick={() => {
          const updatedRecentCards = [
            gridItem.id,
            ...(recentCardIds?.includes(gridItem.id)
              ? without([gridItem.id], recentCardIds)
              : recentCardIds),
          ]
          setRecentCards(updatedRecentCards)
          if (gridItem?.isEmbed) {
            navigate(
              routes.embed({
                sectionId: gridItem?.sectionId ?? 0,
                cardId: gridItem?.id ?? 0,
              }),
            )
          }
          trackEvent('Tech Stack / Form Links', 'home link click', {
            informerName: gridItem?.name,
          })
        }}
        data-testid={`informer-grid-card-${gridItem?.isEmbed}-${gridItem?.id}-${sectionName}`}
      >
        {gridItem?.iconStorageObject?.downloadUrl ? (
          <div
            className={classNames('block h-9 w-9')}
            style={{
              backgroundImage:
                'url(' + gridItem?.iconStorageObject?.downloadUrl + ')',
              backgroundPosition: 'center center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          ></div>
        ) : (
          <ArrowTopRightOnSquareIcon
            data-testid="arrow-top-right-on-square-icon"
            className="my-3 block h-[25px] w-[25px] text-gray-400"
          />
        )}

        <h3
          className={classNames(
            isMobile
              ? '!ml-0 mb-3 mt-2 text-center leading-6'
              : 'ml-3 leading-4',
            'line-clamp-3 text-sm text-gray-700',
          )}
        >
          {gridItem?.name}
        </h3>
      </a>
    </div>
  )
}

export default InformerGridCard
