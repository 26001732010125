import React from 'react'

import { Metadata } from '@redwoodjs/web'

import ClientFeaturesCell from 'src/components/ClientFeaturesCell'
import PageHeader from 'src/components/PageHeader/PageHeader'

const ClientFeaturesPage = () => {
  return (
    <>
      <Metadata title="Client Features" description="Client Features" />

      <PageHeader
        title={'Client Features'}
        parentDataTestId="client-features-page-header"
      />
      <div className="flex h-[calc(100vh-5rem)] flex-1 justify-center">
        <div className="mb-2 h-full w-full overflow-y-auto px-8 pt-8">
          <ClientFeaturesCell />
        </div>
      </div>
    </>
  )
}

export default ClientFeaturesPage
