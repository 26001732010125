import { FC } from 'react'
export const LearnerStatItem: FC<{
  icon: JSX.Element
  value: number
  label: string
}> = ({ icon, value, label }) => {
  return (
    <div className="flex w-full flex-col items-center justify-around gap-0 rounded border border-gray-200 p-2 text-center">
      {icon}
      <p className="text-md font-bold">{value}</p>
      <p className="bold text-xs uppercase text-indigo-600">{label}</p>
    </div>
  )
}
