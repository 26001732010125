import { FC, Fragment, MouseEvent, useEffect, useState } from 'react'

import { TrashIcon } from '@heroicons/react/24/outline'
import { EllipsisHorizontalIcon, PencilIcon } from '@heroicons/react/24/solid'
import { Avatar, Menu, MenuItem, Tooltip } from '@mui/material'
import { captureEvent } from '@sentry/browser'
import { AxiosInstance } from 'axios'
import dayjs from 'dayjs'
import { Mention, MentionsInput } from 'react-mentions'

import Button from 'src/components/Library/Button/Button'
import IconButton from 'src/components/Library/IconButton/IconButton'
import useHubDashStore from 'src/lib/stores/hubDashStore'

import {
  BaserowCommentContent,
  BaserowCommentMention,
  BaserowCommentPayload,
  BaserowWorkspaceUser,
} from '../../lib/types'

export const convertBrCommentToMentionComment = (
  content: (BaserowCommentContent | BaserowCommentMention)[],
): string => {
  const formattedTextArr = content.map((item) => {
    if (item.type === 'text') {
      const itemContent = item as BaserowCommentContent
      return itemContent.text
    }

    if (item.type === 'mention') {
      const itemContent = item as BaserowCommentMention
      return `@[${itemContent.attrs.label}](${itemContent.attrs.id})`
    }

    return ''
  })

  return formattedTextArr.join('')
}

export const convertMentionCommentToBrComment = (
  content: string,
): (BaserowCommentContent | BaserowCommentMention)[] => {
  // Regular expression to identify mentions in the format @[Name](ID)
  const mentionRegex = /@\[(.+?)\]\((\d+)\)/g

  const contentArray = []
  let lastIndex = 0

  content.replace(mentionRegex, (match, name, id, offset) => {
    if (offset > lastIndex) {
      contentArray.push({
        type: 'text',
        text: content.substring(lastIndex, offset),
      })
    }

    contentArray.push({
      type: 'mention',
      attrs: {
        label: name,
        id: id,
      },
    })

    lastIndex = offset + match.length
    return match
  })

  if (lastIndex < content.length) {
    contentArray.push({
      type: 'text',
      text: content.substring(lastIndex),
    })
  }

  return contentArray
}

interface CommentBubbleProps {
  comment: any // Not typed
  commenter: { name: string; avatarUrl: string }
  currentUserName: string
  usersList: BaserowWorkspaceUser[]
  brApi: AxiosInstance
  refetch: () => Promise<void>
}

const MentionTooltip = ({ mentionUser }) => {
  const Popup = (
    <div className="flex items-center gap-3 bg-white text-gray-700">
      <Avatar
        src={mentionUser?.avatarUrl}
        alt={mentionUser?.name ?? 'Unknown User'}
        className="h-10 w-10"
      />
      <div>
        <p className="text-sm">{mentionUser?.name ?? 'Unknown User'}</p>
        <p className="text-xs text-gray-500">{mentionUser?.position}</p>
      </div>
    </div>
  )

  return (
    <Tooltip
      title={Popup}
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#fff',
            boxShadow: '0px 4px 8px rgba(0,0,0,.2)',
            padding: '10px',
            borderRadius: '10px',
            '& .MuiTooltip-arrow': {
              color: '#fff',
            },
          },
        },
      }}
      arrow
    >
      <span className="pointer cursor-pointer text-sm font-bold">{`@${mentionUser?.name ?? 'Unknown User'}`}</span>
    </Tooltip>
  )
}

const CommentBubble: FC<CommentBubbleProps> = ({
  comment,
  commenter,
  currentUserName,
  usersList,
  brApi,
  refetch,
}) => {
  const userList = useHubDashStore((state) => state.userList)
  const storeUserList = userList.filter(
    (user) => user.listType === 'User' && user?.baserowUserId,
  )

  const isCurrentUserComment = comment.first_name === currentUserName

  const justifyClass = isCurrentUserComment ? 'justify-end' : 'justify-start'

  const [commentEditMode, setCommentEditMode] = useState<boolean>(false)
  const [commentSaving, setCommentSaving] = useState<boolean>(false)
  const [enteredComment, setEnteredComment] = useState<string>(
    convertBrCommentToMentionComment(
      comment?.message?.content[0]?.content ?? [],
    ),
  )

  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEditComment = () => {
    handleClose()
    setCommentEditMode(true)
  }

  const handleCommentSubmit = async () => {
    setCommentSaving(true)

    const contentArray = convertMentionCommentToBrComment(enteredComment)

    const commentPayload: BaserowCommentPayload = {
      message: {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: contentArray,
          },
        ],
      },
    }

    try {
      const updateResult = await brApi.patch(
        `/row_comments/${comment?.table_id}/comment/${comment?.id}/`,
        commentPayload,
      )
      if (updateResult.status === 200) {
        refetch()
      }
    } catch (error) {
      captureEvent({
        message: 'HubDash: An error occurred updating a comment.',
        level: 'warning',
        extra: {
          tableId: comment?.table_id,
          commentId: comment?.id,
          errorCode: error?.response?.data?.error,
        },
      })

      // Comment was deleted/missing - refetch to get up to date
      if (error?.response?.data?.error === 'ERROR_ROW_COMMENT_DOES_NOT_EXIST') {
        refetch()
      }
    }
    setCommentSaving(false)
    setCommentEditMode(false)
  }

  const handleDeleteComment = async () => {
    handleClose()

    try {
      const deleteResult = await brApi.delete(
        `/row_comments/${comment?.table_id}/comment/${comment?.id}/`,
      )

      if (deleteResult.status === 200) {
        refetch()
      }
    } catch (error) {
      captureEvent({
        message: 'HubDash: An error occurred deleting a comment.',
        level: 'warning',
        extra: {
          tableId: comment?.table_id,
          commentId: comment?.id,
          errorCode: error?.response?.data?.error,
        },
      })

      // Comment was already deleted/missing - refetch to get up to date
      if (error?.response?.data?.error === 'ERROR_ROW_COMMENT_DOES_NOT_EXIST') {
        refetch()
      }
    }
  }

  useEffect(() => {
    // Reset the edit comment input if cancelled.
    setEnteredComment(
      convertBrCommentToMentionComment(
        comment?.message?.content[0]?.content ?? [],
      ),
    )
  }, [commentEditMode])

  return (
    <div className={`flex w-full ${justifyClass}`}>
      <div className={`flex w-4/5 gap-2 ${justifyClass}`}>
        {!isCurrentUserComment && (
          <div className="flex-shrink-0">
            <Avatar
              src={commenter?.avatarUrl}
              alt={commenter?.name}
              className="bg-gray-200 font-bold text-gray-700"
            >
              {commenter?.name
                .split(' ')
                .map((part) => part[0])
                .slice(0, 2)
                .join('')}
            </Avatar>
          </div>
        )}
        <div className={`flex flex-col ${commentEditMode ? 'w-full' : ''}`}>
          <div
            className={`flex items-center ${justifyClass} gap-4 text-xs text-gray-500`}
          >
            <div className="flex gap-2 text-xs">
              <p className="font-bold">
                {isCurrentUserComment ? 'You' : comment.first_name}
              </p>
              <p className="text-gray-400">
                {dayjs(comment?.created_on).format('DD/MM/YYYY HH:mma')}{' '}
                {comment?.edited && !comment?.trashed && '(edited)'}
              </p>
            </div>
            <div>
              {isCurrentUserComment && !comment?.trashed && (
                <div>
                  <IconButton
                    disabled={commentEditMode}
                    className="p-1"
                    onClick={handleClick}
                  >
                    <EllipsisHorizontalIcon className="h-4 w-4" />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                      className: 'p-0',
                    }}
                  >
                    <MenuItem
                      className="items-center gap-3 px-2 text-sm text-gray-600"
                      disableRipple
                      onClick={handleEditComment}
                    >
                      <PencilIcon className="h-4 w-4" />
                      <p className="pt-0.5">Edit Comment</p>
                    </MenuItem>
                    <MenuItem
                      color="error"
                      className="items-center gap-3 px-2 text-sm text-red-500"
                      disableRipple
                      onClick={handleDeleteComment}
                    >
                      <TrashIcon className="h-4 w-4" />
                      <p className="pt-0.5">Delete Comment</p>
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </div>
          {commentEditMode && (
            <div className="flex w-full flex-col">
              <div className="w-full">
                <MentionsInput
                  disabled={commentSaving}
                  value={enteredComment}
                  onChange={(e) => setEnteredComment(e.target.value)}
                  forceSuggestionsAboveCursor
                  allowSpaceInQuery
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault()
                      handleCommentSubmit()
                    }
                  }}
                  placeholder="Add a comment..."
                  className="min-h-8 w-full rounded border border-gray-300 bg-gray-100"
                  style={{
                    input: {
                      paddingLeft: '5px',
                      paddingTop: '5px',
                    },
                    suggestions: {
                      list: {
                        borderRadius: '5px',
                        border: '1px solid #9ca3af',
                        padding: '5px',
                      },
                      item: {
                        padding: '5px',
                        borderBottom: '1px solid #f3f4f6',
                        '&focused': {
                          backgroundColor: '#f3f4f6',
                        },
                        fontSize: '12px',
                      },
                    },
                  }}
                >
                  <Mention
                    trigger="@"
                    data={usersList.map((user) => ({
                      id: user?.user_id,
                      display: user.name,
                    }))}
                    displayTransform={(_, display) => `@${display}`}
                  />
                </MentionsInput>
              </div>
              <div className="flex items-center justify-end gap-2 py-2">
                <Button
                  onClick={() => setCommentEditMode(false)}
                  className="py-1"
                  variant="text"
                  disabled={commentSaving}
                  fullWidth={false}
                >
                  Cancel
                </Button>
                <Button
                  className="py-1"
                  fullWidth={false}
                  loading={commentSaving}
                  onClick={handleCommentSubmit}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
          {!commentEditMode && (
            <div
              className={`mt-1 flex flex-shrink-0 rounded-lg p-3 ${isCurrentUserComment ? 'bg-gradient-to-b from-indigo-500 to-indigo-600' : 'bg-gradient-to-b from-gray-100 to-gray-200'}`}
            >
              <div
                className={`text-sm font-light ${isCurrentUserComment ? 'text-white' : 'text-black'}`}
              >
                {(comment?.trashed || !comment?.message) && (
                  <span
                    className={`text-sm ${isCurrentUserComment ? 'text-white' : 'text-black'} italic`}
                  >
                    This comment has been deleted.
                  </span>
                )}
                {comment?.message &&
                  comment?.message?.content.map((item, index) => (
                    <div key={index}>
                      {item.content?.map((content, subIndex) => {
                        if (content.type === 'text') {
                          return content.text
                            .split('\n')
                            .map((line, lineIndex) => (
                              <Fragment
                                key={`${index}-${subIndex}-${lineIndex}`}
                              >
                                <span
                                  className="text-sm"
                                  data-testid={`hubdash-comment-line-${line}`}
                                >
                                  {line}
                                </span>
                                <br />
                              </Fragment>
                            ))
                        } else if (content.type === 'mention') {
                          const mentionUser = storeUserList.find(
                            (user) => user.name === content.attrs.label,
                          )
                          return (
                            <MentionTooltip
                              key={`${index}-${subIndex}`}
                              mentionUser={
                                mentionUser ?? {
                                  name: content.attrs.label,
                                  avatarUrl: '/',
                                }
                              }
                            />
                          )
                        } else {
                          return (
                            <p
                              key={`${index}-${subIndex}`}
                              className="text-xs"
                            ></p>
                          )
                        }
                      })}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CommentBubble
