import { Dispatch, FC, SetStateAction, useState } from 'react'

import { PlusIcon } from '@heroicons/react/24/outline'
import {
  ChevronDownIcon,
  UserGroupIcon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import { Avatar } from '@mui/material'
import TextField from '@mui/material/TextField'

import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'src/Providers'

import Button from '../../../Library/Button/Button'
import Dropdown from '../../../Library/Dropdown/Dropdown'
import { EditFormStates } from '../../constants'
import { ViewAsOption } from '../../interfaces'

interface GoalListAdminProps {
  searchVal: string
  setSearchVal: Dispatch<SetStateAction<string>>
  viewAsDropdownOptions: ViewAsOption[]
  viewAsOption: ViewAsOption
  setViewAsOption: Dispatch<SetStateAction<ViewAsOption>>
  adminEditMode: boolean
  sidebarState: EditFormStates.empty | EditFormStates.edit | EditFormStates.new
  setSidebarState: Dispatch<
    SetStateAction<
      EditFormStates.empty | EditFormStates.edit | EditFormStates.new
    >
  >
  resetSidebarState?: () => void
  createGoalFromTemplate?: () => void
  showTemplateEditor: boolean
  setShowTemplateEditor: Dispatch<SetStateAction<boolean>>
}

const GoalListAdmin: FC<GoalListAdminProps> = ({
  searchVal,
  setSearchVal,
  viewAsDropdownOptions,
  viewAsOption,
  setViewAsOption,
  adminEditMode,
  setSidebarState,
  resetSidebarState,
  createGoalFromTemplate,
  showTemplateEditor,
  setShowTemplateEditor,
}) => {
  // Set state for the search functionality on selector
  const [filterViewAsOptions, setFilterViewAsOptions] = useState<string>('')
  const { hasRole } = useAuth()

  const isSuperAdmin = hasRole(['SUPERADMIN'])
  const isAdminAccess = hasRole(['ADMIN', 'OWNER'])

  const { trackEvent } = useAnalytics()

  return (
    <div className="mt-4 flex w-full flex-row items-center justify-start gap-3 2xl:mt-0 2xl:justify-end">
      <div className="relative w-full max-w-[230px]">
        <div className="relative">
          <TextField
            id="adminCardsTextSearch"
            data-testid="adminCardsTextSearch"
            name="Search Cards by text"
            maxRows={4}
            value={searchVal}
            size="small"
            onChange={(e) => {
              setSearchVal(e.target.value)
            }}
            onBlur={() => {
              trackEvent('Goals', 'search goals', { searchVal: searchVal })
            }}
            className="w-full bg-white text-xs"
            placeholder="Search Goals"
          />

          {searchVal.length > 0 && (
            <Button
              variant="text"
              fullWidth={false}
              className="absolute right-2 top-[8px] min-w-0 rounded-full bg-white p-1 hover:bg-gray-100"
              onClick={() => {
                setSearchVal('')
              }}
            >
              <XMarkIcon className="h-4 w-4 text-gray-500" />
              <span className="sr-only">Clear</span>
            </Button>
          )}
        </div>
      </div>

      <div className="w-full max-w-[230px]">
        <Autocomplete
          id="adminCardsViewAsSelect"
          className="w-full bg-white"
          size="small"
          value={viewAsOption}
          groupBy={(option) => option.group}
          onChange={(_, newValue: ViewAsOption) => {
            setViewAsOption(newValue)
            trackEvent('Goals', `view as ${newValue.group}`, {
              name: newValue.name,
            })
          }}
          isOptionEqualToValue={(option, value) =>
            option.group === value.group && option.id === value.id
          }
          inputValue={filterViewAsOptions}
          onInputChange={(_, newInputValue) => {
            setFilterViewAsOptions(newInputValue)
          }}
          options={viewAsDropdownOptions}
          getOptionLabel={(option: ViewAsOption) => option.name ?? ''}
          renderOption={(props, option) => (
            <li {...props}>
              {option.group === 'Membership' && (
                <Avatar
                  className={`mr-4 h-8 w-8 rounded-full ${
                    !option?.avatar && 'bg-indigo-100'
                  }`}
                  src={option?.avatar}
                >
                  <UserIcon className="h-4 w-4 font-thin text-indigo-700" />
                </Avatar>
              )}
              {option.group === 'Membership Group' && (
                <Avatar className="mr-4 h-8 w-8 rounded-full bg-indigo-100">
                  <UserGroupIcon className="h-4 w-4 font-thin text-indigo-700" />
                </Avatar>
              )}
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              placeholder={
                adminEditMode
                  ? 'View as e.g. Sales'
                  : 'Filter by Group e.g. Sales'
              }
              {...params}
            />
          )}
        />
      </div>
      {isAdminAccess && !showTemplateEditor && (
        <Button
          buttonDataTestId={'goal-templates-button'}
          className={'w-auto px-4 pb-[6px] pt-[6px]'}
          variant={'outlined'}
          onClick={() => {
            if (!showTemplateEditor) {
              history.pushState(null, '', '/app/goals/overview/templates')
              setShowTemplateEditor(true)
            }
          }}
        >
          Templates
        </Button>
      )}
      <div className="w-full max-w-[120px] items-center">
        {isAdminAccess || isSuperAdmin ? (
          <Dropdown
            menuItems={[
              {
                label: 'Create A New Goal',
                onClick: () => {
                  resetSidebarState()
                  setTimeout(() => {
                    setSidebarState(EditFormStates.new)
                  }, 200)
                },
              },
              {
                label: 'Create From A Template',
                onClick: () => {
                  resetSidebarState()
                  createGoalFromTemplate()
                },
              },
            ]}
            buttonProps={{
              variant: 'contained',
              endIcon: <ChevronDownIcon className="h-4 w-4" />,
              className: 'pt-[7px] pb-[7px]',
            }}
            buttonDataTestId={'drag-drop-card-new-goal-button'}
          >
            <span>New Goal</span>
          </Dropdown>
        ) : (
          <Button
            id="goals-header-add-new-obj-button"
            buttonDataTestId="createGoalFromHeader"
            onClick={() => {
              resetSidebarState()
              setTimeout(() => {
                setSidebarState(EditFormStates.new)
              }, 0)
            }}
            fullWidth={false}
            endIcon={<PlusIcon className="h-4 w-4" />}
            className={'pb-[7px] pt-[7px]'}
          >
            <span>New Goal</span>
          </Button>
        )}
      </div>
    </div>
  )
}

export default GoalListAdmin
