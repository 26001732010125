import { FC } from 'react'

import { Chip } from '@mui/material'

import MilestoneArrow from 'src/components/MilestoneArrow/MilestoneArrow'

const MilestoneIndicator: FC<{ count: number }> = ({ count }) => {
  // If no milestones on the count, return empty spacer div
  if (!count) {
    return <div className="xl:min-w-[38px]"></div>
  }

  return (
    <Chip
      label={count}
      avatar={<MilestoneArrow />}
      className="hidden h-full w-[38px] bg-transparent stroke-gray-400 text-gray-400 xl:flex"
    />
  )
}

export default MilestoneIndicator
