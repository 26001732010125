import { useState } from 'react'

type LoadingState = { id: number | null; status: boolean }

const useLoadingState = () => {
  const [loadingState, setLoadingState] = useState<LoadingState>({
    id: null,
    status: false,
  })
  const setLoadingStatus = (id: number | null, status: boolean) => {
    setLoadingState({ id, status })
  }
  const resetLoadingStatus = () => {
    setLoadingState({ id: null, status: false })
  }
  return { loadingState, setLoadingStatus, resetLoadingStatus }
}

export default useLoadingState
