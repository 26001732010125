import { FC } from 'react'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import { useAuth } from 'web/src/Providers'

interface NavTabsProps {
  title?: string
  tabs: {
    name: string
    href: string
    current: boolean
  }[]
}

const NavTabs: FC<NavTabsProps> = ({ title, tabs }) => {
  const { currentUser } = useAuth()

  const clientIsPaused = currentUser.parentData.status !== 'ACTIVE'

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const handleSelectChange = (event) => {
    const url = event.target.value
    window.location.replace(url)
  }

  const currentTab = tabs.find((tab) => tab.current)

  return (
    <div className="sticky top-0 z-40 border-b border-gray-200 bg-gray-50 px-4 pb-5 pt-4 sm:pb-0">
      <div className="md:flex md:items-center">
        {title && (
          <h3 className="text-xl font-medium leading-6 text-gray-900">
            {title}
          </h3>
        )}

        {clientIsPaused && (
          <div className="mt-4 flex md:ml-4 md:mt-0">
            <h4 className="font-bold text-red-500">
              Account {currentUser.parentData.status}
            </h4>
          </div>
        )}
      </div>
      <div className={classNames(title ? 'mt-4' : 'mt-0')}>
        {tabs && (
          <>
            <FormControl size="small" fullWidth className="sm:hidden">
              <InputLabel id="demo-controlled-open-select-label">
                Select a tab
              </InputLabel>
              <Select
                id="current-tab"
                name="current-tab"
                className="w-full"
                label="Select a tab"
                value={currentTab?.href ?? ''}
                onChange={handleSelectChange}
              >
                {tabs.map((tab) => (
                  <MenuItem key={tab.name} value={tab.href}>
                    {tab.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="hidden sm:block">
              <nav className="-mb-px flex space-x-8">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      tab.current
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default NavTabs
