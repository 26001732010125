import { FC } from 'react'

import Button from '../Library/Button/Button'

interface HeroCardProps {
  onClick?: () => void
  title?: string
  subtitle?: string
  backgroundImageUrl?: string
  className?: string
}

interface HeroCardParentElementProps extends HeroCardProps {
  children?: JSX.Element[]
}

const HeroCardParentElement: FC<HeroCardParentElementProps> = ({
  onClick,
  className,
  children,
}) => {
  return onClick ? (
    <Button
      variant="text"
      onClick={onClick}
      className={className + ' items-start text-gray-800'}
    >
      {children}
    </Button>
  ) : (
    <div className={className}>{children}</div>
  )
}

const HeroCard: FC<HeroCardProps> = ({
  onClick,
  title,
  subtitle,
  backgroundImageUrl,
  className,
}) => {
  return (
    <HeroCardParentElement
      onClick={() => {
        onClick()
      }}
      className={`relative flex min-h-[400px] w-full flex-col justify-end overflow-hidden rounded-xl border border-solid border-gray-100 bg-white p-10 text-left shadow-md transition-all hover:bg-white hover:shadow-lg xl:w-1/2 ${
        className ?? ''
      }`}
      backgroundImageUrl={backgroundImageUrl}
    >
      {backgroundImageUrl && (
        <img
          src={backgroundImageUrl}
          className="absolute left-0 top-0 h-full w-full rounded-xl object-cover opacity-[.04]"
          alt=""
        />
      )}
      <h2 className="mb-1 select-none text-xl">{title ?? ''}</h2>
      <p className="text-md select-none text-gray-400">{subtitle ?? ''}</p>
    </HeroCardParentElement>
  )
}

export default HeroCard
