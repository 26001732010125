import React, { FC } from 'react'

import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'

import useAnalytics from 'src/lib/hooks/useAnalytics'

interface ButtonWithContextMenuProps {
  children?: JSX.Element
  label?: string | JSX.Element
  buttonClasses?: string
  reactGAButtonLabel?: string
}

const ButtonWithContextMenu: FC<ButtonWithContextMenuProps> = ({
  children,
  label,
  buttonClasses,
  reactGAButtonLabel,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { trackEvent } = useAnalytics()

  return (
    <>
      <Button
        variant="outlined"
        id="context-positioned-button"
        aria-controls={open ? 'context-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(args) => {
          handleClick(args)
          if (reactGAButtonLabel) {
            trackEvent('Course Reporting', `${reactGAButtonLabel} button click`)
          }
        }}
        className={buttonClasses}
      >
        <span className="pr-2">{label}</span>
        <ChevronDownIcon className="h-4 w-4 text-gray-600" />
      </Button>
      <Menu
        elevation={2}
        id="context-positioned-menu"
        aria-labelledby="context-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          marginTop: '6px',
          padding: 0,
          borderRadius: '16px',
          '& .MuiPaper-root': {
            width: '100vw',
            maxWidth: '300px',
          },
          '& ul': {
            padding: 0,
            '& li': {
              paddingTop: '10px',
              paddingBottom: '10px',
            },
          },
        }}
      >
        <div className="w-full">
          <div className="flex items-center justify-between gap-10 border-b border-gray-400 px-4 py-2">
            <span className="capitalize">{label}</span>
            <Button
              variant="outlined"
              onClick={handleClose}
              className="hover:bg-white-400 w-6 min-w-[unset] border-none p-0 text-gray-600 hover:border-none"
              size="small"
            >
              <XMarkIcon className="h-5 w-5" />
            </Button>
          </div>
        </div>
        <div className="w-full">{children}</div>
      </Menu>
    </>
  )
}

export default ButtonWithContextMenu
