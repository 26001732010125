import { Metadata } from '@redwoodjs/web'

const PasswordResetCompletePage = () => {
  return (
    <>
      <Metadata
        title="Password Reset Complete"
        description="Password Reset Complete"
      />

      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="p-x-8 flex min-h-screen flex-col justify-center">
          <h2 className="text-center text-5xl font-extrabold text-[#038EFD]">
            Your password has been reset successfully!
          </h2>
          <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
            You can now log in to The Hub using your new password.
          </h2>
        </div>
      </div>
    </>
  )
}

export default PasswordResetCompletePage
