import { FC, useEffect, useState } from 'react'

import { ArrowLeftIcon, CheckIcon } from '@heroicons/react/24/outline'
import { Accordion, AccordionDetails } from '@mui/material'

import Button from 'src/components/Library/Button'

import { TaskType } from '../../types'
import SelectableOption from '../Option/SelectableOption'

import QuestionSummary from './QuestionSummary'
const number2words = (num: number) => {
  return 'any one two three four five six seven eight nine ten'.split(' ')[
    num % 11
  ]
}

interface ViewQuestionProps {
  questionIndex: number
  question: TaskType['quiz'][0]
  isExpandable: boolean
  isExpanded?: boolean
  onSubmit?: (results: boolean[][]) => void
  onGoBack?: () => void
  results: boolean[][]
  isMarking?: boolean
  showAnswers?: boolean
}

const ViewQuestion: FC<ViewQuestionProps> = ({
  questionIndex,
  question,
  isExpandable,
  isExpanded,
  onSubmit,
  onGoBack,
  results,
  isMarking = false,
  showAnswers,
}) => {
  const markQuestionIsCorrect = () => {
    let correct = true
    question.choices.forEach((choice, choiceIndex) => {
      if (choice['isAnswer'] !== results[questionIndex][choiceIndex]) {
        correct = false
      }
    })
    return correct
  }

  const [expand, toggleExpand] = useState<boolean>(
    isMarking && markQuestionIsCorrect() ? false : isExpanded || true,
  )
  const [selectedChoices, setSelectedChoice] = useState<boolean[]>(
    results[questionIndex],
  )

  const [lastSelectedChoice, setLastSelectedChoice] = useState(-1)

  const [answeredAllQuestions, setAnsweredAllQuestions] = useState(false)

  const handleSelectChoice = (choiceIndex: number) => {
    const selection = selectedChoices
    selection[choiceIndex] = !selectedChoices[choiceIndex]
    const answeredCount = selection.filter((choice) => choice).length

    if (answeredCount > talliedAnswerables) {
      selection[lastSelectedChoice - 1] = false
    }

    setSelectedChoice(selection)

    const answeredCount2 = selection.filter((choice) => choice).length

    const answeredAllQuestions = answeredCount2 >= talliedAnswerables
    setAnsweredAllQuestions(answeredAllQuestions)
  }

  //goes through each answer and tallys isAnswer to be true
  const talliedAnswerables = question.choices.reduce(
    (acc: number, val) => (val['isAnswer'] ? acc + 1 : acc),
    0,
  ) as number

  useEffect(() => {
    setSelectedChoice(results[questionIndex])
  }, [questionIndex])

  useEffect(() => {
    const answeredCount = selectedChoices.filter((choice) => choice).length
    const answeredAllQuestions = talliedAnswerables === answeredCount
    setAnsweredAllQuestions(answeredAllQuestions)

    // if they have already answered questions, pick one of the answered ones
    // to be the last selected
    const lastIndex = selectedChoices.lastIndexOf(true)
    if (lastIndex !== -1) {
      setLastSelectedChoice(lastIndex + 1)
    }
  }, [selectedChoices])

  return (
    <div key={question.id} className="mt-6 flex flex-1">
      <Accordion
        expanded={expand}
        className="w-full rounded border border-gray-200 bg-gray-100"
      >
        <QuestionSummary
          toggleExpand={toggleExpand}
          isMarking={isMarking}
          markQuestionIsCorrect={markQuestionIsCorrect}
          isExpandable={isExpandable}
          expand={expand}
          question={question}
        />
        <AccordionDetails className="p-0 px-2">
          <div className={`pl-1.5 ${isMarking ? 'mt-1' : 'mt-5'} mb-1.5 h-5`}>
            {!isMarking && (
              <div>
                Select{' '}
                {talliedAnswerables <= 10
                  ? number2words(talliedAnswerables)
                  : talliedAnswerables}{' '}
                option
                {talliedAnswerables > 1 && 's'}
              </div>
            )}
          </div>
          {/* TODO: radio Buttons and checkboxes */}
          {question.choices.map((choice, choiceIndex) => (
            <SelectableOption
              key={
                selectedChoices[choiceIndex] +
                ' ' +
                questionIndex +
                ' ' +
                choiceIndex
              }
              choice={choice}
              selectedChoice={selectedChoices[choiceIndex]}
              onSelectChoice={() => handleSelectChoice(choiceIndex)}
              setLastSelectedChoice={setLastSelectedChoice}
              isMarking={isMarking}
              showAnswers={showAnswers}
            />
          ))}
          <div className="flex w-full items-center justify-between py-6">
            {questionIndex > 0 && !isMarking ? (
              <Button
                variant="outlined"
                fullWidth={false}
                size="small"
                onClick={() => onGoBack()}
                disabled={questionIndex === 0}
                startIcon={<ArrowLeftIcon className="2-4 h-4" />}
              >
                Previous Question
              </Button>
            ) : (
              <div />
            )}
            {!isMarking && (
              <Button
                fullWidth={false}
                className={` ${
                  answeredAllQuestions
                    ? 'border-indigo-600'
                    : 'border-indigo-200'
                } ${
                  answeredAllQuestions
                    ? 'rounded bg-indigo-600'
                    : 'rounded bg-indigo-200'
                } `}
                onClick={() => {
                  const newResults = results
                  newResults[questionIndex] = selectedChoices
                  onSubmit(newResults)
                }}
                disabled={!answeredAllQuestions}
                startIcon={<CheckIcon className="h-4 w-4" />}
              >
                Confirm Selection
              </Button>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default ViewQuestion
