import { useCallback, useEffect, useState, useMemo, FC } from 'react'

import { ApolloError } from '@apollo/client'
import { SUPPORTED_FILE_MIME_TYPES } from 'api/src/common/enums'
import {
  GetLearnerCourseHeroStorageObject,
  GetLearnerCourseHeroStorageObjectVariables,
} from 'types/graphql'

import { Form, FormError, FieldError, Label, TextField } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import CoursePlaceholderImage from 'src/components/Learner/Courses/CoursePlaceholderImage/CoursePlaceholderImage'
import Button from 'src/components/Library/Button/Button'
import LearnerCategoriesSelectCell from 'src/components/Settings/LearnerCategory/LearnerCategoriesSelectCell'
import useUploadStorageObject from 'src/lib/hooks/StorageObject/useUploadStorageObject'
import { useAuth } from 'web/src/Providers'

import { LearnerCourse } from '../EditLearnerCourseCell'

const GET_STORAGE_OBJECT = gql`
  query GetLearnerCourseHeroStorageObject($id: Int!) {
    storageObject(id: $id) {
      id
      downloadUrl
    }
  }
`

function dataURLtoFile(dataURL: string, filename: string) {
  const arr = dataURL.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

type ImageGenerator = {
  getImageData: () => any | null
}

interface LearnerWithHeroStorageObjectID extends LearnerCourse {
  heroStorageObjectId: number | null
}

interface LearnerCourseFormProps {
  learnerCourse: LearnerCourse
  learnerCategoryId: number
  onSave: (input: unknown) => void
  error: ApolloError
  cancel: () => void
}

const LearnerCourseForm: FC<LearnerCourseFormProps> = ({
  learnerCourse,
  learnerCategoryId,
  onSave,
  error,
  cancel,
}) => {
  const { currentUser } = useAuth()
  const clientId = useMemo(() => currentUser.parentData.id, [currentUser])

  const [heroStorageObject, setHeroStorageObject] = useState(
    learnerCourse?.heroStorageObject,
  )

  const [randomImage, setRandomImage] = useState<ImageGenerator | null>({
    getImageData: () => null,
  })
  const [tempData, setTempData] = useState<LearnerWithHeroStorageObjectID>(null)
  const [saveReady, setSaveReady] = useState(false)

  const selectedCategoryId =
    learnerCourse?.learnerCategoryId ?? learnerCategoryId
  const [courseName, setCourseName] = useState(learnerCourse?.name)

  const [selectedCategory, setSelectedCategory] = useState(selectedCategoryId)

  const handleCourseNameChange = (event) => {
    setCourseName(event.target.value.slice(0, 96))
  }

  useEffect(() => {
    if (saveReady && tempData) {
      const data = tempData
      data.heroStorageObjectId = heroStorageObject?.id
      onSave(data)
    }
  }, [saveReady, tempData])

  // eslint-disable-next-line require-explicit-generics/require-explicit-generics
  const { loading: getHeroLoading } = useQuery<
    GetLearnerCourseHeroStorageObject,
    GetLearnerCourseHeroStorageObjectVariables
  >(GET_STORAGE_OBJECT, {
    skip: !learnerCourse?.heroStorageObject,
    variables: { id: heroStorageObject?.id },
    onCompleted: ({ storageObject }) => {
      setHeroStorageObject(storageObject)
    },
  })

  const onSubmit = (data) => {
    data.learnerCategoryId = selectedCategory
    if (heroStorageObject?.id === undefined || heroStorageObject?.id === null) {
      // Generate the random image/png data
      const imageData = randomImage.getImageData()

      // Make it a file
      const file = dataURLtoFile(imageData, 'hero.png')

      // Upload
      uploadHero({ file })
    } else {
      data.heroStorageObjectId = heroStorageObject?.id

      onSave(data)
    }
    setTempData(data)
  }

  const [
    uploadHero,
    { result: heroResult, error: heroError, loading: heroLoading },
  ] = useUploadStorageObject({
    prefixes: [`${clientId}`, 'learner', 'course'],
    fileName: 'hero',
    allowedFileMimeTypes: [SUPPORTED_FILE_MIME_TYPES.IMAGE],
  })

  useEffect(() => {
    if (heroResult) {
      setHeroStorageObject(heroResult)
      setSaveReady(true)
    }
  }, [heroResult])

  useEffect(() => {
    heroError && toast.error(heroError.message)
  }, [heroError])

  const uploadHeroHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files || event.target.files.length === 0) {
        toast.error('You must select an image to upload.')
        return
      }

      /** @type {File} */
      const file: File = event?.target?.files?.[0]
      uploadHero({ file })
    },
    [uploadHero],
  )

  return (
    <div className="rw-form-wrapper p-4">
      <Form onSubmit={onSubmit} error={error}>
        <div className="flex flex-col items-center justify-center">
          <div className="flex max-w-xl items-center justify-center p-8">
            {heroStorageObject?.downloadUrl ? (
              <img
                alt={'hero'}
                className="w-90 h-52 rounded-lg"
                src={heroStorageObject?.downloadUrl}
              />
            ) : (
              <CoursePlaceholderImage
                height={160}
                width={305}
                setImage={setRandomImage}
              />
            )}
          </div>

          <input
            type="file"
            accept="image/*"
            className="mt-4 w-96 rounded-md border border-gray-300 bg-white px-3 py-4 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onChange={(e) => uploadHeroHandler(e)}
            disabled={heroLoading || getHeroLoading}
          />
          <p className="mb-2 mt-4 max-w-2xl text-sm text-gray-500">
            Course image (if no image is selected a random image will be used)
          </p>
        </div>
        <div className="mx-auto flex max-w-2xl flex-col">
          <FormError
            error={error}
            wrapperClassName="rw-form-error-wrapper"
            titleClassName="rw-form-error-title"
            listClassName="rw-form-error-list"
          />
          <Label
            name="name"
            className="rw-label"
            errorClassName="rw-label rw-label-error"
          >
            Course title
          </Label>
          <TextField
            name="name"
            defaultValue={learnerCourse?.name}
            value={courseName}
            className="rw-input"
            errorClassName="rw-input rw-input-error"
            validation={{ required: true }}
            onChange={handleCourseNameChange}
          />
          <FieldError name="name" className="rw-field-error" />
          <Label
            name="learnerCourseId"
            className="rw-label mb-2"
            errorClassName="rw-label rw-label-error"
          >
            Category
          </Label>

          <LearnerCategoriesSelectCell
            setSelectedCategory={setSelectedCategory}
            learnerCategoryId={selectedCategory}
          />
          <FieldError name="learnerCategoryId" className="rw-field-error" />
        </div>
        <div className="pt-5">
          <div className="flex justify-end gap-4">
            <Button fullWidth={false} variant="outlined" onClick={cancel}>
              Cancel
            </Button>
            <Button fullWidth={false} type="submit">
              Save
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default LearnerCourseForm
