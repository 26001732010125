import { FC } from 'react'

import { ExclamationCircleIcon } from '@heroicons/react/24/solid'

import { CellAutomation } from '../AutomationCell'

interface AutomationTrackWarningsProps {
  automation: CellAutomation
}

const AutomationTrackWarnings: FC<AutomationTrackWarningsProps> = ({
  automation,
}) => {
  return (
    <div className="mx-auto flex w-full max-w-xl flex-col gap-10 py-10">
      {!automation?.isValidConfig?.valid && (
        <div className="flex items-start gap-6 rounded border border-red-400 bg-red-50 p-4">
          <ExclamationCircleIcon className="h-6 w-6 text-red-400" />
          <div>
            <p className="text-red-500">
              There are issues preventing this automation from running.
            </p>
            <ul className="pt-2">
              {automation?.isValidConfig?.requirements?.map((requirement) => (
                <li key={requirement} className="py-1 text-sm">
                  {requirement}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

export default AutomationTrackWarnings
