import { Box } from '@mui/material'
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

const CircularProgressWithLabel = (
  props: CircularProgressProps & {
    value?: number
    text?: string
    size?: string
  },
) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={100}
        size={props.size}
        className="z-2 relative text-gray-200"
      />
      <CircularProgress
        variant="determinate"
        {...props}
        className="z-1 absolute right-0"
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: props.size || '40px',
          width: props.size || '40px',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          className="font-bold"
        >
          {props.text || `${Math.floor(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

export default CircularProgressWithLabel
