import { Dispatch, SetStateAction } from 'react'

import { POLARITY } from 'api/src/common/enums'
import {
  AnalyseNewEmailsMutationVariables,
  LandlordEmail,
  OverallESIScore,
  type Landlord,
  type SentimentStatusOptions,
} from 'types/graphql'

import { SelectionType } from '../Library/SelectWithSort/SelectWithSort'
import type { FindSettingsSentimentAnalysisMembership } from '../Settings/SentimentAnalysis/SettingsSentimentAnalysisScoreGridCell'

import type { LandlordEmailWithFlaggedEmail } from './Fragments'
import { SentimentAnalysisPermission } from './SentimentAnalysisPermissionCell'

export interface UserPreview extends OverallESIScore {
  name: string
  position?: string
  avatarUrl?: string
}

interface StatusCounts {
  high: number
  mid: number
  low: number
}

export interface FilterByOption {
  id: number
  name: string
  avatarUrl?: string
}

export interface SentimentAnalysisUserBarProps {
  userProfile: UserPreview
  isAnalysing: boolean
  isUnlinking: boolean
  noSavedToken: boolean
  userIsAuthenticated: boolean
  refreshTokenExpiresOn: Date
  generateAzureCode: (reAuth?: boolean) => void
  analyseLandlordEmails: (variables?: AnalyseNewEmailsMutationVariables) => void
  azureConnected: boolean
  unlinkEmailAccount: () => void
  sentimentAnalysisPermission: SentimentAnalysisPermission
}

export interface SentimentAnalysisCSIScoreProps {
  overallScore: number
  statusCounts?: StatusCounts
  hideCounts?: boolean
  tooltip?: string
}

export interface SentimentAnalysisItemHeaderProps {
  propertyOwner: {
    id: number
    name: string
    csiRating: number
    emailCensored: string
    emails?: unknown[]
    sentimentStatus?: SentimentStatusOptions
  }
  hideEmailScore?: boolean
  avatarUrl?: string
  totalEmails?: number
  inboxEmail?: string
  isClient?: boolean
  isLoading?: { id: number; status: boolean }
  handleClickOpen?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => void
}

export interface SentimentAnalysisBadgeProps {
  title: string
  value: number | string
  variant?: SentimentAnalysisLabelThemes
  className?: string
}

export interface SentimentAnalysisScoreCardProps {
  title: string
  tooltip: string
  scores: LandlordEmail['sentimentScores']
  displayScores: SentimentAnalysisEmotions[]
}

export interface SentimentAnalysisScoreProps {
  title: string
  score: number
}

export interface SentimentAnalysisGraphProps {
  emails: LandlordEmailWithFlaggedEmail[]
  hideGraph?: boolean
  tileTitle?: string | JSX.Element
  filterDateRange?: SADateFilterOptions
  unprocessedEmailsCount?: number
  selectedBucket?: SABucketOptions
}
export interface SentimentAnalysisGraphBarProps {
  emails: LandlordEmail[]
}

export enum SentimentAnalysisBadgeClass {
  success = 'text-green-600 bg-green-100',
  warning = 'text-yellow-700 bg-yellow-100',
  danger = 'text-red-600 bg-red-100',
  default = 'text-gray-600 bg-slate-100',
}

export enum SentimentAnalysisEmotions {
  happiness = 'happiness',
  humour = 'humour',
  anger = 'anger',
  sarcasm = 'sarcasm',
}

export interface SentimentAnalysisUserAgreementProps {
  recordId?: number
  appAgreement?: boolean
}

export interface SentimentAnalysisScoreGridProps {
  memberships: FindSettingsSentimentAnalysisMembership[]
  sortByValues: SelectionType
  filterByMember: FilterByOption[]
  filterDateRange: SADateFilterOptions
  filterESIRating: SentimentAnalysisRatingOptions
  filterByFlag: SentimentAnalysisFlagOptions
  filterByClientStatus: SentimentStatusSelectOptions
  debouncedSearchText: string
}

export interface SentimentAnalysisLandlordAccordionProps {
  // Not fully typed: missing `landlordsWithEmailsAndSentimentScores.emails`
  membership: FindSettingsSentimentAnalysisMembership & {
    memberDetails: Landlord
  }
  filterDateRange: SADateFilterOptions
  filterESIRating: SentimentAnalysisRatingOptions
  filterByFlag: SentimentAnalysisFlagOptions
  filterByClientStatus: SentimentStatusSelectOptions
  debouncedSearchText: string
}

export interface SentimentAnalysisLandlordLabelProps {
  id: number
  name: string
  email: string
  csiRating: number
  emailCount: number
  onClick: Dispatch<SetStateAction<number>>
  activeLandlordId: number | null
  sentimentStatus: SentimentStatusOptions
}

export interface SentimentAnalysisScoreGridFilterBarProps {
  membershipOptions: FilterByOption[]
  sortByValues: SelectionType
  setSortByValues: Dispatch<SetStateAction<SelectionType>>
  filterByMember: FilterByOption[]
  setFilterByMember: Dispatch<SetStateAction<FilterByOption[]>>
  filterDateRange: SADateFilterOptions
  setFilterDateRange: Dispatch<SetStateAction<SADateFilterOptions>>
  setFilterDateValue: Dispatch<SetStateAction<string | null>>
}

export interface SentimentAnalysisFilterBarProps {
  totalLandlordCount?: number
  filterByOptions: FilterByOption[]
  filterByValues: FilterByOption[]
  setFilterByValues: Dispatch<SetStateAction<FilterByOption[]>>
  sortByValues: SelectionType
  setSortByValues: Dispatch<SetStateAction<SelectionType>>
  filterDateRange: SADateFilterOptions
  setFilterDateRange: Dispatch<SetStateAction<SADateFilterOptions>>
  setModalOpen?: Dispatch<SetStateAction<boolean>>
  filterESIRating: SentimentAnalysisRatingOptions
  setFilterESIRating: Dispatch<SetStateAction<SentimentAnalysisRatingOptions>>
  adminView?: boolean
  selectedFlagFilter: SentimentAnalysisFlagOptions
  setSelectedFlagFilter: Dispatch<SetStateAction<SentimentAnalysisFlagOptions>>
  filterByClientStatus: SentimentStatusSelectOptions
  setFilterByClientStatus: Dispatch<
    SetStateAction<SentimentStatusSelectOptions>
  >
  currentSearchText?: string
  setCurrentSearchText?: Dispatch<SetStateAction<string>>
  totalFilteredLandlordsCount?: number
}

export enum SentimentAnalysisMetric {
  esi = 'Health',
  pbi = 'PBI',
}
export enum SentimentAnalysisMetricName {
  esi = 'Health Score',
  pbi = 'Property Burden Index',
}

export enum SentimentAnalysisLabelThemes {
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  default = 'default',
}

export enum SASortOptions {
  name = 'name',
  esi = 'Health',
  latest = 'latest',
}

export enum SADateFilterOptions {
  ALL = 'ALL',
  TODAY = 'TODAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum SABucketOptions {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum SADateFilterOptionNames {
  ALL = 'All Time',
  TODAY = 'Today',
  WEEK = 'Last 7 Days',
  MONTH = 'Last 30 Days',
  YEAR = 'Last 12 Months',
}

export enum SentimentAnalysisUserStatus {
  notInvited = 'Not Invited',
  invited = 'Invited',
  active = 'Active User',
  disabled = 'Disabled',
}

export enum SentimentAnalysisAgreement {
  termsAndConditions = 'This is the terms and conditions for individual users',
  appTermsAndConditions = 'This is the terms and conditions for the application',
}

export enum SentimentAnalysisESIRatingLabels {
  ALL = 'all',
}

export const SentimentAnalysisRatingOptions = {
  ...SentimentAnalysisESIRatingLabels,
  ...POLARITY,
}
export type SentimentAnalysisRatingOptions =
  (typeof SentimentAnalysisRatingOptions)[keyof typeof SentimentAnalysisRatingOptions]

export enum SentimentAnalysisFlagOptions {
  ALL = 'ALL',
  FLAGGED = 'FLAGGED',
  REVIEWED = 'REVIEWED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  NOT_FLAGGED = 'NOT_FLAGGED',
}

export enum SentimentStatusSelectOptions {
  ALL = 'ALL',
  IN_REVIEW = 'IN_REVIEW',
  AT_RISK = 'AT_RISK',
  NOT_IN_REVIEW = 'NOT_IN_REVIEW',
}

export const SentimentAnalysisStatusOptionsDisplayMap: Record<
  SentimentStatusSelectOptions,
  string
> = {
  [SentimentStatusSelectOptions.ALL]: 'All',
  [SentimentStatusSelectOptions.IN_REVIEW]: 'In Review',
  [SentimentStatusSelectOptions.AT_RISK]: 'At Risk',
  [SentimentStatusSelectOptions.NOT_IN_REVIEW]: 'Not In Review',
}
