import { FC } from 'react'

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import { isNil } from 'ramda'

import { Link, routes } from '@redwoodjs/router'

import CircularProgressWithLabel from 'src/components/CircularProgressWithLabel/CircularProgressWithLabel'
import { LearnerCourseWithStorageObject } from 'src/lib/interfaces'

import { CoursePreviewImage } from './CoursePreviewImage'

const LearnerCourseProgressPreview: FC<{
  learnerCourse: LearnerCourseWithStorageObject
  percentProgress: number
}> = ({ learnerCourse, percentProgress }) => {
  // if course attached, but not found (no permission or deleted)
  if (!learnerCourse) {
    return (
      <div className="flex flex-col items-center overflow-hidden rounded-lg bg-white">
        <div className="grid h-24 w-full place-items-center rounded bg-gray-100 p-5">
          <span className="block text-center text-sm font-light text-gray-400">
            You do not have access to the linked course.
            <br />
            Please select another.
          </span>
        </div>
      </div>
    )
  }

  // Set the Course Preview Image
  const learnerCoursePreviewImageUrl =
    learnerCourse?.heroStorageObject?.downloadUrl

  // Set the course title
  const courseTitle = learnerCourse?.name

  const isMilestoneCreated = !isNil(percentProgress)

  return (
    <div className="relative flex flex-col overflow-hidden rounded-lg border-t border-gray-200 bg-white shadow-md">
      {isMilestoneCreated && (
        <Link
          className="absolute left-2 mt-2 flex rounded border border-gray-100 bg-white p-1 text-indigo-600 shadow-md"
          to={routes.learnerCourse({ id: learnerCourse.id })}
          title="Go to Course"
        >
          Open course
          <ArrowTopRightOnSquareIcon className="ml-1 h-6 w-6" />
        </Link>
      )}
      <CoursePreviewImage
        imageUrl={learnerCoursePreviewImageUrl}
        variant="large"
      />
      <div className="flex w-full flex-row items-center justify-between gap-2 border-t p-4">
        <p className="font-bold">{courseTitle}</p>
        <CircularProgressWithLabel
          value={percentProgress}
          variant="determinate"
          size="50px"
          sx={{
            color: percentProgress >= 100 ? '#34d399' : '#2563eb',
          }}
        />
      </div>
    </div>
  )
}

export default LearnerCourseProgressPreview
