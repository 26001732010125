import { Drawer } from '@mui/material'

import CardLibraryCell from 'src/components/HubDash/CardLibrary/CardLibraryCell'

export const CardLibrary = ({ cardLibraryOpen, setCardLibraryOpen }) => {
  const handleClose = () => {
    setCardLibraryOpen(false)
  }

  return (
    <Drawer
      anchor={'right'}
      open={cardLibraryOpen}
      onClose={() => handleClose()}
      PaperProps={{
        style: {
          width: '600px',
        },
      }}
    >
      <div className="flex h-full w-full flex-col overflow-auto p-4">
        <h1 className="text-2xl font-bold">Card Library</h1>
        <CardLibraryCell />
      </div>
    </Drawer>
  )
}

export default CardLibrary
