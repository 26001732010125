import { Metadata } from '@redwoodjs/web'

import HubsrowCell from 'src/components/HubsrowCell'
import PageHeader from 'src/components/PageHeader/PageHeader'

const HubsrowPage = () => {
  return (
    <div className="flex h-screen flex-col">
      <Metadata title="Bases" description="Bases" />
      <PageHeader title="Bases" parentDataTestId="bases-page-title" />
      <HubsrowCell />
    </div>
  )
}

export default HubsrowPage
