import { FC, useEffect, useState } from 'react'

import Chip from '@mui/material/Chip'
import { LearnerAttempt, Membership } from 'types/graphql'

import useAnalytics from 'src/lib/hooks/useAnalytics'
import { formatUpdatedAtDate } from 'web/src/Util'

import ProgressRow from './ProgressRow'

interface IndividualProgressProps {
  courseId: number
  learnerCourseTrackings: LearnerAttempt[]
  memberships: Membership[]
  searchVal: string
  setLearnerSummaryModalOpen: (open: boolean) => void
  setSelectedUserId: (userId: number) => void
}

export const IndividualProgress: FC<IndividualProgressProps> = ({
  courseId,
  learnerCourseTrackings,
  memberships,
  searchVal,
  setLearnerSummaryModalOpen,
  setSelectedUserId,
}) => {
  enum statusFilters {
    ALL = 'All',
    COMPLETED = 'Completed',
    IN_PROGRESS = 'In Progress',
  }

  const [filterStatus, setFilterStatus] = useState('ALL')

  const [learnerCourseActivityFeed, setLearnerCourseActivityFeed] = useState(
    learnerCourseTrackings,
  )

  const { trackEvent } = useAnalytics()

  const filterCourseTracking = (learnerCourseTrackings, status, searchVal) => {
    // IF All or no searchVal, return All

    if (status === 'ALL' && searchVal === '') return learnerCourseTrackings

    const learnerCourseTrackingsByStatus =
      status === 'ALL'
        ? learnerCourseTrackings
        : learnerCourseTrackings.filter(
            (courseTracking) => courseTracking.status === status,
          )

    const learnerCourseTrackingsBySearchVal =
      learnerCourseTrackingsByStatus.filter((courseTracking) => {
        const membership = memberships.find(
          (membership) =>
            membership.id === courseTracking.relatedTracking.membershipId,
        )

        const isMemberNameMatch = membership.user.name
          .toLowerCase()
          .includes(searchVal.toLowerCase())

        const isCourseTrackingDateMatch = formatUpdatedAtDate(
          courseTracking.relatedTracking.updatedAt,
        ).includes(searchVal)

        return isMemberNameMatch || isCourseTrackingDateMatch
      })

    return learnerCourseTrackingsBySearchVal
  }

  // Filter items by status on change AND on searchVal change
  useEffect(() => {
    setLearnerCourseActivityFeed(
      filterCourseTracking(learnerCourseTrackings, filterStatus, searchVal),
    )
  }, [filterStatus, searchVal, courseId])

  const feedRows = []
  const excludedMembershipIds = []
  for (const learnerCourseActivityFeedItem of learnerCourseActivityFeed) {
    excludedMembershipIds.push(
      learnerCourseActivityFeedItem.relatedTracking.membershipId,
    )
    feedRows.push(
      <ProgressRow
        key={
          learnerCourseActivityFeedItem.relatedTracking.id +
          '_' +
          filterStatus +
          '_' +
          learnerCourseActivityFeedItem.relatedTracking.membershipId
        }
        learnerCourseTracking={learnerCourseActivityFeedItem}
        membership={memberships.find(
          (membership) =>
            membership.id ===
            learnerCourseActivityFeedItem.relatedTracking.membershipId,
        )}
        setLearnerSummaryModalOpen={setLearnerSummaryModalOpen}
        setSelectedUserId={setSelectedUserId}
      />,
    )
  }

  feedRows.sort((a, b) => {
    return (
      new Date(
        b.props.learnerCourseTracking?.relatedTracking?.updatedAt,
      ).getTime() -
      new Date(
        a.props.learnerCourseTracking?.relatedTracking?.updatedAt,
      ).getTime()
    )
  })

  return (
    <div className="relative max-h-[calc(100vh-77px)] overflow-y-scroll">
      <div className="sticky top-0 z-10 flex items-center justify-between bg-white">
        <div className="w-full border-b border-gray-300 p-6 py-4">
          <div className="flex items-center gap-2">
            {Object.keys(statusFilters).map((statusFilterKey, index) => {
              return (
                <Chip
                  key={index}
                  label={statusFilters[statusFilterKey]}
                  onClick={() => {
                    setFilterStatus(statusFilterKey)
                    trackEvent(
                      'Course Reporting',
                      'individual progress change filter',
                      { newFilterName: statusFilterKey },
                    )
                  }}
                  size="small"
                  className={`px-1 pt-1 ${
                    statusFilterKey === filterStatus
                      ? 'bg-indigo-200 text-indigo-600 hover:bg-indigo-200'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  }`}
                />
              )
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        {!feedRows.length ? (
          <p className="mt-8 w-full text-center text-gray-400">
            No activity found
            <br />
            {filterStatus !== 'ALL' && (
              <small>Status: {statusFilters[filterStatus]}</small>
            )}
            <br />
            {searchVal !== '' && <small>Search: {searchVal}</small>}
          </p>
        ) : (
          feedRows
        )}
      </div>
    </div>
  )
}
