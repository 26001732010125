import React from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Typography from '@mui/material/Typography'
import { DetailedViewRecord } from 'api/src/common/baserowImporterTypes'

interface BaserowImporterViewInformationModalProps {
  enrichedData: DetailedViewRecord
}

const BaserowImporterModalViewDetailsColumnVisibility = ({
  enrichedData,
}: BaserowImporterViewInformationModalProps) => {
  return (
    <Accordion>
      <AccordionSummary
        className="rounded border border-solid border-gray-300"
        expandIcon={<ChevronDownIcon className="h-5 w-5 cursor-pointer" />}
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'column', // Display details in a column
            alignItems: 'flex-start', // Align details to the left
          },
        }}
      >
        <Typography fontWeight={'bold'}>Description</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{enrichedData?.details?.description}</Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default BaserowImporterModalViewDetailsColumnVisibility
