import React, { FC, useEffect, useState } from 'react'

import { CheckCircleIcon } from '@heroicons/react/24/outline'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import dayjs from 'dayjs'
import {
  FindReactMapTemplatesQuery,
  FindReactMapTemplatesQueryVariables,
} from 'types/graphql'
import { useBoolean } from 'usehooks-ts'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import Checkbox from 'src/components/Library/Checkbox/Checkbox'
import { default as EmptyData } from 'src/components/Library/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { default as Spinner } from 'src/components/Library/Loading/Loading'
import { SearchField } from 'src/components/Library/SearchField/SearchField'

type MapTemplate = FindReactMapTemplatesQuery['templates'][0]

export type FindReactMapTemplatesQuerySuccessProps = CellSuccessProps<
  FindReactMapTemplatesQuery,
  FindReactMapTemplatesQueryVariables
>

export interface CreateMapsFromTemplateMutationVariables {
  templateIds: number[]
  categoryId: number
}

export const QUERY = gql`
  query FindReactMapTemplatesQuery {
    templates: reactMapsByTemplate {
      id
      clientId
      updatedAt
      name
      description
      isVisible
      nodes
      connectors
      legend
    }
  }
`

export const Loading = () => (
  <div className={'mb-2 p-2'}>
    <LoadingSpinner />
  </div>
)

export const Empty = () => (
  <div className="mb-16">
    <EmptyData title="No Templates Found">
      <p>There are no templates available for this client.</p>
    </EmptyData>
  </div>
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export type CellReactMapTemplates =
  FindReactMapTemplatesQuerySuccessProps['templates']

export interface CreateMapsFromTemplateMutation {
  (options?: {
    variables: CreateMapsFromTemplateMutationVariables
  }): Promise<any>
}

export interface ReactMapTemplatesCellProps {
  templates: CellReactMapTemplates
  createMapsFromTemplate: CreateMapsFromTemplateMutation
  selectedCategory: { id: number }
  modalVisible: { value: boolean; setFalse: () => void }
}

export const Success: FC<ReactMapTemplatesCellProps> = ({
  templates,
  createMapsFromTemplate,
  selectedCategory,
  modalVisible,
}) => {
  const [checked, setChecked] = useState<MapTemplate[]>([])
  const [isDisabled, setIsDisabled] = useState(true)
  const [search, setSearch] = useState('')
  const doneCreating = useBoolean(false)
  const loading = useBoolean(false)

  useEffect(() => {
    setIsDisabled(checked.length > 0)
  }, [checked])

  const handleToggle = (item: MapTemplate) => () => {
    const currentIndex = checked.indexOf(item)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(item)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const createTemplate = async () => {
    doneCreating.setTrue()
    loading.setTrue()

    const templateIds = checked.map((template) => {
      return template.id
    })
    const categoryId = selectedCategory.id

    try {
      await createMapsFromTemplate({ variables: { templateIds, categoryId } })
      loading.setFalse()
    } catch (error) {
      modalVisible.setFalse()
      toast.error(error.message)
    }
  }

  return (
    <>
      {!doneCreating.value && (
        <>
          <div className={'items-center justify-center px-2'}>
            <SearchField
              value={search}
              onChange={(value: React.SetStateAction<string>) => {
                setSearch(value)
              }}
              placeholder={'Search Map Templates'}
            />
            <div className="my-2 h-96 items-center overflow-y-auto border-t border-gray-200 px-2">
              <List>
                {templates
                  .filter((item) =>
                    item.name.toLowerCase().includes(search.toLowerCase()),
                  )
                  .map((item) => (
                    <ListItem
                      key={item.id}
                      className={'my-2'}
                      alignItems="center"
                      dense
                    >
                      <ListItemButton
                        onClick={handleToggle(item)}
                        alignItems="center"
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.indexOf(item) !== -1}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <div className="flex w-1/2">
                          <ListItemText
                            primary={
                              <p
                                className={
                                  'text-sm font-normal leading-5 text-gray-700'
                                }
                              >
                                {item.name}
                              </p>
                            }
                          />
                        </div>
                        <div className="flex w-1/2 !justify-end">
                          <ListItemText
                            primary={
                              <p
                                className={
                                  'text-sm font-normal leading-5 text-gray-700'
                                }
                              >
                                {dayjs(item.updatedAt).format('DD/MM/YYYY')}
                              </p>
                            }
                          />
                        </div>
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </div>
          </div>
          <div className="flex w-full flex-row justify-end border border-t-gray-300 bg-gray-100 p-2">
            <Stack direction={'row'} spacing={2}>
              <Button
                variant={'text'}
                onClick={() => {
                  modalVisible.setFalse()
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!isDisabled}
                onClick={() => {
                  createTemplate()
                }}
              >
                Create
              </Button>
            </Stack>
          </div>
        </>
      )}

      {doneCreating.value && (
        <>
          <div
            className={
              'my-4 w-full items-center justify-center px-2 text-center'
            }
          >
            {loading.value && (
              <>
                <Spinner />
                <p>Creating Process Maps</p>
              </>
            )}

            {!loading.value && (
              <div className={'w-full items-center justify-center text-center'}>
                <CheckCircleIcon
                  className={'m-auto h-[145px] w-[145px] text-green-600'}
                />
                <p className="my-2 text-xl font-semibold leading-8 text-gray-600">
                  Process Maps Created
                </p>
                <div className={'h-6'}>
                  <Button
                    onClick={() => {
                      modalVisible.setFalse()
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}
