import { FC } from 'react'

import { CheckCircleIcon, ShareIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'

import { navigate, routes } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import CircularProgressWithLabel from 'src/components/CircularProgressWithLabel/CircularProgressWithLabel'
import { default as Placeholder } from 'src/components/Empty/Empty'
import IconButton from 'src/components/IconButton/IconButton'
import { LearnerCategoryCourse } from 'src/components/Learner/Courses/types'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'src/Providers'

interface CourseItemProps {
  course: LearnerCategoryCourse
}
const CourseItem: FC<CourseItemProps> = ({ course }) => {
  const percentage = course.reporting?.attempts?.[0]?.percentage || 0

  const { trackEvent } = useAnalytics()

  const { currentUser } = useAuth()

  const copyToClipboard = (e) => {
    e.stopPropagation()
    const hostname = window.location.hostname
    const path = hostname + '/app/learn/course/' + course.id
    navigator.clipboard
      .writeText(path)
      .then(() => toast.success('Course link copied to clipboard'))
      .catch(() => {})
    trackEvent('Uncategorized', 'copyUrl', {
      copyUrl: path,
    })
  }

  return (
    <div
      key={'course-' + course.id}
      className={
        'mb-4 mr-4 min-h-48 w-60 rounded-lg border border-gray-100 bg-white shadow transition-transform duration-300 hover:-translate-y-1 hover:translate-x-1 hover:drop-shadow-xl'
      }
      onClick={() => {
        navigate(routes.learnerCourse({ id: course.id }))
        trackEvent('Learner', 'Clicked Course', {
          courseName: course.name,
        })
      }}
      onKeyUp={() => {}}
      tabIndex={0}
      role="button"
      data-testid="course-item"
    >
      <div className="group relative min-h-32 w-full">
        <div className="group relative min-h-32 w-full">
          {course.heroUrl ? (
            <img
              className="h-32 w-full rounded-t-lg object-cover hover:z-50"
              src={course.heroUrl}
              alt={course.name}
            />
          ) : (
            <Placeholder className="min-h-32 rounded-md bg-gray-200" image />
          )}
          <div className="hidden duration-300 group-hover:block">
            <IconButton
              className="absolute right-[0.15rem] top-[0.75rem] !border-gray-100 bg-white p-5 opacity-75 hover:bg-indigo-100"
              onClick={copyToClipboard}
              sx={{ border: 1 }}
            >
              <Tooltip title="Share Course">
                <ShareIcon className="h-6 w-6 pr-1 text-slate-500" />
              </Tooltip>
            </IconButton>
          </div>
          <div className="space-between mx-3 my-2 flex min-h-10 flex-1">
            <div
              className={
                'my-2 mr-3 min-h-5 w-44 overflow-hidden text-ellipsis text-sm'
              }
              data-intercom-target={`course-id-${course.id}`}
              data-testid={`learner-course-id-${course.id}`}
            >
              {course.name.trim()}
            </div>
            <div className="flex h-full items-center justify-center">
              {!currentUser.isClientAlias &&
                (percentage < 100 ? (
                  <CircularProgressWithLabel
                    text={percentage === 0 ? 'start' : null}
                    value={percentage}
                  />
                ) : (
                  // if all the tasks have been completed
                  <CheckCircleIcon
                    className={'h-10 w-10 text-green-500'}
                    data-testid="check-circle-icon"
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseItem
