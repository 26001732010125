const DoubleVerticalDotsIcon = () => {
  return (
    <svg
      className="ml-1 mt-3"
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1" cy="1" r="1" fill="#1F2937"></circle>
      <circle cx="6" cy="1" r="1" fill="#1F2937"></circle>
      <circle cx="1" cy="5" r="1" fill="#1F2937"></circle>
      <circle cx="1" cy="9" r="1" fill="#1F2937"></circle>
      <circle cx="6" cy="5" r="1" fill="#1F2937"></circle>
      <circle cx="6" cy="9" r="1" fill="#1F2937"></circle>
    </svg>
  )
}

export default DoubleVerticalDotsIcon
