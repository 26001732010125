import { FC } from 'react'

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@mui/material'

import { Link, routes } from '@redwoodjs/router'

import { GoalListCellItemChildType } from 'src/components/Goals/interfaces'
import { useAuth } from 'src/Providers'
interface CardTitleProps {
  milestone: GoalListCellItemChildType
  hasGroupsOrMembers: boolean
}
const CardTitle: FC<CardTitleProps> = ({ milestone, hasGroupsOrMembers }) => {
  const { currentUser } = useAuth()
  const milestoneIsLearnerCourse = milestone?.attachedLearnerItemId !== null
  const milestoneTypeLabel = milestoneIsLearnerCourse ? 'Learner' : 'Milestone'

  const currentUserId = currentUser.userData.id

  return (
    <div className="flex items-center gap-2 font-sans">
      <div className="flex min-w-[0px] grow flex-col">
        <h1 className="flex text-xs font-medium leading-4 tracking-wide text-indigo-600">
          <span className={hasGroupsOrMembers ? 'flex pr-2' : 'flex'}>
            <span className="text-[10px] uppercase text-gray-500">
              <Tooltip
                title={
                  <div>
                    <div>
                      Created by{' '}
                      {currentUserId === milestone.createdBy
                        ? 'you'
                        : milestone.createdByUserName}{' '}
                      on {new Date(milestone.createdAt).toLocaleString()}
                    </div>
                    <div>
                      Update by{' '}
                      {currentUserId === milestone.updatedBy
                        ? 'you'
                        : milestone.updatedByUserName}{' '}
                      on {new Date(milestone.updatedAt).toLocaleString()}
                    </div>
                  </div>
                }
                arrow
                placement="right"
              >
                <span>{milestoneTypeLabel}</span>
              </Tooltip>
            </span>
            {milestone.attachedLearnerItemId && (
              <Link
                className="ml-1 items-center text-xs text-indigo-600 underline"
                title="Open Course"
                to={routes.learnerCourse({
                  id: milestone.attachedLearnerItemId,
                })}
              >
                <ArrowTopRightOnSquareIcon className="ml-1 inline h-4 w-4" />
              </Link>
            )}
          </span>
        </h1>
        <p
          data-testid={`igm-milestone-title-${milestone.goalTitle}`}
          className="break-words text-sm font-medium text-gray-800"
        >
          {milestone?.goalTitle}
        </p>
      </div>
    </div>
  )
}
export default CardTitle
