import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { Autocomplete, TextField } from '@mui/material'

import { WorkflowsClient } from './LayoutImporterCell'

interface ClientSelectorListProps {
  clients: WorkflowsClient[]
  clientToCopyTo: WorkflowsClient
  setClientToCopyTo: Dispatch<SetStateAction<WorkflowsClient>>
  importLoading: boolean
}

type ClientOption = {
  label: string
  value: number
}

const ClientSelectorList: FC<ClientSelectorListProps> = ({
  clients,
  clientToCopyTo,
  setClientToCopyTo,
  importLoading,
}) => {
  const clientOptions = clients
    ?.map((client) => {
      return { label: client?.name ?? 'Unknown Client', value: client?.id }
    })
    .sort((a, b) => {
      return a?.label?.toLocaleLowerCase() > b?.label?.toLocaleLowerCase()
        ? 1
        : -1
    })

  const [selectedClient, setSelectedClient] = useState<ClientOption>(null)
  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    if (selectedClient) {
      const matchingClient =
        clients?.find((client) => client?.id === selectedClient?.value) ?? null

      setClientToCopyTo(matchingClient)
    } else {
      setClientToCopyTo(null)
    }
  }, [selectedClient])

  useEffect(() => {
    if (!clientToCopyTo) setSelectedClient(null)
  }, [clientToCopyTo])

  return (
    <div className="flex flex-col gap-2">
      <p className="pl-2 text-xl text-gray-500">2. Copy to Client</p>
      <div className="flex flex-wrap items-center gap-6 rounded border bg-white p-6">
        <p className="w-[200px] text-gray-500">Select a Client</p>
        <div className="min-w-[300px] flex-grow">
          <Autocomplete
            disabled={importLoading}
            value={selectedClient}
            onChange={(_event: any, newValue: ClientOption | null) => {
              setSelectedClient(newValue)
            }}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            id="select-a-client"
            options={clientOptions}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Client" />}
          />
        </div>
      </div>
    </div>
  )
}

export default ClientSelectorList
