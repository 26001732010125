import React, { useState, FC } from 'react'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material'
import type {
  UpdateMembershipNotificationRecipients,
  UpdateMembershipNotificationRecipientsVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button'
import type { FilterByOption } from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'
import { QUERY as FindSettingsSentimentAnalysisQuery } from 'src/components/Settings/SentimentAnalysis/SettingsSentimentAnalysisCell/SettingsSentimentAnalysisCell'
import useAnalytics from 'src/lib/hooks/useAnalytics'

import type { Membership } from '../SettingsSentimentAnalysisCell'

interface MemberManagementNotificationRecipientModalProps {
  memberships: Membership[]
  isDialogOpen: boolean
  setIsDialogOpen: (value: boolean) => void
  selectedRowId: number | null
  notificationRecipients: { id: number; name: string }[]
  setNotificationRecipients: (value: { id: number; name: string }[]) => void
}

const MemberManagementNotificationRecipientModal: FC<
  MemberManagementNotificationRecipientModalProps
> = ({
  memberships,
  isDialogOpen,
  setIsDialogOpen,
  selectedRowId,
  notificationRecipients,
  setNotificationRecipients,
}) => {
  const [saving, setSaving] = useState(false)
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const [inputValue, setInputValue] = useState('')
  // initialize google analytics
  const { trackEvent } = useAnalytics()

  const selectedMembership = memberships.find(
    (membership) => membership.id === selectedRowId,
  )

  const filterByOptions = memberships.map((membership) => ({
    id: membership.id,
    name: membership.user.name,
  }))

  const handleRecipientSave = () => {
    setSaving(true)
    handleUpdateMembershipRecipients(notificationRecipients)
  }

  const sendAnalyticsEvent = (eventName: string, analyticsLabel: string) => {
    trackEvent('Sentiment Analysis', eventName, {
      filterValue: analyticsLabel,
    })
  }

  const UPDATE_MEMBERSHIP_NOTIFICATION_RECIPIENTS = gql`
    mutation UpdateMembershipNotificationRecipients(
      $recipientMembershipIds: [Int!]!
      $sentimentMembershipId: Int!
    ) {
      updateSentimentMembershipNotificationRecipients(
        recipientMembershipIds: $recipientMembershipIds
        sentimentMembershipId: $sentimentMembershipId
      )
    }
  `

  const handleUpdateMembershipRecipients = (recipients) => {
    const recipientMembershipIds = recipients.map((recipient) => recipient.id)

    if (selectedRowId) {
      updateSentimentMembershipNotificationRecipients({
        variables: {
          recipientMembershipIds,
          sentimentMembershipId: selectedRowId,
        },
      })
    }
  }

  const [updateSentimentMembershipNotificationRecipients] = useMutation<
    UpdateMembershipNotificationRecipients,
    UpdateMembershipNotificationRecipientsVariables
  >(UPDATE_MEMBERSHIP_NOTIFICATION_RECIPIENTS, {
    onCompleted: () => {
      setSaving(false)
      setIsDialogOpen(false)
      toast.success('Notification recipients updated successfully.')
      sendAnalyticsEvent('Notification Membership Recipients ', 'save')
    },
    onError: (error) => {
      toast.error(error?.message || 'An unexpected error occurred.', {
        duration: 5000,
      })
      setSaving(false)
    },
    refetchQueries: [FindSettingsSentimentAnalysisQuery],
    awaitRefetchQueries: true,
  })

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          maxHeight: 600,
        },
      }}
    >
      <DialogTitle id="confirm-dialog-title">
        Confirm Recipient Change For {selectedMembership?.user?.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          Select the notification recipients for this user
        </DialogContentText>
        <div className="pb-4 pt-6">
          <Autocomplete
            limitTags={1}
            fullWidth={false}
            className="min-w-[300px]"
            size="small"
            multiple
            disableCloseOnSelect
            disableClearable
            id="sa-default-notification-recipients-select"
            options={filterByOptions}
            getOptionLabel={(option: FilterByOption) => option.name}
            value={notificationRecipients}
            onChange={(
              _event: React.ChangeEvent,
              newValue: FilterByOption[],
            ) => {
              setNotificationRecipients(newValue)
              let analyticsLabel: string
              if (newValue.length > 0) {
                analyticsLabel = newValue
                  .map((option) => option.name)
                  .join(', ')
              } else {
                analyticsLabel = 'none selected'
              }
              sendAnalyticsEvent(
                'Notification Recipients Selected',
                analyticsLabel,
              )
            }}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Recipients"
                className="capitalize"
                placeholder={
                  notificationRecipients.length === 0 ? 'None Selected' : ''
                }
                slotProps={{
                  input: {
                    ...params.InputProps,
                    startAdornment: notificationRecipients.length > 0 && (
                      <InputAdornment
                        position="start"
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          marginLeft: '8px',
                          color: '#111827',
                        }}
                      >
                        <span>{`${notificationRecipients.length} Selected`}</span>
                      </InputAdornment>
                    ),
                  },
                  inputLabel: { shrink: true },
                }}
              />
            )}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props} key={option.id} className="capitalize">
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )
            }}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            setIsDialogOpen(false)
          }}
          variant="outlined"
          fullWidth={false}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleRecipientSave()}
          color="primary"
          fullWidth={false}
          loading={saving}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MemberManagementNotificationRecipientModal
