import React from 'react'

import 'reactflow/dist/style.css'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'
import { toPng } from 'html-to-image'
import { getRectOfNodes, getTransformForBounds, useReactFlow } from 'reactflow'

import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'

const InformerOrgChartDownloadButton = ({ orgChartName }) => {
  const { getNodes } = useReactFlow() // The current nodes in the chart
  const [loading, setLoading] = React.useState(false)

  // Function that performs the download click
  const downloadImage = (dataUrl) => {
    const a = document.createElement('a')
    a.setAttribute('download', `${orgChartName}.png`)
    a.setAttribute('href', dataUrl)
    a.click()
  }

  // Allows the chart to be downloaded. Created as a separate component to ensure we can use the react flow (const { getNodes } = useReactFlow())
  const DownloadButton = () => {
    setLoading(true)
    const canvas = document.querySelector('.react-flow__renderer') // Get the chart canvas around where the chart is

    // Default to basic size if we can't get the canvas size
    const width = canvas ? canvas.clientWidth : 1024
    const height = canvas ? canvas.clientHeight : 768

    // The bounds of the nodes in the chart, we can use this to get the size of the chart
    const nodesBounds = getRectOfNodes(getNodes())
    const maxWidth = nodesBounds.width
    const maxHeight = nodesBounds.height

    // Because of the way the chart is rendered, we need to scale the chart to get the correct size otherwise big charts will be blurry
    const scale = Math.ceil(Math.max(maxWidth / width, maxHeight / height))

    // Get the transform for the chart that will allow us to scale the chart to the correct size
    const transform = getTransformForBounds(
      nodesBounds,
      width * scale,
      height * scale,
      0,
      2,
    )

    // Fancy stuff with html-to-image to create a high res image of the chart
    toPng(document.querySelector('.react-flow__viewport') as HTMLElement, {
      backgroundColor: 'white', // Changed to white background
      width: width * scale,
      height: height * scale,
      style: {
        width: `${width * scale}px`, // Convert number to string and append 'px'
        height: `${height * scale}px`, // Convert number to string and append 'px'
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
        transformOrigin: 'top left',
      },
      pixelRatio: 3,
    })
      .then(downloadImage)
      .then(() => {
        setLoading(false)
        toast.success('Downloading...')
      })
      .catch((err) => {
        setLoading(false)
        toast.error('Error creating high resolution image', err)
      })
  }

  return (
    <Tooltip title={'Download Plan'}>
      <div>
        <Button
          fullWidth={false}
          variant="text"
          className="min-w-[0] rounded-full p-2 text-gray-500"
          loading={loading}
          onClick={() => DownloadButton()}
        >
          <ArrowDownTrayIcon className="h-5 w-5" />
        </Button>
      </div>
    </Tooltip>
  )
}

export default InformerOrgChartDownloadButton
