import { FC, useEffect, useState } from 'react'

import { Avatar } from '@mui/material'
import dayjs from 'dayjs'
import {
  UpdateClientIntegrationInput,
  UpdateClientIntegrationMutation,
  UpdateClientIntegrationMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useConfirm } from 'src/lib/hooks/Confirmation'
import { useAuth } from 'src/Providers'

import Button from '../Library/Button/Button'

import { ClientIntegration } from './CustomerIntegrationsCell'
import EditCustomerIntegrationsForm from './EditCustomerIntegrationsForm'
import { IntegrationLogo, StatusLabel } from './IntegrationCard'
import { integrationOptions } from './integrations'

export const UPDATE_CLIENT_INTEGRATION_MUTATION = gql`
  mutation UpdateClientIntegrationMutation(
    $id: Int!
    $input: UpdateClientIntegrationInput!
  ) {
    updateClientIntegration(id: $id, input: $input) {
      id
    }
  }
`

interface CustomerIntegrationsSettingsPanelProps {
  integration: ClientIntegration
}

const CustomerIntegrationsSettingsPanel: FC<
  CustomerIntegrationsSettingsPanelProps
> = ({ integration }) => {
  const { currentUser } = useAuth()

  const integrationConfig =
    integrationOptions[integration?.integrationType] ?? null

  const clientLogomarkUrl = currentUser?.parentData?.logomarkUrl
  const clientName = currentUser?.parentData?.name

  const [statusLoading, setStatusLoading] = useState<boolean>(false)

  const confirmDeactivation = useConfirm()

  const handleIntegrationStatusChange = async (
    newStatus: boolean,
    integrationId: number,
  ) => {
    setStatusLoading(true)

    const data: UpdateClientIntegrationInput = { active: newStatus }

    await updateClientIntegrationStatus({
      variables: { id: integrationId, input: data },
    })
  }

  const [updateClientIntegrationStatus] = useMutation<
    UpdateClientIntegrationMutation,
    UpdateClientIntegrationMutationVariables
  >(UPDATE_CLIENT_INTEGRATION_MUTATION, {
    onCompleted: () => {
      toast.success('Integration updated')
    },
    onError: () => {
      toast.error('There was an error updating your integration.', {
        duration: 5000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['CustomerIntegrationsQuery'],
    awaitRefetchQueries: true,
  })

  useEffect(() => {
    setStatusLoading(false)
  }, [integration])

  return (
    <div className="p-10 px-8">
      <div className="mb-6 flex items-center gap-4 border-b pb-6">
        <IntegrationLogo integrationType={integration?.integrationType} />

        <div className="flex flex-col gap-2">
          <p>{integration?.integrationName}</p>
          <StatusLabel active={integration?.active} />
        </div>
      </div>
      <div className="mb-6 flex items-center justify-between gap-4 border-b pb-6">
        <div className="">
          <p className="text-sm">Created Date</p>
          <p className="text-sm text-gray-400">
            {dayjs(integration?.createdAt).format('DD/MM/YYYY')}
          </p>
        </div>
        <div className="flex gap-2">
          <Avatar
            src={integration?.createdByUser?.avatarUrl ?? clientLogomarkUrl}
          />
          <div>
            <p className="text-sm">Created By</p>
            <p className="text-sm text-gray-400">
              {integration?.createdByUser?.name ?? clientName}
            </p>
          </div>
        </div>
      </div>
      <div className="mb-6 flex justify-between gap-4 border-b pb-6">
        <ul className="text-sm">
          <p className="text-sm">Integrates with:</p>
          <div className="mt-2 flex flex-wrap gap-2">
            {integrationConfig?.features.map((feature, index) => (
              <li
                key={index}
                className="rounded bg-gray-100 px-3 py-1 text-xs text-gray-500"
              >
                {feature}
              </li>
            ))}
          </div>
        </ul>
      </div>
      <div className="mb-6 flex items-center justify-between gap-4 border-b pb-6">
        <div>
          <p>Status</p>
          <p className="text-sm text-gray-400">
            {integration?.active
              ? 'Your integration is active.'
              : 'Your integration in not active.'}
          </p>
        </div>
        <Button
          loading={statusLoading}
          disabled={statusLoading}
          fullWidth={false}
          onClick={() => {
            confirmDeactivation({
              title: integration?.active
                ? 'Deactivate Integration'
                : 'Activate Integration',
              description: `Are you sure you want to ${
                integration?.active ? 'deactivate' : 'activate'
              } this integration?`,
            })
              .then(() => {
                handleIntegrationStatusChange(
                  !integration?.active,
                  integration?.id,
                )
              })
              .catch(() => {
                // Do Nothing - user cancelled
                // Empty catch avoids error logged to console
              })
          }}
          color={integration?.active ? 'error' : 'primary'}
          variant="outlined"
          className="py-0 pt-0.5 text-xs"
        >
          {integration?.active ? 'Deactivate' : 'Activate'}
        </Button>
      </div>
      {integration?.integrationType !== 'PME' && (
        <EditCustomerIntegrationsForm
          integration={integration}
          statusLoading={statusLoading}
        />
      )}
    </div>
  )
}

export default CustomerIntegrationsSettingsPanel
