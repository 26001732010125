import React, { FC, useState } from 'react'

import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Chip } from '@mui/material'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  useGridApiContext,
} from '@mui/x-data-grid'
import { calculateNpsScore } from 'api/src/common/utils'
import {
  NpsAssessor,
  NpsSubject,
  type NpsInsightDataQuery,
} from 'types/graphql'
import { useBoolean } from 'usehooks-ts'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Accordion from 'src/components/Library/Accordion/Accordion'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'
import SimpleForm from 'src/components/Library/SimpleForm/SimpleForm'
import UnderlinedValue from 'src/components/Library/UnderlinedValue/UnderlinedValue'
import Modal from 'src/components/Modal/Modal'
import SpeedMeterIcon from 'src/lib/icons/SpeedMeterIcon/SpeedMeterIcon'
import {
  CREATE_NPS_RESULT,
  DELETE_NPS_RESULT,
  UPDATE_NPS_RESULT,
} from 'src/lib/queries/Settings/NPS/NpsResults'
import { convertNumberToShortForm } from 'src/Util'

const headerStyle =
  'bg-gray-50 text-gray-500 text-xs font-medium tracking-wider leading-3 uppercase'

interface NpsResultProps {
  resultGroup: any
  subjects: NpsInsightDataQuery['subjects']
  assessors: NpsInsightDataQuery['assessors']
  createNpsSubject: (options: any) => any
  readOnly?: boolean
}

const countProperty = <T,>(array: T[], propertyName: string) => {
  let total = 0

  for (const item of array) {
    total += item[propertyName]
  }

  return total
}

const ResultSummary = ({ results }) => {
  const promoterCount = countProperty(results, 'promoterCount')
  const detractorCount = countProperty(results, 'detractorCount')
  const neutralCount = countProperty(results, 'neutralCount')
  return (
    <div className={'mx-2 flex flex-1 items-center justify-end'}>
      <Stack direction={'row'} spacing={2}>
        <Chip
          icon={<SpeedMeterIcon className="h-6 w-6 fill-gray-500" />}
          size={'small'}
          label={calculateNpsScore(promoterCount, detractorCount, neutralCount)}
        />
        <UnderlinedValue
          value={convertNumberToShortForm(detractorCount)}
          color={'red'}
          title={detractorCount}
        />
        <UnderlinedValue
          value={convertNumberToShortForm(neutralCount)}
          color={'yellow'}
          title={neutralCount}
        />
        <UnderlinedValue
          value={convertNumberToShortForm(promoterCount)}
          color={'green'}
          title={promoterCount}
        />
      </Stack>
    </div>
  )
}

const NpsResult: FC<NpsResultProps> = ({
  resultGroup,
  subjects,
  assessors,
  createNpsSubject,
  readOnly,
}) => {
  const newResultModal = useBoolean()
  const [recordLoading, setRecordLoading] = useState(false)
  const [newAssessor, setNewAssessor] = useState(null)
  const [resultsMap, setResultsMap] = useState(
    new Map([
      ['Detractors', '0'],
      ['Passives', '0'],
      ['Promoters', '0'],
    ]),
  )
  const [selectedAssessorIds, setSelectedAssessorIds] = useState(
    resultGroup.results.map((result) => result.assessor.id),
  )

  const updateMap = (key, value) => {
    setResultsMap(new Map(resultsMap.set(key, value)))
  }

  const [createNpsResult] = useMutation(CREATE_NPS_RESULT, {
    onCompleted: () => {
      toast.success('Result Added', {
        duration: 2000,
      })
    },
    refetchQueries: ['NpsResultsQuery', 'NpsCampaignList'],
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
      })
    },
  })

  const [updateNpsResult] = useMutation(UPDATE_NPS_RESULT, {
    onCompleted: () => {
      toast.success('Result Updated', {
        duration: 2000,
      })
    },
    refetchQueries: ['NpsResultsQuery', 'NpsCampaignList'],
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
      })
    },
  })

  const [deleteNpsResult] = useMutation(DELETE_NPS_RESULT, {
    onCompleted: () => {
      toast.success('Result Deleted', {
        duration: 2000,
      })
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
      })
    },
    refetchQueries: ['NpsResultsQuery', 'NpsCampaignList'],
  })

  const SubjectEditCell = (props: GridRenderCellParams) => {
    const { id, value, field } = props
    const apiRef = useGridApiContext()
    const [npsSubject, setNpsSubject] = useState(
      subjects?.find((subject) => value === subject.id) || null,
    )
    const [subjectInput, setSubjectInput] = useState('')

    const handleChange = async (value) => {
      if (value !== null) {
        if (value.__typename === 'NpsSubject') {
          await apiRef.current.setEditCellValue({
            id,
            field,
            value: value.id,
          })
        } else if (value.__typename === 'Membership') {
          await createNpsSubject({
            variables: {
              input: {
                membershipId: value.memberId,
              },
            },
          }).then(async ({ data }) => {
            await apiRef.current.setEditCellValue({
              id,
              field,
              value: data.createNpsSubject.id,
            })
            await apiRef.current.stopRowEditMode({
              id,
            })
          })
        }
      }
    }

    return (
      <Autocomplete
        getOptionLabel={(option) => option.name || option.membership?.user.name}
        renderInput={(params) => <TextField {...params} label="Subject" />}
        options={subjects}
        value={npsSubject}
        inputValue={subjectInput}
        onInputChange={(_, newInputValue) => {
          setSubjectInput(newInputValue)
        }}
        fullWidth
        onChange={(_, newValue) => {
          setNpsSubject(newValue)
          handleChange(newValue)
        }}
      />
    )
  }
  const AssessorEditInputCell = (props: GridRenderCellParams) => {
    const { id, value, field } = props
    const apiRef = useGridApiContext()
    const [npsAssessor, setNpsAssessor] = useState(
      assessors?.find((assessor) => value === assessor.id) || null,
    )
    const [assessorInput, setAssessorsInput] = useState('')
    const handleChange = async (value) => {
      if (value !== null) {
        await apiRef.current.setEditCellValue({
          id,
          field,
          value: value.id,
        })
      }
      apiRef.current.stopCellEditMode({ id, field })
    }

    return (
      <Autocomplete
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Assessor" />}
        options={assessors || []}
        value={npsAssessor}
        inputValue={assessorInput}
        onInputChange={(_, newInputValue) => {
          setAssessorsInput(newInputValue)
        }}
        fullWidth
        onChange={(_, newValue) => {
          setNpsAssessor(newValue)
          handleChange(newValue)
        }}
      />
    )
  }

  const processRowUpdate = React.useCallback(
    async (newRow, _oldRow) => {
      await updateNpsResult({
        variables: {
          id: newRow.id,
          input: {
            campaignId: newRow.campaignId,
            subjectId: parseInt(newRow.subjectId),
            assessorId: parseInt(newRow.assessorId),
            promoterCount: parseInt(
              newRow.promoterCount > 0 ? newRow.promoterCount : 0,
            ),
            detractorCount: parseInt(
              newRow.detractorCount > 0 ? newRow.detractorCount : 0,
            ),
            neutralCount: parseInt(
              newRow.neutralCount > 0 ? newRow.neutralCount : 0,
            ),
          },
        },
      })
      return newRow
    },
    [updateNpsResult],
  )

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    toast.error(error.message)
  }, [])

  const resultColumns: GridColDef[] = [
    {
      field: 'assessorId',
      headerName: 'Assessor',
      headerClassName: headerStyle,
      flex: 1,
      editable: !readOnly,
      renderEditCell: (params) => <AssessorEditInputCell {...params} />,
      renderCell: (params) => {
        const rowAssessor = assessors?.find(
          (assessor) => assessor.id === params.row.assessor?.id,
        )
        return `${rowAssessor?.name || 'Select Assessor'}`
      },
      valueGetter: (_value, row) => {
        return `${row.assessor?.id || ''}`
      },
    },
    {
      field: 'subjectId',
      headerName: 'performer',
      headerClassName: headerStyle,
      flex: 1,
      editable: !readOnly,
      renderEditCell: (params) => <SubjectEditCell {...params} />,
      renderCell: (params) => {
        const rowSubject = subjects.find(
          (subject) => subject.id === params.row.subjectId,
        )

        return `${
          rowSubject?.name || rowSubject?.membership?.user.name || null
        }`
      },
      valueGetter: (_value, row) => {
        return `${row.subjectId}`
      },
    },
    {
      field: 'Score',
      headerName: 'Score',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      headerClassName: headerStyle,
      flex: 1,
      maxWidth: 150,
      valueGetter: (_value, row) => {
        return `${row.score || 0}`
      },
      renderCell: (params) => (
        <>
          <Chip
            icon={<SpeedMeterIcon className="h-6 w-6 fill-gray-700" />}
            size={'small'}
            label={params.value}
          />
        </>
      ),
    },
    {
      field: 'detractorCount',
      headerName: 'Detractors',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      headerClassName: headerStyle,
      editable: !readOnly,
      flex: 1,
      maxWidth: 150,
      valueGetter: (_value, row) => {
        return `${row.detractorCount || 0}`
      },
      renderCell: (params) => (
        <>
          <span className="mx-2 mb-0.5 h-2 w-2 rounded-full bg-red-500"></span>
          {params.value}
        </>
      ),
    },
    {
      field: 'neutralCount',
      headerName: 'Passives',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      headerClassName: headerStyle,
      editable: !readOnly,
      maxWidth: 150,
      flex: 1,
      valueGetter: (_value, row) => {
        return `${row.neutralCount || 0}`
      },
      renderCell: (params) => (
        <>
          <span className="mx-2 mb-0.5 h-2 w-2 rounded-full bg-yellow-500"></span>
          {params.value}
        </>
      ),
    },
    {
      field: 'promoterCount',
      headerName: 'Promoters',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      headerClassName: headerStyle,
      editable: !readOnly,
      flex: 1,
      maxWidth: 150,
      valueGetter: (_value, row) => {
        return `${row.promoterCount || 0}`
      },
      renderCell: (params) => (
        <>
          <span className="mx-2 mb-0.5 h-2 w-2 rounded-full bg-green-500"></span>
          {params.value}
        </>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 40,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<TrashIcon className={'h-4 w-4'} />}
          label="Delete"
          key={params.id}
          onClick={async () =>
            await deleteNpsResult({
              variables: {
                id: params.id,
              },
            })
          }
        />,
      ],
    },
  ]

  const handleNewResult = () => {
    if (!newAssessor) {
      toast.error('Please select a Assessor')
      return
    }
    const results = [...resultsMap.keys()].map((key) => {
      if (
        isNaN(parseInt(String(resultsMap.get(key)))) ||
        resultsMap.get(key) === ''
      ) {
        toast.error(`Please enter a Number for ${key}`)
        return undefined
      } else {
        return parseInt(String(resultsMap.get(key)))
      }
    })

    if (results.includes(undefined)) {
      return
    }

    setRecordLoading(true)

    createNpsResult({
      variables: {
        input: {
          campaignId: resultGroup.results[0].campaignId,
          subjectId: resultGroup.subject.id,
          assessorId: newAssessor.id,
          promoterCount: results[2],
          detractorCount: results[0],
          neutralCount: results[1],
        },
      },
    }).then(() => {
      newResultModal.toggle()
      setRecordLoading(false)
      setResultsMap(
        new Map([
          ['Detractors', '0'],
          ['Passives', '0'],
          ['Promoters', '0'],
        ]),
      )
    })
  }

  return (
    <>
      <Accordion
        key={resultGroup.subject.id}
        title={
          resultGroup.subject?.name ||
          resultGroup.subject?.membership?.user.name ||
          'Undefined Subject'
        }
        borderstyle={'!border-b !border-gray-200'}
        customstyle={'bg-transparent hover:bg-indigo-50 rounded-md'}
        summary={<ResultSummary results={resultGroup.results} />}
        titleDataTestId={`nps-result-accordion-title-${
          resultGroup.subject?.name ||
          resultGroup.subject?.membership?.user.name
        }`}
      >
        {/* need to get the results of the campaign and group them by subject */}
        <div className="flex flex-col">
          <DataGrid
            columns={resultColumns}
            rows={resultGroup.results}
            hideFooter
            rowHeight={35}
            columnHeaderHeight={25}
            disableColumnMenu
            disableColumnSelector
            autoHeight={true}
            editMode={'row'}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  actions: !readOnly,
                  subjectId: !readOnly,
                },
              },
            }}
          />
        </div>
        {!readOnly && (
          <Button
            variant={'text'}
            startIcon={<PlusCircleIcon className={'h-6 w-6 stroke-2'} />}
            fullWidth={false}
            disabled={selectedAssessorIds.length === assessors.length}
            onClick={() => {
              newResultModal.toggle()
            }}
          >
            Add Result To{' '}
            {resultGroup.subject?.name ||
              resultGroup.subject?.membership?.user.name}
          </Button>
        )}
      </Accordion>
      <div>
        {newResultModal.value && (
          <Modal
            onClose={newResultModal.toggle}
            open={newResultModal.value}
            footerVisible
            dialogClassName={'!p-0 max-w-[720px]'}
            className=""
            title={`Add Result For ${
              resultGroup.subject?.name ||
              resultGroup.subject?.membership?.user.name
            }`}
            onConfirm={handleNewResult}
            onCancel={newResultModal.toggle}
            loading={recordLoading}
          >
            <div className={'mb-2 flex w-full items-center justify-center'}>
              <div className={'w-1/2'}>
                <Stack spacing={2}>
                  <Autocomplete
                    getOptionLabel={(option: NpsAssessor) => option?.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Assessor" />
                    )}
                    options={assessors.filter(
                      (assessor) => !selectedAssessorIds.includes(assessor.id),
                    )} // filter out already selected assessors
                    onChange={(_, value) => {
                      setNewAssessor(value)
                    }}
                  />
                  <SimpleForm records={resultsMap} handleChange={updateMap} />
                </Stack>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  )
}

export default NpsResult
