import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { PlusIcon } from '@heroicons/react/24/solid'
import { Box } from '@mui/material'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  GetBaserowTableFields,
  GetBaserowTableFieldsVariables,
} from 'types/graphql'
import { v4 as uuidv4 } from 'uuid'

import { toast } from '@redwoodjs/web/toast'

import { validateRequirements } from 'src/components/Automations/AutomationEditForms/utils'
import AutomationFetchLoading from 'src/components/Automations/AutomationFetchLoading'
import { AutomationBaserowData } from 'src/components/Automations/AutomationLayout'
import RequirementComponent from 'src/components/Automations/AutomationRequirements/RequirementComponent'
import RequirementGroupComponent from 'src/components/Automations/AutomationRequirements/RequirementGroupComponent'
import { GET_BASEROW_FIELDS } from 'src/components/Automations/queries'
import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'
import Button from 'src/components/Library/Button/Button'

import { BaserowField, RequirementGroupType } from '../utils'

interface LinkedFieldFiltersProps {
  setRequirementsInvalid: Dispatch<SetStateAction<boolean>>
  linkedFieldListOfFields: BaserowField[]
  loadingTableFields: boolean
  advancedLogicFilterRequirements: RequirementGroupType[]
  setAdvancedLogicFilterRequirements: Dispatch<
    SetStateAction<RequirementGroupType[]>
  >
  selectedAutomationBaserowData: AutomationBaserowData
}

const LinkedFieldFilters: FC<LinkedFieldFiltersProps> = ({
  setRequirementsInvalid,
  linkedFieldListOfFields,
  loadingTableFields,
  advancedLogicFilterRequirements,
  setAdvancedLogicFilterRequirements,
  selectedAutomationBaserowData,
}) => {
  // Filter Requirements
  const [forceUpdateUuid, setForceUpdateUuid] = useState<string>(uuidv4())

  const [fieldsToMatch, setFieldsToMatch] = useState<BaserowField[]>([])

  const addNewRequirementGroup = () => {
    const newRequirementGroup: RequirementGroupType = {
      rule: 'AND',
      requirements: [],
      id: uuidv4(),
    }

    setAdvancedLogicFilterRequirements([
      ...advancedLogicFilterRequirements,
      newRequirementGroup,
    ])
  }

  const handleGroupUpdate = (index: number, updatedGroup) => {
    const updatedRequirements = [...advancedLogicFilterRequirements]

    if (updatedGroup === null) {
      updatedRequirements.splice(index, 1)
    } else {
      updatedGroup.requirements = updatedGroup.requirements.map((req) => ({
        ...req,
      }))
      updatedRequirements[index] = updatedGroup
    }

    setAdvancedLogicFilterRequirements(updatedRequirements)
  }

  const handleRequirementUpdate = (index: number, updatedReq) => {
    const updatedRequirements = [...advancedLogicFilterRequirements]
    updatedRequirements[index] = updatedReq
    setAdvancedLogicFilterRequirements(updatedRequirements)
  }

  const handleUpdateRequirementDnD = (draggedItemId, destinationGroupId) => {
    const updatedRequirements = [...advancedLogicFilterRequirements]

    const draggedRequirement = findAndRemoveRequirement(
      updatedRequirements,
      draggedItemId,
    )

    const destinationGroup = findGroupById(
      updatedRequirements,
      destinationGroupId,
    )
    if (destinationGroup && draggedRequirement) {
      destinationGroup.requirements.push(draggedRequirement)
    }

    setAdvancedLogicFilterRequirements(updatedRequirements)
  }

  const findAndRemoveRequirement = (groups, requirementId) => {
    for (const group of groups) {
      const index = group?.requirements?.findIndex(
        (req) => req.id === requirementId,
      )
      if (index > -1) {
        return group?.requirements?.splice(index, 1)[0]
      }
      if (group?.requirements) {
        const foundReq = findAndRemoveRequirement(
          group?.requirements,
          requirementId,
        )
        if (foundReq) return foundReq
      }
    }
    return null
  }

  const findGroupById = (groups, groupId) => {
    for (const group of groups) {
      if (group.id === groupId) return group
      if (group.requirements) {
        const foundGroup = findGroupById(group.requirements, groupId)
        if (foundGroup) return foundGroup
      }
    }
    return null
  }

  const getBaserowTableData = async (workspaceId: string, tableId: string) => {
    const { data } = await GetTableFields({
      variables: {
        input: {
          tableId: tableId,
          workspaceId: workspaceId,
        },
      },
    })
    return data
  }

  const [GetTableFields] = useLazyQuery<
    GetBaserowTableFields,
    GetBaserowTableFieldsVariables
  >(GET_BASEROW_FIELDS, {
    onCompleted: (data) => {
      return data.getBaserowTableFields
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
      })
      return []
    },
  })

  useEffect(() => {
    const getMatchingFieldData = async () => {
      const data = await getBaserowTableData(
        selectedAutomationBaserowData.workspace.id.toString(),
        selectedAutomationBaserowData.table.id.toString(),
      )
      setFieldsToMatch(data.getBaserowTableFields)
    }
    if (
      selectedAutomationBaserowData?.table?.id &&
      selectedAutomationBaserowData?.workspace?.id
    ) {
      getMatchingFieldData()
    }
  }, [selectedAutomationBaserowData])

  // THIS causes re-rendering out the wazoo - but also REQUIRED until we can rewrite this whole bit. I don't have time right now :(
  // But if anyone asks why the UI flashes ... its this.
  useEffect(() => {
    setForceUpdateUuid(uuidv4())

    const checkRequirements = validateRequirements(
      advancedLogicFilterRequirements,
    )
    if (checkRequirements.success) {
      setRequirementsInvalid(false)
    } else {
      setRequirementsInvalid(true)
    }
  }, [advancedLogicFilterRequirements])

  return (
    <FormInputRow label="Filter Linked Records">
      <Box className={'border2'}>
        {loadingTableFields && (
          <div className="pt-5">
            <AutomationFetchLoading text="Fetching Table Fields" />
          </div>
        )}

        {!loadingTableFields && (
          <div>
            <>
              <div className="mb-4 flex items-center justify-between gap-4">
                <div>
                  {advancedLogicFilterRequirements?.length === 0 && (
                    <p className="text-sm font-light text-gray-400">
                      None set.
                    </p>
                  )}
                </div>

                {advancedLogicFilterRequirements?.length === 0 && (
                  <Button
                    fullWidth={false}
                    variant="outlined"
                    className="min-w-[0] px-4 hover:bg-indigo-100"
                    onClick={addNewRequirementGroup}
                    startIcon={<PlusIcon className="h-5 w-5" />}
                  >
                    <span className="pt-0.5">Add Requirement</span>
                  </Button>
                )}
              </div>
              {advancedLogicFilterRequirements?.length > 0 && (
                <DndProvider backend={HTML5Backend}>
                  <div key={forceUpdateUuid}>
                    {advancedLogicFilterRequirements?.map(
                      (requirement, index: number) =>
                        'requirements' in requirement ? (
                          <RequirementGroupComponent
                            key={index}
                            availableFields={linkedFieldListOfFields}
                            group={requirement}
                            onUpdate={(updatedGroup) =>
                              handleGroupUpdate(index, updatedGroup)
                            }
                            handleUpdateRequirementDnD={
                              handleUpdateRequirementDnD
                            }
                            fieldsToMatch={fieldsToMatch}
                            topLevel
                          />
                        ) : (
                          <RequirementComponent
                            availableFields={linkedFieldListOfFields}
                            key={index}
                            requirement={requirement}
                            onUpdate={(updatedReq) =>
                              handleRequirementUpdate(index, updatedReq)
                            }
                            fieldsToMatch={fieldsToMatch}
                          />
                        ),
                    )}
                  </div>
                </DndProvider>
              )}
            </>
          </div>
        )}
      </Box>
    </FormInputRow>
  )
}

export default LinkedFieldFilters
