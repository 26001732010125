import { FC } from 'react'

import { PencilIcon } from '@heroicons/react/24/outline'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'

import useAnalytics from 'src/lib/hooks/useAnalytics'
import { SelectedSection } from 'src/pages/SettingsClientToolsPage/SettingsClientToolsPage'

import Button from '../Library/Button/Button'

import DoubleVerticalDotsIcon from './DoubleVerticalDotsIcon'
import { InformerSection } from './SettingsLayoutSectionCell/SettingsLayoutSectionCell'

type Props = {
  onDragEnd: (result: DropResult) => void
  sectionItems: InformerSection[]
  selectedSection: SelectedSection
  setSelectedSection: (section: SelectedSection) => void
  setEditingSection: (section: InformerSection) => void
  openEditSectionModal: () => void
}

const grid = 8

const getItemStyle = (isDragging: boolean, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  borderRadius: '5px',
  // background: 'white',
  background: isDragging ? '#e5e7eb' : 'white',
  ...draggableStyle,
})

const SettingsLayoutDragDrop: FC<Props> = ({
  onDragEnd,
  sectionItems,
  selectedSection,
  setSelectedSection,
  setEditingSection,
  openEditSectionModal,
}) => {
  const { trackEvent } = useAnalytics()
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" type="group">
        {(provided, _snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="section-drag-area !mt-0 p-2"
          >
            {sectionItems.map((item, index) => (
              <Draggable
                key={item.id.toString()}
                draggableId={item.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                    className={` ${
                      item.id === selectedSection?.id
                        ? '!bg-indigo-100'
                        : 'hover:!bg-gray-100'
                    } `}
                  >
                    <div className="group flex flex-row items-center justify-between">
                      <div className="flex grow flex-row items-start gap-1">
                        <div className="flex pt-2 opacity-0 group-hover:opacity-100">
                          <DoubleVerticalDotsIcon />
                        </div>

                        <Button
                          variant="text"
                          className={`flex min-h-10 min-w-[0] items-center justify-start gap-2 !bg-transparent text-left ${
                            item.id === selectedSection?.id
                              ? 'text-indigo-500'
                              : 'text-gray-600'
                          }`}
                          onClick={() =>
                            setSelectedSection({
                              id: item.id,
                              name: item.name,
                            })
                          }
                          buttonDataTestId={`section-${item.name}`}
                        >
                          <div className="flex items-center justify-between gap-3">
                            {item?.iconStorageObject?.downloadUrl &&
                              item.isPinnedToSidebar && (
                                <div
                                  className="h-6 w-6 rounded"
                                  style={{
                                    backgroundImage:
                                      'url(' +
                                      item?.iconStorageObject?.downloadUrl +
                                      ')',
                                    backgroundPosition: 'center center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                  }}
                                ></div>
                              )}
                            <span className="flex-1 shrink">{item.name}</span>
                          </div>
                        </Button>
                        <Button
                          variant="text"
                          fullWidth={false}
                          className={` ${
                            item.id === selectedSection?.id
                              ? 'text-indigo-400 hover:bg-indigo-200 hover:text-indigo-600'
                              : 'text-gray-400 hover:bg-gray-200 hover:text-gray-600'
                          } h-10 min-w-[0px] rounded-none !rounded-r px-3`}
                          onClick={() => {
                            setEditingSection(item)
                            openEditSectionModal()
                            trackEvent('Settings', 'edit section', {
                              section: item.name,
                            })
                          }}
                        >
                          <PencilIcon
                            className="h-full w-5"
                            title="Edit Category"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default SettingsLayoutDragDrop
